/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var WalletAddressType;
(function (WalletAddressType) {
    WalletAddressType["Ordinals"] = "ordinals";
    WalletAddressType["Payment"] = "payment";
})(WalletAddressType || (WalletAddressType = {}));
function WalletAddressTypeFromJSON(json) {
    return WalletAddressTypeFromJSONTyped(json);
}
function WalletAddressTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function WalletAddressTypeToJSON(value) {
    return value;
}

export { WalletAddressType, WalletAddressTypeFromJSON, WalletAddressTypeFromJSONTyped, WalletAddressTypeToJSON };
