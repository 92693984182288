import { useState, useMemo } from 'react';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/multi-wallet';
import { isEmailProviderEnabled } from '../../../../utils/functions/isEmailProviderEnabled/isEmailProviderEnabled.js';
import 'viem/chains';

const useEmailLoginState = (providers, user) => {
    const [loginWithEmail, _setLoginWithEmail] = useState(null);
    const reset = () => {
        _setLoginWithEmail(null);
    };
    const setLoginWithEmail = (value) => {
        _setLoginWithEmail(value);
    };
    const defaultLoginWithEmail = useMemo(() => (user ? false : isEmailProviderEnabled(providers)), [providers, user]);
    return [loginWithEmail !== null && loginWithEmail !== void 0 ? loginWithEmail : defaultLoginWithEmail, setLoginWithEmail, reset];
};

export { useEmailLoginState };
