import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { isPasskeyWalletConnector } from '@dynamic-labs/wallet-connector-core';
import { DynamicError } from '@dynamic-labs/utils';
import { completePasskeyRecovery as completePasskeyRecovery$1, initEmbeddedWalletSession as initEmbeddedWalletSession$1 } from '../../../../data/api.js';
import { decodeJwt } from '../../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import '../../../../context/ViewContext/ViewContext.js';
import 'react';
import '../../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import { EMBEDDED_WALLET_SESSION_SETTINGS } from '../../../../utils/constants/localStorage.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import { LocalStorage } from '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/sdk-api';
import '../../../../shared/consts/index.js';
import { findEmbeddedWalletFromVerifiedCredentials } from '../../../../utils/functions/findEmbeddedWalletFromVerifiedCredentials/findEmbeddedWalletFromVerifiedCredentials.js';
import '@dynamic-labs/multi-wallet';
import { removeElementById } from '../../../../utils/functions/removeElementById/removeElementById.js';
import 'viem/chains';
import { iframeElementId } from '../../PasskeyRecovery/constants.js';

const initEmbeddedWalletSession = ({ authenticatorType, authToken, iframeContainer, iframeElementId, environmentId, userEmail, wallet, sessionExpiration, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!authToken) {
        throw new DynamicError('Auth token is not defined');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const turnkeyRecoveryHandler = (_a = wallet.connector) === null || _a === void 0 ? void 0 : _a.getAuthenticatorHandler();
    if (authenticatorType === 'passkey' &&
        turnkeyRecoveryHandler.isSessionActive()) {
        return addNewPasskeyAuthenticator({
            authToken,
            environmentId,
            userEmail,
            wallet,
        });
    }
    const publicKey = yield turnkeyRecoveryHandler.initRecovery(authenticatorType, iframeContainer, iframeElementId, sessionExpiration);
    if (!publicKey) {
        throw new DynamicError('Something went wrong');
    }
    const response = yield initEmbeddedWalletSession$1({
        authenticatorType,
        environmentId,
        publicKey,
        userJwt: authToken,
        walletId: wallet.id,
    });
    turnkeyRecoveryHandler.recoveryUserId =
        authenticatorType === 'passkey'
            ? response.turnkeyRecoveryUserId
            : response.turnkeyUserId;
    return;
});
const passkeyRecoveryBundleValidation = ({ authToken, bundleInput, wallet, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c, _d, _e;
    if (!bundleInput) {
        throw new DynamicError('Code must be informed');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const decodedJwt = decodeJwt(authToken);
    if (!decodedJwt) {
        throw new DynamicError('User must be logged in with a valid token');
    }
    const organizationId = (_d = (_c = (_b = decodedJwt.verifiedCredentials) === null || _b === void 0 ? void 0 : _b.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _c === void 0 ? void 0 : _c.walletProperties) === null || _d === void 0 ? void 0 : _d.turnkeySubOrganizationId;
    const turnkeyRecoveryHandler = (_e = wallet.connector) === null || _e === void 0 ? void 0 : _e.getAuthenticatorHandler();
    yield turnkeyRecoveryHandler.verifyRecoveryCode(bundleInput, organizationId);
    const sessionSettings = {
        createdAt: new Date().getTime(),
        emailCode: bundleInput,
        userId: turnkeyRecoveryHandler.recoveryUserId,
    };
    LocalStorage.setToLS(EMBEDDED_WALLET_SESSION_SETTINGS, sessionSettings);
});
const resentRecoveryEmail = ({ authenticatorType, authToken, environmentId, wallet, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    if (!authToken) {
        throw new DynamicError('Auth token is not defined');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const publicKey = (_f = wallet.connector) === null || _f === void 0 ? void 0 : _f.getAuthenticatorHandler().publicKey;
    if (!publicKey) {
        throw new DynamicError('Could not proceed with your request. Please restart the process.');
    }
    return initEmbeddedWalletSession$1({
        authenticatorType,
        environmentId,
        publicKey,
        userJwt: authToken,
        walletId: wallet === null || wallet === void 0 ? void 0 : wallet.id,
    });
});
const completePasskeyRecovery = ({ authToken, userEmail, environmentId, wallet, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _g, _h, _j;
    if (!authToken) {
        throw new DynamicError('User must be logged in');
    }
    let decodedJwt = decodeJwt(authToken);
    if (!decodedJwt) {
        throw new DynamicError('User must be logged in with a valid token');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const connector = wallet.connector;
    connector.setEmail(userEmail);
    const organizationId = (_j = (_h = (_g = decodedJwt.verifiedCredentials) === null || _g === void 0 ? void 0 : _g.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _h === void 0 ? void 0 : _h.walletProperties) === null || _j === void 0 ? void 0 : _j.turnkeySubOrganizationId;
    if (!organizationId) {
        throw new DynamicError('Invalid token!');
    }
    const turnkeyRecoveryHandler = wallet.connector.getAuthenticatorHandler();
    const { attestation, challenge } = yield connector.getWebAuthnAttestation();
    // calls turnkey recovery api
    yield turnkeyRecoveryHandler.completeRecovery({
        attestation,
        challenge,
        organizationId,
    });
    // calls readcost recovery api
    const passkeyRecoveryResponse = yield completePasskeyRecovery$1({
        attestation: attestation,
        challenge,
        environmentId,
        userJwt: authToken,
        walletId: wallet === null || wallet === void 0 ? void 0 : wallet.id,
    });
    const { jwt } = passkeyRecoveryResponse;
    decodedJwt = decodeJwt(jwt);
    if (decodedJwt === undefined) {
        throw new DynamicError('Invalid token!');
    }
    const chain = connector.connectedChain;
    const embeddedWalletVerifiedCredential = findEmbeddedWalletFromVerifiedCredentials(jwt, chain);
    if (!embeddedWalletVerifiedCredential) {
        throw new DynamicError('EmbeddedWalletVerifiedCredential not found');
    }
    connector.setVerifiedCredentials(decodedJwt.verifiedCredentials);
    // clear iframeStamper and its reference
    connector.getAuthenticatorHandler().clear();
    // deletes iframe container after usage
    removeElementById(iframeElementId);
    return {
        decodedJwt,
        jwt,
    };
});
const addNewPasskeyAuthenticator = ({ authToken, userEmail, environmentId, wallet, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _k, _l, _m;
    if (!authToken) {
        throw new DynamicError('User must be logged in');
    }
    let decodedJwt = decodeJwt(authToken);
    if (!decodedJwt) {
        throw new DynamicError('User must be logged in with a valid token');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const connector = wallet.connector;
    connector.setEmail(userEmail);
    const organizationId = (_m = (_l = (_k = decodedJwt.verifiedCredentials) === null || _k === void 0 ? void 0 : _k.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _l === void 0 ? void 0 : _l.walletProperties) === null || _m === void 0 ? void 0 : _m.turnkeySubOrganizationId;
    if (!organizationId) {
        throw new DynamicError('Invalid token!');
    }
    const turnkeyRecoveryHandler = wallet.connector.getAuthenticatorHandler();
    const { attestation, challenge } = yield connector.getWebAuthnAttestation();
    // calls turnkey create authenticator api
    yield turnkeyRecoveryHandler.addPasskeyAuthenticator({
        attestation,
        challenge,
        organizationId,
    });
    // calls readcost api to add new authenticator
    const passkeyRecoveryResponse = yield completePasskeyRecovery$1({
        attestation: attestation,
        challenge,
        environmentId,
        userJwt: authToken,
        walletId: wallet === null || wallet === void 0 ? void 0 : wallet.id,
    });
    const { jwt } = passkeyRecoveryResponse;
    decodedJwt = decodeJwt(jwt);
    if (decodedJwt === undefined) {
        throw new DynamicError('Invalid token!');
    }
    const chain = connector.connectedChain;
    const embeddedWalletVerifiedCredential = findEmbeddedWalletFromVerifiedCredentials(jwt, chain);
    if (!embeddedWalletVerifiedCredential) {
        throw new DynamicError('EmbeddedWalletVerifiedCredential not found');
    }
    connector.setVerifiedCredentials(decodedJwt.verifiedCredentials);
    return {
        decodedJwt,
        jwt,
    };
});
const canRestoreEmbeddedWalletSession = (sessionExpirationTime) => {
    if (!(sessionExpirationTime === null || sessionExpirationTime === void 0 ? void 0 : sessionExpirationTime.amount)) {
        return false;
    }
    const sessionSettings = LocalStorage.getFromLS(EMBEDDED_WALLET_SESSION_SETTINGS);
    if (!sessionSettings) {
        return false;
    }
    const sessionExpiration = sessionExpirationTime.amount *
        (sessionExpirationTime.unit === 'minutes' ? 60 : 3600);
    const recoveryExpirationSeconds = (sessionSettings === null || sessionSettings === void 0 ? void 0 : sessionSettings.createdAt) + sessionExpiration * 1000;
    const expirationTime = new Date(recoveryExpirationSeconds);
    if (new Date() >= expirationTime) {
        LocalStorage.removeFromLS(EMBEDDED_WALLET_SESSION_SETTINGS);
        return false;
    }
    return true;
};
const restoreEmbeddedWalletSession = ({ authToken, iframeContainer, iframeElementId, wallet, sessionExpiration, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _o, _p, _q, _r;
    if (!authToken) {
        throw new DynamicError('Auth token is not defined');
    }
    const decodedJwt = decodeJwt(authToken);
    if (!decodedJwt) {
        throw new DynamicError('User must be logged in with a valid token');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const sessionSettings = LocalStorage.getFromLS(EMBEDDED_WALLET_SESSION_SETTINGS);
    const turnkeyRecoveryHandler = (_o = wallet.connector) === null || _o === void 0 ? void 0 : _o.getAuthenticatorHandler();
    if (!sessionSettings || Boolean(turnkeyRecoveryHandler.recoveryUserId)) {
        return false;
    }
    const publicKey = yield turnkeyRecoveryHandler.initRecovery('email', iframeContainer, iframeElementId, sessionExpiration);
    if (!publicKey) {
        throw new DynamicError('Could not open embedded wallet connection communication');
    }
    turnkeyRecoveryHandler.recoveryUserId = sessionSettings.userId;
    const organizationId = (_r = (_q = (_p = decodedJwt.verifiedCredentials) === null || _p === void 0 ? void 0 : _p.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _q === void 0 ? void 0 : _q.walletProperties) === null || _r === void 0 ? void 0 : _r.turnkeySubOrganizationId;
    yield turnkeyRecoveryHandler.verifyRecoveryCode(sessionSettings.emailCode, organizationId);
    return true;
});

export { addNewPasskeyAuthenticator, canRestoreEmbeddedWalletSession, completePasskeyRecovery, initEmbeddedWalletSession, passkeyRecoveryBundleValidation, resentRecoveryEmail, restoreEmbeddedWalletSession };
