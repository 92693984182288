import { jsxs, jsx } from 'react/jsx-runtime';
import { SignInWithEmailIcon } from '@dynamic-labs/iconic';
import 'react';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import { IconWithSpinner } from '../../../../components/IconWithSpinner/IconWithSpinner.js';
import '../../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const EmailConnectorPendingSignModalContent = ({ emailWalletConnector }) => (jsxs("div", { className: 'email-connector-pending-sign-modal-content', children: [jsx(IconWithSpinner, { Icon: SignInWithEmailIcon, iconSize: 80, isSpinning: true }), jsxs("div", { className: 'email-connector-pending-sign-modal-content__body', children: [jsx(Typography, { weight: 'medium', variant: 'title', color: 'primary', className: 'email-connector-pending-sign-modal-content__title', children: "Confirm your email" }), jsxs(Typography, { weight: 'regular', variant: 'body_normal', color: 'secondary', children: ["We\u2019ve sent a verification email to", ' ', jsx(Typography, { variant: 'body_normal', color: 'secondary', weight: 'medium', as: 'span', children: emailWalletConnector.email })] }), jsx(Typography, { weight: 'regular', variant: 'body_small', color: 'secondary', children: "Note: it might take a few seconds to proceed after clicking the link in your email" })] })] }));

export { EmailConnectorPendingSignModalContent };
