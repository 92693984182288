/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var ProviderEnum;
(function (ProviderEnum) {
    ProviderEnum["EmailOnly"] = "emailOnly";
    ProviderEnum["MagicLink"] = "magicLink";
    ProviderEnum["Apple"] = "apple";
    ProviderEnum["Bitbucket"] = "bitbucket";
    ProviderEnum["Coinbasesocial"] = "coinbasesocial";
    ProviderEnum["Discord"] = "discord";
    ProviderEnum["Facebook"] = "facebook";
    ProviderEnum["Github"] = "github";
    ProviderEnum["Gitlab"] = "gitlab";
    ProviderEnum["Google"] = "google";
    ProviderEnum["Instagram"] = "instagram";
    ProviderEnum["Linkedin"] = "linkedin";
    ProviderEnum["Microsoft"] = "microsoft";
    ProviderEnum["Twitch"] = "twitch";
    ProviderEnum["Twitter"] = "twitter";
    ProviderEnum["Blocto"] = "blocto";
    ProviderEnum["Banxa"] = "banxa";
    ProviderEnum["Dynamic"] = "dynamic";
    ProviderEnum["Alchemy"] = "alchemy";
    ProviderEnum["Zerodev"] = "zerodev";
    ProviderEnum["Turnkey"] = "turnkey";
    ProviderEnum["CoinbaseWaas"] = "coinbaseWaas";
    ProviderEnum["Sms"] = "sms";
})(ProviderEnum || (ProviderEnum = {}));
function ProviderEnumFromJSON(json) {
    return ProviderEnumFromJSONTyped(json);
}
function ProviderEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ProviderEnumToJSON(value) {
    return value;
}

export { ProviderEnum, ProviderEnumFromJSON, ProviderEnumFromJSONTyped, ProviderEnumToJSON };
