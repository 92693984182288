/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
function WebhookMessageFromJSON(json) {
    return WebhookMessageFromJSONTyped(json);
}
function WebhookMessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'deliveryId': json['deliveryId'],
        'eventId': json['eventId'],
        'messageId': json['messageId'],
        'environmentId': json['environmentId'],
        'webhookId': json['webhookId'],
        'status': json['status'],
        'deliveredAt': (new Date(json['deliveredAt'])),
        'messageCreatedAt': (new Date(json['messageCreatedAt'])),
        'eventReceivedAt': (new Date(json['eventReceivedAt'])),
        'eventCreatedAt': (new Date(json['eventCreatedAt'])),
        'deliveryAttempt': json['deliveryAttempt'],
        'request': json['request'],
        'response': json['response'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}
function WebhookMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'deliveryId': value.deliveryId,
        'eventId': value.eventId,
        'messageId': value.messageId,
        'environmentId': value.environmentId,
        'webhookId': value.webhookId,
        'status': value.status,
        'deliveredAt': (value.deliveredAt.toISOString()),
        'messageCreatedAt': (value.messageCreatedAt.toISOString()),
        'eventReceivedAt': (value.eventReceivedAt.toISOString()),
        'eventCreatedAt': (value.eventCreatedAt.toISOString()),
        'deliveryAttempt': value.deliveryAttempt,
        'request': value.request,
        'response': value.response,
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

export { WebhookMessageFromJSON, WebhookMessageFromJSONTyped, WebhookMessageToJSON };
