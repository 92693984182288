import { useState } from 'react';
import { isL1FeeSupportedByChain, estimateL1Fee } from '@dynamic-labs/viem-utils';
import { usePromise } from '../usePromise/usePromise.js';
import { useInterval } from '../useInterval/useInterval.js';

/**
 * The interval to refresh the gas price in milliseconds.
 */
const REFRESH_INTERVAL = 20000;
/**
 * Estimates the L1 fee for the transaction. if the chain is supported.
 */
const useEstimateL1Fee = (publicClient, transaction) => {
    var _a;
    const [refetchKey, setRefetchKey] = useState(0);
    const promiseState = usePromise(() => estimateL1Fee(publicClient, transaction), {
        deps: [refetchKey, transaction],
        enabled: isL1FeeSupportedByChain(((_a = publicClient.chain) === null || _a === void 0 ? void 0 : _a.id) || 0),
    });
    useInterval(() => {
        setRefetchKey((key) => key + 1);
    }, REFRESH_INTERVAL);
    return promiseState;
};

export { REFRESH_INTERVAL, useEstimateL1Fee };
