import { CaptchaVerifyUser } from './CaptchaVerifyUser/CaptchaVerifyUser.js';
import { CollectUserDataView } from './CollectUserDataView/CollectUserDataView.js';
import { CollectUserDataViewLoginWithEmail } from './CollectUserDataViewLoginWithEmail/CollectUserDataViewLoginWithEmail.js';
import { EmailConfirmationWaitingView } from './EmailConfirmationWaitingView/EmailConfirmationWaitingView.js';
import { EmailUpdate } from './EmailUpdate/EmailUpdate.js';
import { EmailVerification } from './EmailVerification/EmailVerification.js';
import { EmailWalletOTPVerificationView } from './EmailWalletOTPVerificationView/EmailWalletOTPVerificationView.js';
import { NetworkNotSupported } from './NetworkNotSupported/NetworkNotSupported.js';
import { NetworkNotSupportedSwitchManual } from './NetworkNotSupportedSwitchManual/NetworkNotSupportedSwitchManual.js';
import { NoQrNotInstalledView } from './NoQrNotInstalledView/NoQrNotInstalledView.js';
import { PendingConnectView } from './PendingConnectView/PendingConnectView.js';
import { PendingSignatureView } from './PendingSignatureView/PendingSignatureView.js';
import { QrCodeView } from './QrCodeView/QrCodeView.js';
import { SocialRedirectView } from './SocialRedirectView/SocialRedirectView.js';
import { WalletList } from './WalletList/WalletList.js';
import { NoAccess } from './NoAccess/NoAccess.js';
import { WalletSignSpinnerView } from './WalletSignSpinnerView/WalletSignSpinnerView.js';
import { WalletUsedView } from './WalletUsedView/WalletUsedView.js';
import { SocialWrongAccountView } from './SocialWrongAccountView/SocialWrongAccountView.js';
import { WalletGroupView } from './WalletGroupView/WalletGroupView.js';
import { WalletLockedView } from './WalletLockedView/WalletLockedView.js';
import { BridgeWelcomeView } from './BridgeWelcomeView/BridgeWelcomeView.js';
import { BridgeSummaryView } from './BridgeSummaryView/BridgeSummaryView.js';
import { BridgeNextWalletConnectionView } from './BridgeNextWalletConnectionView/BridgeNextWalletConnectionView.js';
import { AccountExistsView } from './AccountExistsView/AccountExistsView.js';
import { MergeUserAccountsView } from './MergeUserAccountsView/MergeUserAccountsView.js';
import { WalletCannotBeTransferredView } from './WalletCannotBeTransferredView/WalletCannotBeTransferredView.js';
import { LoginView } from './LoginView/LoginView.js';
import { PasskeyIntroView } from './Passkey/PasskeyIntroView/PasskeyIntroView.js';
import { PasskeyBundleRecovery } from './Passkey/PasskeyRecovery/BundleValidation/PasskeyBundleRecovery.js';
import { PasskeyCompleteRecovery } from './Passkey/PasskeyRecovery/CompleteRecovery/PasskeyCompleteRecovery.js';
import { PasskeyInitRecovery } from './Passkey/PasskeyRecovery/InitRecovery/PasskeyInitRecovery.js';
import { RenamePasskeyView } from './Passkey/RenamePasskeyView/RenamePasskeyView.js';
import { EmbeddedAuthChoice } from './Passkey/EmbeddedAuthChoice/EmbeddedAuthChoice.js';
import 'react/jsx-runtime';
import 'react-i18next';
import 'react';
import '../events/dynamicEvents.js';
import '../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../utils/constants/colors.js';
import '../utils/constants/values.js';
import '../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../shared/consts/index.js';
import '../context/CaptchaContext/CaptchaContext.js';
import '../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../context/AccessDeniedContext/AccessDeniedContext.js';
import '../context/AccountExistsContext/AccountExistsContext.js';
import '../config/ApiEndpoint.js';
import '../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../context/MockContext/MockContext.js';
import '../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../context/UserWalletsContext/UserWalletsContext.js';
import '../components/Transition/ZoomTransition/ZoomTransition.js';
import '../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../components/Transition/OpacityTransition/OpacityTransition.js';
import '../components/ShadowDOM/ShadowDOM.js';
import '../components/OverlayCard/OverlayCard.context.js';
import '../context/FooterAnimationContext/index.js';
import '../context/WalletGroupContext/WalletGroupContext.js';
import '../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../components/IconButton/IconButton.js';
import '../components/Alert/Alert.js';
import '../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import './TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../components/InlineWidget/InlineWidget.js';
import '../components/IsBrowser/IsBrowser.js';
import '../components/MenuList/Dropdown/Dropdown.js';
import '../components/Popper/Popper/Popper.js';
import '../components/Popper/PopperContext/PopperContext.js';
import { EmbeddedRevealView } from './EmbeddedReveal/EmbeddedRevealView/EmbeddedRevealView.js';
import { SelectHardwareWalletView } from './SelectHardwareWalletView/SelectHardwareWalletView.js';
import { SelectWalletInWalletGroupView } from './SelectWalletInWalletGroupView/SelectWalletInWalletGroupView.js';
import { WalletRedirectView } from './WalletRedirectView/WalletRedirectView.js';
import { PasskeyNewDomainDetectedView } from './Passkey/PasskeyNewDomainDetectedView/PasskeyNewDomainDetectedView.js';

const viewToComponentMap = {
    'account-exists': AccountExistsView,
    'bridge-next-wallet-connection': BridgeNextWalletConnectionView,
    'bridge-summary': BridgeSummaryView,
    'bridge-welcome': BridgeWelcomeView,
    captcha: CaptchaVerifyUser,
    'chainalysis-blocked-wallet': NoAccess,
    'collect-user-data': CollectUserDataView,
    'collect-user-data-login-with-email': CollectUserDataViewLoginWithEmail,
    'email-wallet-otp-verification-view': EmailWalletOTPVerificationView,
    'embedded-reveal-account-view': EmbeddedRevealView,
    'embedded-reveal-view': EmbeddedRevealView,
    'embedded-wallet-auth-choice': EmbeddedAuthChoice,
    'gate-blocked-wallet': NoAccess,
    'login-with-email-or-wallet': LoginView,
    'login-with-email-or-wallet-full-wallet-list': LoginView,
    'login-with-email-verification': EmailVerification,
    'login-with-wallet-only': LoginView,
    'merge-user-accounts': MergeUserAccountsView,
    'multi-wallet-wallet-list': WalletList,
    'network-not-supported': NetworkNotSupported,
    'network-not-supported-manual': NetworkNotSupportedSwitchManual,
    'no-access': NoAccess,
    'no-qr-not-installed': NoQrNotInstalledView,
    'passkey-intro': PasskeyIntroView,
    'passkey-new-domain-detected': PasskeyNewDomainDetectedView,
    'passkey-recovery-bundle': PasskeyBundleRecovery,
    'passkey-recovery-complete': PasskeyCompleteRecovery,
    'passkey-recovery-start': PasskeyInitRecovery,
    'pending-connect': PendingConnectView,
    'pending-signature': PendingSignatureView,
    'pending-signature-without-back-button': PendingSignatureView,
    'qr-code': QrCodeView,
    'rename-passkey': RenamePasskeyView,
    'select-hardware-wallet': SelectHardwareWalletView,
    'select-wallet-in-wallet-group': SelectWalletInWalletGroupView,
    'social-redirect-view': SocialRedirectView,
    'social-wrong-account': SocialWrongAccountView,
    'update-email': EmailUpdate,
    'verify-email': EmailVerification,
    'wait-for-email-confirmation-view': EmailConfirmationWaitingView,
    'wallet-cannot-be-transferred': WalletCannotBeTransferredView,
    'wallet-connect-mobile-wallets-list': WalletList,
    'wallet-group': WalletGroupView,
    'wallet-list': WalletList,
    'wallet-locked-view': WalletLockedView,
    'wallet-redirect-view': WalletRedirectView,
    'wallet-sign': WalletSignSpinnerView,
    'wallet-used': WalletUsedView,
};

export { viewToComponentMap };
