import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBitcoinConnector } from '@dynamic-labs/wallet-connector-core';
import { classNames } from '../../../../utils/functions/classNames/classNames.js';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import { createOwnerWallet } from '../../../../utils/functions/createOwnerWallet/createOwnerWallet.js';
import '@dynamic-labs/multi-wallet';
import { isAccountAbstractionWallet } from '../../../../utils/functions/isAccountAbstractionWallet/isAccountAbstractionWallet.js';
import { isTestnet } from '../../../../utils/functions/isTestnet/isTestnet.js';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import { useIsTurnkeyWallet } from '../../../../utils/hooks/useIsTurnkeyWallet/useIsTurnkeyWallet.js';
import { usePromise } from '../../../../utils/hooks/usePromise/usePromise.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../../context/LoadingContext/LoadingContext.js';
import { AuthProviderIcon } from '../../../../components/AuthProviderIcon/AuthProviderIcon.js';
import { getExportKeysOption } from '../../helpers/helpers.js';
import 'qrcode';
import { useWidgetContext } from '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import { Alert } from '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import { usePasskeyContext } from '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../views/WalletList/WalletList.js';
import { NetworkPicker } from '../../../../components/NetworkPicker/NetworkPicker.js';
import { useFetchNameService } from '../../hooks/useFetchNameService/useFetchNameService.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { Balance } from '../Balance/Balance.js';
import { ActiveBitcoinWalletAddresses } from './ActiveBitcoinWalletAddresses/ActiveBitcoinWalletAddresses.js';
import { ActiveWalletAddreess } from './ActiveWalletAddress/ActiveWalletAddress.js';

const ICON_SIZE = 28;
const ActiveWalletInformation = () => {
    var _a, _b;
    const { t } = useTranslation();
    const [isNetworkPickerOpen, setIsNetworkPickerOpen] = useState(false);
    const { primaryWallet, authToken, network, projectSettings, handleLogOut, user, } = useInternalDynamicContext();
    const { setDynamicWidgetView } = useWidgetContext();
    const { isTurnkeyWalletWithoutAuthenticator } = useIsTurnkeyWallet();
    const address = primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.address;
    const nameService = useFetchNameService(address);
    const { data: testnet } = usePromise(() => isTestnet(primaryWallet), { deps: [network] });
    const exportKeysOption = getExportKeysOption(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector, projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers);
    const { showAlert } = usePasskeyContext();
    const wallet = isAccountAbstractionWallet(primaryWallet)
        ? createOwnerWallet(primaryWallet, (user === null || user === void 0 ? void 0 : user.verifiedCredentials) || [])
        : primaryWallet;
    const isBitcoin = primaryWallet && isBitcoinConnector(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector);
    const isEmbeddedWallet = Boolean(wallet && ((_b = (_a = wallet === null || wallet === void 0 ? void 0 : wallet.connector) === null || _a === void 0 ? void 0 : _a.key) === null || _b === void 0 ? void 0 : _b.startsWith('turnkey')));
    const options = [
        {
            Icon: null,
            callback: () => (nameService === null || nameService === void 0 ? void 0 : nameService.name) && navigator.clipboard.writeText(nameService === null || nameService === void 0 ? void 0 : nameService.name),
            hide: !(nameService === null || nameService === void 0 ? void 0 : nameService.name),
            text: 'Copy ENS',
        },
        {
            Icon: null,
            callback: () => address && navigator.clipboard.writeText(address),
            hide: !address,
            text: 'Copy wallet address',
        },
        {
            Icon: null,
            callback: () => setDynamicWidgetView('wallet-settings'),
            fontColor: showAlert() ? 'primary' : undefined,
            fontWeight: showAlert() ? 'bold' : undefined,
            hide: !(wallet && wallet.connector.key.startsWith('turnkey')),
            text: 'Wallet settings',
        },
        {
            Icon: null,
            callback: exportKeysOption ||
                (() => logger.info('No option to export private keys')),
            hide: !exportKeysOption,
            text: 'Export private keys',
        },
        {
            Icon: null,
            callback: handleLogOut,
            hide: isEmbeddedWallet,
            text: 'Disconnect',
        },
    ];
    const evmNetworks = ((primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector) &&
        (primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector).evmNetworks) ||
        [];
    const showTestnetAlert = testnet && !isTurnkeyWalletWithoutAuthenticator;
    const activeWalletAddress = () => {
        if (isBitcoin) {
            return (jsx(ActiveBitcoinWalletAddresses, { wallet: primaryWallet, menuOption: options }));
        }
        else if (isEmbeddedWallet) {
            return (jsx(ActiveWalletAddreess, { address: address, nameServiceName: user === null || user === void 0 ? void 0 : user.email, menuOption: options }));
        }
        else {
            return (jsx(ActiveWalletAddreess, { address: address, nameServiceName: nameService === null || nameService === void 0 ? void 0 : nameService.name, menuOption: options }));
        }
    };
    return (jsxs("div", { className: 'active-wallet-information-container', "data-testid": 'active-wallet-information', children: [jsxs("div", { className: 'active-wallet-information', children: [jsxs("div", { className: 'active-wallet-information__header', children: [jsx("div", { className: 'active-wallet-information__avatar', children: (nameService === null || nameService === void 0 ? void 0 : nameService.avatar) ? (jsx("div", { className: 'active-wallet-information__avatar__name-service', children: jsx("img", { src: nameService === null || nameService === void 0 ? void 0 : nameService.avatar, alt: '' }) })) : (jsx("div", { className: 'active-wallet-information__wallet-img', children: jsx(AuthProviderIcon, { jwt: authToken, iconSize: ICON_SIZE, showStatus: !isEmbeddedWallet }) })) }), jsxs("div", { className: 'active-wallet-information__details', children: [jsx("div", { className: classNames('active-wallet-information__addresses-container', {
                                            'active-wallet-information__addresses-container--centered': !(nameService === null || nameService === void 0 ? void 0 : nameService.name),
                                        }), children: activeWalletAddress() }), jsx(NetworkPicker, { currentNetwork: network, connector: primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector, isNetworkPickerOpen: isNetworkPickerOpen, setIsNetworkPickerOpen: setIsNetworkPickerOpen, mainClassName: 'active-wallet-information__network-picker', buttonClassName: 'active-wallet-information__network-picker-button', evmNetworks: evmNetworks, showNetworkName: true })] })] }), showTestnetAlert && (jsx(Alert, { variant: 'warning', copykey: 'dyn_active_wallet_info.testnet_warning', children: t('dyn_active_wallet_info.testnet_warning') }))] }), jsxs("div", { className: 'balance-container', children: [jsx(Typography, { color: 'secondary', variant: 'body_small', children: "Balance" }), primaryWallet && (jsx(Balance, { className: 'balance-container__balance', connector: primaryWallet.connector, address: primaryWallet.address, network: network }))] })] }));
};

export { ActiveWalletInformation };
