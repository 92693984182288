import { jsx, jsxs } from 'react/jsx-runtime';
import React__default, { useState, useMemo, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { isLegacySafari } from '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import '../Alert/Alert.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import { IsBrowser } from '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import css_248z from '../../styles/index.shadow.js';
import { LegacySafariCssOverrides } from '../LegacySafariCssOverrides/LegacySafariCssOverrides.js';
import { isShadowDOMFlagEnabled } from './utils.js';

const ShadowDOMContext = React__default.createContext({});
const ShadowDOMStyle = () => jsx("style", { children: css_248z });
const ShadowDOM = ({ mode = 'open', id, className, children, dataTestId, zIndex, }) => {
    const { shadowDOMEnabled: shadowDOMSetting, cssOverrides } = useInternalDynamicContext();
    const shadowDOMFlag = isShadowDOMFlagEnabled();
    const shadowDOMEnabled = shadowDOMFlag && shadowDOMSetting;
    const [nodeRef, setNodeRef] = useState(null);
    const [shadowRoot, setShadowRoot] = useState();
    const CssOverridesElement = useMemo(() => {
        if (cssOverrides && typeof cssOverrides !== 'string') {
            return () => cssOverrides;
        }
        else if (cssOverrides && typeof cssOverrides === 'string') {
            const StyleElement = () => jsx("style", { children: cssOverrides });
            return StyleElement;
        }
        return null;
    }, [cssOverrides]);
    useLayoutEffect(() => {
        if (nodeRef && shadowDOMEnabled) {
            try {
                const root = nodeRef.attachShadow({
                    mode,
                });
                setShadowRoot(root);
            }
            catch (e) {
                // do nothing
            }
        }
    }, [shadowDOMEnabled, nodeRef, mode]);
    const shouldRenderStyles = Boolean(Object.keys(css_248z).length);
    if (!shadowDOMEnabled) {
        return (jsx(IsBrowser, { children: jsxs("div", { "data-testid": dataTestId, ref: setNodeRef, id: id, className: classNames('dynamic-shadow-dom', className), style: { zIndex }, children: [children, shouldRenderStyles && jsx(ShadowDOMStyle, {}), isLegacySafari() && jsx(LegacySafariCssOverrides, {}), CssOverridesElement && jsx(CssOverridesElement, {})] }) }));
    }
    return (jsx(IsBrowser, { children: jsx(ShadowDOMContext.Provider, { value: { shadowRoot }, children: jsx("div", { "data-testid": dataTestId, ref: setNodeRef, id: id, className: classNames('dynamic-shadow-dom', className), style: { zIndex }, children: shadowRoot &&
                    createPortal(jsxs("div", { className: 'dynamic-shadow-dom-content', children: [children, shouldRenderStyles && jsx(ShadowDOMStyle, {}), isLegacySafari() && jsx(LegacySafariCssOverrides, {}), CssOverridesElement && jsx(CssOverridesElement, {})] }), shadowRoot) }) }) }));
};

export { ShadowDOM, ShadowDOMContext, ShadowDOMStyle };
