/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
function SupportedSecurityMethodFromJSON(json) {
    return SupportedSecurityMethodFromJSONTyped(json);
}
function SupportedSecurityMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isDefault': json['isDefault'],
        'isEnabled': json['isEnabled'],
        'isPermanentAuthenticator': json['isPermanentAuthenticator'],
        'listPosition': json['listPosition'],
    };
}
function SupportedSecurityMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isDefault': value.isDefault,
        'isEnabled': value.isEnabled,
        'isPermanentAuthenticator': value.isPermanentAuthenticator,
        'listPosition': value.listPosition,
    };
}

export { SupportedSecurityMethodFromJSON, SupportedSecurityMethodFromJSONTyped, SupportedSecurityMethodToJSON };
