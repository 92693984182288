import { jsx } from 'react/jsx-runtime';
import 'react';
import '../../../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgCheckCircle } from '../../../../../../shared/assets/check-circle.js';
import '@dynamic-labs/wallet-book';
import '../../../../../../utils/constants/colors.js';
import '../../../../../../utils/constants/values.js';
import '../../../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../../../shared/consts/index.js';
import '../../../../../../events/dynamicEvents.js';
import '../../../../../../../../_virtual/_tslib.js';
import '../../../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../../../config/ApiEndpoint.js';
import '../../../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../../../context/MockContext/MockContext.js';
import '../../../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../../../context/UserWalletsContext/UserWalletsContext.js';
import { classNames } from '../../../../../../utils/functions/classNames/classNames.js';
import '../../../../../Alert/Alert.js';
import { Typography } from '../../../../../Typography/Typography.js';
import { Tooltip } from '../../../../../Tooltip/Tooltip.js';
import '../../../../../IconButton/IconButton.js';
import '../../../../../InlineWidget/InlineWidget.js';
import '../../../../../IsBrowser/IsBrowser.js';
import '../../../../../MenuList/Dropdown/Dropdown.js';
import '../../../../../Transition/ZoomTransition/ZoomTransition.js';
import '../../../../../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../../Transition/OpacityTransition/OpacityTransition.js';
import '../../../../../Popper/Popper/Popper.js';
import '../../../../../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import '../../../../../ShadowDOM/ShadowDOM.js';
import 'formik';
import 'i18next';
import '../../../../../OverlayCard/OverlayCard.context.js';
import '../../../../../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../../../context/LoadingContext/LoadingContext.js';
import '../../../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../../../context/FooterAnimationContext/index.js';
import '../../../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const VerifiedEmailTooltip = (jsx(Typography, { variant: 'numbers_medium', color: 'tooltip', children: "This email is verified" }));
const VerifiedEmailIcon = ({ className, overwriteTooltipProps, }) => {
    const { user } = useInternalDynamicContext();
    if (!(user === null || user === void 0 ? void 0 : user.email))
        return null;
    const isVerified = user === null || user === void 0 ? void 0 : user.verifiedCredentials.some(({ email }) => email === user.email);
    if (!isVerified)
        return null;
    return (jsx(Tooltip, Object.assign({ content: VerifiedEmailTooltip }, overwriteTooltipProps, { children: jsx(SvgCheckCircle, { className: classNames('dynamic-verified-email-icon', className) }) })));
};

export { VerifiedEmailIcon };
