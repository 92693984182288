import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { WalletIcon } from '@dynamic-labs/wallet-book';
import { getChainInfo, isHardwareWalletConnector } from '@dynamic-labs/wallet-connector-core';
import 'react';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../../context/ViewContext/ViewContext.js';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import { Typography } from '../../components/Typography/Typography.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import { Divider } from '../../components/Divider/Divider.js';
import '../../context/LoadingContext/LoadingContext.js';
import { Icon } from '../../components/Icon/Icon.js';
import 'i18next';
import 'qrcode';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import { HardwareWalletToggle } from '../../components/HardwareWalletToggle/HardwareWalletToggle.js';
import { WalletGroupItem } from '../WalletGroupView/components/WalletGroupItem/WalletGroupItem.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const SelectHardwareWalletView = ({ wallet, onSelectWallet, }) => {
    var _a;
    const { t } = useTranslation();
    const chainName = (_a = getChainInfo(wallet.walletConnector.connectedChain)) === null || _a === void 0 ? void 0 : _a.displayName;
    return (jsx("div", { className: 'select-hardware-wallet-view', children: jsxs("div", { className: 'select-hardware-wallet-view__container', children: [jsx(Icon, { className: 'select-hardware-wallet-view__icon', children: jsx(WalletIcon, { walletKey: wallet.key }) }), jsx(Typography, { className: 'select-hardware-wallet-view__text', as: 'p', color: 'secondary', variant: 'body_normal', copykey: 'dyn_select_hardware_wallet.description', children: t('dyn_select_hardware_wallet.description') }), isHardwareWalletConnector(wallet.walletConnector) && (jsxs(Fragment, { children: [jsx(HardwareWalletToggle, { connectors: [wallet.walletConnector], copykey: 'dyn_select_chain.using_hardware_wallet_toggle_label', label: t('dyn_select_chain.using_hardware_wallet_toggle_label', {
                                chainName,
                            }) }), jsx(Divider, {})] })), jsx(WalletGroupItem, { wallet: wallet, onClick: () => onSelectWallet === null || onSelectWallet === void 0 ? void 0 : onSelectWallet(wallet) })] }) }));
};

export { SelectHardwareWalletView };
