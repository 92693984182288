function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { forwardRef } from 'react';
import { Box, Flex } from 'reflexbox';
export { Box, Flex };
export var Text = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    tx: "text"
  }, props));
});
export var Heading = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    as: "h2",
    tx: "text",
    variant: "heading"
  }, props, {
    __css: {
      fontSize: 4,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading'
    }
  }));
});
export var Link = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    as: "a",
    variant: "link"
  }, props));
});
export var Button = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    as: "button",
    tx: "buttons",
    variant: "primary"
  }, props, {
    __css: {
      appearance: 'none',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      fontSize: 'inherit',
      px: 3,
      py: 2,
      color: 'white',
      bg: 'primary',
      border: 0,
      borderRadius: 4
    }
  }));
});
export var Image = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    as: "img"
  }, props, {
    __css: {
      maxWidth: '100%',
      height: 'auto'
    }
  }));
});
export var Card = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    variant: "card"
  }, props));
});
