import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import 'react';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import { Button } from '../../../../components/Button/Button.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import { useCloseMultiWalletPrompt } from '../../hooks/useCloseMultiWalletPrompt/useCloseMultiWalletPrompt.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import { useLoadingContext } from '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { PromptModal } from '../../components/PromptModal/PromptModal.js';
import { WidgetPortal } from '../../components/WidgetPortal/WidgetPortal.js';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const UnlinkWalletPopUpContent = ({ onClickCancel, }) => {
    var _a;
    const { handleUnlinkWallet, setSelectedWalletWithAction, authMode, selectedWalletWithAction, } = useInternalDynamicContext();
    const { t } = useTranslation();
    const { loading, setLoading } = useLoadingContext();
    const walletId = (_a = selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet) === null || _a === void 0 ? void 0 : _a.id;
    if (!walletId) {
        throw new Error('Wallet id is not defined');
    }
    const onConfirmClick = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield handleUnlinkWallet(walletId);
            setSelectedWalletWithAction(null);
        }
        catch (error) {
            logger.error(error);
        }
        finally {
            setLoading(false);
        }
    });
    return (jsxs("div", { className: 'unlink-wallet-popup__container', children: [jsx(Typography, { color: 'primary', className: 'unlink-wallet-popup__title', variant: 'body_normal', as: 'h6', children: authMode === 'connect-only'
                    ? t('dyn_unlink_wallet_pop_up.disconnect_title')
                    : t('dyn_unlink_wallet_pop_up.unlink_title') }), jsx(Typography, { className: 'unlink-wallet-popup__label', variant: 'body_small', color: 'secondary', children: authMode === 'connect-only'
                    ? t('dyn_unlink_wallet_pop_up.disconnect_description')
                    : t('dyn_unlink_wallet_pop_up.unlink_description') }), jsx(Button, { buttonPadding: 'small', onClick: onConfirmClick, disabled: loading, buttonClassName: 'unlink-wallet-popup__button unlink-wallet-popup__button--shadow', color: 'primary', expanded: true, typographyProps: {
                    variant: 'button_secondary',
                    weight: 'medium',
                }, dataTestId: 'unlink-wallet-popup-yes-button', children: authMode === 'connect-only'
                    ? t('dyn_unlink_wallet_pop_up.disconnect_button')
                    : t('dyn_unlink_wallet_pop_up.unlink_button') }), jsx(Button, { expanded: true, buttonPadding: 'small', onClick: onClickCancel, buttonVariant: 'secondary', buttonClassName: 'unlink-wallet-popup__button', typographyProps: {
                    variant: 'button_secondary',
                    weight: 'medium',
                }, children: t('dyn_unlink_wallet_pop_up.cancel_button') })] }));
};
const UnlinkWalletPopUpModal = () => (jsx(PromptModal, { onClose: useCloseMultiWalletPrompt(), children: jsx(UnlinkWalletPopUpContent, { onClickCancel: useCloseMultiWalletPrompt() }) }));
const UnlinkWalletPopUpWidgetPortal = () => (jsx(WidgetPortal, { onClose: useCloseMultiWalletPrompt(), children: jsx(UnlinkWalletPopUpContent, { onClickCancel: useCloseMultiWalletPrompt() }) }));

export { UnlinkWalletPopUpModal, UnlinkWalletPopUpWidgetPortal };
