/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
function ChainConfigurationFromJSON(json) {
    return ChainConfigurationFromJSONTyped(json);
}
function ChainConfigurationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'enabled': json['enabled'],
        'primary': json['primary'],
    };
}
function ChainConfigurationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'enabled': value.enabled,
        'primary': value.primary,
    };
}

export { ChainConfigurationFromJSON, ChainConfigurationFromJSONTyped, ChainConfigurationToJSON };
