import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import { WalletIcon } from '@dynamic-labs/wallet-book';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { PromptModal } from '../../components/PromptModal/PromptModal.js';
import { WidgetPortal } from '../../components/WidgetPortal/WidgetPortal.js';
import { useConnectedAccountWallet } from '../../../../utils/hooks/useConnectedAccountWallet/useConnectedAccountWallet.js';
import { DefaultPromptLayout } from '../DefaultPromptModal/DefaultPromptModal.js';
import { SwitchAccountView } from './SwitchAccountView/SwitchAccountView.js';
import { SwitchOrUseActiveAccountView } from './SwitchOrUseActiveAccountView/SwitchOrUseActiveAccountView.js';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const PendingAccountSwitchContent = () => {
    var _a, _b;
    const { selectedWalletWithAction, primaryWallet, secondaryWallets } = useInternalDynamicContext();
    const selectedConnector = (_a = selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet) === null || _a === void 0 ? void 0 : _a.connector;
    const isSameWallet = (selectedConnector === null || selectedConnector === void 0 ? void 0 : selectedConnector.key) === (primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.key);
    const { data: connectedWallet } = useConnectedAccountWallet((_b = selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet) === null || _b === void 0 ? void 0 : _b.connector, secondaryWallets);
    useEffect(() => {
        //flow client library only supports one connected wallet at any given time.
        const disconnectAndConnect = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if ((selectedConnector === null || selectedConnector === void 0 ? void 0 : selectedConnector.connectedChain) !== 'FLOW') {
                return;
            }
            yield ((_a = selectedConnector === null || selectedConnector === void 0 ? void 0 : selectedConnector.endSession) === null || _a === void 0 ? void 0 : _a.call(selectedConnector));
            (_b = selectedConnector === null || selectedConnector === void 0 ? void 0 : selectedConnector.connect) === null || _b === void 0 ? void 0 : _b.call(selectedConnector);
        });
        disconnectAndConnect();
    }, [selectedConnector]);
    return (jsx(DefaultPromptLayout, { dataTestId: 'pending-account-switch', icon: jsx(WalletIcon, { walletKey: selectedConnector === null || selectedConnector === void 0 ? void 0 : selectedConnector.key }), title: `Switch wallet in ${selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet.connector.name}`, children: isSameWallet || !connectedWallet ? (jsx(SwitchAccountView, { selectedWalletToSwitchTo: selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet })) : (jsx(SwitchOrUseActiveAccountView, { connectedWallet: connectedWallet, selectedWalletToSwitchTo: selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet })) }));
};
const PendingAccountSwitchWidgetPortal = () => (jsx(WidgetPortal, { children: jsx(PendingAccountSwitchContent, {}) }));
const PendingAccountSwitchModal = () => (jsx(PromptModal, { children: jsx(PendingAccountSwitchContent, {}) }));

export { PendingAccountSwitchModal, PendingAccountSwitchWidgetPortal };
