import { jsxs, jsx } from 'react/jsx-runtime';
import { useRef, useEffect } from 'react';
import { useFields } from '../../views/CollectUserDataView/useFields.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import { getValueByKey } from '../../shared/utils/functions/getValueByKey/index.js';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import '../../context/DynamicContext/DynamicContext.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import { useErrorContext } from '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getEnabledOAuthProviders } from '../../utils/functions/getEnabledOAuthProviders/index.js';
import { getUserProfileFields } from '../../utils/functions/getUserProfileFields/getUserProfileFields.js';
import { isSocialKycEnabled } from '../../utils/functions/isSocialKycEnabled/isSocialKycEnabled.js';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import 'react-i18next';
import '../Alert/Alert.js';
import '../ShadowDOM/ShadowDOM.js';
import { ErrorContainer } from '../ErrorContainer/ErrorContainer.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import { UserProfileField } from './parts/UserProfileField/UserProfileField.js';
import { UserProfileSection } from './parts/UserProfileSection/UserProfileSection.js';
import { UserProfileSocialAccount } from './parts/UserProfileSocialAccount/UserProfileSocialAccount.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const UserProfile = ({ rootClassName }) => {
    const { projectSettings, user } = useInternalDynamicContext();
    const { error } = useErrorContext();
    const { fieldsConfig } = useFields();
    const containerRef = useRef(null);
    useEffect(() => {
        if (error && containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [error]);
    const allUserFields = getUserProfileFields({
        projectSettingsKyc: projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.kyc,
        user,
    });
    const providers = isSocialKycEnabled(projectSettings)
        ? getEnabledOAuthProviders(projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers)
        : [];
    const mapFieldKeyToValue = (fieldKey) => user === null || user === void 0 ? void 0 : user[fieldKey];
    if (!user)
        return null;
    return (jsxs("div", { "data-testid": 'userProfile', className: classNames('user-profile', rootClassName), ref: containerRef, children: [error && jsx(ErrorContainer, { children: error }), (allUserFields === null || allUserFields === void 0 ? void 0 : allUserFields.length) > 0 && (jsx(UserProfileSection, { title: 'My information', children: jsx("div", { className: 'user-profile__fields', children: allUserFields.map(({ name }) => {
                        const { label, key } = getValueByKey(fieldsConfig, name);
                        const value = mapFieldKeyToValue(name);
                        return (jsx(UserProfileField, { copykey: key, name: name, label: label, value: value }, name));
                    }) }) })), (providers === null || providers === void 0 ? void 0 : providers.length) > 0 && (jsx(UserProfileSection, { title: 'Social accounts', children: jsx("div", { className: 'user-profile__social-accounts', children: providers.map(({ provider }) => (jsx(UserProfileSocialAccount, { provider: provider }, provider))) }) }))] }));
};

export { UserProfile };
