import { VerifiedCredentialNameToChainEnum } from '../../constants/values.js';

const findTurnkeyWallet = (walletOptions, chainName) => {
    const chain = chainName
        ? VerifiedCredentialNameToChainEnum[chainName]
        : undefined;
    return walletOptions === null || walletOptions === void 0 ? void 0 : walletOptions.find(({ key, walletConnector }) => key.startsWith('turnkey') && (walletConnector === null || walletConnector === void 0 ? void 0 : walletConnector.connectedChain) === chain);
};

export { findTurnkeyWallet };
