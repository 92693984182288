import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useCallback } from 'react';
import { isPasskeyWalletConnector } from '@dynamic-labs/wallet-connector-core';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import { dynamicEvents } from '../../../events/dynamicEvents.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import { createOwnerWallet } from '../../functions/createOwnerWallet/createOwnerWallet.js';
import { createUserProfile } from '../../functions/createUserProfile/createUserProfile.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../functions/getAuthToken/getAuthToken.js';
import { isAccountAbstractionWallet } from '../../functions/isAccountAbstractionWallet/isAccountAbstractionWallet.js';
import { isTurnkeyEnabled } from '../../functions/isTurnkeyEnabled/isTurnkeyEnabled.js';
import { storeAuthToken } from '../../functions/storeAuthToken/index.js';
import { getEnabledEmbeddedWalletsAuthenticators } from '../../functions/getEnabledEmbeddedWalletsAuthenticators/getEnabledEmbeddedWalletsAuthenticators.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useElementById } from '../useElementById/useElementById.js';
import { useEmbeddedWallet } from '../useEmbeddedWallet/useEmbeddedWallet.js';
import '../../../context/ThemeContext/ThemeContext.js';
import { iframeContainerId, iframeElementId } from '../../../views/Passkey/PasskeyRecovery/constants.js';
import { addNewPasskeyAuthenticator, canRestoreEmbeddedWalletSession, restoreEmbeddedWalletSession } from '../../../views/Passkey/utils/passkeyRecovery/passkeyRecovery.js';
import { useIsTurnkeyWallet } from '../useIsTurnkeyWallet/useIsTurnkeyWallet.js';
import { useInitEmbeddedWalletAuthMethod } from '../useInitEmbeddedWalletAuthMethod/useInitEmbeddedWalletAuthMethod.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

// Hook exposed to customers and used internally to trigger passkey recovery
const usePasskeyRecovery = () => {
    const { authToken, environmentId, primaryWallet, projectSettings, setShowAuthFlow, user, setUser, } = useInternalDynamicContext();
    const iframeContainerRef = useElementById(iframeContainerId);
    const { userHasEmbeddedWallet } = useEmbeddedWallet();
    const { isTurnkeyWalletWithoutAuthenticator } = useIsTurnkeyWallet();
    const { initAuthMethod } = useInitEmbeddedWalletAuthMethod();
    const { setView } = useViewContext();
    const wallet = isAccountAbstractionWallet(primaryWallet)
        ? createOwnerWallet(primaryWallet, (user === null || user === void 0 ? void 0 : user.verifiedCredentials) || [])
        : primaryWallet;
    const shouldInitRecovery = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!wallet || !isPasskeyWalletConnector(wallet.connector)) {
            return false;
        }
        const turnkeyRecoveryHandler = wallet.connector.getAuthenticatorHandler();
        // user has passkey authenticator set up OR
        // email auth - session is still up, so no need to recover
        if (!isTurnkeyWalletWithoutAuthenticator ||
            turnkeyRecoveryHandler.isSessionActive() ||
            (yield isSessionRestored())) {
            return false;
        }
        return true;
    }), [primaryWallet, isTurnkeyWalletWithoutAuthenticator]);
    const initPasskeyRecoveryProcess = useCallback((authenticatorType, currentAction) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        validateRecoveryRequest();
        const turnkeyRecoveryHandler = (wallet === null || wallet === void 0 ? void 0 : wallet.connector).getAuthenticatorHandler();
        // email auth - session is still up, so create passkey authenticator usign the same session
        if (authenticatorType === 'passkey' &&
            turnkeyRecoveryHandler.isSessionActive()) {
            const { jwt, decodedJwt } = yield addNewPasskeyAuthenticator({
                authToken,
                environmentId,
                userEmail: user === null || user === void 0 ? void 0 : user.email,
                wallet,
            });
            storeAuthToken(jwt);
            setUser(createUserProfile(decodedJwt));
            setShowAuthFlow(false);
            return wallet;
        }
        const supportedAuthenticators = getEnabledEmbeddedWalletsAuthenticators((_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _a === void 0 ? void 0 : _a.supportedSecurityMethods);
        const supportedAuthenticatorMethods = supportedAuthenticators
            ? Object.keys(supportedAuthenticators)
            : [];
        if (!authenticatorType && supportedAuthenticatorMethods.length > 1) {
            setView('embedded-wallet-auth-choice', { currentAction });
            setShowAuthFlow(true, {
                ignoreIfIsEmbeddedWidget: false,
                performMultiWalletChecks: false,
            });
        }
        else {
            const [authType] = supportedAuthenticatorMethods;
            // since we're forcing the authenticator type = 'email' during transactions when no passkey is configured,
            // we need to make sure it's supported.
            // Otherwise, we'll use the first supported authenticator type from the list
            if (authenticatorType &&
                !supportedAuthenticatorMethods.includes(authenticatorType)) {
                authenticatorType = authType;
            }
            yield initAuthMethod({
                authenticatorType: authenticatorType || authType,
                currentAction,
                iframeContainer: iframeContainerRef.current,
            });
        }
        return new Promise((resolve, reject) => {
            dynamicEvents.once('passkeyRecoveryCompleted', (wallet) => resolve(wallet));
            dynamicEvents.once('passkeyRecoveryFailed', (error) => reject(error));
        });
    }), [setShowAuthFlow, projectSettings, setView, userHasEmbeddedWallet]);
    const validateRecoveryRequest = () => {
        const jwt = getAuthToken();
        if (!jwt) {
            throw new Error('User is not logged in');
        }
        if (!isTurnkeyEnabled(projectSettings)) {
            throw new Error('Passkey embedded wallet is not enabled. Go to the dashboard and make sure to have both Turnkey/Passkey Embedded wallets and at least one EVM network enabled. Also, check if EthereumWalletConnectors is in the  DynamicContextProvider > settings > walletConnectors.');
        }
        if (!userHasEmbeddedWallet()) {
            throw new Error('Passkey embedded wallet not found');
        }
        if (wallet && !isPasskeyWalletConnector(wallet.connector)) {
            throw new Error('Passkey embedded wallet not found.');
        }
    };
    const isSessionRestored = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        if (!((_b = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _b === void 0 ? void 0 : _b.sessionKeyDuration) ||
            !canRestoreEmbeddedWalletSession((_c = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _c === void 0 ? void 0 : _c.sessionKeyDuration)) {
            return false;
        }
        const sessionExpiration = ((_d = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _d === void 0 ? void 0 : _d.sessionKeyDuration.amount) *
            (((_e = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _e === void 0 ? void 0 : _e.sessionKeyDuration.unit) ===
                'minutes'
                ? 60
                : 3600);
        try {
            return yield restoreEmbeddedWalletSession({
                authToken,
                iframeContainer: iframeContainerRef.current,
                iframeElementId,
                sessionExpiration,
                wallet: primaryWallet,
            });
        }
        catch (err) {
            logger.error('Failed to restore embedded wallet', err);
        }
        return false;
    });
    return {
        initPasskeyRecoveryProcess,
        shouldInitRecovery,
    };
};

export { usePasskeyRecovery };
