import { __awaiter } from '../_virtual/_tslib.js';
import { ECDSAProvider, convertWalletClientToAccountSigner } from '@zerodev/sdk';
import { custom, createWalletClient, isHex, fromHex, formatEther } from 'viem';
import { WalletConnectorBase } from '@dynamic-labs/wallet-connector-core';
import { confirmationTransport, unFormatTransaction } from '@dynamic-labs/viem-utils';
import { parseEvmNetworks, DynamicError, DeferredPromise, wrapMethodWithCallback, TransactionGasCannotBeSponsoredError, InsufficientFundsError } from '@dynamic-labs/utils';
import { logger } from './utils/logger.js';

class ZeroDevConnector extends WalletConnectorBase {
    get key() {
        return 'zerodev';
    }
    constructor(opts) {
        var _a;
        super(opts);
        this.connectedChain = 'EVM';
        this.supportedChains = ['ETH', 'EVM'];
        this.isGasSponsorshipDisabled = false;
        this.isEmbeddedWallet = true;
        this.name = 'ZeroDev';
        const zeroDevProjectId = (_a = opts.apiProviders.zerodev) === null || _a === void 0 ? void 0 : _a.clientId;
        if (!zeroDevProjectId) {
            throw new Error('Missing ZeroDev project ID provided. Add your ZeroDev project id to your project configuration via the Dynamic Labs dashboard.');
        }
        this.projectId = zeroDevProjectId;
        this._walletUiUtils = opts.walletUiUtils;
        this.evmNetworks = parseEvmNetworks(opts.evmNetworks);
    }
    getEOAConnector() {
        return this.eoaConnector;
    }
    getAccountAbstractionProvider() {
        return this.zeroDevProvider;
    }
    setEoaConnector(connector) {
        return __awaiter(this, void 0, void 0, function* () {
            this.zeroDevProviderDeferredPromise = new DeferredPromise();
            this.eoaConnector = connector;
            const signer = yield this.eoaConnector.getSigner();
            if (!signer) {
                return;
            }
            const zeroDevProvider = yield ECDSAProvider.init({
                bundlerProvider: 'STACKUP',
                owner: convertWalletClientToAccountSigner(signer),
                projectId: this.projectId,
            });
            wrapMethodWithCallback(zeroDevProvider, 'sendUserOperation', (original, ...args) => {
                this._walletUiUtils.disabledConfirmationOnce();
                return original(...args);
            });
            this.zeroDevProvider = zeroDevProvider;
            yield this.checkIsProjectChainEnabled();
            this.zeroDevProviderDeferredPromise.resolve();
        });
    }
    checkIsProjectChainEnabled() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const client = yield this.getPublicClient();
            const chainName = (_a = client === null || client === void 0 ? void 0 : client.chain) === null || _a === void 0 ? void 0 : _a.name;
            const chainId = (_b = client === null || client === void 0 ? void 0 : client.chain) === null || _b === void 0 ? void 0 : _b.id;
            const isProjectChainEnabled = this.evmNetworks.some((network) => network.chainId === chainId);
            if (!isProjectChainEnabled) {
                logger.error(`Please make sure to enable ${chainName} (${chainId}) in the Dynamic dashboard (https://app.dynamic.xyz/dashboard/configurations#evm).`);
            }
        });
    }
    getProviderWithSponsorshipValidation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.providerWithSponsorship) {
                return this.providerWithSponsorship;
            }
            const { eoaConnector } = this;
            if (!eoaConnector)
                throw new DynamicError('No EOA connector');
            const signer = yield eoaConnector.getSigner();
            this.providerWithSponsorship = yield ECDSAProvider.init({
                bundlerProvider: 'STACKUP',
                opts: {
                    paymasterConfig: {
                        onlySendSponsoredTransaction: true,
                        policy: 'VERIFYING_PAYMASTER',
                    },
                },
                owner: convertWalletClientToAccountSigner(signer),
                projectId: this.projectId,
            });
            wrapMethodWithCallback(this.providerWithSponsorship, 'sendUserOperation', (original, ...args) => {
                this._walletUiUtils.disabledConfirmationOnce();
                return original(...args);
            });
            return this.providerWithSponsorship;
        });
    }
    fetchPublicAddress() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            yield ((_a = this.zeroDevProviderDeferredPromise) === null || _a === void 0 ? void 0 : _a.promise);
            return (_b = this.zeroDevProvider) === null || _b === void 0 ? void 0 : _b.getAddress();
        });
    }
    getConnectedAccounts() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield ((_a = this.zeroDevProviderDeferredPromise) === null || _a === void 0 ? void 0 : _a.promise);
            return this.zeroDevProvider
                ? [yield this.zeroDevProvider.getAddress()]
                : [];
        });
    }
    getNetwork() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            yield ((_a = this.zeroDevProviderDeferredPromise) === null || _a === void 0 ? void 0 : _a.promise);
            return (_b = this.zeroDevProvider) === null || _b === void 0 ? void 0 : _b.rpcClient.chain.id;
        });
    }
    getTransport(provider) {
        const transport = confirmationTransport({
            onPersonalSign: ({ message }) => __awaiter(this, void 0, void 0, function* () {
                this._walletUiUtils.disabledConfirmationOnce();
                return provider.signMessage(message);
            }),
            onSendTransaction: ({ transaction }) => __awaiter(this, void 0, void 0, function* () {
                try {
                    this._walletUiUtils.disabledConfirmationOnce();
                    const effectiveProvider = this.isGasSponsorshipDisabled
                        ? provider
                        : yield this.getProviderWithSponsorshipValidation();
                    const response = yield effectiveProvider.sendTransaction(unFormatTransaction(transaction));
                    return response;
                }
                catch (err) {
                    logger.debug(err);
                    if (TransactionGasCannotBeSponsoredError.isErrorMessage(err)) {
                        throw new TransactionGasCannotBeSponsoredError();
                    }
                    if (InsufficientFundsError.isErrorMessage(err)) {
                        throw new InsufficientFundsError();
                    }
                    throw err;
                }
            }),
            onSignTypedData: ({ message }) => __awaiter(this, void 0, void 0, function* () {
                this._walletUiUtils.disabledConfirmationOnce();
                const signTypedData = JSON.parse(message);
                return provider.signTypedData(signTypedData);
            }),
            provider: provider.rpcClient,
            transport: custom(provider),
            walletUiUtils: this._walletUiUtils,
        });
        return transport;
    }
    getWalletClient() {
        const provider = this.zeroDevProvider;
        if (!provider)
            throw new DynamicError('No provider');
        const transport = this.getTransport(provider);
        const walletClient = createWalletClient({
            transport,
        });
        return walletClient;
    }
    getPublicClient() {
        return __awaiter(this, void 0, void 0, function* () {
            const provider = this.zeroDevProvider;
            if (!provider)
                throw new DynamicError('No provider');
            return provider.rpcClient;
        });
    }
    getSigner() {
        return __awaiter(this, void 0, void 0, function* () {
            const provider = this.zeroDevProvider;
            if (!provider)
                throw new DynamicError('No provider');
            const transport = this.getTransport(provider);
            const address = yield provider.getAddress();
            const walletClient = createWalletClient({
                account: address,
                transport,
            });
            return walletClient;
        });
    }
    canSponsorTransactionGas(transaction) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userOperation = {
                    data: transaction.data ? transaction.data : '0x',
                    target: transaction.to,
                    value: isHex(transaction.value)
                        ? fromHex(transaction.value, 'bigint')
                        : transaction.value,
                };
                const result = yield ((_a = this.zeroDevProvider) === null || _a === void 0 ? void 0 : _a.buildUserOperation(userOperation));
                if (!result)
                    return false;
                return result.paymasterAndData !== '0x';
            }
            catch (err) {
                logger.debug(err);
                return false;
            }
        });
    }
    disableGasSponsorshipOnce() {
        this.isGasSponsorshipDisabled = true;
    }
    getBalance() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const rpcClient = yield ((_a = this.zeroDevProvider) === null || _a === void 0 ? void 0 : _a.rpcClient);
            if (!rpcClient)
                throw new DynamicError('No RPC client');
            const address = yield this.fetchPublicAddress();
            if (!address)
                return;
            return formatEther(yield rpcClient.getBalance({ address: address }));
        });
    }
    signMessage(messageToSign) {
        return __awaiter(this, void 0, void 0, function* () {
            const client = yield this.getSigner();
            const account = yield this.fetchPublicAddress();
            if (!account || !client)
                return;
            return client.signMessage({
                account: account,
                message: messageToSign,
            });
        });
    }
}

export { ZeroDevConnector };
