import { exists } from '../runtime.js';
import { ChainEnumFromJSON, ChainEnumToJSON } from './ChainEnum.js';
import { WalletAdditionalAddressFromJSON, WalletAdditionalAddressToJSON } from './WalletAdditionalAddress.js';
import { WalletProviderEnumFromJSON, WalletProviderEnumToJSON } from './WalletProviderEnum.js';

/* tslint:disable */
function CreateWalletRequestFromJSON(json) {
    return CreateWalletRequestFromJSONTyped(json);
}
function CreateWalletRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'publicWalletAddress': json['publicWalletAddress'],
        'chain': ChainEnumFromJSON(json['chain']),
        'walletName': json['walletName'],
        'walletProvider': WalletProviderEnumFromJSON(json['walletProvider']),
        'additionalWalletAddresses': !exists(json, 'additionalWalletAddresses') ? undefined : (json['additionalWalletAddresses'].map(WalletAdditionalAddressFromJSON)),
    };
}
function CreateWalletRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'publicWalletAddress': value.publicWalletAddress,
        'chain': ChainEnumToJSON(value.chain),
        'walletName': value.walletName,
        'walletProvider': WalletProviderEnumToJSON(value.walletProvider),
        'additionalWalletAddresses': value.additionalWalletAddresses === undefined ? undefined : (value.additionalWalletAddresses.map(WalletAdditionalAddressToJSON)),
    };
}

export { CreateWalletRequestFromJSON, CreateWalletRequestFromJSONTyped, CreateWalletRequestToJSON };
