/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var UserIdentifierTypeEnum;
(function (UserIdentifierTypeEnum) {
    UserIdentifierTypeEnum["Email"] = "email";
    UserIdentifierTypeEnum["Id"] = "id";
})(UserIdentifierTypeEnum || (UserIdentifierTypeEnum = {}));
function UserIdentifierTypeEnumFromJSON(json) {
    return UserIdentifierTypeEnumFromJSONTyped(json);
}
function UserIdentifierTypeEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function UserIdentifierTypeEnumToJSON(value) {
    return value;
}

export { UserIdentifierTypeEnum, UserIdentifierTypeEnumFromJSON, UserIdentifierTypeEnumFromJSONTyped, UserIdentifierTypeEnumToJSON };
