import { useEffect } from 'react';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import { EMBEDDED_WALLET_SESSION_SETTINGS, EMBEDDED_WALLET_SECURE_BANNER } from '../../constants/localStorage.js';
import '../../constants/colors.js';
import '../../constants/values.js';
import { LocalStorage } from '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';

const useClearWalletConnectSessions = ({ connectors }) => {
    const clearAllWalletConnectSessions = () => {
        if (typeof window === 'undefined')
            return;
        for (const key of LocalStorage.getKeys()) {
            if (key.startsWith('walletconnect') ||
                key.startsWith('walletlink') ||
                key.startsWith('wc@2')) {
                localStorage.removeItem(key);
            }
        }
        // remove embedded wallet session iframe
        connectors === null || connectors === void 0 ? void 0 : connectors.forEach((connector) => {
            var _a, _b, _c;
            if ((_b = (_a = connector === null || connector === void 0 ? void 0 : connector.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.startsWith('turnkey')) {
                (_c = connector
                    .getAuthenticatorHandler()) === null || _c === void 0 ? void 0 : _c.clear();
                LocalStorage.removeFromLS(EMBEDDED_WALLET_SESSION_SETTINGS);
                LocalStorage.removeFromLS(EMBEDDED_WALLET_SECURE_BANNER);
            }
        });
    };
    // Clears orphaned wallet connect sessions
    useEffect(() => {
        if (typeof window === 'undefined')
            return;
        if (connectors.length === 0)
            return;
        const keys = new Set(connectors.map((connector) => connector.key));
        for (const key of LocalStorage.getKeys()) {
            if (key.startsWith('walletconnect') &&
                !keys.has(key.replace('walletconnect-', ''))) {
                localStorage.removeItem(key);
            }
        }
    }, [connectors]);
    return { clearAllWalletConnectSessions };
};

export { useClearWalletConnectSessions };
