/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
function EmbeddedWalletAuthTokenFromJSON(json) {
    return EmbeddedWalletAuthTokenFromJSONTyped(json);
}
function EmbeddedWalletAuthTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
    };
}
function EmbeddedWalletAuthTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
    };
}

export { EmbeddedWalletAuthTokenFromJSON, EmbeddedWalletAuthTokenFromJSONTyped, EmbeddedWalletAuthTokenToJSON };
