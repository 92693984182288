import { jsxs, jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import { EmptyHelpContent } from './EmptyHelpContent/EmptyHelpContent.js';
import { WalletMoreInfoContent } from './WalletMoreInfoContent/WalletMoreInfoContent.js';
import { WalletListHelpContent } from './WalletListHelpContent/WalletListHelpContent.js';
import { HelpHeader } from './HelpHeader/HelpHeader.js';
import { PasskeyInfoHelper } from './PasskeyInfoHelper/PasskeyInfoHelper.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const HelpContent = () => (jsxs("div", { children: [jsx(HelpHeader, {}), jsx(HelpContentSwitch, {})] }));
const HelpContentSwitch = () => {
    const { view } = useViewContext();
    const { projectSettings, newToWeb3WalletChainMap, selectedWalletConnector } = useInternalDynamicContext();
    const isWalletListTypeView = useMemo(() => view.type === 'wallet-connect-mobile-wallets-list' ||
        view.type === 'wallet-list' ||
        view.type === 'login-with-email-or-wallet-full-wallet-list' ||
        view.type === 'login-with-wallet-only', [view]);
    const isPendingSignatureTypeView = useMemo(() => view.type === 'pending-signature-without-back-button' ||
        view.type === 'pending-signature', [view]);
    const isPendingConnectTypeView = useMemo(() => view.type === 'pending-connect' || view.type === 'qr-code', [view]);
    const isPasskeyIntroView = useMemo(() => view.type === 'passkey-intro', [view]);
    if (isWalletListTypeView) {
        return (jsx(WalletListHelpContent, { chains: projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.chains, newToWeb3WalletChainMap: newToWeb3WalletChainMap }));
    }
    if (isPendingSignatureTypeView) {
        return (jsx(WalletMoreInfoContent, { variant: 'sign', wallet: selectedWalletConnector || undefined }));
    }
    if (isPendingConnectTypeView) {
        return jsx(WalletMoreInfoContent, { variant: 'connect' });
    }
    if (isPasskeyIntroView) {
        return jsx(PasskeyInfoHelper, {});
    }
    return jsx(EmptyHelpContent, {});
};

export { HelpContent };
