import { useContext } from 'react';
import { connectWithEmailOtpContext } from './connectWithEmailOtpContext.js';

const useConnectWithEmailOtp = ({ provider: defaultProvider, } = {}) => {
    const context = useContext(connectWithEmailOtpContext);
    if (!context) {
        throw new Error('useConnectWithEmailOtp must be used within a DynamicContextProvider');
    }
    return {
        connectWithEmail: (email, options = {}) => context.connectWithEmail(email, Object.assign({ provider: defaultProvider }, options)),
        retryOneTimePassword: context.retryOneTimePassword,
        verifyOneTimePassword: context.verifyOneTimePassword,
    };
};

export { useConnectWithEmailOtp };
