import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { createContext, useContext, useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { getChainInfo, isBitcoinConnector } from '@dynamic-labs/wallet-connector-core';
import { WalletAddressType } from '@dynamic-labs/sdk-api';
import { getOnrampProviders } from '../../data/api.js';
import '../DynamicContext/DynamicContext.js';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../CaptchaContext/CaptchaContext.js';
import '../ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { isOnrampProvider } from '../../utils/functions/isOnrampProvider/isOnrampProvider.js';
import { getWalletAdditionalAddressByType } from '../../utils/functions/getWalletAdditionalAddressByType/getWalletAdditionalAddressByType.js';
import 'viem/chains';
import '../AccessDeniedContext/AccessDeniedContext.js';
import '../AccountExistsContext/AccountExistsContext.js';
import '../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../LoadingContext/LoadingContext.js';
import '../SocialRedirectContext/SocialRedirectContext.js';
import '../WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const FundingContext = createContext(undefined);
const FundingContextProvider = ({ children, }) => {
    var _a, _b;
    const fundingRef = useRef(null);
    const [showFunding, setShowFunding] = useState(false);
    const [supportsFunding, setSupportsFunding] = useState(false);
    const [fundingUrl, setFundingUrl] = useState('');
    const { environmentId, network, primaryWallet, projectSettings } = useInternalDynamicContext();
    const fundingEnabled = (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers) === null || _a === void 0 ? void 0 : _a.some((p) => isOnrampProvider(p) && Boolean(p.enabledAt))) !== null && _b !== void 0 ? _b : false;
    const updateSupportsFunding = useCallback(({ address, token } = {}) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!fundingEnabled)
            return;
        if (!primaryWallet)
            return;
        const chainName = (_c = getChainInfo(primaryWallet.chain)) === null || _c === void 0 ? void 0 : _c.name;
        if (!chainName)
            return;
        const bitcoinAddress = isBitcoinConnector(primaryWallet.connector) &&
            getWalletAdditionalAddressByType(WalletAddressType.Payment, primaryWallet);
        const chainHasNetwork = network && chainName === 'evm';
        try {
            const [{ url }] = yield getOnrampProviders({
                chain: chainName,
                environmentId,
                networkId: chainHasNetwork ? network.toString() : undefined,
                token,
                walletAddress: address || bitcoinAddress || primaryWallet.address,
            });
            if (!url)
                throw new Error('No onramp url found');
            setFundingUrl(url);
            setSupportsFunding(true);
        }
        catch (error) {
            setSupportsFunding(false);
        }
    }), [environmentId, fundingEnabled, network, primaryWallet]);
    const value = useMemo(() => ({
        fundingEnabled,
        fundingRef,
        fundingUrl,
        setShowFunding,
        showFunding,
        supportsFunding,
        updateSupportsFunding,
    }), [
        fundingEnabled,
        fundingUrl,
        showFunding,
        supportsFunding,
        updateSupportsFunding,
    ]);
    useEffect(() => {
        updateSupportsFunding();
    }, [environmentId, fundingEnabled, network, primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.address]);
    return (jsx(FundingContext.Provider, { value: value, children: children }));
};
const useFundingContext = () => {
    const context = useContext(FundingContext);
    if (context === undefined) {
        throw new Error('usage of useFundingContext not wrapped in `FundingContextProvider`.');
    }
    return context;
};

export { FundingContext, FundingContextProvider, useFundingContext };
