import { useRef, useEffect } from 'react';
import { dynamicEvents } from '../../../../events/dynamicEvents.js';

/** Allows us to subscribe to all dynamicEvents with a hook, even the internal ones */
const useInternalDynamicEvents = (event, listener) => {
    const handler = useRef(listener);
    handler.current = listener;
    useEffect(() => {
        const callback = (...args) => handler.current(...args);
        dynamicEvents.on(event, callback);
        return () => {
            dynamicEvents.off(event, callback);
        };
    }, [event]);
};

export { useInternalDynamicEvents };
