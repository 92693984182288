/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var TimeUnitEnum;
(function (TimeUnitEnum) {
    TimeUnitEnum["Days"] = "days";
    TimeUnitEnum["Hours"] = "hours";
    TimeUnitEnum["Minutes"] = "minutes";
})(TimeUnitEnum || (TimeUnitEnum = {}));
function TimeUnitEnumFromJSON(json) {
    return TimeUnitEnumFromJSONTyped(json);
}
function TimeUnitEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TimeUnitEnumToJSON(value) {
    return value;
}

export { TimeUnitEnum, TimeUnitEnumFromJSON, TimeUnitEnumFromJSONTyped, TimeUnitEnumToJSON };
