import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import React__default from 'react';
import { getChainInfo } from '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/sdk-api';
import '../../../../../../shared/logger.js';
import { getChainIcon } from '../../../../../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-book';
import '../../../../../../utils/constants/colors.js';
import '../../../../../../utils/constants/values.js';
import '../../../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '@dynamic-labs/iconic';
import '../../../../../../context/ViewContext/ViewContext.js';
import '../../../../../../shared/consts/index.js';
import { DynamicBridgeWalletCard } from '../../../../components/DynamicBridgeWalletCard/DynamicBridgeWalletCard.js';
import { DynamicBridgeWalletCardBody } from '../../../../components/DynamicBridgeWalletCardBody/DynamicBridgeWalletCardBody.js';
import { Balance } from '../../../../../DynamicWidget/components/Balance/Balance.js';
import '../../../../../../context/DynamicContext/DynamicContext.js';
import '../../../../../../events/dynamicEvents.js';
import '../../../../../../../../_virtual/_tslib.js';
import '../../../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../../../config/ApiEndpoint.js';
import '../../../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../../../context/MockContext/MockContext.js';
import '../../../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../../../components/Alert/Alert.js';
import '../../../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../../../components/IconButton/IconButton.js';
import '../../../../../../components/InlineWidget/InlineWidget.js';
import '../../../../../../components/IsBrowser/IsBrowser.js';
import '../../../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../../../components/Popper/Popper/Popper.js';
import '../../../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../../../context/LoadingContext/LoadingContext.js';
import '../../../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../../../context/FooterAnimationContext/index.js';
import '../../../../../DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../../DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../../DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import { DynamicBridgeWidgetEmptyCard } from '../../../../components/DynamicBridgeWidgetEmptyCard/DynamicBridgeWidgetEmptyCard.js';

const SecondaryWallets = ({ hideUnlinkAction = false }) => {
    const { disconnectWallet, getConnectedWalletById, bridgeChains } = useInternalDynamicContext();
    const wallet = getConnectedWalletById('connect-wallet-1');
    const NetworkIcon = wallet && getChainIcon(wallet === null || wallet === void 0 ? void 0 : wallet.chain);
    const networkInfo = wallet && getChainInfo(wallet === null || wallet === void 0 ? void 0 : wallet.chain);
    return (jsxs(Fragment, { children: [!wallet && (jsx(DynamicBridgeWidgetEmptyCard, { chain: bridgeChains === null || bridgeChains === void 0 ? void 0 : bridgeChains[1].chain })), wallet && NetworkIcon && (jsx(DynamicBridgeWalletCard, { networkIcon: jsx(NetworkIcon, {}), networkName: networkInfo === null || networkInfo === void 0 ? void 0 : networkInfo.displayName, children: jsx(DynamicBridgeWalletCardBody, { walletKey: wallet.connector.key, walletAddress: wallet.address, walletBalance: 
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    jsx(Balance, { address: wallet.address, connector: wallet.connector, network: wallet.chain }), onDisconnectClick: !hideUnlinkAction ? () => disconnectWallet(wallet.id) : undefined }, wallet.id) }))] }));
};
const MemoSecondaryWallets = React__default.memo(SecondaryWallets);

export { MemoSecondaryWallets, SecondaryWallets };
