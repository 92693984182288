/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var UnprocessableEntityErrorCode;
(function (UnprocessableEntityErrorCode) {
    UnprocessableEntityErrorCode["EmailAlreadyExists"] = "email_already_exists";
    UnprocessableEntityErrorCode["AllowlistAlreadyExists"] = "allowlist_already_exists";
    UnprocessableEntityErrorCode["AllowlistEntryAlreadyExists"] = "allowlist_entry_already_exists";
    UnprocessableEntityErrorCode["ReassignWalletError"] = "reassign_wallet_error";
    UnprocessableEntityErrorCode["ReassignWalletConfirm"] = "reassign_wallet_confirm";
    UnprocessableEntityErrorCode["MembersCannotDeleteThemself"] = "members_cannot_delete_themself";
    UnprocessableEntityErrorCode["UsernameAlreadyExists"] = "username_already_exists";
    UnprocessableEntityErrorCode["WrongEmailVerificationToken"] = "wrong_email_verification_token";
    UnprocessableEntityErrorCode["WrongSmsVerificationToken"] = "wrong_sms_verification_token";
    UnprocessableEntityErrorCode["InvalidEmailVerification"] = "invalid_email_verification";
    UnprocessableEntityErrorCode["InvalidSmsVerification"] = "invalid_sms_verification";
    UnprocessableEntityErrorCode["InvalidVerification"] = "invalid_verification";
    UnprocessableEntityErrorCode["TooManySmsVerificationAttempts"] = "too_many_sms_verification_attempts";
    UnprocessableEntityErrorCode["TooManyEmailVerificationAttempts"] = "too_many_email_verification_attempts";
    UnprocessableEntityErrorCode["TooManyVerificationAttempts"] = "too_many_verification_attempts";
    UnprocessableEntityErrorCode["OrganizationNameAlreadyExists"] = "organization_name_already_exists";
    UnprocessableEntityErrorCode["ProjectNameAlreadyExists"] = "project_name_already_exists";
    UnprocessableEntityErrorCode["WalletNotDeployed"] = "wallet_not_deployed";
    UnprocessableEntityErrorCode["EmailVerificationRequired"] = "email_verification_required";
    UnprocessableEntityErrorCode["InviteAddressRequired"] = "invite_address_required";
    UnprocessableEntityErrorCode["ProviderNotAvailable"] = "provider_not_available";
    UnprocessableEntityErrorCode["ForbiddenUnlinkRequest"] = "forbidden_unlink_request";
    UnprocessableEntityErrorCode["InvalidUnlinkRequest"] = "invalid_unlink_request";
    UnprocessableEntityErrorCode["TooManyApiTokens"] = "too_many_api_tokens";
    UnprocessableEntityErrorCode["LockTimeout"] = "lock_timeout";
    UnprocessableEntityErrorCode["LockTooManyAttempts"] = "lock_too_many_attempts";
    UnprocessableEntityErrorCode["NftTokenGatingNotSupportedForChain"] = "nft_token_gating_not_supported_for_chain";
    UnprocessableEntityErrorCode["EmptyChainName"] = "empty_chain_name";
    UnprocessableEntityErrorCode["NoEnabledEmailProvider"] = "no_enabled_email_provider";
    UnprocessableEntityErrorCode["NoEnabledSmsProvider"] = "no_enabled_sms_provider";
    UnprocessableEntityErrorCode["InvalidKeyExportUrl"] = "invalid_key_export_url";
    UnprocessableEntityErrorCode["InvalidDynamicProps"] = "invalid_dynamic_props";
    UnprocessableEntityErrorCode["TooManyOrganizationsForUser"] = "too_many_organizations_for_user";
    UnprocessableEntityErrorCode["TooManyProjectsForOrganization"] = "too_many_projects_for_organization";
    UnprocessableEntityErrorCode["EmailAssociatedWithDifferentProvider"] = "email_associated_with_different_provider";
    UnprocessableEntityErrorCode["UserHasAlreadyAccountWithEmail"] = "user_has_already_account_with_email";
    UnprocessableEntityErrorCode["UserHasAlreadyAccountWithPhoneNumber"] = "user_has_already_account_with_phone_number";
    UnprocessableEntityErrorCode["OtherVerifyFailure"] = "other_verify_failure";
    UnprocessableEntityErrorCode["EmailTiedToEmbeddedWallet"] = "email_tied_to_embedded_wallet";
    UnprocessableEntityErrorCode["InvalidInvite"] = "invalid_invite";
    UnprocessableEntityErrorCode["SocialAccountAlreadyExists"] = "social_account_already_exists";
    UnprocessableEntityErrorCode["InvalidEmailAddress"] = "invalid_email_address";
    UnprocessableEntityErrorCode["ConflictingEmbeddedWalletProviders"] = "conflicting_embedded_wallet_providers";
    UnprocessableEntityErrorCode["InvalidUser"] = "invalid_user";
    UnprocessableEntityErrorCode["InvalidCorsOrigins"] = "invalid_cors_origins";
    UnprocessableEntityErrorCode["InvalidPrivateKeyFormat"] = "invalid_private_key_format";
    UnprocessableEntityErrorCode["InvalidEmbeddedWalletSettings"] = "invalid_embedded_wallet_settings";
    UnprocessableEntityErrorCode["WalletNotSupportPasskey"] = "wallet_not_support_passkey";
    UnprocessableEntityErrorCode["RecoveryEmailUnavailableOrInvalid"] = "recovery_email_unavailable_or_invalid";
    UnprocessableEntityErrorCode["ConnectError"] = "connect_error";
    UnprocessableEntityErrorCode["InvalidWalletName"] = "invalid_wallet_name";
    UnprocessableEntityErrorCode["InvalidWalletAddress"] = "invalid_wallet_address";
    UnprocessableEntityErrorCode["EmailRecoveryDisabled"] = "email_recovery_disabled";
    UnprocessableEntityErrorCode["NoCompatibleWalletServiceEnabled"] = "no_compatible_wallet_service_enabled";
    UnprocessableEntityErrorCode["MissingAaProjectId"] = "missing_aa_project_id";
    UnprocessableEntityErrorCode["LinkedEmbeddedWallet"] = "linked_embedded_wallet";
})(UnprocessableEntityErrorCode || (UnprocessableEntityErrorCode = {}));
function UnprocessableEntityErrorCodeFromJSON(json) {
    return UnprocessableEntityErrorCodeFromJSONTyped(json);
}
function UnprocessableEntityErrorCodeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function UnprocessableEntityErrorCodeToJSON(value) {
    return value;
}

export { UnprocessableEntityErrorCode, UnprocessableEntityErrorCodeFromJSON, UnprocessableEntityErrorCodeFromJSONTyped, UnprocessableEntityErrorCodeToJSON };
