import { useState, useMemo, useCallback } from 'react';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import { isDisplayOrderPriorityForWeb3 } from '../../../../shared/utils/functions/isDisplayOrderPriorityForWeb3/isDisplayOrderPriorityForWeb3.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';

const web2DisplayOrder = ['email', 'wallet'];
const web3DisplayOrder = ['wallet', 'email'];
const useDisplayOrderState = (projectSettings) => {
    const [selectedDisplayOrder, setSelectedDisplayOrder] = useState(null);
    const defaultDisplayOrder = useMemo(() => {
        var _a;
        const displayOrderFromApi = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _a === void 0 ? void 0 : _a.displayOrder;
        if (!displayOrderFromApi)
            return web3DisplayOrder;
        if (isDisplayOrderPriorityForWeb3(displayOrderFromApi)) {
            return web3DisplayOrder;
        }
        if (web2DisplayOrder) {
            return web2DisplayOrder;
        }
        return web3DisplayOrder;
    }, [projectSettings]);
    const setDisplayOrderPriority = useCallback((priority) => {
        if (priority === 'web3') {
            setSelectedDisplayOrder(web3DisplayOrder);
        }
        else {
            setSelectedDisplayOrder(web2DisplayOrder);
        }
    }, [setSelectedDisplayOrder]);
    return [selectedDisplayOrder || defaultDisplayOrder, setDisplayOrderPriority];
};

export { useDisplayOrderState };
