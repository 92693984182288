/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var EmbeddedWalletProviderEnum;
(function (EmbeddedWalletProviderEnum) {
    EmbeddedWalletProviderEnum["Turnkey"] = "turnkey";
    EmbeddedWalletProviderEnum["Turnkeyhd"] = "turnkeyhd";
})(EmbeddedWalletProviderEnum || (EmbeddedWalletProviderEnum = {}));
function EmbeddedWalletProviderEnumFromJSON(json) {
    return EmbeddedWalletProviderEnumFromJSONTyped(json);
}
function EmbeddedWalletProviderEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function EmbeddedWalletProviderEnumToJSON(value) {
    return value;
}

export { EmbeddedWalletProviderEnum, EmbeddedWalletProviderEnumFromJSON, EmbeddedWalletProviderEnumFromJSONTyped, EmbeddedWalletProviderEnumToJSON };
