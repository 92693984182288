import { ProviderEnum } from '@dynamic-labs/sdk-api';

const mapEmailProviderToProviderEnum = {
    dynamic: ProviderEnum.Dynamic,
    emailOnly: ProviderEnum.EmailOnly,
    magicLink: ProviderEnum.MagicLink,
};
const allSupportedProviders = Object.values(mapEmailProviderToProviderEnum);

export { allSupportedProviders, mapEmailProviderToProviderEnum };
