import { jsx, jsxs } from 'react/jsx-runtime';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import { getChainIcon } from '../../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import 'react';
import '@dynamic-labs/utils';
import '@dynamic-labs/iconic';
import '../../../context/ViewContext/ViewContext.js';
import '../../../shared/consts/index.js';
import { Icon } from '../../../components/Icon/Icon.js';
import { classNames } from '../../../utils/functions/classNames/classNames.js';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '../../../context/DynamicContext/DynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../components/Typography/Typography.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import '../WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const WalletListGridTabs = ({ items, onSelect, value, }) => (jsx("div", { className: 'wallet-list-grid-tabs', children: items.map((item, index) => {
        const selected = value === index;
        const { icon, text } = item.label;
        const key = `${text}-${icon}`;
        const ChainIcon = icon ? getChainIcon(icon) : null;
        return (jsxs("button", { type: 'button', className: classNames('wallet-list-grid-tabs__button', {
                'wallet-list-grid-tabs__button--selected': selected,
                'wallet-list-grid-tabs__button--text': Boolean(text),
            }), tabIndex: -1, "aria-label": text !== null && text !== void 0 ? text : icon, onClick: () => onSelect(index), children: [ChainIcon && (jsx(Icon, { size: 'small', className: classNames({
                        'wallet-list-grid-tabs__icon--selected': !selected,
                    }), children: jsx(ChainIcon, {}) })), text && (jsx(Typography, { as: 'span', variant: 'body_small', weight: selected ? 'bold' : 'medium', color: selected ? 'primary' : 'secondary', children: text }))] }, key));
    }) }));

export { WalletListGridTabs };
