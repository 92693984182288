import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { usePasskeyRecovery } from '../usePasskeyRecovery/usePasskeyRecovery.js';

const useEmbeddedWalletAuthenticator = () => {
    const { initPasskeyRecoveryProcess, shouldInitRecovery } = usePasskeyRecovery();
    const createOrRefreshAuthenticatorSession = (authenticatorType) => __awaiter(void 0, void 0, void 0, function* () {
        // user has passkey authenticator set up OR active session
        // there is no need to create or refresh the authenticator
        if (authenticatorType !== 'passkey' && !(yield shouldInitRecovery())) {
            return;
        }
        return initPasskeyRecoveryProcess(authenticatorType);
    });
    return { createOrRefreshAuthenticatorSession };
};

export { useEmbeddedWalletAuthenticator };
