import { jsx } from 'react/jsx-runtime';
import { useWalletBookContext, findWalletGroupOverride, findWalletBookWallet, BrandIcon } from '@dynamic-labs/wallet-book';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import 'react';
import { ReactComponent as SvgStroke } from '../../../../shared/assets/stroke.js';
import { getWalletListItemLabel } from '../../../../shared/utils/functions/getWalletListItemLabel/getWalletListItemLabel.js';
import 'viem';
import '../../../../shared/utils/classes/storage/localStorage.js';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../utils/constants/values.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/multi-wallet';
import '../../../../utils/constants/colors.js';
import { pixelToRem } from '../../../../utils/functions/pixelToRem/pixelToRem.js';
import 'viem/chains';
import '../../../../events/dynamicEvents.js';
import '../../../../context/DynamicContext/DynamicContext.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import { Badge } from '../../../../components/Badge/Badge.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import { ListTileAnimatedTrailing } from '../../../../components/ListTileAnimatedTrailing/ListTileAnimatedTrailing.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../../context/LoadingContext/LoadingContext.js';
import { Icon } from '../../../../components/Icon/Icon.js';
import 'i18next';
import 'qrcode';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { ListTile } from '../../../../components/ListTile/ListTile.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const WalletGroupItem = ({ groupKey, wallet, onClick, }) => {
    const { walletBook } = useWalletBookContext();
    const walletOverride = findWalletGroupOverride(walletBook, groupKey, wallet.key);
    const walletData = findWalletBookWallet(walletBook, wallet.key);
    const brand = (walletOverride === null || walletOverride === void 0 ? void 0 : walletOverride.brand) || (walletData === null || walletData === void 0 ? void 0 : walletData.brand);
    const name = (walletOverride === null || walletOverride === void 0 ? void 0 : walletOverride.name) || (walletData === null || walletData === void 0 ? void 0 : walletData.name);
    const icon = brand ? (jsx(BrandIcon, { brand: brand, walletKey: wallet.key, style: { height: pixelToRem(28), width: pixelToRem(28) } })) : null;
    const label = getWalletListItemLabel({
        wallet,
    });
    const trailing = (jsx(ListTileAnimatedTrailing, { hoverElement: 
        // eslint-disable-next-line react/jsx-wrap-multilines
        jsx(Icon, { color: 'text-tertiary', size: 'mini', children: jsx(SvgStroke, {}) }), children: label && (jsx(Badge, { dot: true, text: jsx(Typography, { variant: 'body_small', children: label }) })) }));
    return (jsx(ListTile, { onClick: onClick, leading: icon, trailing: trailing, children: name }));
};

export { WalletGroupItem };
