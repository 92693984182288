import { createIconic } from '../../Iconic.js';

const BitbucketIcon = createIconic({
    alt: 'Bitbucket',
    iconName: 'bitbucket',
    sourcePath: 'icons/social/bitbucket.svg',
});
const DiscordIcon = createIconic({
    alt: 'Discord',
    iconName: 'discord',
    sourcePath: 'icons/social/discord.svg',
});
const FacebookIcon = createIconic({
    alt: 'Facebook',
    iconName: 'facebook',
    sourcePath: 'icons/social/facebook.svg',
});
const GithubIcon = createIconic({
    alt: 'Github',
    iconName: 'github',
    sourcePath: 'icons/social/github.svg',
});
const GithubDarkIcon = createIconic({
    alt: 'Github',
    iconName: 'github-dark',
    sourcePath: 'icons/social/github-dark.svg',
});
const GitlabIcon = createIconic({
    alt: 'Gitlab',
    iconName: 'gitlab',
    sourcePath: 'icons/social/gitlab.svg',
});
const GoogleIcon = createIconic({
    alt: 'Google',
    iconName: 'google',
    sourcePath: 'icons/social/google.svg',
});
const HelpdeskIcon = createIconic({
    alt: 'Helpdesk',
    iconName: 'helpdesk',
    sourcePath: 'icons/social/helpdesk.svg',
});
const LinkedinIcon = createIconic({
    alt: 'Linkedin',
    iconName: 'linkedin',
    sourcePath: 'icons/social/linkedin.svg',
});
const MicrosoftIcon = createIconic({
    alt: 'Microsoft',
    iconName: 'microsoft',
    sourcePath: 'icons/social/microsoft.svg',
});
const SlackIcon = createIconic({
    alt: 'Slack',
    iconName: 'slack',
    sourcePath: 'icons/social/slack.svg',
});
const TelegramIcon = createIconic({
    alt: 'Telegram',
    iconName: 'telegram',
    sourcePath: 'icons/social/telegram.svg',
});
const TwitchIcon = createIconic({
    alt: 'Twitch',
    iconName: 'twitch',
    sourcePath: 'icons/social/twitch.svg',
});
const TwitchDarkIcon = createIconic({
    alt: 'Twitch',
    iconName: 'twitch-dark',
    sourcePath: 'icons/social/twitch-dark.svg',
});
const TwitterIcon = createIconic({
    alt: 'Twitter',
    iconName: 'twitter',
    sourcePath: 'icons/social/twitter.svg',
});
const TwitterDarkIcon = createIconic({
    alt: 'Twitter',
    iconName: 'twitter-dark',
    sourcePath: 'icons/social/twitter-dark.svg',
});
const AppleSocialIcon = createIconic({
    alt: 'Apple',
    iconName: 'apple',
    sourcePath: 'icons/social/apple.svg',
});
const AppleDarkSocialIcon = createIconic({
    alt: 'Apple',
    iconName: 'apple-dark',
    sourcePath: 'icons/social/apple-dark.svg',
});
const CoinbaseSocialIcon = createIconic({
    alt: 'Coinbase',
    iconName: 'coinbasesocial',
    sourcePath: 'icons/social/coinbasesocial.svg',
});
const InstagramIcon = createIconic({
    alt: 'Instagram',
    iconName: 'instagram',
    sourcePath: 'icons/social/instagram.svg',
});
const YouTubeIcon = createIconic({
    alt: 'YouTube',
    iconName: 'youtube',
    sourcePath: 'icons/social/youtube.svg',
});
const OnePasswordIcon = createIconic({
    alt: 'onePassword',
    iconName: 'one-password',
    sourcePath: 'icons/social/onePassword.svg',
});
const OnePasswordDarkIcon = createIconic({
    alt: 'onePassword-dark',
    iconName: 'one-password-dark',
    sourcePath: 'icons/social/onePassword-dark.svg',
});

export { AppleDarkSocialIcon, AppleSocialIcon, BitbucketIcon, CoinbaseSocialIcon, DiscordIcon, FacebookIcon, GithubDarkIcon, GithubIcon, GitlabIcon, GoogleIcon, HelpdeskIcon, InstagramIcon, LinkedinIcon, MicrosoftIcon, OnePasswordDarkIcon, OnePasswordIcon, SlackIcon, TelegramIcon, TwitchDarkIcon, TwitchIcon, TwitterDarkIcon, TwitterIcon, YouTubeIcon };
