import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { useCallback } from 'react';
import { ChainEnum } from '@dynamic-labs/sdk-api';
import '../../../../context/DynamicContext/DynamicContext.js';
import { decodeJwt } from '../../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import '../../../constants/colors.js';
import '../../../constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import { dynamicEvents } from '../../../../events/dynamicEvents.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import { findEmbeddedWalletFromVerifiedCredentials } from '../../../functions/findEmbeddedWalletFromVerifiedCredentials/findEmbeddedWalletFromVerifiedCredentials.js';
import { findTurnkeyWallet } from '../../../functions/findTurnkeyWallet/findTurnkeyWallet.js';
import '@dynamic-labs/multi-wallet';
import { getUserWalletsFromVerifiedCredentials } from '../../../functions/getUserWalletsFromVerifiedCredentials/getUserWalletsFromVerifiedCredentials.js';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useCreateDynamicEmbeddedWalletMutation } from '../../useCreateDynamicEmbeddedWalletMutation/useCreateDynamicEmbeddedWalletMutation.js';
import { findSmartWallet, initializeSmartWallet } from '../../useWalletConnectors/utils/smartWallet/smartWallet.js';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

// Hook exposed to customers and used internally to trigger embedded wallet creation
const useTurnkey = () => {
    const { projectSettings, setPrimaryWalletId, walletConnectorOptions, setShowAuthFlow, primaryWallet, environmentId, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const { createDynamicEmbeddedWalletMutation } = useCreateDynamicEmbeddedWalletMutation();
    const startPregeneratedWalletCreationFlow = useCallback((jwt, decodedJwt) => __awaiter(void 0, void 0, void 0, function* () {
        yield createDynamicEmbeddedWalletMutation({
            authToken: jwt,
            decodedJwt,
            environmentId,
            walletConnectorOptions,
            withAuthenticator: false,
        });
        setShowAuthFlow(false, { performMultiWalletChecks: false }); // close email otp pin modal
        return new Promise((resolve, reject) => {
            dynamicEvents.once('embeddedWalletCreated', (wallet) => resolve(wallet));
            dynamicEvents.once('embeddedWalletFailed', (error) => reject(error));
        });
    }), [
        createDynamicEmbeddedWalletMutation,
        environmentId,
        walletConnectorOptions,
        setShowAuthFlow,
    ]);
    const startEmbeddedWalletCreationFlow = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setShowAuthFlow(true, {
            ignoreIfIsEmbeddedWidget: false,
            performMultiWalletChecks: false,
        });
        setView('passkey-intro');
        return new Promise((resolve, reject) => {
            dynamicEvents.once('embeddedWalletCreated', (wallet) => resolve(wallet));
            dynamicEvents.once('embeddedWalletFailed', (error) => {
                // when creating a passkey, if user cancels the passkey modal more than once
                // it will throw this DOMException, but we don't want to let user to have access
                // to the app before they've a passkey correctly setup
                if (error instanceof DOMException &&
                    error.name === 'NotAllowedError') {
                    logger.error('User cancelled the passkey creation.', error);
                    return;
                }
                reject(error);
            });
        });
    }), [setShowAuthFlow, setView]);
    const returnEmbeddedWallet = useCallback((jwt, embeddedWalletVerifiedCredential) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const decodedJwt = decodeJwt(jwt);
        const { chain } = embeddedWalletVerifiedCredential;
        const turnkeyWallet = findTurnkeyWallet(walletConnectorOptions, chain);
        // if user already has embedded wallet, set it as primary wallet
        if (decodedJwt) {
            (_a = turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) === null || _a === void 0 ? void 0 : _a.setVerifiedCredentials(decodedJwt.verifiedCredentials);
            const smartWallet = findSmartWallet(embeddedWalletVerifiedCredential, decodedJwt.verifiedCredentials);
            if (smartWallet) {
                yield initializeSmartWallet({
                    account: smartWallet,
                    verifiedCredentials: decodedJwt.verifiedCredentials,
                    walletConnectorOptions,
                });
                setPrimaryWalletId(smartWallet.id);
            }
            else {
                setPrimaryWalletId(embeddedWalletVerifiedCredential.id);
            }
        }
        /** It's not authenticated if it still hasn't created a passkey */
        const authenticated = Boolean((_b = embeddedWalletVerifiedCredential.walletProperties) === null || _b === void 0 ? void 0 : _b.isAuthenticatorAttached);
        const passkeyWallet = {
            address: (yield ((_c = turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) === null || _c === void 0 ? void 0 : _c.fetchPublicAddress())) || '',
            authenticated,
            chain: ((_d = turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) === null || _d === void 0 ? void 0 : _d.connectedChain) || '',
            connected: true,
            connector: (turnkeyWallet === null || turnkeyWallet === void 0 ? void 0 : turnkeyWallet.walletConnector) || {},
            id: embeddedWalletVerifiedCredential.id,
        };
        return Promise.resolve(passkeyWallet);
    }), [setPrimaryWalletId, walletConnectorOptions]);
    const createTurnkeyWallet = useCallback((jwt, decodedJwt, chain = ChainEnum.Evm) => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        const userWaletsCredentials = getUserWalletsFromVerifiedCredentials(jwt);
        // if user doesn't have a wallet yet,
        if (!(userWaletsCredentials === null || userWaletsCredentials === void 0 ? void 0 : userWaletsCredentials.length)) {
            // create wallet without authenticator
            if (!((_e = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _e === void 0 ? void 0 : _e.forceAuthenticatorAtSignup)) {
                return startPregeneratedWalletCreationFlow(jwt, decodedJwt);
            }
            // show the passkey view to create an embedded wallet
            return startEmbeddedWalletCreationFlow();
        }
        setShowAuthFlow(false, { performMultiWalletChecks: false });
        const embeddedWalletVerifiedCredential = findEmbeddedWalletFromVerifiedCredentials(jwt, chain);
        // if user logged in with MM for example, just continue the flow
        if (!embeddedWalletVerifiedCredential) {
            // user logged in through an email linked to a third party wallet
            // at this point primary wallet is still undefined and we need to set it
            if (!primaryWallet) {
                setPrimaryWalletId(userWaletsCredentials[0].id);
            }
            return Promise.resolve(primaryWallet);
        }
        // if user already has embedded wallet, returns it
        return returnEmbeddedWallet(jwt, embeddedWalletVerifiedCredential);
    }), [
        projectSettings,
        setShowAuthFlow,
        returnEmbeddedWallet,
        startEmbeddedWalletCreationFlow,
        startPregeneratedWalletCreationFlow,
        primaryWallet,
        setPrimaryWalletId,
    ]);
    return {
        createTurnkeyWallet,
    };
};

export { useTurnkey };
