import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { isEmbeddedConnector, isBloctoConnector } from '@dynamic-labs/wallet-connector-core';
import 'react';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../constants/colors.js';
import '../../../constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import { useCaptchaContext } from '../../../../context/CaptchaContext/CaptchaContext.js';
import { useErrorContext } from '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import { useVerifyWallet } from '../../useVerifyWallet/useVerifyWallet.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useSignConnectOnlyUser = () => {
    const { setView } = useViewContext();
    const { setErrorMessage } = useErrorContext();
    const { getCaptchaToken } = useCaptchaContext();
    const { connectWallet, consumeNonce, displaySiweStatement, projectSettings, environmentId, setIsSingleWalletAccount, setShowAuthFlow, setSelectedWalletConnectorKey, walletUiUtils, } = useInternalDynamicContext();
    const verifyWallet = useVerifyWallet({
        consumeNonce,
        displaySiweStatement,
        environmentId,
        projectSettings,
        setIsSingleWalletAccount,
    });
    return ({ walletConnector, fetchPublicAddressOpts = undefined, }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (isEmbeddedConnector(walletConnector) &&
                !isBloctoConnector(walletConnector)) {
                walletUiUtils.disabledConfirmationOnce();
            }
            // Prepare states to show the pending signature view (without back button)
            // We need this because in connect-only we don't want to show wallet-list
            // We don't want to show the back button in the pending signature view
            // because we don't want to go back to the wallet-list
            setSelectedWalletConnectorKey(walletConnector.key);
            setView('pending-signature-without-back-button');
            setShowAuthFlow(true);
            // Wallet should be already connected at this point
            // We just need to verify it
            const connectionResult = yield connectWallet(walletConnector, fetchPublicAddressOpts);
            if (!(connectionResult === null || connectionResult === void 0 ? void 0 : connectionResult.address)) {
                return;
            }
            yield verifyWallet({
                captchaToken: getCaptchaToken(),
                publicWalletAddress: connectionResult.address,
                walletConnector,
            });
        }
        catch (err) {
            logger.debug(err);
            setErrorMessage(err.toString());
            setShowAuthFlow(false, { emitCancelAuth: true });
            return;
        }
    });
};

export { useSignConnectOnlyUser };
