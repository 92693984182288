import { normalizeWalletName } from '@dynamic-labs/wallet-connector-core';
import { VerifiedCredentialNameToChainEnum } from '../../../../constants/values.js';

// remaining normalizeWalletName until QNTM-846 is complete due to us still passing the walletname to redcoast:
// https://linear.app/dynamic-labs/issue/QNTM-846/remove-usage-of-walletwalletconnectorwname-to-instead-use
const findWallet = (account, walletOptions) => {
    const normalizedWalletName = account.walletName && normalizeWalletName(account.walletName);
    if (normalizedWalletName === 'turnkeyhd') {
        const chain = account.chain
            ? VerifiedCredentialNameToChainEnum[account.chain]
            : undefined;
        return walletOptions.find((wallet) => normalizedWalletName === wallet.key &&
            wallet.walletConnector.connectedChain === chain);
    }
    return walletOptions.find((wallet) => normalizedWalletName === wallet.key);
};

export { findWallet };
