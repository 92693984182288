import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { custom, createWalletClient } from 'viem';
import * as chains from 'viem/chains';

const findChain = (chainId) => Object.values(chains).find((chain) => chain.id.toString() === chainId.toString());
/**
 * Creates a WalletClient instance from a given Wallet object.
 *
 * This function initializes a WalletClient with the appropriate transport, chain, and account
 * details based on the provided Wallet.
 *
 * @param {Wallet} wallet - The wallet
 * @returns {Promise<WalletClient<Transport, Chain, Account>>} - A promise that resolves to a WalletClient instance.
 * @throws Will throw an error if the wallet's chain is not 'eip155', the wallet's network is not set, or the
 * chain is not supported.
 */
const createWalletClientFromWallet = (wallet) => __awaiter(void 0, void 0, void 0, function* () {
    if (wallet.chain !== 'eip155') {
        throw new Error(`Wallet chain "${wallet.chain}" is not viem compatible`);
    }
    const network = wallet.network || (yield wallet.connector.getNetwork());
    if (!network) {
        throw new Error('Wallet network is not set');
    }
    const walletClient = yield wallet.connector.getWalletClient();
    const chain = walletClient.chain || findChain(network);
    const account = walletClient.account || wallet.address;
    const transport = custom(walletClient.transport);
    if (chain === undefined) {
        throw new Error(`Chain "${network}" is not supported.`);
    }
    return createWalletClient({
        account,
        chain,
        transport,
    });
});

export { createWalletClientFromWallet };
