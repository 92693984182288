import 'react';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import { useMockContext } from '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { isUserLinkingWallet } from '../../../shared/utils/functions/isUserLinkingWallet/isUserLinkingWallet.js';
import { useDynamicLayoutData } from '../useDynamicLayoutData/useDynamicLayoutData.js';
import { useFooterAnimationContext } from '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useAuthLayoutChecks = () => {
    const { view } = useViewContext();
    const { user, customPrivacyPolicy, customTermsOfServices, privacyPolicyUrl, termsOfServiceUrl, isBridgeFlow, authMode, } = useInternalDynamicContext();
    const { isFooterExpanded } = useFooterAnimationContext();
    const { mockedSDK } = useMockContext();
    const { helpHeaderData } = useDynamicLayoutData({
        authMode,
        view: view.type,
    });
    const displayBorderBelowHeader = (view.type !== 'wallet-list' &&
        view.type !== 'login-with-email-or-wallet-full-wallet-list' &&
        view.type !== 'multi-wallet-wallet-list' &&
        view.type !== 'login-with-wallet-only') ||
        isFooterExpanded;
    const isWalletListTypeView = view.type === 'wallet-connect-mobile-wallets-list' ||
        view.type === 'wallet-list' ||
        view.type === 'login-with-email-or-wallet-full-wallet-list' ||
        view.type === 'login-with-wallet-only';
    const showHelpContent = Boolean(helpHeaderData &&
        // Notice the negation here
        !(view.type === 'qr-code' ||
            view.type === 'no-qr-not-installed' ||
            view.type === 'wallet-used' ||
            view.type === 'chainalysis-blocked-wallet' ||
            view.type === 'wallet-sign' ||
            view.type === 'collect-user-data' ||
            view.type === 'passkey-new-domain-detected' ||
            isUserLinkingWallet(user, view.type, mockedSDK)));
    const showHeader = view.type !== 'collect-user-data' &&
        view.type !== 'no-access' &&
        view.type !== 'chainalysis-blocked-wallet' &&
        view.type !== 'wallet-locked-view' &&
        view.type !== 'gate-blocked-wallet' &&
        view.type !== 'email-wallet-otp-verification-view' &&
        view.type !== 'bridge-welcome' &&
        view.type !== 'bridge-summary' &&
        view.type !== 'bridge-next-wallet-connection' &&
        view.type !== 'passkey-intro' &&
        view.type !== 'passkey-new-domain-detected' &&
        view.type !== 'social-redirect-view' &&
        view.type !== 'update-email' &&
        view.type !== 'collect-user-data-login-with-email' &&
        view.type !== 'passkey-recovery-bundle' &&
        view.type !== 'passkey-recovery-start' &&
        view.type !== 'embedded-reveal-view' &&
        view.type !== 'embedded-reveal-account-view' &&
        view.type !== 'rename-passkey' &&
        view.type !== 'embedded-wallet-auth-choice';
    const showConnectedWalletProgress = isBridgeFlow &&
        (view.type === 'wallet-list' ||
            view.type === 'qr-code' ||
            view.type === 'wallet-connect-mobile-wallets-list' ||
            view.type === 'pending-connect' ||
            view.type === 'pending-signature' ||
            view.type === 'pending-signature-without-back-button' ||
            view.type === 'bridge-next-wallet-connection' ||
            view.type === 'bridge-summary');
    const showToSFooter = Boolean(!isUserLinkingWallet(user, view.type, mockedSDK) &&
        (view.type === 'wallet-list' ||
            view.type === 'wallet-connect-mobile-wallets-list' ||
            // Don't show empty footer during the login
            ((view.type === 'login-with-email-or-wallet' ||
                view.type === 'login-with-email-or-wallet-full-wallet-list' ||
                view.type === 'login-with-wallet-only' ||
                view.type === 'multi-wallet-wallet-list') &&
                (termsOfServiceUrl ||
                    privacyPolicyUrl ||
                    customPrivacyPolicy ||
                    customTermsOfServices))));
    const showDynamicFooter = view.type === 'login-with-email-verification' ||
        view.type === 'verify-email' ||
        view.type === 'pending-connect' ||
        view.type === 'wallet-group' ||
        view.type === 'select-wallet-in-wallet-group' ||
        view.type === 'select-hardware-wallet' ||
        view.type === 'pending-signature' ||
        view.type === 'qr-code' ||
        view.type === 'wallet-used' ||
        view.type === 'email-wallet-otp-verification-view';
    const showCloseButton = view.type !== 'login-with-email-or-wallet-full-wallet-list' &&
        view.type !== 'login-with-email-verification' &&
        view.type !== 'network-not-supported' &&
        view.type !== 'passkey-recovery-complete' &&
        view.type !== 'passkey-recovery-start' &&
        view.type !== 'passkey-recovery-bundle' &&
        view.type !== 'rename-passkey' &&
        view.type !== 'qr-code';
    const showBackButton = view.type === 'pending-signature' ||
        view.type === 'no-qr-not-installed' ||
        view.type === 'wallet-connect-mobile-wallets-list' ||
        view.type === 'login-with-email-verification' ||
        view.type === 'login-with-email-or-wallet-full-wallet-list' ||
        view.type === 'wallet-group' ||
        view.type === 'select-wallet-in-wallet-group' ||
        view.type === 'select-hardware-wallet' ||
        view.type === 'pending-connect' ||
        view.type === 'account-exists' ||
        view.type === 'passkey-intro' ||
        view.type === 'qr-code' ||
        (view.type === 'merge-user-accounts' && !user);
    const showDefaultFooter = !showConnectedWalletProgress && !showDynamicFooter && !showToSFooter;
    return {
        displayBorderBelowHeader,
        isWalletListTypeView,
        showBackButton,
        showCloseButton,
        showConnectedWalletProgress,
        showDefaultFooter,
        showDynamicFooter,
        showHeader,
        showHelpContent,
        showToSFooter,
    };
};

export { useAuthLayoutChecks };
