import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../IconButton/IconButton.js';
import { IconWithSpinner } from '../IconWithSpinner/IconWithSpinner.js';
import { ModalHeader } from '../ModalHeader/ModalHeader.js';
import { Typography } from '../Typography/Typography.js';
import { PinField } from '../PinField/PinField.js';
import { IconWithStatus } from '../IconWithStatus/IconWithStatus.js';
import { ModalHeaderBanner } from '../ModalHeaderBanner/ModalHeaderBanner.js';
import { Icon } from '../Icon/Icon.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgCheck } from '../../shared/assets/check.js';
import { ReactComponent as SvgChevronLeft } from '../../shared/assets/chevron-left.js';
import { ReactComponent as SvgPencilAltIcon } from '../../shared/assets/pencil-alt-icon.js';
import { ReactComponent as SvgSignInWithEmail } from '../../shared/assets/sign-in-with-email.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import { shortenEmail } from '../../shared/utils/functions/shortenEmail/shortenEmail.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import { RetrySendVerificationCodeSection } from '../RetrySendVerificationCodeSection/RetrySendVerificationCodeSection.js';

const OTPVerificationView = ({ onClickBack, onClickEditEmail, isLoading, email, onPinComplete, isValid, error, retryHandler, onPinChange, successBannerTextKey, }) => {
    const { t } = useTranslation();
    const backButton = onClickBack && (jsx(IconButton, { type: 'button', onClick: onClickBack, "data-testid": 'back-button', children: jsx(SvgChevronLeft, {}) }));
    const EmailIcon = useMemo(() => {
        const EmailIcon = (props) => (jsx(Icon, { color: 'brand-primary', children: jsx(SvgSignInWithEmail, Object.assign({}, props, { "data-testid": 'sign-in-with-email-icon' })) }));
        return EmailIcon;
    }, []);
    const titleCopyKey = () => isValid
        ? 'dyn_otp_verification.verification_succeeded'
        : 'dyn_otp_verification.confirm_code';
    return (jsxs(Fragment, { children: [jsx(ModalHeader, { alignContent: 'bottom', leading: onClickBack && backButton, children: jsx("div", { className: 'otp-verification-view__status-header', children: jsx(Typography, { weight: 'medium', variant: 'title', color: 'primary', className: 'otp-verification-view__title', copykey: titleCopyKey(), children: t(titleCopyKey()) }) }) }), successBannerTextKey && (jsx(ModalHeaderBanner, { type: 'notice', messageKey: successBannerTextKey, disableTruncate: true })), jsx(ModalHeaderBanner, { type: 'error', messageKey: error === null || error === void 0 ? void 0 : error.message }), jsxs("div", { className: classNames('otp-verification-view', {
                    'otp-verification-view--message': Boolean(successBannerTextKey || error),
                }), children: [isValid && (jsx(IconWithStatus, { Icon: EmailIcon, iconSize: 96, InnerIcon: SvgCheck })), !isValid && (jsx(IconWithSpinner, { Icon: EmailIcon, iconSize: 96, isSpinning: isLoading, className: 'otp-verification-view__icon-with-spinner', treatAsFunctionComponent: true })), jsxs("div", { className: 'otp-verification-view__body', children: [isValid && (jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', copykey: 'dyn_otp_verification.hang_tight', children: t('dyn_otp_verification.hang_tight') })), !isValid && (jsxs(Fragment, { children: [jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', copykey: 'dyn_otp_verification.code_sent', children: t('dyn_otp_verification.code_sent') }), jsxs("div", { className: 'otp-verification-view__email-container', children: [jsx(Typography, { variant: 'body_normal', weight: 'bold', color: 'secondary', children: shortenEmail(email) }), onClickEditEmail && (jsx(IconButton, { onClick: onClickEditEmail, "data-testid": 'otp-verification-view__edit-button', children: jsx(Icon, { color: 'text-tertiary', children: jsx(SvgPencilAltIcon, {}) }) }))] })] }))] }), jsx(PinField, { initialValue: Array(6).join('.').split('.'), isLoading: isLoading, handleComplete: onPinComplete, isValidated: isValid, inputMode: 'numeric', pattern: '[0-9]*', hasError: Boolean(error), onChange: onPinChange }), jsx(RetrySendVerificationCodeSection, { className: 'otp-verification-view__retry-container', retryHandler: retryHandler })] })] }));
};

export { OTPVerificationView };
