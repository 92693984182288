import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useCallback } from 'react';
import { useDynamicBridgeWidgetContext } from '../../context/DynamicBridgeWidgetContext/DynamicBridgeWidgetContext.js';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import { useUserUpdateRequestInternal } from '../../../../utils/hooks/useUserUpdateRequest/useUserUpdateRequest.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import { UserProfileForm } from '../../../../components/UserProfileForm/UserProfileForm.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const EditProfileView = () => {
    const { setView } = useViewContext();
    const { goToProfileView } = useDynamicBridgeWidgetContext();
    const { setShowAuthFlow } = useInternalDynamicContext();
    const { updateUser } = useUserUpdateRequestInternal({
        validationSchemaStripUnknown: true,
    });
    const onEditProfileFormSubmit = useCallback((formValues) => __awaiter(void 0, void 0, void 0, function* () {
        const { isEmailVerificationRequired } = yield updateUser(formValues);
        goToProfileView();
        if (isEmailVerificationRequired) {
            setShowAuthFlow(true, {
                ignoreIfIsEmbeddedWidget: false,
                performMultiWalletChecks: false,
            });
            setView('verify-email');
        }
    }), [goToProfileView, setShowAuthFlow, setView, updateUser]);
    return (jsx(UserProfileForm, { onEditProfileSubmit: onEditProfileFormSubmit, onEditProfileCancel: goToProfileView }));
};

export { EditProfileView, EditProfileView as default };
