var groups = {
	argentx: {
		brand: {
			alt: "ArgentX",
			primaryColor: "#FF875B",
			spriteId: "argentx"
		},
		key: "argentx",
		name: "ArgentX"
	},
	bitgetwallet: {
		brand: {
			alt: "Bitget Wallet",
			spriteId: "bitget"
		},
		key: "bitgetwallet",
		name: "Bitget Wallet"
	},
	blocto: {
		brand: {
			alt: "Blocto",
			primaryColor: "#5E678A",
			spriteId: "blocto"
		},
		key: "blocto",
		name: "Blocto"
	},
	brave: {
		brand: {
			alt: "Brave Browser",
			primaryColor: "#4B49C6",
			spriteId: "brave"
		},
		key: "brave",
		name: "Brave"
	},
	coinbase: {
		brand: {
			alt: "Coinbase Wallet",
			primaryColor: "#1648F9",
			spriteId: "coinbase"
		},
		key: "coinbase",
		name: "Coinbase"
	},
	exodus: {
		brand: {
			alt: "Exodus Wallet",
			spriteId: "exodus"
		},
		key: "exodus",
		name: "Exodus"
	},
	magiceden: {
		brand: {
			alt: "Magic Eden",
			spriteId: "magiceden"
		},
		key: "magiceden",
		name: "Magic Eden"
	},
	okxwallet: {
		brand: {
			alt: "OKX Wallet",
			spriteId: "okx"
		},
		key: "okxwallet",
		name: "OKX Wallet"
	},
	phantom: {
		brand: {
			alt: "Phantom",
			primaryColor: "#4B49C6",
			spriteId: "phantom"
		},
		key: "phantom",
		name: "Phantom"
	}
};
var wallets = {
	metamask: {
		brand: {
			alt: "MetaMask Wallet",
			primaryColor: "#E8831D",
			spriteId: "metamask"
		},
		chains: [
			"eip155:1"
		],
		desktop: {
			chromeId: "nkbihfbeogaeaoehlefnkodbefgpgknn",
			edgeId: "ejbalbakoplchlghecdalmeeeajnimhm",
			firefoxId: "ether-metamask"
		},
		eip6963Config: {
			rdns: "io.metamask"
		},
		filterFromWalletConnect: true,
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isMetaMask",
						value: true
					},
					{
						flag: "isDawn",
						value: false
					},
					{
						flag: "isBraveWallet",
						value: false
					},
					{
						flag: "isTrustWallet",
						value: false
					},
					{
						flag: "isExodus",
						value: false
					},
					{
						flag: "isZerion",
						value: false
					},
					{
						flag: "isSuperb",
						value: false
					},
					{
						flag: "isRabby",
						value: false
					},
					{
						flag: "isOKExWallet",
						value: false
					},
					{
						flag: "isOkxWallet",
						value: false
					},
					{
						flag: "isRainbow",
						value: false
					},
					{
						flag: "isAvalanche",
						value: false
					},
					{
						flag: "isMagicEden",
						value: false
					}
				]
			}
		],
		mobile: {
			androidId: "io.metamask",
			iosId: "id1438144202",
			native: "metamask://wc",
			universal: "https://metamask.app.link/wc"
		},
		name: "MetaMask",
		shortName: "MetaMask",
		walletConnect: {
			sdks: [
				"sign_v1",
				"sign_v2"
			]
		}
	},
	coinbase: {
		brand: {
			alt: "Coinbase Wallet",
			primaryColor: "#1648F9",
			spriteId: "coinbase"
		},
		chainGroup: "coinbase",
		desktop: {
			chromeId: "hnfanknocfeofbddgcijnmhnfnkdnaad"
		},
		eip6963Config: {
			rdns: "com.coinbase.wallet"
		},
		group: "coinbase",
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isCoinbaseWallet",
						value: true
					}
				],
				windowLocations: [
					"coinbaseWalletExtension"
				]
			}
		],
		mobile: {
			androidId: "org.toshi",
			iosId: "id1278383455"
		},
		name: "Coinbase",
		shortName: "Coinbase Wallet"
	},
	coinbasesolana: {
		brand: {
			alt: "Coinbase Wallet",
			primaryColor: "#1648F9",
			spriteId: "coinbase"
		},
		chainGroup: "coinbase",
		desktop: {
			chromeId: "hnfanknocfeofbddgcijnmhnfnkdnaad"
		},
		group: "coinbase",
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
				],
				windowLocations: [
					"coinbaseSolana"
				]
			}
		],
		name: "Coinbase",
		shortName: "Coinbase Wallet (Solana)"
	},
	phantom: {
		brand: {
			alt: "Phantom Wallet",
			primaryColor: "#4B49C6",
			spriteId: "phantom"
		},
		chainGroup: "phantom",
		desktop: {
			chromeId: "bfnaelmomeimhlpmgjnjophhpkkoljpa",
			firefoxId: "phantom-app"
		},
		group: "phantom",
		hardwareWallets: [
			"ledger"
		],
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
					{
						flag: "isPhantom",
						value: true
					},
					{
						flag: "isBraveWallet",
						value: false
					},
					{
						flag: "isExodus",
						value: false
					},
					{
						flag: "isMagicEden",
						value: false
					},
					{
						flag: "isGlow",
						value: false
					}
				],
				windowLocations: [
					"phantom.solana"
				]
			}
		],
		mobile: {
			androidId: "app.phantom",
			iosId: "id1598432977"
		},
		name: "Phantom"
	},
	phantomevm: {
		brand: {
			alt: "Phantom Wallet",
			primaryColor: "#4B49C6",
			spriteId: "phantom"
		},
		chainGroup: "phantom",
		desktop: {
			chromeId: "bfnaelmomeimhlpmgjnjophhpkkoljpa",
			firefoxId: "phantom-app"
		},
		group: "phantom",
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isPhantom",
						value: true
					}
				],
				windowLocations: [
					"phantom.ethereum"
				]
			}
		],
		mobile: {
			androidId: "app.phantom",
			iosId: "id1598432977"
		},
		name: "Phantom",
		shortName: "Phantom (EVM)"
	},
	phantomledger: {
		brand: {
			alt: "Phantom Wallet",
			primaryColor: "#4B49C6",
			spriteId: "phantom"
		},
		chainGroup: "phantom",
		desktop: {
			chromeId: "bfnaelmomeimhlpmgjnjophhpkkoljpa",
			firefoxId: "phantom-app"
		},
		group: "phantom",
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
					{
						flag: "isPhantom",
						value: true
					},
					{
						flag: "isBraveWallet",
						value: false
					},
					{
						flag: "isExodus",
						value: false
					},
					{
						flag: "isMagicEden",
						value: false
					},
					{
						flag: "isGlow",
						value: false
					}
				],
				windowLocations: [
					"phantom.solana"
				]
			}
		],
		mobile: {
			androidId: "app.phantom",
			iosId: "id1598432977"
		},
		name: "Phantom",
		shortName: "Phantom (Ledger)"
	},
	walletconnect: {
		brand: {
			alt: "WalletConnect",
			primaryColor: "#3182CE",
			spriteId: "walletconnect"
		},
		mobile: {
			androidId: "enable-android",
			iosId: "id1438144202"
		},
		name: "WalletConnect"
	},
	argentx: {
		brand: {
			alt: "Argent X Wallet",
			primaryColor: "#FF875B",
			spriteId: "argentx"
		},
		desktop: {
			chromeId: "dlcobpjiigpikoobohmabehhmhfoodbb",
			edgeId: "ajcicjlkibolbeaaagejfhnofogocgcj",
			firefoxId: "argent-x"
		},
		mobile: {
			androidId: "im.argent.contractwalletclient",
			iosId: "id1358741926"
		},
		name: "Argent X"
	},
	myalgo: {
		brand: {
			alt: "MyAlgo Wallet",
			spriteId: "myalgo"
		},
		name: "MyAlgo"
	},
	blocto: {
		brand: {
			alt: "Blocto Wallet",
			primaryColor: "#5E678A",
			spriteId: "blocto"
		},
		chainGroup: "blocto",
		group: "blocto",
		mobile: {
			androidId: "com.portto.blocto",
			iosId: "id1481181682"
		},
		name: "Blocto",
		shortName: "Blocto (Flow)"
	},
	solflare: {
		brand: {
			alt: "Solflare Wallet",
			primaryColor: "#FC7227",
			spriteId: "solflare"
		},
		desktop: {
			chromeId: "bhhhlbepdkbapadjdnnojkbgioiodbic"
		},
		hardwareWallets: [
			"ledger"
		],
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
					{
						flag: "isSolflare",
						value: true
					}
				],
				windowLocations: [
					"solflare"
				]
			}
		],
		mobile: {
			androidId: "com.solflare.mobile",
			iosId: "id1580902717"
		},
		name: "Solflare"
	},
	braavos: {
		brand: {
			alt: "Braavos Wallet",
			primaryColor: "#FABB38",
			spriteId: "braavos"
		},
		desktop: {
			chromeId: "jnlgamecbpmbajjfhmmmlhejkemejdma",
			edgeId: "hkkpjehhcnhgefhbdcgfkeegglpjchdc",
			firefoxId: "braavos-wallet"
		},
		mobile: {
			androidId: "app.braavos.wallet",
			iosId: "id1636013523"
		},
		name: "Braavos"
	},
	trust: {
		brand: {
			alt: "Trust Wallet",
			primaryColor: "#0500FF",
			spriteId: "7677b54f-3486-46e2-4e37-bf8747814f00"
		},
		chains: [
			"eip155:1"
		],
		desktop: {
			chromeId: "egjidjbpglichdcondbcbdnbeeppgdph"
		},
		eip6963Config: {
			rdns: "com.trustwallet.app"
		},
		filterFromWalletConnect: true,
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isTrustWallet",
						value: true
					}
				],
				windowLocations: [
					"trustWallet"
				]
			}
		],
		mobile: {
			androidId: "com.wallet.crypto.trustapp",
			iosId: "id1288339409",
			native: "trust://wc"
		},
		name: "Trust",
		shortName: "Trust",
		walletConnect: {
			sdks: [
				"sign_v1",
				"sign_v2",
				"auth_v1"
			]
		}
	},
	rainbow: {
		brand: {
			alt: "Rainbow Wallet",
			primaryColor: "#001e59",
			spriteId: "rainbow"
		},
		chains: [
			"eip155:1",
			"eip155:10",
			"eip155:137",
			"eip155:42161",
			"eip155:56",
			"eip155:7777777",
			"eip155:8453"
		],
		desktop: {
			chromeId: "opfgelmcmbiajamepnmloijbpoleiama",
			edgeId: "cpojfbodiccabbabgimdeohkkpjfpbnf",
			firefoxId: "rainbow-extension"
		},
		eip6963Config: {
			rdns: "me.rainbow"
		},
		filterFromWalletConnect: true,
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isRainbow",
						value: true
					}
				],
				windowLocations: [
					"rainbow"
				]
			}
		],
		mobile: {
			androidId: "me.rainbow",
			iosId: "id1457119021",
			native: "rainbow://wc",
			universal: "https://rnbwapp.com/wc"
		},
		name: "Rainbow",
		walletConnect: {
			sdks: [
				"sign_v1",
				"sign_v2",
				"auth_v1"
			]
		}
	},
	dapper: {
		brand: {
			alt: "Dapper Wallet",
			primaryColor: "#762FBE",
			spriteId: "dapper"
		},
		mobile: {
			android: "fake-to-get-dapper-to-appear",
			ios: "fake-to-get-dapper-to-appear"
		},
		name: "Dapper"
	},
	slope: {
		brand: {
			alt: "Slope Wallet",
			primaryColor: "#6E66FA",
			spriteId: "slope"
		},
		desktop: {
			chromeId: "pocmplpaccanhmnllbbkpgfliimjljgo"
		},
		mobile: {
			androidId: "com.wd.wallet",
			iosId: "id1574624530"
		},
		name: "Slope"
	},
	glow: {
		brand: {
			alt: "Glow Wallet",
			spriteId: "glow"
		},
		desktop: {
			chromeId: "ojbcfhjmpigfobfclfflafhblgemeidi",
			edgeId: "niihfokdlimbddhfmngnplgfcgpmlido",
			firefoxId: "glow-solana-wallet"
		},
		hardwareWallets: [
			"ledger"
		],
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
					{
						flag: "isGlow",
						value: true
					}
				],
				windowLocations: [
					"glowSolana"
				]
			}
		],
		name: "Glow"
	},
	lilico: {
		brand: {
			alt: "Lilico Wallet",
			spriteId: "lilico"
		},
		desktop: {
			chromeId: "hpclkefagolihohboafpheddmmgdffjm"
		},
		filterFromWalletConnect: true,
		name: "Lilico"
	},
	magicemailotp: {
		brand: {
			alt: "Magic Email OTP",
			spriteId: "magiclink"
		},
		name: "Magic Email OTP"
	},
	magiclink: {
		brand: {
			alt: "Magic Link",
			spriteId: "magiclink"
		},
		name: "Magic Link"
	},
	magicsocial: {
		brand: {
			alt: "Magic Social",
			spriteId: "magiclink"
		},
		name: "Magic Social"
	},
	magiceden: {
		brand: {
			alt: "Magic Eden",
			spriteId: "magiceden"
		},
		chainGroup: "magiceden",
		desktop: {
			chromeId: "mkpegjkblkkefacfnmkajcjmabijhclg"
		},
		group: "magiceden",
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isMagicEden",
						value: true
					}
				],
				windowLocations: [
					"magicEden.ethereum"
				]
			}
		],
		name: "Magic Eden"
	},
	magicedensol: {
		brand: {
			alt: "Magic Eden",
			spriteId: "magiceden"
		},
		chainGroup: "magiceden",
		desktop: {
			chromeId: "mkpegjkblkkefacfnmkajcjmabijhclg"
		},
		group: "magiceden",
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
					{
						flag: "isMagicEden",
						value: true
					}
				],
				windowLocations: [
					"magicEden.solana"
				]
			}
		],
		name: "Magic Eden",
		shortName: "Magic Eden (Solana)"
	},
	exodus: {
		brand: {
			alt: "Exodus Wallet",
			spriteId: "4c16cad4-cac9-4643-6726-c696efaf5200"
		},
		chainGroup: "exodus",
		chains: [
			"eip155:1",
			"eip155:137",
			"eip155:43114",
			"eip155:56",
			"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ"
		],
		desktop: {
			chromeId: "aholpfdialjgjfhomihkjbmgjidlcdno"
		},
		filterFromWalletConnect: true,
		group: "exodus",
		mobile: {
			androidId: "exodusmovement.exodus",
			iosId: "id1414384820",
			native: "exodus://wc"
		},
		name: "Exodus",
		walletConnect: {
			sdks: [
				"sign_v1",
				"sign_v2",
				"auth_v1"
			]
		}
	},
	exodusevm: {
		brand: {
			alt: "Exodus Wallet",
			spriteId: "exodus"
		},
		chainGroup: "exodus",
		chains: [
			"eip155:1",
			"eip155:137",
			"eip155:43114",
			"eip155:56"
		],
		desktop: {
			chromeId: "aholpfdialjgjfhomihkjbmgjidlcdno"
		},
		filterFromWalletConnect: true,
		group: "exodus",
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isExodus",
						value: true
					}
				]
			}
		],
		mobile: {
			androidId: "exodusmovement.exodus",
			iosId: "id1414384820",
			native: "exodus://wc"
		},
		name: "Exodus",
		shortName: "Exodus Wallet (EVM)",
		walletConnect: {
			sdks: [
				"sign_v2",
				"auth_v1"
			]
		}
	},
	exodussol: {
		brand: {
			alt: "Exodus Wallet",
			spriteId: "exodus"
		},
		chainGroup: "exodus",
		desktop: {
			chromeId: "aholpfdialjgjfhomihkjbmgjidlcdno"
		},
		group: "exodus",
		injectedConfig: [
			{
				chain: "sol",
				extensionLocators: [
					{
						flag: "isPhantom",
						value: true
					},
					{
						flag: "isExodus",
						value: true
					}
				],
				windowLocations: [
					"exodus.solana"
				]
			}
		],
		mobile: {
			androidId: "exodusmovement.exodus",
			iosId: "id1414384820"
		},
		name: "Exodus",
		shortName: "Exodus Wallet (Solana)"
	},
	okxwallet: {
		brand: {
			alt: "OKX Wallet",
			spriteId: "okx"
		},
		chainGroup: "okxwallet",
		chains: [
			"eip155:1",
			"eip155:137",
			"eip155:43114",
			"eip155:56",
			"eip155:66"
		],
		desktop: {
			chromeId: "mcohilncbfahbmgdjkbpemcciiolgcge"
		},
		eip6963Config: {
			rdns: "com.okex.wallet"
		},
		filterFromWalletConnect: true,
		group: "okxwallet",
		injectedConfig: [
			{
				chain: "evm",
				extensionLocators: [
					{
						flag: "isOKExWallet",
						value: true
					},
					{
						flag: "isOkxWallet",
						value: true
					}
				],
				windowLocations: [
					"okxwallet"
				]
			}
		],
		mobile: {
			androidId: "com.okinc.okex.gp",
			iosId: "id1327268470",
			native: "okex://main/wc"
		},
		name: "OKX",
		shortName: "OKX Wallet",
		walletConnect: {
			sdks: [
				"sign_v2"
			]
		}
	},
	backpack: {
		brand: {
			alt: "Backpack",
			primaryColor: "#e43c3c",
			spriteId: "backpack"
		},
		desktop: {
			chromeId: "aflkmfhebedbjioipglgcbcmnbpgliof"
		},
		name: "Backpack (EVM)"
	},
	okxwalletbtc: {
		brand: {
			alt: "OKX Wallet",
			spriteId: "okx"
		},
		chainGroup: "okxwallet",
		desktop: {
			chromeId: "mcohilncbfahbmgdjkbpemcciiolgcge"
		},
		group: "okxwallet",
		injectedConfig: [
			{
				chain: "btc",
				extensionLocators: [
				],
				windowLocations: [
					"okxwallet.bitcoin"
				]
			}
		],
		mobile: {
			androidId: "com.okinc.okex.gp",
			iosId: "id1327268470"
		},
		name: "OKX",
		shortName: "OKX Wallet (Bitcoin)"
	},
	unisat: {
		brand: {
			alt: "UniSat",
			spriteId: "unisat"
		},
		desktop: {
			chromeId: "ppbibelpcjmhbdihakflkdcoccbgbkpo"
		},
		injectedConfig: [
			{
				chain: "btc",
				extensionLocators: [
				],
				windowLocations: [
					"unisat"
				]
			}
		],
		name: "UniSat"
	},
	zerodev: {
		brand: {
			alt: "Smart Wallet",
			spriteId: "smartwallet"
		},
		name: "ZeroDev"
	}
};
var walletBookFallbacks = {
	groups: groups,
	wallets: wallets
};

export { walletBookFallbacks as default, groups, wallets };
