import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { useCallback } from 'react';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../constants/colors.js';
import '../../../constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import { verifyEmail } from '../../../../data/api.js';
import { useUpdateUserProfileByJWTCallback } from '../../useUpdateUserProfileByJWTCallback/useUpdateUserProfileByJWTCallback.js';
import { useEmailVerificationContext } from '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

// Hook exposed to the public as a scoped function in useUserUpdateRequest result
const useEmailVerificationRequest = () => {
    const { onboardingOnlyJwt, authToken, environmentId } = useInternalDynamicContext();
    const jwtToken = onboardingOnlyJwt || authToken;
    const { updateUserProfileByJWT } = useUpdateUserProfileByJWTCallback();
    const { verificationUUID: emailVerificationContextVerificationUUID } = useEmailVerificationContext();
    const verifyEmail$1 = useCallback((verificationToken, verificationUUID) => __awaiter(void 0, void 0, void 0, function* () {
        if (!jwtToken)
            throw new Error('No JWT token provided');
        // VerificationUUID is saved in the context in case the view changes
        // after a profile update (changing the view from edit profile to verify email)
        if (!verificationUUID && !emailVerificationContextVerificationUUID)
            throw new Error('No verification UUID provided');
        const verifyEmailResponse = yield verifyEmail({
            environmentId,
            verificationToken,
            verificationUUID: String(verificationUUID || emailVerificationContextVerificationUUID),
        }, jwtToken);
        yield updateUserProfileByJWT(verifyEmailResponse.jwt);
        return verifyEmailResponse;
    }), [
        emailVerificationContextVerificationUUID,
        environmentId,
        jwtToken,
        updateUserProfileByJWT,
    ]);
    return { verifyEmail: verifyEmail$1 };
};

export { useEmailVerificationRequest };
