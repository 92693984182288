import { jsxs, jsx } from 'react/jsx-runtime';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import { getChainIcon } from '../../../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import 'react';
import '@dynamic-labs/utils';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import '../../../../shared/consts/index.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/multi-wallet';
import { pixelToRem } from '../../../../utils/functions/pixelToRem/pixelToRem.js';
import 'viem/chains';
import { classNames } from '../../../../utils/functions/classNames/classNames.js';

const WalletIconWithNetwork = ({ iconSize = 24, chainName, Icon, showNetwork = true, }) => {
    const ChainIcon = chainName ? getChainIcon(chainName) : undefined;
    return (jsxs("div", { className: 'wallet-icon-with-network__container', children: [jsx(Icon, { style: {
                    height: pixelToRem(iconSize),
                    width: pixelToRem(iconSize),
                } }), ChainIcon && showNetwork && (jsx("div", { "data-testid": 'network-container', className: classNames('wallet-icon-with-network__network-container'), children: jsx(ChainIcon, {}) }))] }));
};

export { WalletIconWithNetwork };
