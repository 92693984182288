import { normalizeWalletName } from './normalizeWalletName.js';
import { logger } from './logger.js';

const getWalletBookWallet = (walletBook, walletKey) => {
    var _a, _b, _c;
    if (!walletKey)
        throw new Error('Wallet key is required. Please pass in a wallet key to getWalletBookWallet');
    const walletDataFromKeyLookup = (_a = walletBook === null || walletBook === void 0 ? void 0 : walletBook.wallets) === null || _a === void 0 ? void 0 : _a[walletKey];
    if (!walletDataFromKeyLookup) {
        // adds fallback support. normalizeWalletName will be removed in v2
        const normalizedWalletKey = normalizeWalletName(walletKey);
        const walletDataFromNormalizedKeyLookup = (_b = walletBook === null || walletBook === void 0 ? void 0 : walletBook.wallets) === null || _b === void 0 ? void 0 : _b[normalizedWalletKey];
        if (walletDataFromNormalizedKeyLookup) {
            logger.warn(`deprecated: using walletName: ${walletKey} to look up wallet data is deprecated. use connector.key instead ${normalizedWalletKey}`);
            return walletDataFromNormalizedKeyLookup;
        }
        throw new Error(`Wallet ${normalizedWalletKey} not found in wallet book (${Object.keys((_c = walletBook === null || walletBook === void 0 ? void 0 : walletBook.wallets) !== null && _c !== void 0 ? _c : {}).length} wallets found)`);
    }
    return walletDataFromKeyLookup;
};

export { getWalletBookWallet };
