import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { WalletAddressType } from '@dynamic-labs/sdk-api';
import 'react';
import '../../../../../events/dynamicEvents.js';
import '../../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../../context/DynamicContext/DynamicContext.js';
import '../../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgCopy } from '../../../../../shared/assets/copy.js';
import '@dynamic-labs/wallet-book';
import { shortenWalletAddress } from '../../../../../shared/utils/functions/shortenWalletAddress/shortenWalletAddress.js';
import '../../../../../utils/constants/colors.js';
import '../../../../../utils/constants/values.js';
import '../../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../../shared/consts/index.js';
import '../../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getWalletAdditionalAddressByType } from '../../../../../utils/functions/getWalletAdditionalAddressByType/getWalletAdditionalAddressByType.js';
import 'viem/chains';
import '../../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../../config/ApiEndpoint.js';
import '../../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../../../context/MockContext/MockContext.js';
import '../../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../../components/Typography/Typography.js';
import '../../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../../../context/LoadingContext/LoadingContext.js';
import { Icon } from '../../../../../components/Icon/Icon.js';
import 'i18next';
import 'qrcode';
import '../../../context/DynamicWidgetContext.js';
import { DotsMenu } from '../../DotsMenu/DotsMenu.js';
import '../../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../../components/IconButton/IconButton.js';
import '../../../../../components/Alert/Alert.js';
import '../../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { CopyButton } from '../../../../../components/CopyButton/CopyButton.js';
import '../../../../../components/InlineWidget/InlineWidget.js';
import '../../../../../components/IsBrowser/IsBrowser.js';
import '../../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../../components/Popper/Popper/Popper.js';
import '../../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const ActiveBitcoinWalletAddresses = ({ menuOption, wallet }) => {
    var _a, _b;
    const { t } = useTranslation();
    const ordinalsAddress = (_a = getWalletAdditionalAddressByType(WalletAddressType.Ordinals, wallet)) !== null && _a !== void 0 ? _a : wallet === null || wallet === void 0 ? void 0 : wallet.address;
    const paymenyAddress = (_b = getWalletAdditionalAddressByType(WalletAddressType.Payment, wallet)) !== null && _b !== void 0 ? _b : wallet === null || wallet === void 0 ? void 0 : wallet.address;
    return (jsxs(Fragment, { children: [jsxs("div", { className: 'active-wallet-information__address-container', children: [jsxs("div", { children: [jsx(Typography, { className: 'active-wallet-information__address', weight: 'regular', variant: 'body_small', color: 'secondary', children: t('dyn_active_wallet_info.ordinals_address') }), jsx(Typography, { className: 'active-wallet-information__address', weight: 'medium', variant: 'body_normal', color: 'primary', children: shortenWalletAddress(ordinalsAddress, 4, 4) })] }), jsx(DotsMenu, { "data-testid": 'dots-menu', options: menuOption, buttonClassName: 'active-wallet-information__dots-menu', buttonClassNameWithOpenMenu: 'active-wallet-information__dots-menu' })] }), jsxs("div", { className: 'active-wallet-information__address-container', children: [jsxs("div", { children: [jsx(Typography, { className: 'active-wallet-information__address', weight: 'regular', variant: 'body_small', color: 'secondary', children: t('dyn_active_wallet_info.payment_address') }), jsx(Typography, { className: 'active-wallet-information__address', weight: 'regular', variant: 'body_small', color: 'primary', children: shortenWalletAddress(paymenyAddress, 4, 4) })] }), jsx(CopyButton, { textToCopy: paymenyAddress !== null && paymenyAddress !== void 0 ? paymenyAddress : '', children: jsx(Icon, { size: 'xsmall', children: jsx(SvgCopy, {}) }) })] })] }));
};

export { ActiveBitcoinWalletAddresses };
