import { jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { isEmailOTPWalletConnector, isEmailWalletConnector } from '@dynamic-labs/wallet-connector-core';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import { useCloseMultiWalletPrompt } from '../../hooks/useCloseMultiWalletPrompt/useCloseMultiWalletPrompt.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { PromptModal } from '../../components/PromptModal/PromptModal.js';
import { WidgetPortal } from '../../components/WidgetPortal/WidgetPortal.js';
import { NoQrNotInstalledModalView } from '../NoQrNotInstalledModalView/NoQrNotInstalledModalView.js';
import { PendingConnectionModal, PendingConnectionWidgetPortal } from '../PendingConnectionModal/PendingConnectionModal.js';
import { PendingSignModal, PendingSignWidgetPortal } from '../PendingSignModal/PendingSignModal.js';
import { QrCodeModalView } from '../QrCodeModalView/QrCodeModalView.js';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const AwaitingConnectionContent = ({ PendingSign, PendingConnection, }) => {
    var _a, _b;
    const { selectedWalletConnector, showQrcodeModal, selectedWalletWithAction } = useInternalDynamicContext();
    const Pending = useMemo(() => {
        if (selectedWalletConnector &&
            (isEmailOTPWalletConnector(selectedWalletConnector) ||
                isEmailWalletConnector(selectedWalletConnector))) {
            return PendingSign;
        }
        return PendingConnection;
    }, [selectedWalletConnector]);
    if (showQrcodeModal) {
        if (selectedWalletConnector === null || selectedWalletConnector === void 0 ? void 0 : selectedWalletConnector.canConnectViaQrCode) {
            return (jsx(QrCodeModalView, { walletId: (_b = (_a = selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '' }));
        }
        return jsx(NoQrNotInstalledModalView, {});
    }
    return jsx(Pending, {});
};
const AwaitingConnectionWidgetPortal = () => (jsx(WidgetPortal, { onClose: useCloseMultiWalletPrompt(), children: jsx(AwaitingConnectionContent, { PendingSign: PendingSignWidgetPortal, PendingConnection: PendingConnectionWidgetPortal }) }));
const AwaitingConnectionModal = () => (jsx(PromptModal, { onClose: useCloseMultiWalletPrompt(), children: jsx(AwaitingConnectionContent, { PendingSign: PendingSignModal, PendingConnection: PendingConnectionModal }) }));

export { AwaitingConnectionModal, AwaitingConnectionWidgetPortal };
