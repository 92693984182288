import * as allChains from 'wagmi/chains';
import { resolveRpcUrlFromEvmNetworks } from '../utils/resolveRpcUrlFromEvmNetworks/resolveRpcUrlFromEvmNetworks.js';

// eslint-disable-next-line import/no-namespace
const updateRpcProvider = (rpcUrls, privateCustomerRpcUrls, name) => {
    const findRpcUrl = privateCustomerRpcUrls === null || privateCustomerRpcUrls === void 0 ? void 0 : privateCustomerRpcUrls.find((rpcUrl) => {
        if (typeof rpcUrl === 'string') {
            return rpcUrl.includes(name);
        }
        else if (typeof rpcUrl.http === 'string') {
            return rpcUrl.http.includes(name);
        }
        else {
            return false;
        }
    });
    if (typeof findRpcUrl === 'string') {
        rpcUrls[name] = { http: [findRpcUrl] };
    }
    else if (typeof (findRpcUrl === null || findRpcUrl === void 0 ? void 0 : findRpcUrl.http) === 'string') {
        rpcUrls[name] = { http: [findRpcUrl.http] };
    }
};
const mapDynamicChainToWagmi = (dynamicChain) => {
    /**
     * Find the chain in Wagmi that matches the chainId of the dynamic chain.
     */
    const wagmiChain = Object.values(allChains).find((chain) => chain.id === dynamicChain.chainId);
    return overrideWagmiChainWithDynamicSettings(wagmiChain, dynamicChain);
};
const generateRpcUrls = (dynamicChain) => {
    const rpcUrlResolved = resolveRpcUrlFromEvmNetworks(dynamicChain);
    const rpcUrls = {
        default: {
            http: rpcUrlResolved,
        },
        public: {
            http: rpcUrlResolved,
        },
    };
    const rpcUrlProviders = ['alchemy', 'infura'];
    rpcUrlProviders.forEach((name) => updateRpcProvider(rpcUrls, dynamicChain.privateCustomerRpcUrls, name));
    return rpcUrls;
};
const overrideWagmiChainWithDynamicSettings = (wagmiChain, dynamicChain) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return (Object.assign(Object.assign({}, wagmiChain), { blockExplorers: Object.assign(Object.assign({}, wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.blockExplorers), { default: Object.assign(Object.assign({}, (_a = wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.blockExplorers) === null || _a === void 0 ? void 0 : _a.default), { name: '', url: (_c = (_b = dynamicChain.blockExplorerUrls) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : '' }) }), contracts: (_d = dynamicChain.contracts) !== null && _d !== void 0 ? _d : wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.contracts, id: (_e = wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.id) !== null && _e !== void 0 ? _e : dynamicChain.chainId, name: (_g = (_f = dynamicChain.vanityName) !== null && _f !== void 0 ? _f : dynamicChain.name) !== null && _g !== void 0 ? _g : wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.name, nativeCurrency: (_h = dynamicChain.nativeCurrency) !== null && _h !== void 0 ? _h : wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.nativeCurrency, network: (_k = (_j = wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.network) !== null && _j !== void 0 ? _j : dynamicChain.chainName) !== null && _k !== void 0 ? _k : '', rpcUrls: generateRpcUrls(dynamicChain) }));
};

export { mapDynamicChainToWagmi, overrideWagmiChainWithDynamicSettings };
