import { jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { getChainInfo } from '@dynamic-labs/wallet-connector-core';
import { useWalletBookContext, findWalletGroupOverride, BrandIcon } from '@dynamic-labs/wallet-book';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import { getChainIcon } from '../../../../shared/utils/functions/chain/getChainIcon.js';
import { getWalletListItemLabel } from '../../../../shared/utils/functions/getWalletListItemLabel/getWalletListItemLabel.js';
import 'viem';
import '../../../../shared/utils/classes/storage/localStorage.js';
import '@dynamic-labs/utils';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgStroke } from '../../../../shared/assets/stroke.js';
import '../../../../shared/consts/index.js';
import { Icon } from '../../../../components/Icon/Icon.js';
import { pixelToRem } from '../../../../utils/functions/pixelToRem/pixelToRem.js';
import { Badge } from '../../../../components/Badge/Badge.js';
import { ListTileAnimatedTrailing } from '../../../../components/ListTileAnimatedTrailing/ListTileAnimatedTrailing.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '../../../../context/DynamicContext/DynamicContext.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { ListTile } from '../../../../components/ListTile/ListTile.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const WalletGroupItem = ({ wallet, onClick, }) => {
    const [supportedChain] = wallet.walletConnector.supportedChains;
    const { walletBook } = useWalletBookContext();
    const chainInfo = getChainInfo(supportedChain);
    const walletOverride = wallet.group
        ? findWalletGroupOverride(walletBook, wallet.group, wallet.key)
        : undefined;
    const label = getWalletListItemLabel({
        wallet,
    });
    const trailing = (jsx(ListTileAnimatedTrailing, { hoverElement: 
        // eslint-disable-next-line react/jsx-wrap-multilines
        jsx(Icon, { color: 'text-tertiary', size: 'mini', children: jsx(SvgStroke, {}) }), children: label && (jsx(Badge, { dot: true, text: jsx(Typography, { variant: 'body_small', children: label }) })) }));
    const renderChainIcon = useMemo(() => {
        const iconStyle = { height: pixelToRem(28), width: pixelToRem(28) };
        if (walletOverride === null || walletOverride === void 0 ? void 0 : walletOverride.brand) {
            return (jsx(BrandIcon, { brand: walletOverride.brand, walletKey: wallet.key, style: iconStyle }));
        }
        const ChainIcon = getChainIcon(supportedChain);
        return jsx(ChainIcon, { style: iconStyle });
    }, [supportedChain, wallet.key, walletOverride]);
    const renderChainName = (walletOverride === null || walletOverride === void 0 ? void 0 : walletOverride.name) || (chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.displayName) || wallet.name;
    return (jsx(ListTile, { className: 'chain-card', onClick: onClick, leading: renderChainIcon, trailing: trailing, children: renderChainName }, wallet.key));
};

export { WalletGroupItem };
