import { jsxs, jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getChainInfo } from '@dynamic-labs/wallet-connector-core';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import { getChainIcon } from '../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '@dynamic-labs/iconic';
import '../../context/ViewContext/ViewContext.js';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import { ConnectedWalletsListLayout } from '../../layouts/ConnectedWalletsListLayout/ConnectedWalletsListLayout.js';
import { DynamicBridgeWalletCard } from '../../widgets/DynamicBridgeWidget/components/DynamicBridgeWalletCard/DynamicBridgeWalletCard.js';
import { DynamicBridgeWalletCardBody } from '../../widgets/DynamicBridgeWidget/components/DynamicBridgeWalletCardBody/DynamicBridgeWalletCardBody.js';
import { MemoSecondaryWallets } from '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { Balance } from '../../widgets/DynamicWidget/components/Balance/Balance.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const BridgeSummaryView = () => {
    const { primaryWallet, setShowAuthFlow, appName, bridgeChains = [], } = useInternalDynamicContext();
    const { t } = useTranslation();
    const onClickClose = () => setShowAuthFlow(false, { emitCancelAuth: true });
    const body = useMemo(() => {
        const blockchainNames = bridgeChains.map(({ chain }) => { var _a; return (_a = getChainInfo(chain)) === null || _a === void 0 ? void 0 : _a.blockchainName; });
        const [firstBlockchainName, secondBlockchainName] = blockchainNames;
        return t('dyn_bridge.summary_view.connection_succeed', {
            firstBlockchainName,
            secondBlockchainName,
        });
    }, [bridgeChains, t]);
    const createPrimaryWalletCard = (wallet) => {
        const PrimaryWalletChainIcon = getChainIcon(wallet.chain);
        const primaryWalletChainInfo = getChainInfo(wallet.chain);
        return (jsx(DynamicBridgeWalletCard, { networkIcon: jsx(PrimaryWalletChainIcon, {}), networkName: primaryWalletChainInfo === null || primaryWalletChainInfo === void 0 ? void 0 : primaryWalletChainInfo.displayName, children: jsx(DynamicBridgeWalletCardBody, { walletKey: wallet.connector.key, walletAddress: wallet.address, walletBalance: 
                // eslint-disable-next-line react/jsx-wrap-multilines
                jsx(Balance, { address: wallet.address, connector: wallet.connector, network: wallet.chain }) }) }));
    };
    return (jsxs(ConnectedWalletsListLayout, { onClickClose: onClickClose, onClickAction: onClickClose, copykey: 'dyn_bridge.summary_view.title', title: t('dyn_bridge.summary_view.title', { appName }), body: body, children: [primaryWallet && createPrimaryWalletCard(primaryWallet), jsx(MemoSecondaryWallets, { hideUnlinkAction: true })] }));
};

export { BridgeSummaryView };
