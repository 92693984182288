import { jsx } from 'react/jsx-runtime';
import { DynamicBaseWidget } from '../../components/DynamicBaseWidget/DynamicBaseWidget.js';
import { useMultiWalletPromptState } from '../DynamicWidget/hooks/useMultiWalletPromptState/useMultiWalletPromptState.js';
import { DetectedNewWalletModal } from '../DynamicWidget/prompts/DetectedNewWalletModal/DetectedNewWalletModal.js';
import { PendingAccountSwitchToLinkModal } from '../DynamicWidget/prompts/PendingAccountSwitchToLinkModal/PendingAccountSwitchToLinkModal.js';
import { PendingAccountSwitchModal } from '../DynamicWidget/prompts/PendingAccountSwitchModal/PendingAccountSwitchModal.js';
import { AwaitingConnectionModal } from '../DynamicWidget/prompts/AwaitingConnectionModal/AwaitingConnectionModal.js';
import { PendingSignModal } from '../DynamicWidget/prompts/PendingSignModal/PendingSignModal.js';
import { PrimaryNotConnectedModal } from '../DynamicWidget/prompts/PrimaryNotConnectedModal/PrimaryNotConnectedModal.js';
import { UnlinkWalletPopUpModal } from '../DynamicWidget/prompts/UnlinkWalletPopUp/UnlinkWalletPopUp.js';
import 'react';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import { useIsLoggedIn } from '../../utils/hooks/useIsLoggedIn/useIsLoggedIn.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const DynamicMultiWalletPromptsWidget = () => {
    const isLoggedIn = useIsLoggedIn();
    const multiWalletPromptState = useMultiWalletPromptState();
    const mapMultiWalletPromptStateToModalComponent = {
        awaiting_connection: AwaitingConnectionModal,
        detected_new_wallet: DetectedNewWalletModal,
        linking_new_wallet: PendingAccountSwitchToLinkModal,
        pending_account_switch: PendingAccountSwitchModal,
        pending_sign: PendingSignModal,
        primary_not_connected: PrimaryNotConnectedModal,
        unlink_wallet: UnlinkWalletPopUpModal,
    };
    if (multiWalletPromptState === 'idle' || !isLoggedIn)
        return null;
    const ModalComponent = mapMultiWalletPromptStateToModalComponent[multiWalletPromptState];
    return (jsx(DynamicBaseWidget, { children: jsx(ModalComponent, {}) }));
};

export { DynamicMultiWalletPromptsWidget };
