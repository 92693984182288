import { jsx, jsxs } from 'react/jsx-runtime';
import 'react';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgClose } from '../../shared/assets/close.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import { Typography } from '../../components/Typography/Typography.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import { Button } from '../../components/Button/Button.js';
import { ModalHeader } from '../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import { Step } from '../../components/Step/Step.js';
import { Stepper } from '../../components/Stepper/Stepper.js';
import '../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const BridgeWelcomeLayout = ({ body, chains = [], onClickAction, onClickClose, title, actionButtonText, copykey, }) => {
    const closeButton = onClickClose && (jsx(IconButton, { type: 'button', onClick: onClickClose, "data-testid": 'close-button', children: jsx(SvgClose, {}) }));
    return (jsxs("div", { className: 'bridge-welcome-layout', children: [jsx(ModalHeader, { trailing: closeButton, alignContent: 'bottom', children: jsx("div", { className: 'bridge-welcome-layout__chain-icon-group', children: [...chains].reverse().map((chain, index) => (jsx("div", { className: 'bridge-welcome-layout__chain-icon', style: { zIndex: index }, children: chain.icon }, chain.id))) }) }), jsxs("div", { className: 'bridge-welcome-layout__body', children: [jsxs("div", { className: 'bridge-welcome-layout__message-container', children: [jsx(Typography, { variant: 'title', color: 'primary', copykey: copykey, children: title }), jsx(Typography, { variant: 'body_normal', color: 'secondary', children: body })] }), jsx("div", { children: jsx(Stepper, { children: chains.map((chain) => (jsx(Step, { children: chain.name }, chain.id))) }) }), jsx(Button, { buttonVariant: 'primary', buttonPadding: 'large', onClick: onClickAction, children: actionButtonText })] })] }));
};

export { BridgeWelcomeLayout };
