import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../events/dynamicEvents.js';
import '@dynamic-labs/utils';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgAndroidTouchId } from '../../../shared/assets/android-touch-id.js';
import { ReactComponent as SvgMail } from '../../../shared/assets/mail.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useElementById } from '../../../utils/hooks/useElementById/useElementById.js';
import '../../../context/ThemeContext/ThemeContext.js';
import { iframeContainerId } from '../PasskeyRecovery/constants.js';
import { useInitEmbeddedWalletAuthMethod } from '../../../utils/hooks/useInitEmbeddedWalletAuthMethod/useInitEmbeddedWalletAuthMethod.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import { Icon } from '../../../components/Icon/Icon.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { PasskeyDeviceIcon } from '../PasskeyDeviceIcon/PasskeyDeviceIcon.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import '../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { ActionCard } from './ActionCard/ActionCard.js';

const EmbeddedAuthChoiceCards = ({ currentAction, supportedAuthenticators, }) => {
    var _a, _b;
    const [selectedAuthType, setSelectedAuthType] = useState();
    const { projectSettings } = useInternalDynamicContext();
    const { t } = useTranslation();
    const { initAuthMethod, isLoading } = useInitEmbeddedWalletAuthMethod();
    const iframeContainerRef = useElementById(iframeContainerId);
    const supportedAuthenticatorsMap = {
        email: {
            badgeKey: 'dyn_embedded_authenticator.email.badge',
            description: t('dyn_embedded_authenticator.email.description', {
                expiration: (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _a === void 0 ? void 0 : _a.sessionKeyDuration) === null || _b === void 0 ? void 0 : _b.amount,
            }),
            descriptionKey: 'dyn_embedded_authenticator.email.description',
            icon: (jsx(Icon, { color: 'text-tertiary', size: 'small', children: jsx(SvgMail, {}) }, 'one-time-code-icon')),
            titleKey: 'dyn_embedded_authenticator.email.title',
        },
        passkey: {
            badgeKey: 'dyn_embedded_authenticator.passkey.badge',
            description: t('dyn_embedded_authenticator.passkey.description'),
            descriptionKey: 'dyn_embedded_authenticator.passkey.description',
            icon: (jsx(PasskeyDeviceIcon, { defaultIcon: jsx(SvgAndroidTouchId, { "data-testid": 'default-icon' }) })),
            titleKey: currentAction === 'reveal'
                ? 'dyn_embedded_authenticator.passkey.reveal_title'
                : 'dyn_embedded_authenticator.passkey.title',
        },
    };
    const handleSelection = (authenticatorType) => () => __awaiter(void 0, void 0, void 0, function* () {
        setSelectedAuthType(authenticatorType);
        yield initAuthMethod({
            authenticatorType: authenticatorType,
            currentAction,
            iframeContainer: iframeContainerRef.current,
        });
    });
    return (jsx("div", { className: 'embedded-auth-choice-cards', children: supportedAuthenticators &&
            supportedAuthenticators.map((authenticatorType) => (jsx(ActionCard, { LeadingIcon: supportedAuthenticatorsMap[authenticatorType]
                    .icon, action: handleSelection(authenticatorType), badgeKey: supportedAuthenticatorsMap[authenticatorType]
                    .badgeKey, badgeVariant: authenticatorType === 'passkey' ? 'primary' : 'secondary', descriptionKey: supportedAuthenticatorsMap[authenticatorType]
                    .descriptionKey, description: supportedAuthenticatorsMap[authenticatorType]
                    .description, titleKey: supportedAuthenticatorsMap[authenticatorType]
                    .titleKey, isLoading: isLoading && authenticatorType === selectedAuthType, dataTestId: `embedded-auth-choice__item-${authenticatorType}` }, authenticatorType))) }));
};

export { EmbeddedAuthChoiceCards };
