import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useState } from 'react';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import { dynamicEvents } from '../../../events/dynamicEvents.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import { createOwnerWallet } from '../../functions/createOwnerWallet/createOwnerWallet.js';
import { createUserProfile } from '../../functions/createUserProfile/createUserProfile.js';
import '@dynamic-labs/multi-wallet';
import { isAccountAbstractionWallet } from '../../functions/isAccountAbstractionWallet/isAccountAbstractionWallet.js';
import { storeAuthToken } from '../../functions/storeAuthToken/index.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import { iframeElementId } from '../../../views/Passkey/PasskeyRecovery/constants.js';
import { initEmbeddedWalletSession } from '../../../views/Passkey/utils/passkeyRecovery/passkeyRecovery.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

// This hook needs to be async that's why it's not using useMutation
const useInitEmbeddedWalletAuthMethod = () => {
    const { authToken, primaryWallet, environmentId, user, projectSettings, setShowAuthFlow, setUser, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const initAuthMethod = ({ authenticatorType, iframeContainer, currentAction, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        setIsLoading(true);
        const wallet = isAccountAbstractionWallet(primaryWallet)
            ? createOwnerWallet(primaryWallet, (user === null || user === void 0 ? void 0 : user.verifiedCredentials) || [])
            : primaryWallet;
        const sessionExpiration = ((_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _a === void 0 ? void 0 : _a.sessionKeyDuration) === null || _b === void 0 ? void 0 : _b.amount) || 0;
        const sessionExpirationUnit = ((_d = (_c = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _c === void 0 ? void 0 : _c.sessionKeyDuration) === null || _d === void 0 ? void 0 : _d.unit) ===
            'minutes'
            ? 60
            : 3600;
        const showEmailBundle = authenticatorType === 'email' ||
            !(wallet === null || wallet === void 0 ? void 0 : wallet.connector)
                .getAuthenticatorHandler()
                .isSessionActive();
        try {
            const result = yield initEmbeddedWalletSession({
                authToken,
                authenticatorType,
                environmentId,
                iframeContainer,
                iframeElementId,
                sessionExpiration: sessionExpiration * sessionExpirationUnit || undefined,
                userEmail: (user === null || user === void 0 ? void 0 : user.email) || '',
                wallet,
            });
            if (result && result.jwt && result.decodedJwt) {
                storeAuthToken(result.jwt);
                setUser(createUserProfile(result.decodedJwt));
            }
            if (showEmailBundle) {
                setView('passkey-recovery-bundle', {
                    authenticatorType,
                    currentAction,
                });
                setShowAuthFlow(true, {
                    ignoreIfIsEmbeddedWidget: false,
                    performMultiWalletChecks: false,
                });
            }
            else {
                setShowAuthFlow(false);
            }
        }
        catch (err) {
            setError(err);
            logger.error('Failed to create embedded wallet', err);
            dynamicEvents.emit('passkeyRecoveryFailed', err);
        }
        finally {
            setIsLoading(false);
        }
    });
    return {
        error,
        initAuthMethod,
        isLoading,
    };
};

export { useInitEmbeddedWalletAuthMethod };
