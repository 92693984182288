import { useInternalDynamicEvents } from '../../../../utils/hooks/events/useDynamicEvents/useDynamicEvents.js';
import 'react';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import '../../../CaptchaContext/CaptchaContext.js';
import '../../../ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../AccessDeniedContext/AccessDeniedContext.js';
import '../../../AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../events/dynamicEvents.js';
import '../../../ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../LoadingContext/LoadingContext.js';
import '../../../SocialRedirectContext/SocialRedirectContext.js';
import '../../../WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

/** Ties the customer callbacks to our events, such that our events will also trigger the callbacks */
const useTieCallbacksToEvents = ({ clientCallbacks, connectedWallets, user, }) => {
    useInternalDynamicEvents('emailVerificationResult', (result, email) => {
        var _a, _b;
        if (result)
            (_a = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onEmailVerificationSuccess) === null || _a === void 0 ? void 0 : _a.call(clientCallbacks, email);
        else
            (_b = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onEmailVerificationFailure) === null || _b === void 0 ? void 0 : _b.call(clientCallbacks, email);
    });
    useInternalDynamicEvents('authFlowCancelled', () => { var _a; return (_a = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onAuthFlowCancel) === null || _a === void 0 ? void 0 : _a.call(clientCallbacks); });
    useInternalDynamicEvents('authFlowOpen', () => {
        var _a;
        (_a = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onAuthFlowOpen) === null || _a === void 0 ? void 0 : _a.call(clientCallbacks);
    });
    useInternalDynamicEvents('authFlowClose', () => {
        var _a;
        (_a = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onAuthFlowClose) === null || _a === void 0 ? void 0 : _a.call(clientCallbacks);
    });
    useInternalDynamicEvents('walletConnected', (wallet) => {
        var _a;
        (_a = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onConnect) === null || _a === void 0 ? void 0 : _a.call(clientCallbacks, { wallet, walletConnector: wallet.connector });
    });
    useInternalDynamicEvents('logout', () => {
        var _a;
        if (clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onDisconnect)
            for (const connectedWallet of connectedWallets) {
                clientCallbacks.onDisconnect({
                    wallet: connectedWallet,
                    walletConnector: connectedWallet === null || connectedWallet === void 0 ? void 0 : connectedWallet.connector,
                });
            }
        (_a = clientCallbacks === null || clientCallbacks === void 0 ? void 0 : clientCallbacks.onLogout) === null || _a === void 0 ? void 0 : _a.call(clientCallbacks, user);
    });
};

export { useTieCallbacksToEvents };
