import { useRef, useEffect } from 'react';

const useOnClickOutside = (ref, handler) => {
    const handlerRef = useRef(handler);
    handlerRef.current = handler;
    useEffect(() => {
        const listener = (event) => {
            event.stopPropagation();
            const realTarget = event.composedPath().shift();
            if (!ref.current ||
                ref.current.contains(event.currentTarget) ||
                ref.current.contains(realTarget)) {
                return;
            }
            handlerRef.current(event);
        };
        document.addEventListener('touchstart', listener);
        document.addEventListener('click', listener);
        return () => {
            document.removeEventListener('touchstart', listener);
            document.removeEventListener('click', listener);
        };
    }, [ref]);
};

export { useOnClickOutside };
