const filterWalletsByKey = (wallets, keys) => keys
    .flatMap((key) => wallets.find((w) => walletHasKey(w, key)))
    .filter(isWalletConnector);
const walletHasKey = (wallet, key) => wallet.key === key;
const isWalletConnector = (item) => Boolean(item);
const getWalletConnectorByKey = (wallets, key) => filterWalletsByKey(wallets, [key]).length > 0
    ? filterWalletsByKey(wallets, [key])[0]
    : null;

export { getWalletConnectorByKey };
