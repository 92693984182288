import { jsx } from 'react/jsx-runtime';
import React, { useRef, useMemo, useEffect } from 'react';
import { configureChains, mainnet, createConfig, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { useDynamicContext, useUserWallets } from '@dynamic-labs/sdk-react-core';
import { Connector } from './Connector.js';
import { getConnector } from './getConnector.js';
import { getWagmiChainsFromDynamicChains } from './getWagmiChainsFromDynamicChains.js';
import { getWagmiProvidersFromDynamicChains } from './getWagmiProvidersFromDynamicChains.js';
import { SyncDynamicWagmi } from './SyncDynamicWagmi.js';
import { findAndOrderEvmWallets } from './utils/findAndOrderEvmWallets/findAndOrderEvmWallets.js';

const { publicClient } = configureChains([mainnet], [publicProvider()]);
const config = createConfig({
    autoConnect: true,
    publicClient,
});
const DynamicWagmiConnector = ({ evmNetworks: customerSuppliedEvmNetworks, children, }) => {
    const { networkConfigurations, handleLogOut, sendWagmiSettings, primaryWallet, } = useDynamicContext();
    /**
     * This will prevent the wagmi Connector from calling handleLogOut when we are the ones calling disconnect
     * as a result of a handleLogOut call (see SyncDynamicWagmi), but will preserve the existing behavior for customers.
     */
    const preventWagmiSyncFromCallingLogout = useRef(false);
    // Sort places the primary wallet as the first wallet to attempt to connect with
    const wallets = useUserWallets().sort((wallet) => wallet.address === (primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.address) ? -1 : 1);
    const evmWallets = useMemo(() => findAndOrderEvmWallets(primaryWallet, wallets), [primaryWallet, wallets]);
    useEffect(() => {
        // only send wagmi settings once, when the first time DynamicWagmiConnector renders
        sendWagmiSettings({
            dynamicWagmiSettings: customerSuppliedEvmNetworks,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { chains, publicClient, webSocketPublicClient } = useMemo(() => {
        var _a;
        const evmNetworks = ((_a = customerSuppliedEvmNetworks !== null && customerSuppliedEvmNetworks !== void 0 ? customerSuppliedEvmNetworks : networkConfigurations === null || networkConfigurations === void 0 ? void 0 : networkConfigurations.evm) !== null && _a !== void 0 ? _a : []).map((network) => {
            network.chainId = parseInt(network.chainId.toString());
            return network;
        });
        const mappedChains = getWagmiChainsFromDynamicChains(evmNetworks);
        const mappedProviders = getWagmiProvidersFromDynamicChains(evmNetworks);
        return configureChains(mappedChains, mappedProviders);
    }, [customerSuppliedEvmNetworks, networkConfigurations === null || networkConfigurations === void 0 ? void 0 : networkConfigurations.evm]);
    const connector = useMemo(() => getConnector(evmWallets, (wallet) => new Connector({
        chains,
        handleLogOut,
        preventWagmiSyncFromCallingLogout,
        walletConnector: wallet.connector,
    })), [evmWallets, chains, handleLogOut, preventWagmiSyncFromCallingLogout]);
    /**
     * Updating the wagmi config must be done in a useEffect because
     * when setting the public client and connectors, wagmi will fire
     * an rerender.
     * Keeping the update in a useEffect will prevent an state update
     * while react is in the middle of rendering.
     */
    useEffect(() => {
        config.setPublicClient(publicClient);
        config.setWebSocketPublicClient(webSocketPublicClient);
        config.setConnectors(connector ? [connector] : []);
    }, [connector, publicClient, webSocketPublicClient]);
    if (React.version.startsWith('18')) {
        return (jsx(WagmiConfig, { config: config, children: jsx(SyncDynamicWagmi, { connector: connector, preventWagmiSyncFromCallingLogout: preventWagmiSyncFromCallingLogout, wallets: wallets, children: children }) }));
    }
    // use React.createElement to prevent bunding react/jsx-runtime,
    // which is not compatible when bundling apps using React 17
    return React.createElement(WagmiConfig, { config: config }, React.createElement(SyncDynamicWagmi, { connector, preventWagmiSyncFromCallingLogout, wallets }, children));
};

export { DynamicWagmiConnector };
