const evmWalletsFilter = (wallet) => wallet.connector.connectedChain === 'EVM';
const findAndOrderEvmWallets = (primaryWallet, wallets) => {
    const isPrimaryWalletEvm = (primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.connectedChain) === 'EVM';
    if (isPrimaryWalletEvm) {
        const secondaryEvmWallets = wallets
            .filter(evmWalletsFilter)
            .filter((wallet) => wallet.address !== primaryWallet.address);
        return [primaryWallet, ...secondaryEvmWallets];
    }
    return wallets.filter(evmWalletsFilter);
};

export { findAndOrderEvmWallets };
