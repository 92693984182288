import { __awaiter } from '../../../_virtual/_tslib.js';
import { toAccount } from 'viem/accounts';

const createAccountWithUiConfirmation = ({ address, account, provider, walletUiUtils, }) => toAccount({
    address,
    signMessage: (...args) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        return walletUiUtils.signMessage({
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return (yield account()).signMessage(...args); }),
            message: (_a = args[0]) === null || _a === void 0 ? void 0 : _a.message.toString(),
        });
    }),
    signTransaction: (...args) => __awaiter(void 0, void 0, void 0, function* () {
        return walletUiUtils.sendTransaction({
            handler: () => __awaiter(void 0, void 0, void 0, function* () { return (yield account()).signTransaction(...args); }),
            provider,
            transaction: args[0],
        });
    }),
    signTypedData: (...args) => walletUiUtils.signMessage({
        handler: () => __awaiter(void 0, void 0, void 0, function* () { return (yield account()).signTypedData(...args); }),
        message: JSON.stringify(args[0]),
    }),
});

export { createAccountWithUiConfirmation };
