import { __awaiter } from '../../../../../_virtual/_tslib.js';

const createMagicHandler = (connector, handleInstalledExtensionClick) => (email) => __awaiter(void 0, void 0, void 0, function* () {
    if (!connector) {
        throw new Error('OTP wallet connector was not found');
    }
    connector.setEmail(email);
    handleInstalledExtensionClick(connector);
    return {
        retry: () => connector.retryOneTimePassword(),
        verify: (oneTimePassword) => __awaiter(void 0, void 0, void 0, function* () {
            return ({
                email,
                verified: yield connector.verifyOneTimePassword(oneTimePassword),
            });
        }),
    };
});

export { createMagicHandler };
