import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useEffect, useMemo } from 'react';
import { SdkViewSectionType } from '@dynamic-labs/sdk-api';
import { isMobile } from '@dynamic-labs/utils';
import { Divider } from '../../components/Divider/Divider.js';
import { useErrorContext } from '../../context/ErrorContext/ErrorContext.js';
import { ErrorContainer } from '../../components/ErrorContainer/ErrorContainer.js';
import { useLoadingContext } from '../../context/LoadingContext/LoadingContext.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';
import { WalletList } from '../WalletList/WalletList.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/DynamicContext/DynamicContext.js';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../context/FooterAnimationContext/index.js';
import { useAuthLayoutChecks } from '../../utils/hooks/useAuthLayoutChecks/useAuthLayoutChecks.js';
import { Typography } from '../../components/Typography/Typography.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import 'i18next';
import 'qrcode';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '@hcaptcha/react-hcaptcha';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { LoginEmailForm } from './sections/EmailSignInSection/LoginEmailForm.js';
import { DefaultFooter } from '../../layout/DynamicAuthLayout/DefaultFooter/DefaultFooter.js';
import { WalletSignInSection } from './sections/WalletSignInSection/WalletSignInSection.js';
import { SocialSignInSection } from './sections/SocialSignInSection/SocialSignInSection.js';
import { useLoginView } from './useLoginView.js';

const LoginView = () => {
    var _a;
    const { error } = useErrorContext();
    const { loading } = useLoadingContext();
    const { view, setView } = useViewContext();
    const { isWalletOnly, logingViewSections } = useLoginView();
    const [showWalletList, setShowWalletList] = useState(isWalletOnly);
    const [isLoading, setIsLoading] = useState(loading);
    useEffect(() => {
        // toolkit log in with email was set to false
        if (isWalletOnly) {
            setView('login-with-wallet-only');
            setShowWalletList(true);
        }
        // returning from 'View all wallets' button
        if (!isWalletOnly && view.type === 'login-with-email-or-wallet') {
            setShowWalletList(false);
        }
        // toolkit log in with email was set back to true
        if (!isWalletOnly &&
            (!showWalletList || view.type === 'login-with-wallet-only')) {
            setView('login-with-email-or-wallet');
            setShowWalletList(false);
        }
    }, [isWalletOnly, view.type]);
    const { showDefaultFooter } = useAuthLayoutChecks();
    const numOfWallets = ((_a = logingViewSections.find((section) => section.type === SdkViewSectionType.Wallet)) === null || _a === void 0 ? void 0 : _a.numOfItemsToDisplay) || 0;
    const renderViewSections = useMemo(() => logingViewSections.map((viewSection, index) => {
        switch (viewSection.type) {
            case SdkViewSectionType.Separator:
                return (jsx(Divider, { text: viewSection.label }, `${viewSection.type}_${index}`));
            case SdkViewSectionType.Email:
                return (jsx(LoginEmailForm, { onSubmit: () => setIsLoading(true), onSubmitError: () => setIsLoading(false), isLoading: isLoading }, `${viewSection.type}_${index}`));
            case SdkViewSectionType.Wallet:
                return (jsx(WalletSignInSection, { numberOfItensToShow: viewSection.numOfItemsToDisplay || 0, onSubmit: () => {
                        setView('login-with-email-or-wallet-full-wallet-list'); // this view is just to show the back button
                        setShowWalletList(true);
                    }, isLoading: isLoading }, `${viewSection.type}_${index}`));
            case SdkViewSectionType.Social:
                return (jsx(SocialSignInSection, { collapsedLayout: numOfWallets > 1, sectionData: viewSection }, `${viewSection.type}_${index}`));
            case SdkViewSectionType.Text:
                return (jsx(Typography, { variant: 'body_normal', color: 'secondary', align: viewSection.alignment, className: 'login-view__text', children: viewSection.label }, `${viewSection.type}_${index}`));
            default:
                return null;
        }
    }), [logingViewSections, isLoading, numOfWallets, setView]);
    if (showWalletList) {
        return jsx(WalletList, {});
    }
    const shouldShowFooter = isMobile() && showDefaultFooter;
    return (jsxs(Fragment, { children: [error && (jsx(ErrorContainer, { className: 'login-view__error-container', withIcon: false, children: error })), jsx("div", { className: classNames('login-view__container', {
                    'login-view__container--error': Boolean(error),
                }), children: jsx("div", { className: 'login-view__scroll', "data-testid": 'login-view-scroll', children: renderViewSections }) }), shouldShowFooter && jsx(DefaultFooter, { hideBorder: true })] }));
};

export { LoginView };
