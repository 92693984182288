/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var ExportModelEnum;
(function (ExportModelEnum) {
    ExportModelEnum["Users"] = "users";
})(ExportModelEnum || (ExportModelEnum = {}));
function ExportModelEnumFromJSON(json) {
    return ExportModelEnumFromJSONTyped(json);
}
function ExportModelEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ExportModelEnumToJSON(value) {
    return value;
}

export { ExportModelEnum, ExportModelEnumFromJSON, ExportModelEnumFromJSONTyped, ExportModelEnumToJSON };
