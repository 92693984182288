import { ChainEnum } from '@dynamic-labs/sdk-api';
import { TurnkeyEVMWalletConnector } from './lib/TurnkeyEVMWalletConnector/TurnkeyEVMWalletConnector.js';
export { TurnkeyEVMWalletConnector } from './lib/TurnkeyEVMWalletConnector/TurnkeyEVMWalletConnector.js';
import { TurnkeyWalletConnectorInfo } from './types.js';
import { TurnkeySolanaWalletConnector } from './lib/TurnkeySolanaWalletConnector/TurnkeySolanaWalletConnector.js';
export { TurnkeySolanaWalletConnector } from './lib/TurnkeySolanaWalletConnector/TurnkeySolanaWalletConnector.js';

const TurnkeyWalletConnectors = (props, chain = ChainEnum.Evm) => {
    var _a, _b;
    if (((_a = props.apiProviders) === null || _a === void 0 ? void 0 : _a.turnkey) && chain === ChainEnum.Evm) {
        return [
            TurnkeyEVMWalletConnector.bind(null, TurnkeyWalletConnectorInfo.TurnkeyHD),
            TurnkeyEVMWalletConnector.bind(null, TurnkeyWalletConnectorInfo.Turnkey),
        ];
    }
    else if (((_b = props.apiProviders) === null || _b === void 0 ? void 0 : _b.turnkey) && chain === ChainEnum.Sol) {
        return [
            TurnkeySolanaWalletConnector.bind(null, TurnkeyWalletConnectorInfo.TurnkeyHD),
        ];
    }
    return [];
};

export { TurnkeyWalletConnectors };
