import { ZeroDevConnector } from './ZeroDevConnector.js';
export { EthereumAaConnector } from './ethereum-aa.js';
export { isZeroDevConnector } from './utils/isZeroDevConnector.js';

const ZeroDevSmartWalletConnectors = (props) => {
    var _a;
    if ((_a = props.apiProviders) === null || _a === void 0 ? void 0 : _a.zerodev) {
        return [ZeroDevConnector];
    }
    return [];
};

export { ZeroDevSmartWalletConnectors };
