/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var HardwareWalletEnum;
(function (HardwareWalletEnum) {
    HardwareWalletEnum["Ledger"] = "ledger";
})(HardwareWalletEnum || (HardwareWalletEnum = {}));
function HardwareWalletEnumFromJSON(json) {
    return HardwareWalletEnumFromJSONTyped(json);
}
function HardwareWalletEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function HardwareWalletEnumToJSON(value) {
    return value;
}

export { HardwareWalletEnum, HardwareWalletEnumFromJSON, HardwareWalletEnumFromJSONTyped, HardwareWalletEnumToJSON };
