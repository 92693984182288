/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var BillingSubscriptionPlanTypeEnum;
(function (BillingSubscriptionPlanTypeEnum) {
    BillingSubscriptionPlanTypeEnum["Free"] = "free";
    BillingSubscriptionPlanTypeEnum["Advanced"] = "advanced";
    BillingSubscriptionPlanTypeEnum["Enterprise"] = "enterprise";
})(BillingSubscriptionPlanTypeEnum || (BillingSubscriptionPlanTypeEnum = {}));
function BillingSubscriptionPlanTypeEnumFromJSON(json) {
    return BillingSubscriptionPlanTypeEnumFromJSONTyped(json);
}
function BillingSubscriptionPlanTypeEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function BillingSubscriptionPlanTypeEnumToJSON(value) {
    return value;
}

export { BillingSubscriptionPlanTypeEnum, BillingSubscriptionPlanTypeEnumFromJSON, BillingSubscriptionPlanTypeEnumFromJSONTyped, BillingSubscriptionPlanTypeEnumToJSON };
