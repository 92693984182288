import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgMultipleAccountSecurity } from '../../../shared/assets/multiple-account-security.js';
import { ReactComponent as SvgPasskeyAccountSecurity } from '../../../shared/assets/passkey-account-security.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getEnabledEmbeddedWalletsAuthenticators } from '../../../utils/functions/getEnabledEmbeddedWalletsAuthenticators/getEnabledEmbeddedWalletsAuthenticators.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import { usePasskeyRecovery } from '../../../utils/hooks/usePasskeyRecovery/usePasskeyRecovery.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import { useIsTurnkeyWallet } from '../../../utils/hooks/useIsTurnkeyWallet/useIsTurnkeyWallet.js';
import { Typography } from '../../../components/Typography/Typography.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import { Button } from '../../../components/Button/Button.js';
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import '../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { EmbeddedAuthChoiceCards } from '../EmbeddedAuthChoiceCards/EmbeddedAuthChoiceCards.js';
import { AuthChoiceInfo } from './AuthChoiceInfo/AuthChoiceInfo.js';

const titleDescriptionMap = {
    helpSession: {
        description: 'dyn_embedded_authenticator.need_help_description',
        title: 'dyn_embedded_authenticator.need_help_title',
    },
    reveal: {
        description: 'dyn_embedded_authenticator.reveal_description',
        title: 'dyn_embedded_authenticator.reveal_title',
    },
    sendTransaction: {
        description: 'dyn_embedded_authenticator.description',
        title: 'dyn_embedded_authenticator.title',
    },
    signMessage: {
        description: 'dyn_embedded_authenticator.description',
        title: 'dyn_embedded_authenticator.title',
    },
};
const EmbeddedAuthChoice = ({ currentAction, }) => {
    const { t } = useTranslation();
    const { primaryWallet, projectSettings, setShowAuthFlow } = useInternalDynamicContext();
    const { initPasskeyRecoveryProcess } = usePasskeyRecovery();
    const { isTurnkeyWallet } = useIsTurnkeyWallet();
    const supportedAuthenticators = useMemo(() => {
        var _a;
        const supportedAuths = getEnabledEmbeddedWalletsAuthenticators((_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _a === void 0 ? void 0 : _a.supportedSecurityMethods);
        const supportedMethods = Object.keys(supportedAuths);
        if (!currentAction || !['helpSession', 'reveal'].includes(currentAction)) {
            return supportedMethods.filter((auth) => auth !== 'email');
        }
        return supportedMethods;
    }, [projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets]);
    const isEmailAuthSessionActive = () => {
        if (!primaryWallet || !isTurnkeyWallet) {
            return false;
        }
        const turnkeyAuthenticatorHandler = primaryWallet.connector.getAuthenticatorHandler();
        return turnkeyAuthenticatorHandler.isSessionActive();
    };
    const renderIcon = () => supportedAuthenticators.length > 1 ? (jsx(SvgMultipleAccountSecurity, {})) : (jsx(SvgPasskeyAccountSecurity, {}));
    const buttonCopyKey = isEmailAuthSessionActive()
        ? 'dyn_embedded_authenticator.passkey.auth_info.start_button'
        : 'dyn_embedded_authenticator.passkey.auth_info.add_button';
    const { title, description } = titleDescriptionMap[currentAction || 'signMessage'];
    if (!(supportedAuthenticators === null || supportedAuthenticators === void 0 ? void 0 : supportedAuthenticators.length)) {
        return null;
    }
    return (jsxs(Fragment, { children: [jsx(ModalHeader, { children: jsx(Typography, { as: 'h1', variant: 'title', color: 'primary', "data-testid": 'dynamic-auth-modal-heading', className: 'header__typography', copykey: title, children: t(title) }) }), jsxs("div", { className: 'embedded-auth-choice', children: [jsx("div", { className: 'embedded-auth-choice__header', children: renderIcon() }), jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', className: 'embedded-auth-choice__subtitle', copykey: description, children: t(description) }), supportedAuthenticators.length > 1 ? (jsx(EmbeddedAuthChoiceCards, { currentAction: currentAction, supportedAuthenticators: supportedAuthenticators })) : (jsxs(Fragment, { children: [jsx(AuthChoiceInfo, { choice: 'passkey' }), jsx(Button, { dataTestId: 'passkey-only-choice-button', buttonVariant: 'brand-primary', color: 'brand-primary', expanded: true, onClick: () => initPasskeyRecoveryProcess('passkey'), copykey: buttonCopyKey, typographyProps: {
                                    color: 'inherit',
                                }, children: t(buttonCopyKey) })] })), currentAction !== 'reveal' && (jsx(Button, { buttonVariant: 'primary', buttonClassName: 'embedded-auth-choice__skip-button', expanded: true, onClick: () => setShowAuthFlow(false), copykey: 'dyn_embedded_authenticator.skip', children: t('dyn_embedded_authenticator.skip') }))] })] }));
};

export { EmbeddedAuthChoice };
