import { jsx } from 'react/jsx-runtime';
import { getChainInfo } from '@dynamic-labs/wallet-connector-core';
import 'react';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import { getChainIcon } from '../../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/iconic';
import '../../../context/ViewContext/ViewContext.js';
import '../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import { getChainStepState } from '../utils/getChainStepState/getChainStepState.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import { Step } from '../../../components/Step/Step.js';
import { Stepper } from '../../../components/Stepper/Stepper.js';
import '../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const WalletProgressStepper = () => {
    const { bridgeChainsToConnect = [], bridgeChains = [] } = useInternalDynamicContext();
    const allChains = bridgeChains.map(({ chain }) => chain);
    const chainsLeftToConnect = [...bridgeChainsToConnect].map(({ chain }) => chain);
    return (jsx("div", { className: 'wallet-progress-stepper', "data-testid": 'wallet-progress-stepper', children: jsx(Stepper, { children: allChains.map((chain, index) => {
                var _a;
                const chainInfo = getChainInfo(chain);
                const PrimaryWalletChainIcon = getChainIcon(chain);
                return (jsx(Step, { state: getChainStepState(chain, chainsLeftToConnect), icon: jsx(PrimaryWalletChainIcon, {}), children: (_a = chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.blockchainName) !== null && _a !== void 0 ? _a : chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.displayName }, chain));
            }) }) }));
};

export { WalletProgressStepper };
