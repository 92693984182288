import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useCallback } from 'react';
import '../../../context/DynamicContext/DynamicContext.js';
import { decodeJwt } from '../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/sdk-api';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import { dynamicEvents } from '../../../events/dynamicEvents.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../functions/getAuthToken/getAuthToken.js';
import { isTurnkeyEnabled } from '../../functions/isTurnkeyEnabled/isTurnkeyEnabled.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useEmbeddedWallet } from '../useEmbeddedWallet/useEmbeddedWallet.js';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

// Hook exposed to customers and used internally to trigger embedded reveal
const useEmbeddedReveal = () => {
    const { projectSettings, setShowAuthFlow } = useInternalDynamicContext();
    const { userHasEmbeddedWallet } = useEmbeddedWallet();
    const { setView } = useViewContext();
    const initExportProcess = useCallback((recoveryPhrase = false) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const jwt = getAuthToken();
        if (!jwt) {
            throw new Error('User is not logged in');
        }
        if (!isTurnkeyEnabled(projectSettings)) {
            throw new Error('Passkey embedded wallet is not enabled. Go to the dashboard and make sure to have both Turnkey/Passkey Embedded wallets and at least one EVM network enabled. Also, check if EthereumWalletConnectors is in the  DynamicContextProvider > settings > walletConnectors.');
        }
        if (!userHasEmbeddedWallet()) {
            throw new Error('Passkey embedded wallet not found');
        }
        const decodedJwt = decodeJwt(jwt);
        const walletProperties = (_b = (_a = decodedJwt === null || decodedJwt === void 0 ? void 0 : decodedJwt.verifiedCredentials) === null || _a === void 0 ? void 0 : _a.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _b === void 0 ? void 0 : _b.walletProperties;
        const turnkeyHDWalletId = walletProperties === null || walletProperties === void 0 ? void 0 : walletProperties.turnkeyHDWalletId;
        if (recoveryPhrase && !turnkeyHDWalletId) {
            throw new Error('Wallet is non-HD and does not have a recovery phrase');
        }
        setShowAuthFlow(true, {
            ignoreIfIsEmbeddedWidget: false,
            performMultiWalletChecks: false,
        });
        setView('embedded-reveal-view', { exportPrivateKey: !recoveryPhrase });
        return new Promise((resolve, reject) => {
            dynamicEvents.once('embeddedWalletRevealCompleted', (wallet) => resolve(wallet));
            dynamicEvents.once('embeddedWalletRevealFailed', (error) => reject(error));
        });
    }), [setShowAuthFlow, projectSettings, setView, userHasEmbeddedWallet]);
    return {
        initExportProcess,
    };
};

export { useEmbeddedReveal };
