import { useTranslation } from 'react-i18next';
import 'react';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useNoAccessText = () => {
    const { socialMediaLinkText, socialMediaUrl, accessDeniedMessagePrimary, accessDeniedMessageSecondary, } = useInternalDynamicContext();
    const { view } = useViewContext();
    const { t } = useTranslation();
    const isChainalysisView = view.type === 'chainalysis-blocked-wallet';
    const isGateBlockedView = view.type === 'gate-blocked-wallet';
    const isAccessListView = view.type === 'no-access';
    const getSocialMediaLinkText = () => {
        if (isChainalysisView) {
            return t(textData.chainalysis._socialMediaLinkText);
        }
        if (isGateBlockedView) {
            return undefined;
        }
        return socialMediaLinkText;
    };
    const getSocialMediaUrl = () => {
        if (isChainalysisView) {
            return t(textData.chainalysis._socialMediaUrl);
        }
        if (isGateBlockedView) {
            return undefined;
        }
        return socialMediaUrl;
    };
    const getTitle = () => {
        if (isChainalysisView) {
            return t(textData.chainalysis.title);
        }
        if (isGateBlockedView) {
            return t(textData.gate.title);
        }
        return accessDeniedMessagePrimary || t(textData.default.title);
    };
    const getSubtitle = () => {
        if (isChainalysisView) {
            return t(textData.chainalysis.subtitle);
        }
        if (isGateBlockedView) {
            return t(textData.gate.subtitle);
        }
        return accessDeniedMessageSecondary || t(textData.default.subtitle);
    };
    const getButtonTitle = () => {
        if (isChainalysisView) {
            return t(textData.chainalysis.buttonTitle);
        }
        if (isGateBlockedView) {
            return t(textData.gate.buttonTitle);
        }
        return t(textData.default.buttonTitle);
    };
    return {
        _socialMediaLinkText: getSocialMediaLinkText(),
        _socialMediaUrl: getSocialMediaUrl(),
        buttonTitle: getButtonTitle(),
        isAccessListView,
        isChainalysisView,
        isGateBlockedView,
        subtitle: getSubtitle(),
        title: getTitle(),
    };
};
const textData = {
    chainalysis: {
        _socialMediaLinkText: 'dyn_no_access.chainalysis.social_media_link_text',
        _socialMediaUrl: 'dyn_no_access.chainalysis.social_media_link_url',
        buttonTitle: 'dyn_no_access.chainalysis.button_text',
        subtitle: 'dyn_no_access.chainalysis.description',
        title: 'dyn_no_access.chainalysis.title',
    },
    default: {
        buttonTitle: 'dyn_no_access.default.button_text',
        subtitle: 'dyn_no_access.default.description',
        title: 'dyn_no_access.default.title',
    },
    gate: {
        _socialMediaLinkText: undefined,
        _socialMediaUrl: undefined,
        buttonTitle: 'dyn_no_access.gate.button_text',
        subtitle: 'dyn_no_access.gate.description',
        title: 'dyn_no_access.gate.title',
    },
};

export { textData, useNoAccessText };
