/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var CurrencyType;
(function (CurrencyType) {
    CurrencyType["Fiat"] = "fiat";
    CurrencyType["Crypto"] = "crypto";
})(CurrencyType || (CurrencyType = {}));
function CurrencyTypeFromJSON(json) {
    return CurrencyTypeFromJSONTyped(json);
}
function CurrencyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CurrencyTypeToJSON(value) {
    return value;
}

export { CurrencyType, CurrencyTypeFromJSON, CurrencyTypeFromJSONTyped, CurrencyTypeToJSON };
