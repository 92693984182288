/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var SdkViewSectionAlignment;
(function (SdkViewSectionAlignment) {
    SdkViewSectionAlignment["Center"] = "center";
    SdkViewSectionAlignment["Left"] = "left";
    SdkViewSectionAlignment["Right"] = "right";
})(SdkViewSectionAlignment || (SdkViewSectionAlignment = {}));
function SdkViewSectionAlignmentFromJSON(json) {
    return SdkViewSectionAlignmentFromJSONTyped(json);
}
function SdkViewSectionAlignmentFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function SdkViewSectionAlignmentToJSON(value) {
    return value;
}

export { SdkViewSectionAlignment, SdkViewSectionAlignmentFromJSON, SdkViewSectionAlignmentFromJSONTyped, SdkViewSectionAlignmentToJSON };
