import { __awaiter } from '../../../_virtual/_tslib.js';
import { createPublicClient, http, formatEther } from 'viem';
import { createAccount } from '@turnkey/viem';
import { IframeStamper } from '@turnkey/iframe-stamper';
import { WebauthnStamper } from '@turnkey/webauthn-stamper';
import { TurnkeyClient } from '@turnkey/http';
import { getRpcUrlForChain, logger } from '@dynamic-labs/wallet-connector-core';
import { createWalletClientWithUiConfirmation } from '@dynamic-labs/viem-utils';
import { parseEvmNetworks, getOrMapViemChain, getTLD } from '@dynamic-labs/utils';
import { ProviderChain } from '@dynamic-labs/rpc-providers';
import { findTurnkeyVerifiedCredential } from '../utils/findTurnkeyVerifiedCredential/findTurnkeyVerifiedCredential.js';
import '../utils/convertAttestationTransports/convertAttestationTransports.js';
import 'tldts';
import '@solana/web3.js';
import { TurnkeyWalletConnectorBase } from '../TurnkeyWalletConnectorBase/TurnkeyWalletConnectorBase.js';
import { TURNKEY_API_BASE_URL } from '../constants.js';

class TurnkeyEVMWalletConnector extends TurnkeyWalletConnectorBase {
    constructor(nameAndKey, props) {
        var _a;
        super(nameAndKey, props);
        // Public fields
        this.connectedChain = 'EVM';
        this.supportedChains = ['ETH', 'EVM'];
        this.verifiedCredentialChain = 'eip155';
        this.evmNetworks = parseEvmNetworks(props.evmNetworks);
        this.walletUiUtils = props.walletUiUtils;
        this._turnkeyAccount = undefined;
        this._selectedChainId = this.getLastUsedChainId();
        this.chainRpcProviders = props.chainRpcProviders;
        (_a = this.chainRpcProviders) === null || _a === void 0 ? void 0 : _a.registerChainProviders(ProviderChain.EVM, (config) => {
            const rpcProviders = {};
            if (config === null || config === void 0 ? void 0 : config.evm) {
                rpcProviders.evm = parseEvmNetworks(config.evm).map((network) => {
                    var _a;
                    const rpcUrl = ((_a = network.privateCustomerRpcUrls) === null || _a === void 0 ? void 0 : _a[0]) || network.rpcUrls[0];
                    const provider = createPublicClient({
                        chain: getOrMapViemChain(network),
                        transport: http(rpcUrl),
                    });
                    return {
                        chainId: network.chainId,
                        chainName: network.name,
                        provider,
                    };
                });
            }
            return rpcProviders.evm;
        });
        this.__turnkeyClient = this.getTurnkeyClient();
    }
    getLastUsedChainId() {
        var _a;
        if (this.lastUsedChainId) {
            return this.lastUsedChainId;
        }
        if (!((_a = this.evmNetworks) === null || _a === void 0 ? void 0 : _a.length)) {
            return undefined;
        }
        return this.evmNetworks[0].chainId;
    }
    // Public methods
    getNetwork() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return (_a = (yield this.getSigner())) === null || _a === void 0 ? void 0 : _a.getChainId();
        });
    }
    supportsNetworkSwitching() {
        return true;
    }
    switchNetwork({ networkChainId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            this.lastUsedChainId = networkChainId;
            this._selectedChainId = networkChainId;
            yield this.refreshTurnkeyAccount();
            this.emit('chainChange', {
                chain: networkChainId.toString(),
            });
        });
    }
    setVerifiedCredentials(verifiedCredentials) {
        const turnkeyVerifiedCredential = findTurnkeyVerifiedCredential(verifiedCredentials, 'eip155');
        const didTurnkeyVerifiedCredentialsChanged = JSON.stringify(this.verifiedCredential) !==
            JSON.stringify(turnkeyVerifiedCredential);
        if (!didTurnkeyVerifiedCredentialsChanged) {
            return;
        }
        this.verifiedCredential = turnkeyVerifiedCredential;
        this.refreshTurnkeyAccount();
    }
    getRpcUrl() {
        const chain = this._selectedChainId;
        return getRpcUrlForChain({
            chainId: chain,
            networks: this.evmNetworks,
        });
    }
    getBalance() {
        return __awaiter(this, void 0, void 0, function* () {
            const address = this.turnkeyAddress;
            if (!address) {
                return undefined;
            }
            const rpcUrl = this.getRpcUrl();
            if (!rpcUrl) {
                return undefined;
            }
            const client = createPublicClient({
                transport: http(rpcUrl),
            });
            const balance = yield client.getBalance({
                address: address,
            });
            return formatEther(balance);
        });
    }
    signMessage(messageToSign) {
        return __awaiter(this, void 0, void 0, function* () {
            const signer = yield this.getSigner();
            if (!signer) {
                throw new Error('Signer not found');
            }
            return signer.signMessage({
                message: messageToSign,
            });
        });
    }
    getPublicClient() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.evmNetworks.length === 0) {
                return undefined;
            }
            const networkId = (_a = (yield this.getNetwork())) !== null && _a !== void 0 ? _a : 1;
            const configurations = {
                cosmos: [],
                evm: this.evmNetworks,
                solana: [],
                starknet: undefined,
            };
            return (_c = (_b = this.chainRpcProviders) === null || _b === void 0 ? void 0 : _b.getEvmProviderByChainId(configurations, networkId)) === null || _c === void 0 ? void 0 : _c.provider;
        });
    }
    getSigner() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getTurnkeyAccount();
            return this.getWalletClient();
        });
    }
    getTurnkeyClient() {
        var _a;
        let rpId = getTLD();
        if (!rpId) {
            rpId = window.location.hostname;
        }
        const stamper = new WebauthnStamper({
            rpId,
        });
        this.__turnkeyClient =
            (_a = this.getAuthenticatorHandler().client) !== null && _a !== void 0 ? _a : new TurnkeyClient({
                baseUrl: TURNKEY_API_BASE_URL,
            }, stamper);
        return this.__turnkeyClient;
    }
    // decides in runtime which stamper to use and creates the corresponding account
    getAccount() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if ((this.getAuthenticatorHandler().recoveryType === 'passkey' &&
                ((_a = this.__turnkeyClient) === null || _a === void 0 ? void 0 : _a.stamper) instanceof IframeStamper) ||
                (this.getAuthenticatorHandler().recoveryType === 'email' &&
                    ((_b = this.__turnkeyClient) === null || _b === void 0 ? void 0 : _b.stamper) instanceof WebauthnStamper) ||
                this.__turnkeyClient !== this.getAuthenticatorHandler().client) {
                yield this.refreshTurnkeyAccount();
            }
            return this._turnkeyAccount;
        });
    }
    getWalletClient() {
        const rpcUrl = this.networkRpcUrl;
        const account = this._turnkeyAccount;
        const evmNetwork = this.currentEvmNetwork;
        if (!account || !rpcUrl || !evmNetwork) {
            return undefined;
        }
        return createWalletClientWithUiConfirmation({
            account: this.getAccount.bind(this),
            address: this.turnkeyAddress,
            chain: getOrMapViemChain(evmNetwork),
            transport: http(rpcUrl),
            walletUiUtils: this.walletUiUtils,
        });
    }
    // Private methods
    refreshTurnkeyAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            this._turnkeyAccount = undefined;
            return this.getTurnkeyAccount();
        });
    }
    get currentChainId() {
        var _a, _b, _c;
        return (_a = this._selectedChainId) !== null && _a !== void 0 ? _a : (_c = (_b = this.evmNetworks) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.chainId;
    }
    get networkRpcUrl() {
        const chainId = this.currentChainId;
        const evmNetwork = this.evmNetworks.find((network) => network.chainId === chainId);
        if (!evmNetwork) {
            return undefined;
        }
        const rpcUrl = getRpcUrlForChain({
            chainId: chainId,
            networks: this.evmNetworks,
        });
        return rpcUrl;
    }
    get currentEvmNetwork() {
        const chainId = this.currentChainId;
        return this.evmNetworks.find((network) => network.chainId === chainId);
    }
    getTurnkeyAccount() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (this._turnkeyAccount) {
                return this._turnkeyAccount;
            }
            const { turnkeySubOrganizationId } = (_a = this.walletProperties) !== null && _a !== void 0 ? _a : {};
            const { address } = (_b = this.verifiedCredential) !== null && _b !== void 0 ? _b : {};
            if (!turnkeySubOrganizationId || !address) {
                return;
            }
            this._turnkeyAccount = yield createAccount({
                client: this.getTurnkeyClient(),
                ethereumAddress: address,
                organizationId: turnkeySubOrganizationId,
                signWith: address,
            });
            return this._turnkeyAccount;
        });
    }
    get lastUsedChainId() {
        const lastUsedChainIdLS = localStorage.getItem(TurnkeyEVMWalletConnector.lastUsedChainIdStorageKey);
        if (!lastUsedChainIdLS)
            return undefined;
        try {
            const chainId = parseInt(lastUsedChainIdLS);
            if (isNaN(chainId)) {
                return undefined;
            }
            const isChainCurrentlyEnabled = this.evmNetworks.some((network) => network.chainId === chainId);
            if (!isChainCurrentlyEnabled) {
                const lastUsedChainId = this.evmNetworks[0].chainId;
                this.lastUsedChainId = lastUsedChainId;
                return this.lastUsedChainId;
            }
            return chainId;
        }
        catch (err) {
            logger.error(err);
            return undefined;
        }
    }
    set lastUsedChainId(chainId) {
        if (chainId === undefined) {
            localStorage.removeItem(TurnkeyEVMWalletConnector.lastUsedChainIdStorageKey);
        }
        else {
            localStorage.setItem(TurnkeyEVMWalletConnector.lastUsedChainIdStorageKey, chainId.toString());
        }
    }
}
TurnkeyEVMWalletConnector.lastUsedChainIdStorageKey = 'turnkey-last-used-chain-id';

export { TurnkeyEVMWalletConnector };
