import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { createEmailVerification, signInWithEmailVerification } from '../../../data/api.js';

const createEmailHandler = (environmentId, createUserProfileByJWT) => (email) => __awaiter(void 0, void 0, void 0, function* () {
    const { verificationUUID } = yield createEmailVerification({
        email,
        environmentId,
    });
    return {
        verify: (oneTimePassword) => __awaiter(void 0, void 0, void 0, function* () {
            const { jwt } = yield signInWithEmailVerification({
                environmentId,
                verificationToken: oneTimePassword,
                verificationUUID,
            });
            yield createUserProfileByJWT(jwt);
            return {
                email,
                verified: Boolean(jwt),
            };
        }),
    };
});

export { createEmailHandler };
