import { jsx, jsxs } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import 'react';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgPromptAccountSwitch } from '../../../../shared/assets/prompt-account-switch.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { PromptModal } from '../../components/PromptModal/PromptModal.js';
import { WidgetPortal } from '../../components/WidgetPortal/WidgetPortal.js';
import { useCloseMultiWalletPrompt } from '../../hooks/useCloseMultiWalletPrompt/useCloseMultiWalletPrompt.js';
import '../../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import { Button } from '../../../../components/Button/Button.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import '../../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const DetectedNewWalletContent = () => {
    const { t } = useTranslation();
    const { setMultiWalletWidgetState, primaryWallet } = useInternalDynamicContext();
    const handleClick = () => {
        setMultiWalletWidgetState('awaiting_signature', undefined, 'linking_new_wallet');
    };
    return (jsxs("div", { className: 'detected-new-wallet-modal__content', children: [jsx(Typography, { variant: 'body_normal', color: 'primary', children: t('dyn_detected_new_wallet.title') }), jsx(SvgPromptAccountSwitch, {}), jsx(Typography, { variant: 'body_small', color: 'secondary', children: t('dyn_detected_new_wallet.description', {
                    walletName: primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.name,
                }) }), jsx(Button, { expanded: true, onClick: handleClick, typographyProps: {
                    variant: 'button_secondary',
                }, children: t('dyn_detected_new_wallet.confirm_button') })] }));
};
const DetectedNewWalletWidgetPortal = () => (jsx(WidgetPortal, { onClose: useCloseMultiWalletPrompt(), children: jsx(DetectedNewWalletContent, {}) }));
const DetectedNewWalletModal = () => (jsx(PromptModal, { onClose: useCloseMultiWalletPrompt(), children: jsx(DetectedNewWalletContent, {}) }));

export { DetectedNewWalletModal, DetectedNewWalletWidgetPortal };
