import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { useRef, useCallback } from 'react';
import { revokeSession } from '../../../../data/api.js';
import { dynamicEvents } from '../../../../events/dynamicEvents.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import { WALLET_PICKER_SEARCH_KEY, IS_MULTI_WALLET_ENABLED, AUTH_TOKEN } from '../../../../utils/constants/localStorage.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import { LocalStorage } from '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../../../utils/functions/getAuthToken/getAuthToken.js';
import 'viem/chains';
import { useInternalDynamicEvents } from '../../../../utils/hooks/events/useDynamicEvents/useDynamicEvents.js';
import '../../DynamicContext.js';
import '../../../CaptchaContext/CaptchaContext.js';
import '../../../ErrorContext/ErrorContext.js';
import '../../../AccessDeniedContext/AccessDeniedContext.js';
import '../../../AccountExistsContext/AccountExistsContext.js';
import '../../../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../LoadingContext/LoadingContext.js';
import '../../../SocialRedirectContext/SocialRedirectContext.js';
import '../../../WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const useHandleLogout = (params) => {
    const paramsRef = useRef(params);
    paramsRef.current = params;
    const logout = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const p = paramsRef.current;
        p.setSdkHasLoaded(false);
        p.clearAllWalletConnectSessions();
        p.setBridgeChainsToConnect(p.bridgeChains);
        p.setShowDynamicUserProfile(false);
        p.setShowBridgeWidget(false);
        p.resetLoginWithEmail();
        LocalStorage.removeFromLS(WALLET_PICKER_SEARCH_KEY);
        if (!p.toolkitEnabled) {
            LocalStorage.removeFromLS(IS_MULTI_WALLET_ENABLED);
        }
        const jwt = getAuthToken();
        if (jwt && p.user) {
            // do not await this async call - just fire and forget
            revokeSession({ environmentId: p.environmentId, sessionId: p.user.sessionId }, jwt);
        }
        yield Promise.all(p.walletConnectors.map((connector) => connector.endSession()));
        yield Promise.all(p.connectedWallets.map((connectedWallet) => connectedWallet.connector.endSession()));
        // Necessary when user logs out in user data collection view
        // Dont require flag to be set though
        if (p.isVerificationInProgress)
            p.setIsVerificationInProgress(false);
        p.setLegacyIsVerifying(false);
        LocalStorage.removeFromLS(AUTH_TOKEN);
        p.setAuthMode(p.initialAuthenticationMode);
        p.setShowAuthFlow(false, {
            emitCancelAuth: false,
            ignoreIfIsEmbeddedWidget: false,
            initializeWalletConnect: false,
            performMultiWalletChecks: false,
        });
        p.setMultiWalletWidgetState('idle');
        p.removeLsSettings();
        p.handleRemoveLsNetworks();
        p.setOnboardingOnlyJwt(undefined);
        p.removeConnectedWalletsInfo();
        p.removeIsFullyConnected();
        p.clearPrimaryWalletId();
        p.removeConnectedNameService();
        dynamicEvents.emit('logout');
        p.removeUser();
    }), []);
    /** Allows triggering a logout from anywhere in the code */
    useInternalDynamicEvents('triggerLogout', () => logout());
    return logout;
};

export { useHandleLogout };
