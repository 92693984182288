import { useCallback } from 'react';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';

/**
 * this hook wraps `setShowAuthFlow`. WalletConnect requires initialization
 * before we can connect a wallet. The goal here is that when a user clicks
 * on the "Connect your wallet" button, the SDK starts initializing WalletConnect
 * and will prime it for connection. This way, when the user clicks on the
 * "Connect" button in the WalletConnect modal, there is a uri ready to go
 * to immediately trigger the deep link on mobile
 */
const useSetShowLinkNewWalletModal = ({ setShowAuthFlow, isMultiWalletEnabled, isAuthenticated, }) => {
    const setSetShowLinkNewWalletModalWithInit = useCallback((value) => {
        if (value) {
            // Do not change state if the user is already signed in and multi-wallet is not enabled
            if (!isAuthenticated)
                return;
            if (!isMultiWalletEnabled) {
                logger.warn('setShowLinkNewWalletModal was called while the user was not logged in. This results in a no-op. To log the user in, use setShowAuthFlow. For more information, see: https://docs.dynamic.xyz/react-sdk/hooks/usedynamiccontext');
                return;
            }
        }
        setShowAuthFlow(value, {
            emitCancelAuth: true,
            ignoreIfIsEmbeddedWidget: true,
            initializeWalletConnect: true,
            performMultiWalletChecks: false,
        });
    }, [setShowAuthFlow, isAuthenticated, isMultiWalletEnabled]);
    return setSetShowLinkNewWalletModalWithInit;
};

export { useSetShowLinkNewWalletModal };
