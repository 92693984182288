import { jsx } from 'react/jsx-runtime';
import { Toggle } from '../Toggle/Toggle.js';
import { Icon } from '../Icon/Icon.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import 'react';
import { ReactComponent as SvgCheck } from '../../shared/assets/check.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import { ListTile } from '../ListTile/ListTile.js';

const HardwareWalletToggle = ({ connectors, label, copykey, }) => {
    const toggle = (jsx(Toggle, { defaultChecked: connectors.some((connector) => connector.isHardwareWalletEnabled), onChange: (event) => {
            connectors.forEach((connector) => {
                connector.isHardwareWalletEnabled = event.target.checked;
            });
        }, "data-testid": 'hardware-wallet-toggle', icon: 
        // eslint-disable-next-line react/jsx-wrap-multilines
        jsx(Icon, { size: 'small', children: jsx(SvgCheck, {}) }) }));
    return (jsx(ListTile, { disabled: true, trailing: toggle, children: jsx("span", { copykey, children: label }) }));
};

export { HardwareWalletToggle };
