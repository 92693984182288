import { useRef, useEffect } from 'react';

const useWindowEvent = (eventName, listener) => {
    // Create a ref that stores handler
    const handler = useRef(listener);
    handler.current = listener;
    useEffect(() => {
        if (!(window === null || window === void 0 ? void 0 : window.addEventListener))
            return;
        // Create event listener that calls handler function stored in ref
        const callback = (event) => handler.current(event);
        window.addEventListener(eventName, callback);
        // Remove event callback on cleanup
        return () => {
            window.removeEventListener(eventName, callback);
        };
    }, [eventName]);
};

export { useWindowEvent };
