import { __rest } from '../../../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { classNames } from '../../utils/functions/classNames/classNames.js';

const Toggle = (_a) => {
    var { icon, variant = 'primary', className } = _a, props = __rest(_a, ["icon", "variant", "className"]);
    return (jsxs("label", { className: classNames('toggle', className), children: [jsx("input", Object.assign({ type: 'checkbox', className: 'toggle--input' }, props)), jsx("div", { className: classNames('toggle--content', `toggle--content__variant-${variant}`), children: jsx("div", { className: 'toggle--knob', children: icon }) })] }));
};

export { Toggle };
