import 'react';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import { createOwnerWallet } from '../../functions/createOwnerWallet/createOwnerWallet.js';
import '@dynamic-labs/multi-wallet';
import { isAccountAbstractionWallet } from '../../functions/isAccountAbstractionWallet/isAccountAbstractionWallet.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useIsTurnkeyWallet = () => {
    var _a;
    const { primaryWallet, user } = useInternalDynamicContext();
    const wallet = isAccountAbstractionWallet(primaryWallet)
        ? createOwnerWallet(primaryWallet, (user === null || user === void 0 ? void 0 : user.verifiedCredentials) || [])
        : primaryWallet;
    const getTurnkeyVerifiedCredential = () => {
        if (!wallet || !user) {
            return undefined;
        }
        const isPrimaryWalletTurnkey = wallet.connector.key.startsWith('turnkey');
        if (!isPrimaryWalletTurnkey) {
            return undefined;
        }
        const primaryWalletId = wallet.id;
        const turnkeyVC = user.verifiedCredentials.find((vc) => vc.id === primaryWalletId);
        if (!turnkeyVC) {
            return undefined;
        }
        return turnkeyVC;
    };
    const getUnclaimedTurnkeyTheSignerOfScw = () => {
        if (!wallet || !user) {
            return false;
        }
        const unclaimedTurnkeyVc = user.verifiedCredentials.find((vc) => {
            var _a, _b;
            return ((_a = vc.walletName) === null || _a === void 0 ? void 0 : _a.startsWith('turnkey')) &&
                ((_b = vc.walletProperties) === null || _b === void 0 ? void 0 : _b.isAuthenticatorAttached) === false;
        });
        const smartContractWalletWithUnclaimedTurnkeySigner = user.verifiedCredentials.find((vc) => unclaimedTurnkeyVc && vc.signerRefId === unclaimedTurnkeyVc.id);
        return smartContractWalletWithUnclaimedTurnkeySigner;
    };
    const turnkeyVerifiedCredential = getTurnkeyVerifiedCredential();
    const unclaimedTurnkeyTheSignerOfScw = getUnclaimedTurnkeyTheSignerOfScw();
    const isUnclaimedTurnkeyTheSignerOfScw = Boolean(unclaimedTurnkeyTheSignerOfScw);
    const isTurnkeyWallet = Boolean(turnkeyVerifiedCredential || isUnclaimedTurnkeyTheSignerOfScw);
    const hasTurnkeyVerifiedCredentialAuthenticator = Boolean((_a = turnkeyVerifiedCredential === null || turnkeyVerifiedCredential === void 0 ? void 0 : turnkeyVerifiedCredential.walletProperties) === null || _a === void 0 ? void 0 : _a.isAuthenticatorAttached);
    const isTurnkeyWalletWithoutAuthenticator = (isTurnkeyWallet && !hasTurnkeyVerifiedCredentialAuthenticator) ||
        isUnclaimedTurnkeyTheSignerOfScw;
    return {
        hasTurnkeyVerifiedCredentialAuthenticator,
        isTurnkeyWallet,
        isTurnkeyWalletWithoutAuthenticator,
        turnkeyVerifiedCredential,
    };
};

export { useIsTurnkeyWallet };
