import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useState, useCallback } from 'react';
import { ChainEnum } from '@dynamic-labs/sdk-api';
import '../../../context/DynamicContext/DynamicContext.js';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import { dynamicEvents } from '../../../events/dynamicEvents.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import { createUserProfile } from '../../functions/createUserProfile/createUserProfile.js';
import '@dynamic-labs/multi-wallet';
import { storeAuthToken } from '../../functions/storeAuthToken/index.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { createEmbeddedWalletWithPasskey } from '../../../views/Passkey/utils/createEmbeddedWalletWithPasskey/createEmbeddedWalletWithPasskey.js';
import { findPasskeyEmailWalletConnector } from '../../../views/Passkey/utils/findPasskeyEmailWalletConnector/findPasskeyEmailWalletConnector.js';
import { createPregeneratedWallet } from '../../../views/Passkey/utils/createPregeneratedWallet/createPregeneratedWallet.js';
import { findSmartWallet, initializeSmartWallet } from '../useWalletConnectors/utils/smartWallet/smartWallet.js';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

// This hook needs to be async that's why it's not using useMutation
const useCreateDynamicEmbeddedWalletMutation = () => {
    var _a, _b, _c;
    const { setPrimaryWalletId, setCallback, setUser, user, setShowAuthFlow, projectSettings, } = useInternalDynamicContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const embeddedChainConfig = ((_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.embeddedWallets) === null || _b === void 0 ? void 0 : _b.chainConfigurations) || [];
    const primaryEmbeddedChain = ((_c = embeddedChainConfig.find((chain) => chain.primary)) === null || _c === void 0 ? void 0 : _c.name) === 'SOL'
        ? ChainEnum.Sol
        : ChainEnum.Evm;
    const embeddedChains = embeddedChainConfig
        .filter((chain) => chain.enabled)
        .map((c) => (c.name === 'SOL' ? ChainEnum.Sol : ChainEnum.Evm));
    const createDynamicEmbeddedWalletMutation = useCallback(({ authToken, decodedJwt, environmentId, walletConnectorOptions, withAuthenticator, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        setIsLoading(true);
        const createEmbeddedWalletMethodToUse = withAuthenticator
            ? createEmbeddedWalletWithPasskey
            : createPregeneratedWallet;
        try {
            const { embeddedWalletVerifiedCredential, jwt, decodedJwt: updatedDecodedJwt, walletConnector, } = yield createEmbeddedWalletMethodToUse({
                authToken,
                chains: embeddedChains,
                environmentId,
                user: user !== null && user !== void 0 ? user : createUserProfile(decodedJwt),
                walletConnector: findPasskeyEmailWalletConnector(walletConnectorOptions, primaryEmbeddedChain),
            });
            const turnkeyVerifiedCredentials = (_d = updatedDecodedJwt === null || updatedDecodedJwt === void 0 ? void 0 : updatedDecodedJwt.verifiedCredentials) === null || _d === void 0 ? void 0 : _d.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'));
            /** It's not authenticated if it's a turnkey wallet that still hasn't created a passkey */
            const authenticated = Boolean((_e = turnkeyVerifiedCredentials === null || turnkeyVerifiedCredentials === void 0 ? void 0 : turnkeyVerifiedCredentials.walletProperties) === null || _e === void 0 ? void 0 : _e.isAuthenticatorAttached);
            const passkeyWallet = {
                address: (yield walletConnector.fetchPublicAddress()) || '',
                authenticated,
                chain: walletConnector.connectedChain || '',
                connected: true,
                connector: walletConnector,
                id: embeddedWalletVerifiedCredential.id,
            };
            dynamicEvents.emit('embeddedWalletCreated', passkeyWallet);
            const smartWallet = findSmartWallet(embeddedWalletVerifiedCredential, updatedDecodedJwt.verifiedCredentials);
            if (smartWallet) {
                yield initializeSmartWallet({
                    account: smartWallet,
                    verifiedCredentials: updatedDecodedJwt.verifiedCredentials,
                    walletConnectorOptions,
                });
                setPrimaryWalletId(smartWallet.id);
            }
            else {
                setPrimaryWalletId(embeddedWalletVerifiedCredential.id);
            }
            storeAuthToken(jwt);
            setUser(createUserProfile(updatedDecodedJwt));
            setShowAuthFlow(false, { performMultiWalletChecks: false });
            setCallback('embeddedWalletCreated', {
                email: (turnkeyVerifiedCredentials === null || turnkeyVerifiedCredentials === void 0 ? void 0 : turnkeyVerifiedCredentials.email) || '',
                verifiedCredential: turnkeyVerifiedCredentials,
            });
        }
        catch (err) {
            setError(err);
            logger.error('Failed to create embedded wallet', err);
            dynamicEvents.emit('embeddedWalletFailed', err);
        }
        finally {
            setIsLoading(false);
        }
    }), [setCallback, setPrimaryWalletId, setShowAuthFlow, setUser, user]);
    return {
        createDynamicEmbeddedWalletMutation,
        error,
        isLoading,
    };
};

export { useCreateDynamicEmbeddedWalletMutation };
