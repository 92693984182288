/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
function ProjectSettingsKycFromJSON(json) {
    return ProjectSettingsKycFromJSONTyped(json);
}
function ProjectSettingsKycFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'required': json['required'],
        'enabled': json['enabled'],
        'unique': json['unique'],
        'verify': json['verify'],
    };
}
function ProjectSettingsKycToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'required': value.required,
        'enabled': value.enabled,
        'unique': value.unique,
        'verify': value.verify,
    };
}

export { ProjectSettingsKycFromJSON, ProjectSettingsKycFromJSONTyped, ProjectSettingsKycToJSON };
