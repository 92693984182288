import { jsxs, jsx } from 'react/jsx-runtime';
import 'react';
import '../../../../../events/dynamicEvents.js';
import '../../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgGear } from '../../../../../shared/assets/gear.js';
import '@dynamic-labs/wallet-book';
import { shortenWalletAddress } from '../../../../../shared/utils/functions/shortenWalletAddress/shortenWalletAddress.js';
import '../../../../../utils/constants/colors.js';
import '../../../../../utils/constants/values.js';
import '../../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../../shared/consts/index.js';
import '../../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../../config/ApiEndpoint.js';
import '../../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../../context/MockContext/MockContext.js';
import '../../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../../components/Typography/Typography.js';
import '../../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../../../context/LoadingContext/LoadingContext.js';
import { IconWithStatus } from '../../../../../components/IconWithStatus/IconWithStatus.js';
import 'i18next';
import 'qrcode';
import '../../../context/DynamicWidgetContext.js';
import { DotsMenu } from '../../DotsMenu/DotsMenu.js';
import '../../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../../components/IconButton/IconButton.js';
import '../../../../../components/Alert/Alert.js';
import '../../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import { usePasskeyContext } from '../../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../../../components/InlineWidget/InlineWidget.js';
import '../../../../../components/IsBrowser/IsBrowser.js';
import '../../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../../components/Popper/Popper/Popper.js';
import '../../../../../components/Popper/PopperContext/PopperContext.js';
import '../../../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const ActiveWalletAddreess = ({ address, nameServiceName, menuOption, }) => {
    const { showAlert } = usePasskeyContext();
    return (jsxs("div", { className: 'active-wallet-information__address-container', children: [jsxs("div", { children: [jsx(Typography, { className: 'active-wallet-information__handle', color: 'primary', variant: 'body_normal', children: nameServiceName }), jsx(Typography, { className: 'active-wallet-information__address', weight: nameServiceName ? 'regular' : 'medium', variant: nameServiceName ? 'body_small' : 'body_normal', color: nameServiceName ? 'secondary' : 'primary', children: shortenWalletAddress(address, 4, 4) })] }), jsx(DotsMenu, { "data-testid": 'dots-menu', options: menuOption, buttonClassName: 'active-wallet-information__dots-menu', buttonClassNameWithOpenMenu: 'active-wallet-information__dots-menu', iconOverride: showAlert() ? (jsx(IconWithStatus, { Icon: () => jsx(SvgGear, { width: 16, height: 16 }), variant: 'yellow' })) : (jsx(SvgGear, { width: 16, height: 16 })) })] }));
};

export { ActiveWalletAddreess };
