import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useWalletBookContext, getWalletGroup, WalletIcon } from '@dynamic-labs/wallet-book';
import { isHardwareWalletConnector, getChainInfo } from '@dynamic-labs/wallet-connector-core';
import { useWalletGroupContext } from '../../context/WalletGroupContext/WalletGroupContext.js';
import 'react';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../../context/ViewContext/ViewContext.js';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import { useWalletItemActions } from '../../utils/hooks/useWalletItemActions/useWalletItemActions.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import { Divider } from '../../components/Divider/Divider.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import { HardwareWalletToggle } from '../../components/HardwareWalletToggle/HardwareWalletToggle.js';
import { WalletGroupViewLayout } from '../../components/WalletGroupViewLayout/WalletGroupViewLayout.js';
import { WalletGroupItem } from './components/WalletGroupItem/WalletGroupItem.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const WalletGroupView = () => {
    var _a, _b;
    const { selectedWalletGroup } = useWalletGroupContext();
    const { walletBook } = useWalletBookContext();
    const { handleWalletItemClick } = useWalletItemActions();
    const { t } = useTranslation();
    if (!selectedWalletGroup)
        throw new Error('Could not access WalletGroup view without selected group');
    const walletBookData = getWalletGroup(walletBook, selectedWalletGroup === null || selectedWalletGroup === void 0 ? void 0 : selectedWalletGroup.key);
    const hardwareWalletConnectors = selectedWalletGroup.groupedWallets
        .map(({ walletConnector }) => walletConnector)
        .filter(isHardwareWalletConnector)
        .filter((connector) => connector.canConnectWithHardwareWallet());
    const hardwareConnectedChain = (_a = hardwareWalletConnectors[0]) === null || _a === void 0 ? void 0 : _a.connectedChain;
    const chainName = hardwareConnectedChain && ((_b = getChainInfo(hardwareConnectedChain)) === null || _b === void 0 ? void 0 : _b.displayName);
    return (jsx(WalletGroupViewLayout, { icon: jsx(WalletIcon, { walletKey: walletBookData.key }), title: t('dyn_select_chain.description'), titleCopyKey: 'dyn_select_chain.description', children: jsxs("div", { className: 'wallet-group__list', children: [Boolean(hardwareWalletConnectors.length) && (jsxs(Fragment, { children: [jsx(HardwareWalletToggle, { connectors: hardwareWalletConnectors, copykey: 'dyn_select_chain.using_hardware_wallet_toggle_label', label: t('dyn_select_chain.using_hardware_wallet_toggle_label', {
                                chainName,
                            }) }), jsx(Divider, {})] })), jsx("div", { className: 'wallet-group-view-layout__list', children: selectedWalletGroup.groupedWallets.map((wallet, index) => (jsx(WalletGroupItem, { wallet: wallet, onClick: () => handleWalletItemClick(wallet) }, `${wallet.key}_${index}`))) })] }) }));
};

export { WalletGroupView };
