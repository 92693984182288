import { useMemo } from 'react';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import { useTransactionEstimatedGas } from '../../../../utils/hooks/useTransactionEstimatedGas/useTransactionEstimatedGas.js';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import { useEstimateL1Fee } from '../../../../utils/hooks/useEstimateL1Fee/useEstimateL1Fee.js';
import '../../helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

/**
 * Estimates the total gas price to be paid for the transaction.
 */
const useTransactionGas = ({ provider, transaction, }) => {
    const { data: l1GasPrice } = useEstimateL1Fee(provider, transaction);
    const { data: estimatedGas, isLoading: isEstimatedGasLoading } = useTransactionEstimatedGas(provider, transaction);
    const gasPrice = useMemo(() => {
        const { maxFeePerGas, gasPrice } = transaction;
        if (gasPrice)
            return gasPrice;
        if (!maxFeePerGas) {
            return undefined;
        }
        return maxFeePerGas;
    }, [transaction]);
    /**
     * Generates the total gas price to be paid for the transaction.
     * Gas price is calculated by multiplying the gas price by the estimated gas.
     * If the chain is L1, the l1 extra gas price is added to the total gas price.
     */
    const gasTotalPrice = useMemo(() => {
        const l2GasEstimate = gasPrice && estimatedGas ? gasPrice * estimatedGas : undefined;
        if (l1GasPrice && l2GasEstimate) {
            return l2GasEstimate + l1GasPrice;
        }
        return l2GasEstimate;
    }, [gasPrice, estimatedGas, l1GasPrice]);
    const isLoading = isEstimatedGasLoading;
    const isGasEstimated = Boolean(estimatedGas) && !transaction.gasPrice;
    return {
        gasTotalPrice,
        isGasEstimated,
        isLoading,
    };
};

export { useTransactionGas };
