/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var WalletProviderEnum;
(function (WalletProviderEnum) {
    WalletProviderEnum["BrowserExtension"] = "browserExtension";
    WalletProviderEnum["CustodialService"] = "custodialService";
    WalletProviderEnum["WalletConnect"] = "walletConnect";
    WalletProviderEnum["QrCode"] = "qrCode";
    WalletProviderEnum["DeepLink"] = "deepLink";
    WalletProviderEnum["EmbeddedWallet"] = "embeddedWallet";
    WalletProviderEnum["SmartContractWallet"] = "smartContractWallet";
})(WalletProviderEnum || (WalletProviderEnum = {}));
function WalletProviderEnumFromJSON(json) {
    return WalletProviderEnumFromJSONTyped(json);
}
function WalletProviderEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function WalletProviderEnumToJSON(value) {
    return value;
}

export { WalletProviderEnum, WalletProviderEnumFromJSON, WalletProviderEnumFromJSONTyped, WalletProviderEnumToJSON };
