/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var InviteStatusEnum;
(function (InviteStatusEnum) {
    InviteStatusEnum["Pending"] = "pending";
    InviteStatusEnum["Rejected"] = "rejected";
    InviteStatusEnum["Accepted"] = "accepted";
})(InviteStatusEnum || (InviteStatusEnum = {}));
function InviteStatusEnumFromJSON(json) {
    return InviteStatusEnumFromJSONTyped(json);
}
function InviteStatusEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function InviteStatusEnumToJSON(value) {
    return value;
}

export { InviteStatusEnum, InviteStatusEnumFromJSON, InviteStatusEnumFromJSONTyped, InviteStatusEnumToJSON };
