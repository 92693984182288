import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { getTLD } from '@dynamic-labs/utils';
import '../../events/dynamicEvents.js';
import '../DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import { EMBEDDED_WALLET_SECURE_BANNER } from '../../utils/constants/localStorage.js';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import { useLocalStorage } from '../../shared/utils/hooks/useLocalStorage/useLocalStorage.js';
import '../../shared/consts/index.js';
import { useInternalDynamicContext } from '../DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../CaptchaContext/CaptchaContext.js';
import '../ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../utils/functions/getAuthToken/getAuthToken.js';
import 'viem/chains';
import '../AccessDeniedContext/AccessDeniedContext.js';
import '../AccountExistsContext/AccountExistsContext.js';
import { getUserPasskeys } from '../../data/api.js';
import '../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useEmbeddedWallet } from '../../utils/hooks/useEmbeddedWallet/useEmbeddedWallet.js';
import '../ThemeContext/ThemeContext.js';
import { useIsTurnkeyWallet } from '../../utils/hooks/useIsTurnkeyWallet/useIsTurnkeyWallet.js';
import { usePromise } from '../../utils/hooks/usePromise/usePromise.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../LoadingContext/LoadingContext.js';
import '../SocialRedirectContext/SocialRedirectContext.js';
import '../WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const PasskeyContext = createContext(undefined);
const PasskeyContextProviderWithBrowser = ({ children, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { userHasEmbeddedWallet } = useEmbeddedWallet();
    const { isTurnkeyWalletWithoutAuthenticator } = useIsTurnkeyWallet();
    const { environmentId, projectSettings } = useInternalDynamicContext();
    // state for when passkey list needs to re-render
    const [passkeyEdited, setPasskeyEdited] = useState(false);
    // states to pass into rename passkey popup
    const [passkey, setPasskey] = useState(undefined);
    const [passkeyIcon, setPasskeyIcon] = useState(undefined);
    const [_showSecureAccountBanner, setShowSecureAccountBanner] = useLocalStorage(EMBEDDED_WALLET_SECURE_BANNER, true);
    const showSecureAccountBanner = useMemo(() => {
        var _a, _b, _c, _d;
        const isPasskeyEnabled = Boolean((_d = (_c = (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.embeddedWallets) === null || _b === void 0 ? void 0 : _b.supportedSecurityMethods) === null || _c === void 0 ? void 0 : _c.passkey) === null || _d === void 0 ? void 0 : _d.isEnabled);
        return (_showSecureAccountBanner !== false &&
            isTurnkeyWalletWithoutAuthenticator &&
            isPasskeyEnabled);
    }, [
        isTurnkeyWalletWithoutAuthenticator,
        _showSecureAccountBanner,
        (_d = (_c = (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.embeddedWallets) === null || _b === void 0 ? void 0 : _b.supportedSecurityMethods) === null || _c === void 0 ? void 0 : _c.passkey) === null || _d === void 0 ? void 0 : _d.isEnabled,
    ]);
    const showAlert = useCallback(() => {
        var _a, _b, _c, _d;
        const isPasskeyEnabled = Boolean((_d = (_c = (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.embeddedWallets) === null || _b === void 0 ? void 0 : _b.supportedSecurityMethods) === null || _c === void 0 ? void 0 : _c.passkey) === null || _d === void 0 ? void 0 : _d.isEnabled);
        return (!showSecureAccountBanner &&
            isTurnkeyWalletWithoutAuthenticator &&
            isPasskeyEnabled);
    }, [
        showSecureAccountBanner,
        isTurnkeyWalletWithoutAuthenticator,
        (_h = (_g = (_f = (_e = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _e === void 0 ? void 0 : _e.embeddedWallets) === null || _f === void 0 ? void 0 : _f.supportedSecurityMethods) === null || _g === void 0 ? void 0 : _g.passkey) === null || _h === void 0 ? void 0 : _h.isEnabled,
    ]);
    const userJwt = getAuthToken();
    // It fetches user passkeys if user has embedded wallet
    const getUserPasskeysResult = usePromise(() => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield getUserPasskeys({
            environmentId: environmentId,
            userJwt: userJwt,
        });
        return data.passkeys;
    }), {
        deps: [environmentId, userJwt, userHasEmbeddedWallet(), passkeyEdited],
        enabled: Boolean(environmentId && userJwt && userHasEmbeddedWallet()),
    });
    // Returns true if user has passkeys for current origin
    const userHasPasskeysForCurrentOrigin = useMemo(() => {
        if (typeof window === 'undefined') {
            return false;
        }
        const { location: { hostname }, } = window;
        if (!getUserPasskeysResult.data)
            return false;
        return getUserPasskeysResult.data.some((passkey) => {
            if (!passkey.origin)
                return false;
            try {
                const passkeyHostname = new URL(passkey.origin).hostname;
                return passkeyHostname === getTLD() || passkeyHostname === hostname;
            }
            catch (e) {
                logger.error(e);
                return false;
            }
        });
    }, [getUserPasskeysResult.data]);
    // user has passkey configured, but not for current origin
    const userNeedsCrossDomainPasskey = !isTurnkeyWalletWithoutAuthenticator && !userHasPasskeysForCurrentOrigin;
    const value = useMemo(() => {
        var _a;
        return ({
            passkey,
            passkeyEdited,
            passkeyIcon,
            setPasskey,
            setPasskeyEdited,
            setPasskeyIcon,
            setShowSecureAccountBanner,
            showAlert,
            showSecureAccountBanner,
            userHasPasskeysForCurrentOrigin,
            userNeedsCrossDomainPasskey,
            userPasskeys: (_a = getUserPasskeysResult.data) !== null && _a !== void 0 ? _a : [],
            userPasskeysLoading: getUserPasskeysResult.isLoading,
        });
    }, [
        passkey,
        passkeyEdited,
        passkeyIcon,
        userHasPasskeysForCurrentOrigin,
        getUserPasskeysResult.data,
        getUserPasskeysResult.isLoading,
        isTurnkeyWalletWithoutAuthenticator,
        userNeedsCrossDomainPasskey,
        showAlert,
        setShowSecureAccountBanner,
        showSecureAccountBanner,
    ]);
    return (jsx(PasskeyContext.Provider, { value: value, children: children }));
};
// We need to export the context this way so it works with SSR (next.js)
const PasskeyContextProvider = ({ children, }) => (jsx(PasskeyContextProviderWithBrowser, { children: children }));
const usePasskeyContext = () => {
    const context = useContext(PasskeyContext);
    if (context === undefined) {
        throw new Error('usage of PasskeyContext not wrapped in `PasskeyContextProvider`.');
    }
    return context;
};

export { PasskeyContext, PasskeyContextProvider, usePasskeyContext };
