import { exists } from '../runtime.js';
import { HardwareWalletEnumFromJSON, HardwareWalletEnumToJSON } from './HardwareWalletEnum.js';

/* tslint:disable */
function WalletPropertiesFromJSON(json) {
    return WalletPropertiesFromJSONTyped(json);
}
function WalletPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'turnkeySubOrganizationId': !exists(json, 'turnkeySubOrganizationId') ? undefined : json['turnkeySubOrganizationId'],
        'turnkeyPrivateKeyId': !exists(json, 'turnkeyPrivateKeyId') ? undefined : json['turnkeyPrivateKeyId'],
        'turnkeyHDWalletId': !exists(json, 'turnkeyHDWalletId') ? undefined : json['turnkeyHDWalletId'],
        'isAuthenticatorAttached': !exists(json, 'isAuthenticatorAttached') ? undefined : json['isAuthenticatorAttached'],
        'hardwareWallet': !exists(json, 'hardwareWallet') ? undefined : HardwareWalletEnumFromJSON(json['hardwareWallet']),
        'backup': !exists(json, 'backup') ? undefined : json['backup'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'claimed': !exists(json, 'claimed') ? undefined : json['claimed'],
    };
}
function WalletPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'turnkeySubOrganizationId': value.turnkeySubOrganizationId,
        'turnkeyPrivateKeyId': value.turnkeyPrivateKeyId,
        'turnkeyHDWalletId': value.turnkeyHDWalletId,
        'isAuthenticatorAttached': value.isAuthenticatorAttached,
        'hardwareWallet': HardwareWalletEnumToJSON(value.hardwareWallet),
        'backup': value.backup,
        'password': value.password,
        'claimed': value.claimed,
    };
}

export { WalletPropertiesFromJSON, WalletPropertiesFromJSONTyped, WalletPropertiesToJSON };
