import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicError } from '@dynamic-labs/utils';
import { getProperErrorMessage } from '../../../modals/SignMessageConfirmationModal/getProperErrorMessage.js';
import { dynamicEvents } from '../../../events/dynamicEvents.js';
import '../../../context/DynamicContext/DynamicContext.js';
import { decodeJwt } from '../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgClose } from '../../../shared/assets/close.js';
import { ReactComponent as SvgEyeOffIcon } from '../../../shared/assets/eye-off-icon.js';
import { ReactComponent as SvgExportEmbeddedHero } from '../../../shared/assets/export-embedded-hero.js';
import { ReactComponent as SvgWalletV2 } from '../../../shared/assets/wallet-v2.js';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/sdk-api';
import '../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import { createOwnerWallet } from '../../../utils/functions/createOwnerWallet/createOwnerWallet.js';
import '@dynamic-labs/multi-wallet';
import { isAccountAbstractionWallet } from '../../../utils/functions/isAccountAbstractionWallet/isAccountAbstractionWallet.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import { useMutation } from '../../../utils/hooks/useMutation/useMutation.js';
import { usePromise } from '../../../utils/hooks/usePromise/usePromise.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../components/Typography/Typography.js';
import { Badge } from '../../../components/Badge/Badge.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import { ErrorContainer } from '../../../components/ErrorContainer/ErrorContainer.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import { Button } from '../../../components/Button/Button.js';
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { Checkbox } from '../../../components/Checkbox/Checkbox.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import '../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { cleanupExport, exportCredential, initExport } from '../utils/turnkeyExport/turnkeyExport.js';
import { iframeContainerId, iframeElementId } from '../constants.js';

const EmbeddedRevealView = ({ exportPrivateKey, }) => {
    var _a, _b;
    const { authToken, primaryWallet, user, setShowAuthFlow, environmentId } = useInternalDynamicContext();
    const [acknowledgement1, setAcknowledgement1] = useState(false);
    const iframeContainerRef = useRef(null);
    const decodedJwt = decodeJwt(authToken);
    const walletProperties = (_b = (_a = decodedJwt === null || decodedJwt === void 0 ? void 0 : decodedJwt.verifiedCredentials) === null || _a === void 0 ? void 0 : _a.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _b === void 0 ? void 0 : _b.walletProperties;
    const isTurnkeyHDWallet = walletProperties === null || walletProperties === void 0 ? void 0 : walletProperties.turnkeyHDWalletId;
    const wallet = isAccountAbstractionWallet(primaryWallet)
        ? createOwnerWallet(primaryWallet, (user === null || user === void 0 ? void 0 : user.verifiedCredentials) || [])
        : primaryWallet;
    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            cleanupExport({
                wallet,
            });
        };
        // only run once on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { isLoading: exportLoading } = usePromise(() => {
        var _a;
        const iframeContainerElement = iframeContainerRef.current;
        if (!iframeContainerElement ||
            ((_a = iframeContainerElement === null || iframeContainerElement === void 0 ? void 0 : iframeContainerElement.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return;
        }
        return initExport({
            iframeContainer: iframeContainerElement,
            iframeElementId,
            wallet,
        });
    });
    const { mutate: handleExportWallet, isLoading, error, data, } = useMutation(() => __awaiter(void 0, void 0, void 0, function* () {
        const wallet = isAccountAbstractionWallet(primaryWallet)
            ? createOwnerWallet(primaryWallet, (user === null || user === void 0 ? void 0 : user.verifiedCredentials) || [])
            : primaryWallet;
        return exportCredential({
            address: exportPrivateKey ? wallet === null || wallet === void 0 ? void 0 : wallet.address : undefined,
            authToken,
            environmentId,
            userEmail: user === null || user === void 0 ? void 0 : user.email,
            wallet,
        });
    }), {
        onFailure: (err) => {
            logger.error('Failed to export', err);
            dynamicEvents.emit('embeddedWalletRevealFailed', err);
        },
    });
    const errorText = useMemo(() => {
        if (!error) {
            return undefined;
        }
        if (error instanceof DynamicError) {
            return error.message;
        }
        return getProperErrorMessage(error);
    }, [error]);
    const { t } = useTranslation();
    const contentHeader = (jsx("div", { className: 'embedded-reveal-view__body__description', children: !isLoading && data && !error ? (jsx(Typography, { variant: 'body_normal', color: 'secondary', weight: 'regular', children: t('dyn_embedded_reveal.reveal_description') })) : (jsx("div", { className: 'embedded-reveal-view__header', children: jsx(SvgExportEmbeddedHero, {}) })) }));
    useEffect(() => {
        var _a;
        if (((_a = iframeContainerRef === null || iframeContainerRef === void 0 ? void 0 : iframeContainerRef.current) === null || _a === void 0 ? void 0 : _a.children) && data) {
            // eslint-disable-next-line prefer-destructuring
            const iframeElement = iframeContainerRef.current.children[0];
            iframeElement === null || iframeElement === void 0 ? void 0 : iframeElement.setAttribute('style', !isTurnkeyHDWallet || exportPrivateKey
                ? 'height: 60px'
                : 'height: 100px');
        }
    }, [iframeContainerRef, data, isTurnkeyHDWallet, exportPrivateKey]);
    const closeButton = (jsx(IconButton, { onClick: () => setShowAuthFlow(false, { emitCancelAuth: true }), type: 'button', children: jsx(SvgClose, {}) }));
    const hasInjectedCredential = !isLoading && data && !error;
    const credentialTitle = !isTurnkeyHDWallet || exportPrivateKey
        ? t('dyn_embedded_reveal.private_key_title')
        : t('dyn_embedded_reveal.recovery_phrase_title');
    const title = hasInjectedCredential
        ? credentialTitle
        : t('dyn_embedded_reveal.agreement_title');
    return (jsxs(Fragment, { children: [jsx(ModalHeader, { trailing: closeButton, children: jsx(Typography, { as: 'h1', variant: 'title', color: 'primary', "data-testid": 'dynamic-auth-modal-heading', className: 'header__typography', children: title }) }), jsx("div", { className: 'embedded-reveal-view', children: jsxs("div", { className: 'embedded-reveal-view__body', children: [contentHeader, jsx("div", { id: iframeContainerId, style: { display: data ? 'block' : 'none' }, ref: iframeContainerRef }), hasInjectedCredential ? (jsx(Button, { buttonPadding: 'large', buttonVariant: 'brand-primary', onClick: () => setShowAuthFlow(false), loading: isLoading, copykey: 'dyn_embedded_reveal.done_button_label', typographyProps: {
                                color: 'inherit',
                            }, children: t('dyn_embedded_reveal.done_button_label') })) : (jsxs(Fragment, { children: [jsx("div", { className: 'embedded-reveal-view__body__description', children: jsx(Badge, { text: t('dyn_embedded_reveal.badge_label'), className: 'embedded-reveal-view__body__badge' }) }), errorText && jsx(ErrorContainer, { children: errorText }), jsxs("div", { className: 'embedded-reveal-view__body__card', children: [jsxs("div", { className: 'embedded-reveal-view__body__card__statement', children: [jsx("div", { className: 'embedded-reveal-view__body__card__icon', children: jsx(SvgWalletV2, {}) }), jsxs("div", { children: [jsx(Typography, { variant: 'body_normal', color: 'primary', style: { letterSpacing: '-0.15px' }, copykey: 'dyn_embedded_reveal.statement_1.title', children: t('dyn_embedded_reveal.statement_1.title') }), jsx(Typography, { variant: 'body_small', color: 'secondary', weight: 'regular', copykey: 'dyn_embedded_reveal.statement_1.description', children: t('dyn_embedded_reveal.statement_1.description') })] })] }), jsxs("div", { className: 'embedded-reveal-view__body__card__statement', children: [jsx("div", { className: 'embedded-reveal-view__body__card__icon', children: jsx(SvgEyeOffIcon, {}) }), jsxs("div", { children: [jsx(Typography, { variant: 'body_normal', color: 'primary', style: { letterSpacing: '-0.15px' }, copykey: 'dyn_embedded_reveal.statement_2.title', children: t('dyn_embedded_reveal.statement_2.title') }), jsx(Typography, { variant: 'body_small', color: 'secondary', weight: 'regular', copykey: 'dyn_embedded_reveal.statement_2.description', children: t('dyn_embedded_reveal.statement_2.description', {
                                                                credential_type: credentialTitle === null || credentialTitle === void 0 ? void 0 : credentialTitle.toLowerCase(),
                                                            }) })] })] })] }), jsxs("div", { className: 'embedded-reveal-view__body__confirm_card', children: [jsxs("button", { className: 'embedded-reveal-view__body__card__acknowledgement', onClick: () => setAcknowledgement1(!acknowledgement1), children: [jsx("div", { children: jsx(Checkbox, { checked: acknowledgement1, onChange: () => setAcknowledgement1(!acknowledgement1), className: 'embedded-reveal-view__body__card__statement__checkbox', id: 'embedded-reveal-checkbox-1' }) }), jsx("div", { children: jsx(Typography, { variant: 'body_normal', color: 'primary', style: { letterSpacing: '-0.15px' }, copykey: 'dyn_embedded_reveal.checkbox_label', children: t('dyn_embedded_reveal.checkbox_label') }) })] }), jsx(Button, { buttonPadding: 'large', buttonVariant: 'brand-primary', typographyProps: {
                                                color: 'inherit',
                                            }, onClick: () => handleExportWallet(), disabled: !acknowledgement1 || exportLoading, loading: isLoading, dataTestId: 'embedded-reveal-button', copykey: 'dyn_embedded_reveal.reveal_button_label', children: t('dyn_embedded_reveal.reveal_button_label') })] })] }))] }) })] }));
};

export { EmbeddedRevealView };
