import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { getChainInfo } from '@dynamic-labs/wallet-connector-core';
import 'react';
import '../../../../events/dynamicEvents.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import { usePromise } from '../../../../utils/hooks/usePromise/usePromise.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const useFetchCurrency = (connector, network) => {
    const fetchChainCurrency = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const chainId = yield connector.getNetwork();
        const chainInfo = getChainInfo((_a = connector.connectedChain) !== null && _a !== void 0 ? _a : '');
        /* istanbul ignore else */
        if (!chainId) {
            return chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.symbol;
        }
        const network = connector.connectedChain === 'STARK' ? 'starknetNetworks' : 'evmNetworks';
        const result = (_b = connector[network]) === null || _b === void 0 ? void 0 : _b.find((element) => element.chainId === chainId);
        return (_d = (_c = result === null || result === void 0 ? void 0 : result.nativeCurrency) === null || _c === void 0 ? void 0 : _c.symbol) !== null && _d !== void 0 ? _d : chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.symbol;
    });
    const { data: currency } = usePromise(fetchChainCurrency, {
        deps: [network],
    });
    return [currency];
};

export { useFetchCurrency };
