import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { isPasskeyWalletConnector } from '@dynamic-labs/wallet-connector-core';
import { DynamicError } from '@dynamic-labs/utils';
import { decodeJwt } from '../../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import '../../../../context/ViewContext/ViewContext.js';
import 'react';
import '../../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/sdk-api';
import '../../../../shared/consts/index.js';
import { exportEmbeddedWallet } from '../../../../data/api.js';

const initExport = ({ iframeContainer, iframeElementId, wallet, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const turnkeyExportHandler = (_a = wallet.connector) === null || _a === void 0 ? void 0 : _a.getExportHandler();
    const publicKey = yield turnkeyExportHandler.initExport(iframeContainer, iframeElementId);
    if (!publicKey) {
        throw new DynamicError('Something went wrong');
    }
    return turnkeyExportHandler;
});
const handleExportInitCheck = ({ authToken, userEmail, wallet, }) => {
    var _a, _b;
    if (!authToken) {
        throw new DynamicError('User must be logged in');
    }
    const decodedJwt = decodeJwt(authToken);
    if (!decodedJwt) {
        throw new DynamicError('User must be logged in with a valid token');
    }
    if (!(wallet === null || wallet === void 0 ? void 0 : wallet.connector) ||
        !(wallet === null || wallet === void 0 ? void 0 : wallet.id) ||
        !isPasskeyWalletConnector(wallet === null || wallet === void 0 ? void 0 : wallet.connector)) {
        throw new DynamicError('PasskeyWalletConnector not found');
    }
    const connector = wallet.connector;
    connector.setEmail(userEmail);
    const walletProperties = (_b = (_a = decodedJwt.verifiedCredentials) === null || _a === void 0 ? void 0 : _a.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _b === void 0 ? void 0 : _b.walletProperties;
    const turnkeyHDWalletId = walletProperties === null || walletProperties === void 0 ? void 0 : walletProperties.turnkeyHDWalletId;
    const privateKeyId = walletProperties === null || walletProperties === void 0 ? void 0 : walletProperties.turnkeyPrivateKeyId;
    const organizationId = walletProperties === null || walletProperties === void 0 ? void 0 : walletProperties.turnkeySubOrganizationId;
    if (!organizationId ||
        (turnkeyHDWalletId === undefined && privateKeyId === undefined)) {
        throw new DynamicError('Invalid token!');
    }
    return {
        connector,
        organizationId,
        privateKeyId,
        turnkeyHDWalletId,
    };
};
const extractExportBundle = ({ address, privateKeyId, activity, }) => {
    var _a, _b, _c;
    const exportWalletResult = address
        ? (_a = activity.result) === null || _a === void 0 ? void 0 : _a.exportWalletAccountResult
        : (_b = activity.result) === null || _b === void 0 ? void 0 : _b.exportWalletResult;
    const result = privateKeyId
        ? (_c = activity.result) === null || _c === void 0 ? void 0 : _c.exportPrivateKeyResult
        : exportWalletResult;
    return result === null || result === void 0 ? void 0 : result.exportBundle;
};
const exportCredential = ({ authToken, userEmail, wallet, environmentId, address, }) => __awaiter(void 0, void 0, void 0, function* () {
    const { connector, turnkeyHDWalletId, privateKeyId, organizationId } = handleExportInitCheck({
        authToken,
        userEmail,
        wallet,
    });
    const turnkeyExportHandler = connector.getExportHandler();
    const targetPublicKey = turnkeyExportHandler.publicKey;
    if (!targetPublicKey) {
        throw new DynamicError('Must initialize export first');
    }
    // calls turnkey export api
    let newActivity;
    if (turnkeyHDWalletId) {
        newActivity = yield turnkeyExportHandler.exportWallet({
            address,
            organizationId,
            walletId: turnkeyHDWalletId,
        });
    }
    else if (privateKeyId) {
        newActivity = yield turnkeyExportHandler.exportPrivateKey({
            organizationId,
            privateKeyId,
        });
    }
    const activityId = newActivity.id;
    const activityStatus = newActivity.status;
    let exportBundle;
    if (activityStatus === 'ACTIVITY_STATUS_COMPLETED') {
        exportBundle = extractExportBundle({
            activity: newActivity,
            address,
            privateKeyId,
        });
    }
    else {
        const res = yield exportEmbeddedWallet({
            activityId,
            environmentId,
            userJwt: authToken || '',
            walletId: (wallet === null || wallet === void 0 ? void 0 : wallet.id) || '',
        });
        exportBundle = res === null || res === void 0 ? void 0 : res.exportBundle;
    }
    if (!exportBundle) {
        throw new DynamicError('Invalid export response');
    }
    if (address || privateKeyId) {
        return turnkeyExportHandler.verifyExportPrivateKey(exportBundle);
    }
    return turnkeyExportHandler.verifyExportWallet(exportBundle);
});
const cleanupExport = ({ wallet }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const connector = wallet === null || wallet === void 0 ? void 0 : wallet.connector;
    // clear iframeStamper and its reference
    (_b = connector === null || connector === void 0 ? void 0 : connector.getExportHandler()) === null || _b === void 0 ? void 0 : _b.clear();
});

export { cleanupExport, exportCredential, extractExportBundle, initExport };
