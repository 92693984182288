/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var ChainalysisCheckResultEnum;
(function (ChainalysisCheckResultEnum) {
    ChainalysisCheckResultEnum["Ok"] = "OK";
    ChainalysisCheckResultEnum["Blocked"] = "BLOCKED";
    ChainalysisCheckResultEnum["Failed"] = "FAILED";
})(ChainalysisCheckResultEnum || (ChainalysisCheckResultEnum = {}));
function ChainalysisCheckResultEnumFromJSON(json) {
    return ChainalysisCheckResultEnumFromJSONTyped(json);
}
function ChainalysisCheckResultEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ChainalysisCheckResultEnumToJSON(value) {
    return value;
}

export { ChainalysisCheckResultEnum, ChainalysisCheckResultEnumFromJSON, ChainalysisCheckResultEnumFromJSONTyped, ChainalysisCheckResultEnumToJSON };
