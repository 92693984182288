/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var RoleEnum;
(function (RoleEnum) {
    RoleEnum["Owner"] = "owner";
    RoleEnum["Admin"] = "admin";
    RoleEnum["Viewer"] = "viewer";
})(RoleEnum || (RoleEnum = {}));
function RoleEnumFromJSON(json) {
    return RoleEnumFromJSONTyped(json);
}
function RoleEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function RoleEnumToJSON(value) {
    return value;
}

export { RoleEnum, RoleEnumFromJSON, RoleEnumFromJSONTyped, RoleEnumToJSON };
