import { useState } from 'react';
import { usePromise } from '../usePromise/usePromise.js';
import '../../../events/dynamicEvents.js';
import { useWalletConnectorEvent } from '../events/useWalletConnectorEvent/useWalletConnectorEvent.js';

/**
 * Sync wallet connector network.
 *
 * @param walletConnector - The WalletConnector instance to be used.
 *
 * @returns {{ network: string | number | undefined }} An object containing the current network state.
 *
 * @example
 * // Example of using the useWalletConnectorNetwork hook
 * const { network } = useWalletConnectorNetwork(walletConnector);
 *
 * // You can then use the 'network' state in your component
 * return <div>Current Network: {network}</div>;
 */
const useWalletConnectorNetwork = (walletConnector) => {
    const [network, setNetwork] = useState();
    usePromise(() => walletConnector === null || walletConnector === void 0 ? void 0 : walletConnector.getNetwork(), {
        onResolve: (network) => setNetwork(network),
    });
    useWalletConnectorEvent(walletConnector || undefined, 'chainChange', ({ chain }) => setNetwork(chain));
    /**
     * Listen to disconnect event and update the network because ArgentX
     * emits a disconnect event when the user switches network.
     */
    useWalletConnectorEvent(walletConnector || undefined, 'disconnect', () => {
        if ((walletConnector === null || walletConnector === void 0 ? void 0 : walletConnector.key) === 'argentx') {
            walletConnector.getNetwork().then(setNetwork);
        }
    });
    return { network };
};

export { useWalletConnectorNetwork };
