import { ChainEnum } from '@dynamic-labs/sdk-api';

const DEFAULT_NUMBER_OF_WALLETS_TO_SHOW = 10;
// references:
// https://www.iana.org/assignments/jwt/jwt.xhtml#claims
// https://github.com/jaredhanson/id-blockchain-claims-in-jwt/blob/main/draft.md
const ChainEnumToVerifiedCredentialName = {
    ALGO: 'algorand',
    BTC: 'bip122',
    COSMOS: 'cosmos',
    ETH: 'eip155',
    EVM: 'eip155',
    FLOW: 'flow',
    SOL: 'solana',
    STARK: 'starknet',
};
const VerifiedCredentialNameToChainEnum = {
    algorand: ChainEnum.Algo,
    bip122: ChainEnum.Btc,
    cosmos: ChainEnum.Cosmos,
    eip155: ChainEnum.Evm,
    flow: ChainEnum.Flow,
    solana: ChainEnum.Sol,
    starknet: ChainEnum.Stark,
};

export { ChainEnumToVerifiedCredentialName, DEFAULT_NUMBER_OF_WALLETS_TO_SHOW, VerifiedCredentialNameToChainEnum };
