import { jsx } from 'react/jsx-runtime';
import { ProviderEnum, SignInProviderEnum } from '@dynamic-labs/sdk-api';
import 'react';
import '../../../../context/DynamicContext/DynamicContext.js';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getSocialSignInProviderFromString } from '../../../../utils/functions/getSocialSignInProviderFromString/getSocialSignInProviderFromString.js';
import { isProviderEnabled } from '../../../../utils/functions/isProviderEnabled/isProviderEnabled.js';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import { MagicSocialSignIn } from './MagicSocialSignIn/MagicSocialSignIn.js';
import { DynamicSocialSignIn } from './DynamicSocialSignIn/DynamicSocialSignIn.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const SocialSignInSection = ({ sectionData, collapsedLayout, }) => {
    var _a, _b, _c;
    const { projectSettings } = useInternalDynamicContext();
    const providers = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers) !== null && _a !== void 0 ? _a : [];
    const componentProps = {
        collapsedLayout,
        defaultProvider: getSocialSignInProviderFromString(sectionData.defaultItem),
        numOfItemsToDisplay: sectionData.numOfItemsToDisplay,
    };
    const isMagicEnabled = isProviderEnabled(providers, ProviderEnum.MagicLink);
    if (isMagicEnabled &&
        ((_b = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.socialSignIn) === null || _b === void 0 ? void 0 : _b.signInProvider) ===
            SignInProviderEnum.MagicLink) {
        return jsx(MagicSocialSignIn, Object.assign({}, componentProps));
    }
    if (((_c = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.socialSignIn) === null || _c === void 0 ? void 0 : _c.signInProvider) ===
        SignInProviderEnum.Dynamic) {
        return jsx(DynamicSocialSignIn, Object.assign({}, componentProps));
    }
    logger.error('Failed to render SocialSignInSection - no sign in provider enabled');
    return null;
};

export { SocialSignInSection };
