import { __awaiter } from '../../../../_virtual/_tslib.js';
import { useCallback } from 'react';
import { isPhantomRedirectConnector } from '@dynamic-labs/wallet-connector-core';
import '../../events/dynamicEvents.js';
import '@dynamic-labs/utils';
import '../DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import { useViewContext } from '../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import { useInternalDynamicContext } from '../DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import { useCaptchaContext } from '../CaptchaContext/CaptchaContext.js';
import '../ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import { useVerifyWallet } from '../../utils/hooks/useVerifyWallet/useVerifyWallet.js';
import '../../config/ApiEndpoint.js';
import '../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import '../AccountExistsContext/AccountExistsContext.js';
import 'yup';
import 'react-i18next';
import '../MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import '../AccessDeniedContext/AccessDeniedContext.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../LoadingContext/LoadingContext.js';
import '../SocialRedirectContext/SocialRedirectContext.js';
import '../WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const useResponseHandlers = () => {
    const { walletConnectorOptions, consumeNonce, displaySiweStatement, environmentId, projectSettings, setIsSingleWalletAccount, connectWallet, setShowAuthFlow, setSelectedWalletConnectorKey, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const { getCaptchaToken } = useCaptchaContext();
    const verifyWallet = useVerifyWallet({
        consumeNonce,
        displaySiweStatement,
        environmentId,
        projectSettings,
        setIsSingleWalletAccount,
    });
    const phantomRedirectConnector = walletConnectorOptions
        .map(({ walletConnector }) => walletConnector)
        .find(isPhantomRedirectConnector);
    const handleConnectResponse = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!phantomRedirectConnector) {
            return;
        }
        // this completes the connection step
        yield phantomRedirectConnector.getSession();
        yield connectWallet(phantomRedirectConnector);
        setShowAuthFlow(true, { ignoreIfIsEmbeddedWidget: true });
        setView('pending-signature');
        setSelectedWalletConnectorKey('phantom');
    }), [
        connectWallet,
        phantomRedirectConnector,
        setSelectedWalletConnectorKey,
        setShowAuthFlow,
        setView,
    ]);
    const handleUserResponse = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!phantomRedirectConnector) {
            return;
        }
        setSelectedWalletConnectorKey('phantom');
        const { signature, message } = phantomRedirectConnector.extractSignature();
        const [address] = yield phantomRedirectConnector.getConnectedAccounts();
        setView('wallet-redirect-view');
        yield verifyWallet({
            captchaToken: getCaptchaToken(),
            messageToSignOverride: message,
            publicWalletAddress: address,
            signedMessageOverride: signature,
            walletConnector: phantomRedirectConnector,
        });
    }), [getCaptchaToken, phantomRedirectConnector, setView]);
    return {
        handleConnectResponse,
        handleUserResponse,
        phantomRedirectConnector,
    };
};

export { useResponseHandlers };
