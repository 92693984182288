import { __rest, __awaiter } from '../../_virtual/_tslib.js';
import { createWalletClient, custom } from 'viem';
import { findWalletBookWallet } from '@dynamic-labs/wallet-book';
import '../polyfills.js';
import '@dynamic-labs/turnkey';
import '@dynamic-labs/utils';
import { EthProviderHelper } from '../ethProviderHelper.js';
import { EthWalletConnector } from '../EthWalletConnector.js';
import '../walletConnect/walletConnectV2.js';
import '@walletconnect/ethereum-provider';
import '@dynamic-labs/wallet-connector-core';
import '@walletconnect/client';
import { getCoinbaseProvider, fetchPublicAddress, signMessage, killCoinbaseSession } from './client/client.js';

class Coinbase extends EthWalletConnector {
    constructor(_a) {
        var { appName, appLogoUrl, evmNetworks } = _a, props = __rest(_a, ["appName", "appLogoUrl", "evmNetworks"]);
        super(Object.assign({ evmNetworks }, props));
        this.name = 'Coinbase';
        this.supportedChains = ['EVM', 'ETH'];
        this.connectedChain = 'EVM';
        this.canConnectViaQrCode = true;
        this.coinbaseProviderOpts = {
            appLogoUrl: appLogoUrl,
            appName: appName,
            evmNetworks: evmNetworks,
        };
        const wallet = findWalletBookWallet(this.walletBook, this.key);
        if (!wallet)
            return;
        this.ethProviderHelper = new EthProviderHelper(wallet);
    }
    setupEventListeners() {
        var _a, _b, _c;
        const provider = (_a = this.ethProviderHelper) === null || _a === void 0 ? void 0 : _a.findProvider();
        if (!provider) {
            return;
        }
        const setupResponse = (_c = (_b = this.ethProviderHelper) === null || _b === void 0 ? void 0 : _b._setupEventListeners) === null || _c === void 0 ? void 0 : _c.call(_b, this);
        if (!(setupResponse === null || setupResponse === void 0 ? void 0 : setupResponse.tearDownEventListeners))
            return;
        this.teardownEventListeners = setupResponse.tearDownEventListeners;
    }
    getWalletClient() {
        var _a;
        if (this.isInstalledOnBrowser()) {
            return (_a = this.ethProviderHelper) === null || _a === void 0 ? void 0 : _a.findWalletClient();
        }
        return createWalletClient({
            transport: custom(getCoinbaseProvider({
                opts: this.coinbaseProviderOpts,
            })),
        });
    }
    isInstalledOnBrowser() {
        var _a;
        return ((_a = this.ethProviderHelper) === null || _a === void 0 ? void 0 : _a.findProvider()) !== undefined;
    }
    fetchPublicAddress(opts) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isInstalledOnBrowser()) {
                return (_a = this.ethProviderHelper) === null || _a === void 0 ? void 0 : _a.fetchPublicAddress();
            }
            return fetchPublicAddress(this.coinbaseProviderOpts, opts);
        });
    }
    signMessage(messageToSign) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isInstalledOnBrowser()) {
                return (_a = this.ethProviderHelper) === null || _a === void 0 ? void 0 : _a.signMessage(messageToSign);
            }
            return signMessage(this.coinbaseProviderOpts, messageToSign);
        });
    }
    endSession() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isInstalledOnBrowser())
                return;
            killCoinbaseSession();
        });
    }
}

export { Coinbase };
