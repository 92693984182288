/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var AuthModeEnum;
(function (AuthModeEnum) {
    AuthModeEnum["Only"] = "connect-only";
    AuthModeEnum["AndSign"] = "connect-and-sign";
})(AuthModeEnum || (AuthModeEnum = {}));
function AuthModeEnumFromJSON(json) {
    return AuthModeEnumFromJSONTyped(json);
}
function AuthModeEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function AuthModeEnumToJSON(value) {
    return value;
}

export { AuthModeEnum, AuthModeEnumFromJSON, AuthModeEnumFromJSONTyped, AuthModeEnumToJSON };
