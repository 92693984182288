import { jsx, jsxs } from 'react/jsx-runtime';
import { WalletIcon } from '@dynamic-labs/wallet-book';
import 'react';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import { shortenWalletAddress } from '../../../../shared/utils/functions/shortenWalletAddress/shortenWalletAddress.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { PromptModal } from '../../components/PromptModal/PromptModal.js';
import { WidgetPortal } from '../../components/WidgetPortal/WidgetPortal.js';
import { DefaultPromptLayout } from '../DefaultPromptModal/DefaultPromptModal.js';
import { Divider } from '../../../../components/Divider/Divider.js';
import '../../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import { LogoutButton } from '../../../../components/LogoutButton/LogoutButton.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import '../../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const PrimaryNotConnectedContent = () => {
    var _a;
    const { primaryWallet } = useInternalDynamicContext();
    return (jsxs(DefaultPromptLayout, { dataTestId: 'wallet-not-connected', icon: jsx(WalletIcon, { walletKey: (_a = primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector) === null || _a === void 0 ? void 0 : _a.key }), title: `Switch wallet in ${primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.name}`, children: [jsxs(Typography, { variant: 'body_normal', color: 'secondary', weight: 'regular', "data-testid": 'content', children: ["Your active wallet in", ' ', jsx(Typography, { as: 'span', weight: 'medium', color: 'primary', children: primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector.name }), ' ', "is not linked. Please switch to wallet", ' ', jsx(Typography, { as: 'span', weight: 'medium', color: 'primary', children: shortenWalletAddress(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.address, 3, 3) }), ' ', "to log in."] }), jsx(Typography, { variant: 'body_normal', color: 'secondary', weight: 'regular', "data-testid": 'content', children: "You can link this wallet to this account after you log in." }), jsx(Divider, { fullWidth: true, text: 'Or' }), jsx(LogoutButton, { isTextButton: false, buttonClassName: 'primary-not-connected__log-out-button' })] }));
};
const PrimaryNotConnectedWidgetPortal = () => (jsx(WidgetPortal, { children: jsx(PrimaryNotConnectedContent, {}) }));
const PrimaryNotConnectedModal = () => {
    const { view } = useViewContext();
    // This modal would hide the view where we ask the user if they want to transfer the new wallet
    // So in that specific case, we don't show it
    if (view.type === 'wallet-used')
        return null;
    return (jsx(PromptModal, { children: jsx(PrimaryNotConnectedContent, {}) }));
};

export { PrimaryNotConnectedModal, PrimaryNotConnectedWidgetPortal };
