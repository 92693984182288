import { useState, useCallback } from 'react';
import { dynamicEvents } from '../../../../events/dynamicEvents.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import { useErrorContext } from '../../../ErrorContext/ErrorContext.js';

/**
 * There are a multitude of scenarios where we want to ignore or insert functionality to calls
 * to setShowAuthFlow. This hook wraps it with an options parameter to allow encapsulating these
 * different scenarios and makes it especially easy to customize exactly which version of this method
 * is being passed to useDynamicContext.
 */
const useShowAuthFlow = ({ isAuthenticated, isRenderingEmbeddedAuthFlow, isMultiWalletEnabled, walletConnectorOptions, }) => {
    const [showAuthFlow, _setShowAuthFlow] = useState(false);
    const { setError } = useErrorContext();
    const setShowAuthFlow = useCallback((value, options = {}) => {
        var _a;
        const { emitCancelAuth, ignoreIfIsEmbeddedWidget, initializeWalletConnect, performMultiWalletChecks, clearErrors, } = Object.assign({ clearErrors: true, emitCancelAuth: false, ignoreIfIsEmbeddedWidget: true, initializeWalletConnect: true, performMultiWalletChecks: false }, options);
        if (!showAuthFlow && value) {
            dynamicEvents.emit('authFlowOpen');
        }
        else if (showAuthFlow && !value) {
            dynamicEvents.emit('authFlowClose');
        }
        if (value) {
            if (ignoreIfIsEmbeddedWidget && isRenderingEmbeddedAuthFlow.current)
                return;
            if (performMultiWalletChecks && isAuthenticated) {
                if (!isMultiWalletEnabled) {
                    logger.warn('setShowAuthFlow was called while the user is logged in, but Dynamic is not configured with multi-wallet enabled. This results in a no-op.');
                    return;
                }
                logger.warn('Using setShowAuthFlow to render the wallet list when the user is logged in is deprecated. Use setShowLinkNewWalletModal instead. For more information, see: https://docs.dynamic.xyz/react-sdk/hooks/usedynamiccontext');
            }
            if (initializeWalletConnect) {
                (_a = walletConnectorOptions
                    .find((wallet) => wallet.walletConnector.key === 'walletconnect')) === null || _a === void 0 ? void 0 : _a.walletConnector.init();
            }
        }
        else if (emitCancelAuth && showAuthFlow)
            dynamicEvents.emit('authFlowCancelled');
        if (clearErrors)
            setError(undefined);
        _setShowAuthFlow(value);
    }, [
        isAuthenticated,
        isMultiWalletEnabled,
        isRenderingEmbeddedAuthFlow,
        setError,
        showAuthFlow,
        walletConnectorOptions,
    ]);
    return [showAuthFlow, setShowAuthFlow];
};

export { useShowAuthFlow };
