import { __awaiter } from '../../../_virtual/_tslib.js';
import { getWebAuthnAttestation } from '@turnkey/http';
import { WalletConnectorBase, logger } from '@dynamic-labs/wallet-connector-core';
import { getTLD } from '@dynamic-labs/utils';
import { base64UrlEncode } from '../utils/base64UrlEncode/base64UrlEncode.js';
import { generateRandomBuffer } from '../utils/generateRandomBuffer/generateRandomBuffer.js';
import { convertAttestationTransports } from '../utils/convertAttestationTransports/convertAttestationTransports.js';
import 'tldts';
import '@solana/web3.js';
import { turnkeyAuthenticatorRecoveryHandler } from '../AuthenticatorHandler/TurnkeyAuthenticatorRecoveryHandler.js';
import { ExportHandler } from '../ExportHandler/ExportHandler.js';

class TurnkeyWalletConnectorBase extends WalletConnectorBase {
    constructor(nameAndKey, props) {
        super(props);
        // Public fields
        this.canConnectViaEmail = false;
        this.isEmbeddedWallet = true;
        if (!props.appName) {
            throw new Error('appName not set');
        }
        this.name = nameAndKey.name;
        this.overrideKey = nameAndKey.key;
        this.appName = props.appName;
        this.__authenticatorMethodHandler = turnkeyAuthenticatorRecoveryHandler;
        this.__exportHandler = new ExportHandler();
    }
    getWebAuthnAttestation() {
        return __awaiter(this, void 0, void 0, function* () {
            const challenge = generateRandomBuffer();
            const authenticatorUserId = generateRandomBuffer();
            const { email } = this;
            if (!email) {
                throw new Error('Email not set');
            }
            const webAuthnCreateParams = {
                publicKey: {
                    authenticatorSelection: {
                        authenticatorAttachment: undefined,
                        requireResidentKey: false,
                        residentKey: 'preferred',
                        userVerification: 'discouraged',
                    },
                    challenge,
                    pubKeyCredParams: [
                        {
                            alg: -7,
                            type: 'public-key',
                        },
                    ],
                    rp: {
                        id: getTLD(),
                        name: this.appName,
                    },
                    user: {
                        displayName: email,
                        id: authenticatorUserId,
                        name: email,
                    },
                },
            };
            let attestation;
            try {
                attestation = yield getWebAuthnAttestation(webAuthnCreateParams);
            }
            catch (error) {
                logger.warn(`Unable to register webauthn credential on the current page's TLD ${getTLD()}. Falling back to using hostname. ${window.location.hostname}`, error);
                // Create the passkey on the hostname instead.
                webAuthnCreateParams.publicKey.rp.id = window.location.hostname;
                attestation = yield getWebAuthnAttestation(webAuthnCreateParams);
            }
            return {
                attestation: {
                    attestationObject: attestation.attestationObject,
                    clientDataJson: attestation.clientDataJson,
                    credentialId: attestation.credentialId,
                    transports: convertAttestationTransports(attestation.transports),
                },
                challenge: base64UrlEncode(challenge),
            };
        });
    }
    getAuthenticatorHandler() {
        return this.__authenticatorMethodHandler;
    }
    getExportHandler() {
        return this.__exportHandler;
    }
    // Public methods
    get email() {
        return this._email;
    }
    setEmail(email) {
        this._email = email;
    }
    clearEmail() {
        this._email = null;
    }
    fetchPublicAddress() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return (_a = this.verifiedCredential) === null || _a === void 0 ? void 0 : _a.address;
        });
    }
    getConnectedAccounts() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const address = (_a = this.verifiedCredential) === null || _a === void 0 ? void 0 : _a.address;
            if (!address) {
                return [];
            }
            return [address];
        });
    }
    getMobileOrInstalledWallet() {
        return this;
    }
    get turnkeyAddress() {
        var _a;
        const { address } = (_a = this.verifiedCredential) !== null && _a !== void 0 ? _a : {};
        return address;
    }
    get walletProperties() {
        const { walletProperties } = this.verifiedCredential || {};
        return walletProperties;
    }
    // Private methods
    set verifiedCredential(verifiedCredential) {
        this._verifiedCredential = verifiedCredential;
    }
    get verifiedCredential() {
        return this._verifiedCredential;
    }
}

export { TurnkeyWalletConnectorBase };
