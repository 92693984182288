import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgCheckCircle } from '../../shared/assets/check-circle.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useCountdown } from '../../utils/hooks/useCountdown/useCountdown.js';
import '../../context/ThemeContext/ThemeContext.js';
import { useMutation } from '../../utils/hooks/useMutation/useMutation.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import '../Alert/Alert.js';
import { Typography } from '../Typography/Typography.js';
import { TextButton } from '../TextButton/TextButton.js';
import '../ShadowDOM/ShadowDOM.js';
import { Icon } from '../Icon/Icon.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const RetrySendVerificationCodeSection = ({ retryHandler, secondsToRetry = 15, disabled = false, className }) => {
    const { t } = useTranslation();
    const [retried, setRetried] = useState(false);
    const { remainingSeconds, startCountdown } = useCountdown({
        callback: () => setRetried(false),
        seconds: secondsToRetry,
    });
    const { isLoading, mutate: retry } = useMutation(retryHandler, {
        onSuccess: () => {
            setRetried(true);
            startCountdown();
        },
    });
    const containerClassName = classNames('retry-send-verification-code-section', className);
    if (retried) {
        return (jsxs("div", { className: containerClassName, children: [jsx(Typography, { variant: 'body_small', color: 'secondary', copykey: 'dyn_email_verification.resend_code_in', children: t('dyn_email_verification.resend_code_in', { remainingSeconds }) }), jsxs("span", { className: 'retry-send-verification-code-section__confirmation', children: [jsx(Icon, { color: 'success-1', children: jsx(SvgCheckCircle, {}) }), jsx(Typography, { variant: 'body_small', weight: 'medium', color: 'primary', copykey: 'dyn_email_verification.code_sent', children: t('dyn_email_verification.code_sent') })] })] }));
    }
    return (jsxs("div", { className: containerClassName, children: [jsx(Typography, { variant: 'body_small', weight: 'medium', color: 'secondary', copykey: 'dyn_email_verification.code_not_received', children: t('dyn_email_verification.code_not_received') }), jsx(TextButton, { className: 'email-verification__text-button', disabled: isLoading || disabled, onClick: () => retry(), copykey: 'dyn_email_verification.resend_code', children: t('dyn_email_verification.resend_code') })] }));
};

export { RetrySendVerificationCodeSection };
