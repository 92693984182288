import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgDomain } from '../../../shared/assets/domain.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getEnabledEmbeddedWalletsAuthenticators } from '../../../utils/functions/getEnabledEmbeddedWalletsAuthenticators/getEnabledEmbeddedWalletsAuthenticators.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../components/Typography/Typography.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import { NeedHelpSection } from '../../../components/NeedHelpSection/NeedHelpSection.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import '../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { EmbeddedAuthChoiceCards } from '../EmbeddedAuthChoiceCards/EmbeddedAuthChoiceCards.js';

const PasskeyNewDomainDetectedView = () => {
    const { t } = useTranslation();
    const { projectSettings } = useInternalDynamicContext();
    const supportedAuthenticators = useMemo(() => {
        var _a;
        const supportedAuths = getEnabledEmbeddedWalletsAuthenticators((_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets) === null || _a === void 0 ? void 0 : _a.supportedSecurityMethods);
        return Object.keys(supportedAuths).filter((auth) => auth !== 'email');
    }, [projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.embeddedWallets]);
    return (jsxs(Fragment, { children: [jsx(ModalHeader, { children: jsx(Typography, { as: 'h1', variant: 'title', color: 'primary', "data-testid": 'dynamic-auth-modal-heading', className: 'header__typography', copykey: 'dyn_passkey_new_domain_detected.title', children: t('dyn_passkey_new_domain_detected.title') }) }), jsxs("div", { className: 'passkey-new-domain-detected', children: [jsx(SvgDomain, { className: 'passkey-new-domain-detected__header' }), jsx(Typography, { variant: 'body_normal', weight: 'regular', color: 'secondary', children: t('dyn_passkey_new_domain_detected.description') }), jsx(EmbeddedAuthChoiceCards, { supportedAuthenticators: supportedAuthenticators }), jsx(NeedHelpSection, { isRecoveryFlow: true })] })] }));
};

export { PasskeyNewDomainDetectedView };
