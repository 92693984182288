/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var NextViewEnum;
(function (NextViewEnum) {
    NextViewEnum["Done"] = "done";
    NextViewEnum["VerifyEmail"] = "verify-email";
    NextViewEnum["VerifiedAndTransferred"] = "verified-and-transferred";
})(NextViewEnum || (NextViewEnum = {}));
function NextViewEnumFromJSON(json) {
    return NextViewEnumFromJSONTyped(json);
}
function NextViewEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function NextViewEnumToJSON(value) {
    return value;
}

export { NextViewEnum, NextViewEnumFromJSON, NextViewEnumFromJSONTyped, NextViewEnumToJSON };
