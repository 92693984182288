/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var EmbeddedWalletSecurityMethod;
(function (EmbeddedWalletSecurityMethod) {
    EmbeddedWalletSecurityMethod["Passkey"] = "passkey";
    EmbeddedWalletSecurityMethod["Passphrase"] = "passphrase";
})(EmbeddedWalletSecurityMethod || (EmbeddedWalletSecurityMethod = {}));
function EmbeddedWalletSecurityMethodFromJSON(json) {
    return EmbeddedWalletSecurityMethodFromJSONTyped(json);
}
function EmbeddedWalletSecurityMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function EmbeddedWalletSecurityMethodToJSON(value) {
    return value;
}

export { EmbeddedWalletSecurityMethod, EmbeddedWalletSecurityMethodFromJSON, EmbeddedWalletSecurityMethodFromJSONTyped, EmbeddedWalletSecurityMethodToJSON };
