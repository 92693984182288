import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import '../../../events/dynamicEvents.js';
import '@dynamic-labs/utils';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgChevronLeft } from '../../../shared/assets/chevron-left.js';
import { ReactComponent as SvgClose } from '../../../shared/assets/close.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../../utils/functions/getAuthToken/getAuthToken.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import { updateUserPasskey } from '../../../data/api.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../context/MockContext/MockContext.js';
import '../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../context/FooterAnimationContext/index.js';
import { Typography } from '../../../components/Typography/Typography.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import { Button } from '../../../components/Button/Button.js';
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../../components/IconButton/IconButton.js';
import '../../../components/Alert/Alert.js';
import { PasskeySuccessEnum } from '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import { usePasskeyContext } from '../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import '../../../components/InlineWidget/InlineWidget.js';
import { Input } from '../../../components/Input/Input.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import '../../WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const RenamePasskeyView = () => {
    var _a;
    const { passkey, setPasskey, passkeyIcon, passkeyEdited, setPasskeyEdited } = usePasskeyContext();
    const { t } = useTranslation();
    const { environmentId, setShowAuthFlow, setPasskeySuccessPopup } = useInternalDynamicContext();
    const [error, setError] = useState(false);
    const handleUpdatePasskey = (alias) => __awaiter(void 0, void 0, void 0, function* () {
        if (alias && (passkey === null || passkey === void 0 ? void 0 : passkey.id)) {
            const newPasskey = yield updateUserPasskey({
                alias: alias.trim(),
                environmentId,
                id: passkey.id,
                userJwt: getAuthToken(),
            });
            if (newPasskey) {
                setPasskey(newPasskey);
                setPasskeyEdited(!passkeyEdited);
                setShowAuthFlow(false);
                setPasskeySuccessPopup(PasskeySuccessEnum.EDITED);
            }
            else
                setError(true);
        }
        else
            setError(true);
    });
    const backButton = (jsx(IconButton, { type: 'button', onClick: () => {
            setShowAuthFlow(false);
        }, "data-testid": 'back-button', children: jsx(SvgChevronLeft, {}) }));
    const closeButton = (jsx(IconButton, { onClick: () => setShowAuthFlow(false), type: 'button', id: 'close-button', "data-testid": 'close-button', children: jsx(SvgClose, {}) }));
    if (passkey) {
        return (jsxs("div", { className: 'edit-passkeys-widget-view', children: [jsx(ModalHeader, { leading: backButton, trailing: closeButton }), jsx("div", { className: 'edit-passkeys-widget-view__icon', children: passkeyIcon }), jsx(Typography, { as: 'h1', variant: 'title', color: 'primary', className: 'edit-passkeys-widget-view__title', copykey: 'dyn_rename_passkeys.title', children: t('dyn_rename_passkeys.title') }), jsx(Formik, { initialValues: { alias: (_a = passkey.alias) !== null && _a !== void 0 ? _a : '' }, onSubmit: (values) => {
                        handleUpdatePasskey(values.alias);
                    }, children: ({ values }) => (jsxs(Form, { className: 'edit-passkeys-widget-view__body', children: [jsx(Field, { "data-testid": 'input-field', name: 'alias', type: 'text', label: t('dyn_rename_passkeys.input_label'), copykey: 'dyn_rename_passkeys.input_label', className: 'edit-passkeys-widget-view__input', as: Input, error: error, maxLength: 32 }), jsx(Button, { type: 'submit', className: 'edit-passkeys-widget-view__body', buttonPadding: 'large', disabled: !values.alias, copykey: 'dyn_rename_passkeys.save', children: t('dyn_rename_passkeys.save') }), error && (jsx(Typography, { as: 'h1', variant: 'body_small', color: 'error-1', copykey: 'dyn_rename_passkeys.error', children: t('dyn_rename_passkeys.error') }))] })) })] }));
    }
    return (jsx(Typography, { as: 'h1', variant: 'title', color: 'primary', className: 'edit-passkeys-widget-view__title', children: t('dyn_rename_passkeys.not_found') }));
};

export { RenamePasskeyView };
