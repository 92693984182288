import { createIconic } from '../../Iconic.js';

const AlgorandIcon = createIconic({
    alt: 'Algorand Chain',
    iconName: 'algorand',
    sourcePath: 'icons/chains/algorand.svg',
});
const ArbitrumIcon = createIconic({
    alt: 'Arbitrum Chain',
    iconName: 'arbitrum',
    sourcePath: 'icons/chains/arbitrum.svg',
});
const AuroraIcon = createIconic({
    alt: 'Aurora Chain',
    iconName: 'aurora',
    sourcePath: 'icons/chains/aurora.svg',
});
const BaseChainIcon = createIconic({
    alt: 'Base Chain',
    iconName: 'base',
    sourcePath: 'icons/chains/base.svg',
});
const BitcoinIcon = createIconic({
    alt: 'Bitcoin Chain',
    iconName: 'bitcoin',
    sourcePath: 'icons/chains/bitcoin.svg',
});
const BnbIcon = createIconic({
    alt: 'BNB Chain',
    iconName: 'bnb',
    sourcePath: 'icons/chains/bnb.svg',
});
const CosmosIcon = createIconic({
    alt: 'Cosmos Chain',
    iconName: 'cosmos',
    sourcePath: 'icons/chains/cosmos.svg',
});
const OsmosisIcon = createIconic({
    alt: 'Osmosis Chain',
    iconName: 'osmosis',
    sourcePath: 'icons/chains/osmosis.svg',
});
const AxelarIcon = createIconic({
    alt: 'Axelar Chain',
    iconName: 'axelar',
    sourcePath: 'icons/chains/axelar.svg',
});
const EthereumIcon = createIconic({
    alt: 'Ethereum Chain',
    iconName: 'ethereum',
    sourcePath: 'icons/chains/ethereum.svg',
});
const GoerliIcon = createIconic({
    alt: 'Goerli Chain',
    iconName: 'goerli',
    sourcePath: 'icons/chains/goerli.svg',
});
const ZkSyncIcon = createIconic({
    alt: 'zkSync Era Chain',
    iconName: 'zkSync',
    sourcePath: 'icons/chains/zkSync.svg',
});
const FlowIcon = createIconic({
    alt: 'Flow Chain',
    iconName: 'flow',
    sourcePath: 'icons/chains/flow.svg',
});
const GnosisIcon = createIconic({
    alt: 'Gnosis Chain',
    iconName: 'gnosis',
    sourcePath: 'icons/chains/gnosis.svg',
});
const OptimismIcon = createIconic({
    alt: 'Optimism Chain',
    iconName: 'optimism',
    sourcePath: 'icons/chains/optimism.svg',
});
const PalmIcon = createIconic({
    alt: 'Palm Chain',
    iconName: 'palm',
    sourcePath: 'icons/chains/palm.svg',
});
const PolygonIcon = createIconic({
    alt: 'Polygon Chain',
    iconName: 'polygon',
    sourcePath: 'icons/chains/polygon.svg',
});
const SolanaIcon = createIconic({
    alt: 'Solana Chain',
    iconName: 'solana',
    sourcePath: 'icons/chains/solana.svg',
});
const StarknetIcon = createIconic({
    alt: 'Starknet Chain',
    iconName: 'starknet',
    sourcePath: 'icons/chains/starknet.svg',
});

export { AlgorandIcon, ArbitrumIcon, AuroraIcon, AxelarIcon, BaseChainIcon, BitcoinIcon, BnbIcon, CosmosIcon, EthereumIcon, FlowIcon, GnosisIcon, GoerliIcon, OptimismIcon, OsmosisIcon, PalmIcon, PolygonIcon, SolanaIcon, StarknetIcon, ZkSyncIcon };
