import { useState, useMemo } from 'react';
import { useInterval } from '../useInterval/useInterval.js';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import { usePromise } from '../usePromise/usePromise.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

/**
 * The interval to refresh the gas price in milliseconds.
 */
const REFRESH_INTERVAL = 20000;
/**
 * The multiplier to apply to the gas price.
 * This will define how aggressive the gas price will be.
 * This value should be between 1 and 2, where 1 is less aggressive and 2 is more aggressive.
 */
const gasMultiplier = BigInt(2);
/**
 * A React Hook that returns a transaction object with the gas price set to the current gas price from the provider.
 *
 * @param {Object} props - The properties for the hook.
 * @param {PublicClient} props.provider - The provider to use for getting the current gas price.
 * @param {TransactionRequest} props.transaction - The transaction object.
 *
 * @returns {TransactionRequest} A new transaction object with the gas price.
 *
 * @example
 * const updatedTransaction = useTransactionWithGasPrice({
 *   provider: myProvider,
 *   transaction: myTransaction,
 * });
 */
const useTransactionWithGasPrice = ({ provider, transaction, enabled = true, }) => {
    const isGasPriceDefinedInTransaction = Boolean(transaction.gasPrice);
    const isMaxFeePerGasDefinedInTransaction = Boolean(transaction.maxFeePerGas);
    const [estimationKey, setEstimationKey] = useState(0);
    const shouldApplyGasFee = isGasPriceDefinedInTransaction === false &&
        isMaxFeePerGasDefinedInTransaction === false &&
        enabled === true;
    const { data: feesPerGas } = usePromise(() => provider.estimateFeesPerGas(), {
        deps: [estimationKey],
        enabled: shouldApplyGasFee,
    });
    /**
     * This interval triggers the refresh of the transaction gas fees every 20 seconds.
     */
    useInterval(() => {
        setEstimationKey((estimationKey) => estimationKey + 1);
    }, REFRESH_INTERVAL);
    return useMemo(() => {
        if (!shouldApplyGasFee) {
            return transaction;
        }
        if (feesPerGas &&
            feesPerGas.maxFeePerGas &&
            feesPerGas.maxPriorityFeePerGas) {
            return Object.assign(Object.assign({}, transaction), { maxFeePerGas: feesPerGas.maxFeePerGas * gasMultiplier, maxPriorityFeePerGas: feesPerGas.maxPriorityFeePerGas * gasMultiplier });
        }
        return transaction;
    }, [feesPerGas, transaction, shouldApplyGasFee]);
};

export { REFRESH_INTERVAL, useTransactionWithGasPrice };
