import { findWalletBookWallet } from '@dynamic-labs/wallet-book';

const createWallet = (walletBook, wallet) => {
    var _a;
    const walletBookWallet = findWalletBookWallet(walletBook, wallet.key);
    return {
        chainGroup: walletBookWallet === null || walletBookWallet === void 0 ? void 0 : walletBookWallet.chainGroup,
        group: (walletBookWallet === null || walletBookWallet === void 0 ? void 0 : walletBookWallet.chainGroup) || (walletBookWallet === null || walletBookWallet === void 0 ? void 0 : walletBookWallet.walletGroup),
        injectedConfig: walletBookWallet === null || walletBookWallet === void 0 ? void 0 : walletBookWallet.injectedConfig,
        isInstalledOnBrowser: (_a = wallet.isInstalledOnBrowser()) !== null && _a !== void 0 ? _a : false,
        key: wallet.key,
        name: wallet.name,
        walletConnector: wallet,
        walletGroup: walletBookWallet === null || walletBookWallet === void 0 ? void 0 : walletBookWallet.walletGroup,
    };
};

export { createWallet };
