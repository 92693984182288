import { jsxs, jsx } from 'react/jsx-runtime';
import 'react';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import { DynamicAuthLayout } from '../../layout/DynamicAuthLayout/DynamicAuthLayout.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import { Modal } from '../../components/Modal/Modal.js';
import { ModalCard } from '../../components/ModalCard/ModalCard.js';
import 'react-focus-lock';
import '../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import '../../components/IconButton/IconButton.js';
import '../../components/Alert/Alert.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { WalletProgressStepper } from '../../layout/DynamicAuthLayout/WalletProgressStepper/WalletProgressStepper.js';
import { useAuthLayoutChecks } from '../../utils/hooks/useAuthLayoutChecks/useAuthLayoutChecks.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import '../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';

const DynamicAuthModal = ({ children, onClose, transitionProps, projectSettings, className, }) => {
    const { showConnectedWalletProgress } = useAuthLayoutChecks();
    return (jsxs(Modal, { isShown: Boolean(transitionProps === null || transitionProps === void 0 ? void 0 : transitionProps.isShown), children: [showConnectedWalletProgress && (jsx(ModalCard, { borderRadius: 'small', children: jsx(WalletProgressStepper, {}) })), jsx(ModalCard, { dataTestId: 'dynamic-auth-modal', children: jsx(DynamicAuthLayout, { className: className, projectSettings: projectSettings, onClose: onClose, hideBridgeProgressSteppers: true, children: children }) })] }));
};

export { DynamicAuthModal };
