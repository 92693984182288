import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useRef } from 'react';
import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { isEmailOTPWalletConnector } from '@dynamic-labs/wallet-connector-core';
import '../DynamicContext/DynamicContext.js';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import { dynamicEvents } from '../../events/dynamicEvents.js';
import '../CaptchaContext/CaptchaContext.js';
import '../ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { isProviderEnabled } from '../../utils/functions/isProviderEnabled/isProviderEnabled.js';
import { isEmailValid } from '../../utils/functions/isEmailValid/isEmailValid.js';
import 'viem/chains';
import '../AccessDeniedContext/AccessDeniedContext.js';
import '../AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import { useCreateUserProfileByJWTCallback } from '../../utils/hooks/useCreateUserProfileByJWTCallback/useCreateUserProfileByJWTCallback.js';
import '../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../UserWalletsContext/UserWalletsContext.js';
import { useWalletItemActions } from '../../utils/hooks/useWalletItemActions/useWalletItemActions.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../LoadingContext/LoadingContext.js';
import '../SocialRedirectContext/SocialRedirectContext.js';
import '../WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import { connectWithEmailOtpContext } from './connectWithEmailOtpContext.js';
import { findEmailProvider } from './utils/findEmailProvider.js';
import { createMagicHandler } from './utils/createMagicHandler.js';
import { createEmailHandler } from './utils/createEmailHandler.js';

const { Provider } = connectWithEmailOtpContext;
const ConnectWithEmailOtpProvider = ({ children, }) => {
    const { walletConnectorOptions, environmentId, projectSettings, setCallback, } = useInternalDynamicContext();
    const { createUserProfileByJWT } = useCreateUserProfileByJWTCallback();
    const { handleInstalledExtensionClick } = useWalletItemActions();
    const lastVerificationHandlerRef = useRef(null);
    const providers = (projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers) || [];
    const emailOtpWalletConnector = walletConnectorOptions
        .map(({ walletConnector }) => walletConnector)
        .find(isEmailOTPWalletConnector);
    const mapProvider = {
        [ProviderEnum.Dynamic]: {
            enabled: isProviderEnabled(providers, ProviderEnum.Dynamic),
            handler: createEmailHandler(environmentId, createUserProfileByJWT),
        },
        [ProviderEnum.EmailOnly]: {
            enabled: isProviderEnabled(providers, ProviderEnum.EmailOnly),
            handler: createEmailHandler(environmentId, createUserProfileByJWT),
        },
        [ProviderEnum.MagicLink]: {
            enabled: isProviderEnabled(providers, ProviderEnum.MagicLink),
            handler: createMagicHandler(emailOtpWalletConnector, handleInstalledExtensionClick),
        },
    };
    const connectWithEmail = (email, { provider } = {}) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isEmailValid(email)) {
            throw new Error('Email is not valid');
        }
        const effectiveEmailProvider = provider || (yield findEmailProvider(environmentId, email));
        if (!mapProvider[effectiveEmailProvider].enabled) {
            throw new Error('Email provider is not enabled');
        }
        const verificationHandler = yield mapProvider[effectiveEmailProvider].handler(email);
        lastVerificationHandlerRef.current = verificationHandler;
    });
    const verifyOneTimePassword = (oneTimePassword) => __awaiter(void 0, void 0, void 0, function* () {
        if (!lastVerificationHandlerRef.current) {
            throw new Error('Verification have not started, use connectWithEmail before verifying');
        }
        const { email, verified } = yield lastVerificationHandlerRef.current.verify(oneTimePassword);
        dynamicEvents.emit('emailVerificationResult', verified, email);
        if (verified) {
            setCallback('authSuccess');
        }
        else {
            throw new Error('Email verification failed');
        }
    });
    const retryOneTimePassword = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!lastVerificationHandlerRef.current) {
            throw new Error('Verification have not started, use connectWithEmail before verifying');
        }
        if (!lastVerificationHandlerRef.current.retry) {
            throw new Error('Re-send is not supported');
        }
        return lastVerificationHandlerRef.current.retry();
    });
    return (jsx(Provider, { value: { connectWithEmail, retryOneTimePassword, verifyOneTimePassword }, children: children }));
};

export { ConnectWithEmailOtpProvider };
