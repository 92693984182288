import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { Formik, Form } from 'formik';
import { useUserDataForm } from '../../utils/hooks/useUserDataForm/useUserDataForm.js';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgCheck } from '../../shared/assets/check.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/multi-wallet';
import { getUserProfileFields } from '../../utils/functions/getUserProfileFields/getUserProfileFields.js';
import 'viem/chains';
import '../../context/DynamicContext/DynamicContext.js';
import '../../events/dynamicEvents.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../context/MockContext/MockContext.js';
import 'react-i18next';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import '../Alert/Alert.js';
import { Button } from '../Button/Button.js';
import '../ShadowDOM/ShadowDOM.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import { DynamicWidgetFooter } from '../../widgets/DynamicWidget/components/DynamicWidgetFooter/DynamicWidgetFooter.js';
import 'yup';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import { UserDataFields } from '../../views/CollectUserDataView/UserDataFields/index.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const UserProfileForm = ({ filterFields, formClassName, fieldsContainerStyle, onEditProfileSubmit, onEditProfileCancel, options = {
    buttonsAsFooter: true,
    cancelText: 'Cancel',
    shouldEnforcePolicies: false,
    submitText: 'Save',
}, }) => {
    const { projectSettings, user } = useInternalDynamicContext();
    const targetFields = useMemo(() => {
        const allUserFields = getUserProfileFields({
            projectSettingsKyc: projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.kyc,
            user,
        });
        if (!filterFields)
            return allUserFields;
        return allUserFields.filter(({ name }) => filterFields.includes(name));
    }, [filterFields, projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.kyc, user]);
    const { fetch, formikInitialValues, formikValidationSchema, onFormSubmit } = useUserDataForm({
        fields: targetFields,
        onSubmit: onEditProfileSubmit,
        shouldEnforcePolicies: options.shouldEnforcePolicies,
        userProfile: user,
    });
    const makeButtonsContent = (isSubmitting, isValid, dirty) => (jsxs(Fragment, { children: [!options.hideCancelButton && (jsx(Button, Object.assign({ disabled: fetch, expanded: true, buttonPadding: 'medium', buttonVariant: 'secondary', type: 'button', typographyProps: { variant: 'button_secondary' }, onClick: onEditProfileCancel, dataTestId: 'cancel-button' }, options.cancelButtonProps, { children: options.cancelText }))), jsx(Button, Object.assign({ buttonClassName: 'dynamic-widget-footer__button', disabled: fetch || !isValid || !dirty, expanded: true, type: 'submit', buttonPadding: 'medium', typographyProps: { variant: 'button_secondary' }, loading: isSubmitting, startSlot: jsx(SvgCheck, {}), dataTestId: 'submit-button' }, options.submitButtonProps, { children: options.submitText }))] }));
    return (jsx(Formik, { onSubmit: onFormSubmit, initialValues: formikInitialValues, validationSchema: formikValidationSchema, validateOnChange: true, children: ({ errors, touched, isSubmitting, isValid, dirty }) => (jsxs(Form, { className: classNames('dynamic-widget-edit-profile-view', formClassName), "data-testid": 'editUserProfileForm', children: [jsx("div", { className: 'dynamic-widget-edit-profile-view__fields', style: fieldsContainerStyle, children: jsx(UserDataFields, { errors: errors, fields: targetFields, touched: touched }) }), options.buttonsAsFooter ? (jsx(DynamicWidgetFooter, { children: makeButtonsContent(isSubmitting, isValid, dirty) })) : (makeButtonsContent(isSubmitting, isValid, dirty))] })) }));
};

export { UserProfileForm };
