import { LAST_USED_WALLET } from '../../../../utils/constants/localStorage.js';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import { LocalStorage } from '../../classes/storage/localStorage.js';
import { isWalletGroup } from '../../../../utils/functions/walletListBuilder/utils/isWalletGroup/isWalletGroup.js';
import { getWalletGroupType } from '../../../../utils/functions/getWalletGroupType/getWalletGroupType.js';

const getLastUsedWalletKey = () => LocalStorage.getFromLS(LAST_USED_WALLET);
const getWalletGroupLabel = (walletGroup) => {
    const lastUsedWalletKey = getLastUsedWalletKey();
    const hasNestedLastUsedWallet = walletGroup.groupedWallets.some((walletOption) => walletOption.walletConnector.key === lastUsedWalletKey);
    if (hasNestedLastUsedWallet) {
        return 'Last used';
    }
    const hasNestedInstalledWallets = walletGroup.groupedWallets.some((walletOption) => walletOption.isInstalledOnBrowser);
    if (hasNestedInstalledWallets) {
        return 'Installed';
    }
    if (getWalletGroupType(walletGroup) === 'multi-chain') {
        return 'Multichain';
    }
    return undefined;
};
const getWalletLabel = (walletOption) => {
    const lastUsedWalletKey = getLastUsedWalletKey();
    const { key: walletKey } = walletOption.walletConnector;
    const isLastUsedWallet = walletKey === lastUsedWalletKey;
    if (isLastUsedWallet)
        return 'Last used';
    if (walletOption.isInstalledOnBrowser)
        return 'Installed';
    return undefined;
};
const getWalletListItemLabel = ({ wallet }) => {
    if (isWalletGroup(wallet)) {
        return getWalletGroupLabel(wallet);
    }
    return getWalletLabel(wallet);
};

export { getWalletListItemLabel };
