import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../events/dynamicEvents.js';
import '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import { decodeJwt } from '../../../../shared/utils/functions/decodeJwt/decodeJwt.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgAndroidTouchId } from '../../../../shared/assets/android-touch-id.js';
import { ReactComponent as SvgCheckCircle } from '../../../../shared/assets/check-circle.js';
import { ReactComponent as SvgChevronLeft } from '../../../../shared/assets/chevron-left.js';
import { ReactComponent as SvgExportPrivateKey } from '../../../../shared/assets/export-private-key.js';
import { ReactComponent as SvgExportRecoveryPhrase } from '../../../../shared/assets/export-recovery-phrase.js';
import '../../../../shared/logger.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/sdk-api';
import '../../../../shared/consts/index.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import { useThemeContext } from '../../../../context/ThemeContext/ThemeContext.js';
import { usePasskeyRecovery } from '../../../../utils/hooks/usePasskeyRecovery/usePasskeyRecovery.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../context/FooterAnimationContext/index.js';
import { useIsTurnkeyWallet } from '../../../../utils/hooks/useIsTurnkeyWallet/useIsTurnkeyWallet.js';
import { Typography } from '../../../../components/Typography/Typography.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import 'react-focus-lock';
import '../../../../context/LoadingContext/LoadingContext.js';
import 'i18next';
import 'qrcode';
import { useWidgetContext } from '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import 'formik';
import { Button } from '../../../../components/Button/Button.js';
import { ModalHeader } from '../../../../components/ModalHeader/ModalHeader.js';
import { IconButton } from '../../../../components/IconButton/IconButton.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { PasskeyDeviceIcon } from '../../../../views/Passkey/PasskeyDeviceIcon/PasskeyDeviceIcon.js';
import { useEmbeddedReveal } from '../../../../utils/hooks/useEmbeddedReveal/useEmbeddedReveal.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import { Spinner } from '../../../../components/Spinner/spinner.js';
import '../../../../views/WalletList/WalletList.js';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';

const WalletSettingsView = () => {
    var _a, _b, _c, _d, _e, _f;
    const { goToInitialDynamicWidgetView, setDynamicWidgetView } = useWidgetContext();
    const { authToken, projectSettings } = useInternalDynamicContext();
    const { initExportProcess } = useEmbeddedReveal();
    const { isTurnkeyWallet, isTurnkeyWalletWithoutAuthenticator } = useIsTurnkeyWallet();
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const { initPasskeyRecoveryProcess, shouldInitRecovery } = usePasskeyRecovery();
    const [isLoading, setIsLoading] = useState(false);
    const decodedJwt = decodeJwt(authToken);
    const walletProperties = (_b = (_a = decodedJwt === null || decodedJwt === void 0 ? void 0 : decodedJwt.verifiedCredentials) === null || _a === void 0 ? void 0 : _a.find(({ walletName }) => walletName === null || walletName === void 0 ? void 0 : walletName.startsWith('turnkey'))) === null || _b === void 0 ? void 0 : _b.walletProperties;
    const isTurnkeyHDWallet = walletProperties === null || walletProperties === void 0 ? void 0 : walletProperties.turnkeyHDWalletId;
    const isPasskeyEnabled = Boolean((_f = (_e = (_d = (_c = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _c === void 0 ? void 0 : _c.embeddedWallets) === null || _d === void 0 ? void 0 : _d.supportedSecurityMethods) === null || _e === void 0 ? void 0 : _e.passkey) === null || _f === void 0 ? void 0 : _f.isEnabled);
    const isTurnkeyWalletWithAuthenticator = isTurnkeyWallet && !isTurnkeyWalletWithoutAuthenticator;
    const handlePasskeyClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isTurnkeyWalletWithAuthenticator) {
            return setDynamicWidgetView('manage-passkeys');
        }
        setIsLoading(true);
        try {
            yield initPasskeyRecoveryProcess('passkey');
        }
        finally {
            setIsLoading(false);
        }
    });
    const handleExportClick = (recoveryPhrase) => __awaiter(void 0, void 0, void 0, function* () {
        if (yield shouldInitRecovery()) {
            yield initPasskeyRecoveryProcess(undefined, 'reveal');
        }
        return initExportProcess(recoveryPhrase);
    });
    const renderPasskeysButtonEndSlot = () => {
        if (isTurnkeyWalletWithAuthenticator)
            return (jsx(SvgCheckCircle, { className: 'wallet-settings-view__body__section__button__icon-secondary--success' }));
        if (isLoading)
            return jsx(Spinner, {});
        return undefined;
    };
    const backButton = (jsx(IconButton, { type: 'button', onClick: goToInitialDynamicWidgetView, "data-testid": 'back-button', children: jsx(SvgChevronLeft, {}) }));
    return (jsxs("div", { className: 'wallet-settings-view', children: [jsx(ModalHeader, { leading: backButton, children: jsx("div", { className: 'wallet-settings-view__header', children: jsx(Typography, { variant: 'title', color: 'primary', copykey: 'dyn_wallet_settings.title', children: t('dyn_wallet_settings.title') }) }) }), jsxs("div", { className: 'wallet-settings-view__body', children: [isPasskeyEnabled && (jsxs("div", { className: 'wallet-settings-view__body__section', children: [jsx("div", { className: 'wallet-settings-view__body__section__title', children: t('dyn_wallet_settings.signing_section.title') }), jsx(Button, { dataTestId: 'manage-passkeys-button', onClick: () => handlePasskeyClick(), buttonClassName: 'wallet-settings-view__body__section__button', startSlot: 
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                jsxs("div", { className: 'wallet-settings-view__body__section__button__start-slot', children: [jsx(PasskeyDeviceIcon, { className: 'wallet-settings-view__body__section__button__icon', defaultIcon: jsx(SvgAndroidTouchId, { "data-testid": 'default-icon' }) }), jsx(Typography, { color: 'primary', weight: 'medium', copykey: 'dyn_wallet_settings.signing_section.passkey_button', children: t('dyn_wallet_settings.signing_section.passkey_button') })] }), endSlot: renderPasskeysButtonEndSlot(), disabled: isLoading, showInternalLoading: false })] })), jsxs("div", { className: 'wallet-settings-view__body__section', children: [jsx("div", { className: 'wallet-settings-view__body__section__title', children: t('dyn_wallet_settings.export_section.title') }), jsx(Button, { dataTestId: 'embedded-reveal-account-button', onClick: () => handleExportClick(), buttonClassName: 'wallet-settings-view__body__section__button', startSlot: 
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                jsxs("div", { className: 'wallet-settings-view__body__section__button__start-slot', children: [jsx(SvgExportPrivateKey, { className: `wallet-settings-view__body__section__button__icon${theme.theme.name === 'dark' ? '__stroke' : ''}` }), jsx(Typography, { color: 'primary', weight: 'medium', copykey: 'dyn_wallet_settings.export_section.private_key_button', children: t('dyn_wallet_settings.export_section.private_key_button') })] }), endSlot: jsx(SvgChevronLeft, { className: 'wallet-settings-view__body__section__button__icon-secondary--rotate' }), showInternalLoading: false }), isTurnkeyHDWallet && (jsx(Button, { dataTestId: 'embedded-reveal-button', onClick: () => handleExportClick(true), buttonClassName: 'wallet-settings-view__body__section__button', startSlot: 
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                jsxs("div", { className: 'wallet-settings-view__body__section__button__start-slot', children: [jsx(SvgExportRecoveryPhrase, { className: `wallet-settings-view__body__section__button__icon${theme.theme.name === 'dark' ? '__stroke' : ''}` }), jsx(Typography, { color: 'primary', weight: 'medium', copykey: 'dyn_wallet_settings.export_section.srp_button', children: t('dyn_wallet_settings.export_section.srp_button') })] }), endSlot: jsx(SvgChevronLeft, { className: 'wallet-settings-view__body__section__button__icon-secondary--rotate' }), showInternalLoading: false }))] })] })] }));
};

export { WalletSettingsView };
