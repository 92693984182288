/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var EmbeddedWalletAuthType;
(function (EmbeddedWalletAuthType) {
    EmbeddedWalletAuthType["Mpc"] = "mpc";
})(EmbeddedWalletAuthType || (EmbeddedWalletAuthType = {}));
function EmbeddedWalletAuthTypeFromJSON(json) {
    return EmbeddedWalletAuthTypeFromJSONTyped(json);
}
function EmbeddedWalletAuthTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function EmbeddedWalletAuthTypeToJSON(value) {
    return value;
}

export { EmbeddedWalletAuthType, EmbeddedWalletAuthTypeFromJSON, EmbeddedWalletAuthTypeFromJSONTyped, EmbeddedWalletAuthTypeToJSON };
