/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var GateRuleType;
(function (GateRuleType) {
    GateRuleType["Nft"] = "nft";
    GateRuleType["Token"] = "token";
})(GateRuleType || (GateRuleType = {}));
function GateRuleTypeFromJSON(json) {
    return GateRuleTypeFromJSONTyped(json);
}
function GateRuleTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function GateRuleTypeToJSON(value) {
    return value;
}

export { GateRuleType, GateRuleTypeFromJSON, GateRuleTypeFromJSONTyped, GateRuleTypeToJSON };
