import { __awaiter } from '../../../_virtual/_tslib.js';
import { LAMPORTS_PER_SOL, PublicKey, sendAndConfirmRawTransaction } from '@solana/web3.js';
import { WebauthnStamper } from '@turnkey/webauthn-stamper';
import { TurnkeyClient } from '@turnkey/http';
import { TurnkeySigner } from '@turnkey/solana';
import { IframeStamper } from '@turnkey/iframe-stamper';
import { ProviderChain } from '@dynamic-labs/rpc-providers';
import { DynamicError, bufferToBase64 } from '@dynamic-labs/utils';
import { TurnkeyWalletConnectorBase } from '../TurnkeyWalletConnectorBase/TurnkeyWalletConnectorBase.js';
import { findTurnkeyVerifiedCredential } from '../utils/findTurnkeyVerifiedCredential/findTurnkeyVerifiedCredential.js';
import '../utils/convertAttestationTransports/convertAttestationTransports.js';
import { getTLD } from '../utils/getTLD/getTLD.js';
import { createSolanaConnection } from '../utils/createSolanaConnection/createSolanaConnection.js';
import { TURNKEY_API_BASE_URL } from '../constants.js';

class TurnkeySolanaWalletConnector extends TurnkeyWalletConnectorBase {
    constructor(nameAndKey, props) {
        var _a;
        super(nameAndKey, props);
        // Public fields
        this.connectedChain = 'SOL';
        this.supportedChains = ['SOL'];
        this.verifiedCredentialChain = 'solana';
        this.solNetworks = props.solNetworks;
        this.walletUiUtils = props.walletUiUtils;
        this._turnkeyAccount = undefined;
        this._connectionClient = undefined;
        this.chainRpcProviders = props.chainRpcProviders;
        (_a = this.chainRpcProviders) === null || _a === void 0 ? void 0 : _a.registerChainProviders(ProviderChain.SOLANA, (config) => {
            const rpcProviders = {};
            if (config === null || config === void 0 ? void 0 : config.solana) {
                rpcProviders.solana = config.solana.map((network) => {
                    var _a;
                    const rpcUrl = ((_a = network.privateCustomerRpcUrls) === null || _a === void 0 ? void 0 : _a[0]) || network.rpcUrls[0];
                    const connection = createSolanaConnection(rpcUrl);
                    return {
                        chainId: network.chainId.toString(),
                        chainName: network.name,
                        provider: connection,
                    };
                });
            }
            return rpcProviders.solana;
        });
        this.__turnkeyClient = this.getTurnkeyClient();
    }
    getRpcUrl() {
        var _a;
        const [network] = this.solNetworks;
        if (!network) {
            throw new DynamicError('No enabled networks');
        }
        return ((_a = network.privateCustomerRpcUrls) === null || _a === void 0 ? void 0 : _a[0]) || network.rpcUrls[0];
    }
    getConnection(commitmentOrConfig) {
        if (!this._connectionClient) {
            const rpcUrl = this.getRpcUrl();
            if (!rpcUrl)
                throw new DynamicError('No rpcUrl');
            this._connectionClient = createSolanaConnection(rpcUrl, commitmentOrConfig);
        }
        return this._connectionClient;
    }
    getWalletClient() {
        return this.getConnection();
    }
    getNetwork() {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = this.getConnection();
            let genesisHash = yield connection.getGenesisHash();
            genesisHash = genesisHash.substring(0, 32);
            // see: https://github.com/ChainAgnostic/namespaces/blob/main/solana/caip2.md
            if (genesisHash === '5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp') {
                return 'mainnet';
            }
            else if (genesisHash === 'EtWTRABZaYq6iMfeYKouRu166VU2xqa1') {
                return 'devnet';
            }
            else {
                return 'testnet';
            }
        });
    }
    getPublicClient() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.solNetworks.length === 0)
                return;
            const configurations = {
                cosmos: [],
                evm: undefined,
                solana: this.solNetworks,
                starknet: undefined,
            };
            return (_b = (_a = this.chainRpcProviders) === null || _a === void 0 ? void 0 : _a.getSolanaRpcProviderByChainId(configurations, '101')) === null || _b === void 0 ? void 0 : _b.provider;
        });
    }
    supportsNetworkSwitching() {
        return false;
    }
    setVerifiedCredentials(verifiedCredentials) {
        const turnkeyVerifiedCredential = findTurnkeyVerifiedCredential(verifiedCredentials, ProviderChain.SOLANA);
        const didTurnkeyVerifiedCredentialsChanged = JSON.stringify(this.verifiedCredential) !==
            JSON.stringify(turnkeyVerifiedCredential);
        if (!didTurnkeyVerifiedCredentialsChanged) {
            return;
        }
        this.verifiedCredential = turnkeyVerifiedCredential;
        this.refreshTurnkeyAccount();
    }
    getAccount() {
        return this.turnkeyAddress;
    }
    refreshTurnkeyAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            this._turnkeyAccount = undefined;
            return this.getTurnkeyAccount();
        });
    }
    getTurnkeyClient() {
        var _a;
        let rpId = getTLD();
        if (!rpId) {
            rpId = window.location.hostname;
        }
        const stamper = new WebauthnStamper({
            rpId,
        });
        this.__turnkeyClient =
            (_a = this.getAuthenticatorHandler().client) !== null && _a !== void 0 ? _a : new TurnkeyClient({
                baseUrl: TURNKEY_API_BASE_URL,
            }, stamper);
        return this.__turnkeyClient;
    }
    createTurnkeyAccount({ organizationId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const turnkeyClient = this.getTurnkeyClient();
            const signer = new TurnkeySigner({ client: turnkeyClient, organizationId });
            return signer;
        });
    }
    getTurnkeyAccount() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (this._turnkeyAccount &&
                ((this.getAuthenticatorHandler().recoveryType === 'passkey' &&
                    ((_a = this.__turnkeyClient) === null || _a === void 0 ? void 0 : _a.stamper) instanceof WebauthnStamper) ||
                    (this.getAuthenticatorHandler().recoveryType === 'email' &&
                        ((_b = this.__turnkeyClient) === null || _b === void 0 ? void 0 : _b.stamper) instanceof IframeStamper)) &&
                this.__turnkeyClient === this.getAuthenticatorHandler().client) {
                return this._turnkeyAccount;
            }
            const { turnkeySubOrganizationId } = (_c = this.walletProperties) !== null && _c !== void 0 ? _c : {};
            const { address } = (_d = this.verifiedCredential) !== null && _d !== void 0 ? _d : {};
            if (!turnkeySubOrganizationId || !address) {
                return;
            }
            this._turnkeyAccount = yield this.createTurnkeyAccount({
                organizationId: turnkeySubOrganizationId,
            });
            return this._turnkeyAccount;
        });
    }
    getSigner() {
        return this.getTurnkeyAccount();
    }
    getBalance() {
        return __awaiter(this, void 0, void 0, function* () {
            const address = this.getAccount();
            if (!address) {
                return undefined;
            }
            const connectionClient = this.getConnection();
            const publicKey = new PublicKey(address);
            const balance = yield connectionClient.getBalance(publicKey);
            const solBalance = this.lamportsToSol(balance);
            return solBalance.toString();
        });
    }
    signMessage(messageToSign) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.turnkeyAddress) {
                throw new DynamicError('No turnkey account');
            }
            const address = this.turnkeyAddress;
            const signedMessage = yield this.walletUiUtils.signMessage({
                handler: () => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    const enc = new TextEncoder();
                    const encodedMessage = enc.encode(messageToSign);
                    const signedMessageRaw = yield ((_a = (yield this.getTurnkeyAccount())) === null || _a === void 0 ? void 0 : _a.signMessage(encodedMessage, address));
                    return bufferToBase64(signedMessageRaw || Buffer.from([]));
                }),
                message: messageToSign,
            });
            return signedMessage;
        });
    }
    signTransaction({ transaction, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const account = yield this.getTurnkeyAccount();
            const address = this.turnkeyAddress;
            if (!account || !address) {
                throw new Error('No turnkey account');
            }
            // this.walletUiUtils.({ message: 'Signing transaction...' });
            yield account.addSignature(transaction, address);
            return transaction.serialize();
        });
    }
    signAndSendTransaction({ transaction, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const signedTransaction = yield this.signTransaction({ transaction });
            const connectionClient = this.getConnection('confirmed');
            // find different method to send transaction, method to be deprecated
            const sentTransaction = yield sendAndConfirmRawTransaction(connectionClient, signedTransaction, {
                commitment: 'confirmed',
            });
            return sentTransaction;
        });
    }
    lamportsToSol(lamports) {
        return lamports / LAMPORTS_PER_SOL;
    }
}

export { TurnkeySolanaWalletConnector };
