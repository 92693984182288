import { __awaiter } from '../../../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useState, useCallback } from 'react';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../Alert/Alert.js';
import '../ShadowDOM/ShadowDOM.js';
import { ConnectButton } from './components/ConnectButton.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const DynamicConnectButton = ({ buttonContainerClassName = '', buttonClassName = '', children, copykey, }) => {
    const { isProjectSettingsLoading, setShowAuthFlow, setSelectedWalletConnectorKey, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const [didUserClick, setUserClicked] = useState(false);
    const handleOnClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!didUserClick) {
            setUserClicked(true);
        }
        setSelectedWalletConnectorKey(null);
        setShowAuthFlow(true);
    }), [didUserClick, setSelectedWalletConnectorKey, setShowAuthFlow, setView]);
    return (jsx(ConnectButton, { isLoading: isProjectSettingsLoading, isActive: didUserClick, onClick: handleOnClick, buttonContainerClassName: buttonContainerClassName, buttonClassName: buttonClassName, copykey: copykey, children: children }));
};

export { DynamicConnectButton };
