import { createIconic } from '../../Iconic.js';

const OneInchIcon = createIconic({
    alt: '1inch Wallet',
    iconName: '1inch.svg',
    sourcePath: 'icons/wallets/1inch.svg',
});
const ArgentXIcon = createIconic({
    alt: 'Argent X Wallet',
    iconName: 'argentx',
    sourcePath: 'icons/wallets/argentx.svg',
});
const ArgentMobile = createIconic({
    alt: 'Argent Mobile',
    iconName: 'argentmobile',
    sourcePath: 'icons/wallets/argent-mobile.svg',
});
const ArgentWebWallet = createIconic({
    alt: 'Argent Web Wallet',
    iconName: 'argentwebwallet',
    sourcePath: 'icons/wallets/argent-web-wallet.svg',
});
const BackpackIcon = createIconic({
    alt: 'Backpack Wallet',
    iconName: 'backpack',
    sourcePath: 'icons/wallets/backpack.svg',
});
const BinanceIcon = createIconic({
    alt: 'Binance Wallet',
    iconName: 'binance.svg',
    sourcePath: 'icons/wallets/binance.svg',
});
const BitPayIcon = createIconic({
    alt: 'BitPay Wallet',
    iconName: 'bitpay.svg',
    sourcePath: 'icons/wallets/bitpay.svg',
});
const BloctoIcon = createIconic({
    alt: 'Blocto Wallet',
    iconName: 'blocto',
    sourcePath: 'icons/wallets/blocto.svg',
});
const BraavosIcon = createIconic({
    alt: 'Braavos Wallet',
    iconName: 'braavos',
    sourcePath: 'icons/wallets/braavos.svg',
});
const Coin90Icon = createIconic({
    alt: 'Coin98 Wallet',
    iconName: 'coin98.svg',
    sourcePath: 'icons/wallets/coin98.svg',
});
const CoinbaseIcon = createIconic({
    alt: 'Coinbase Wallet',
    iconName: 'coinbase',
    sourcePath: 'icons/wallets/coinbase.svg',
});
const CryptoIcon = createIconic({
    alt: 'Crypto Wallet',
    iconName: 'crypto.svg',
    sourcePath: 'icons/wallets/crypto.svg',
});
const DapperIcon = createIconic({
    alt: 'Dapper Wallet',
    iconName: 'dapper',
    sourcePath: 'icons/wallets/dapper.svg',
});
const DawnIcon = createIconic({
    alt: 'Dawn Wallet',
    iconName: 'dawn',
    sourcePath: 'icons/wallets/dawn.svg',
});
const DefaultIcon = createIconic({
    alt: 'Default Wallet',
    iconName: 'defaultwallet',
    sourcePath: 'icons/wallets/default.svg',
});
const EmailSignIcon = createIconic({
    alt: 'Dapper Wallet',
    iconName: 'emailsign.svg',
    sourcePath: 'icons/wallets/emailsign.svg',
});
const SignInWithEmailIcon = createIconic({
    alt: 'Sign in with Email',
    iconName: 'signinwithemail',
    sourcePath: 'icons/wallets/signinwithemail.svg',
});
const ExodusIcon = createIconic({
    alt: 'Exodus Wallet',
    iconName: 'exodus',
    sourcePath: 'icons/wallets/exodus.svg',
});
const FireblocksIcon = createIconic({
    alt: 'Fireblocks Wallet',
    iconName: 'fireblocks.svg',
    sourcePath: 'icons/wallets/fireblocks.svg',
});
const FortmaticIcon = createIconic({
    alt: 'Fortmatic Wallet',
    iconName: 'fortmatic',
    sourcePath: 'icons/wallets/fortmatic.svg',
});
const FrameIcon = createIconic({
    alt: 'Frame Wallet',
    iconName: 'frame',
    sourcePath: 'icons/wallets/frame.svg',
});
const GamestopIcon = createIconic({
    alt: 'Gamestop Wallet',
    iconName: 'gamestop',
    sourcePath: 'icons/wallets/gamestop.svg',
});
const GlowIcon = createIconic({
    alt: 'Glow Wallet',
    iconName: 'glow',
    sourcePath: 'icons/wallets/glow.svg',
});
const ImTokenWallet = createIconic({
    alt: 'ImToken Wallet',
    iconName: 'imtoken.svg',
    sourcePath: 'icons/wallets/imtoken.svg',
});
const InjectedWalletIcon = createIconic({
    alt: 'Injected Wallet',
    iconName: 'injectedwallet',
    sourcePath: 'icons/wallets/injectedwallet.svg',
});
const KeplrIcon = createIconic({
    alt: 'Keplr Wallet',
    iconName: 'keplr',
    sourcePath: 'icons/wallets/keplr.svg',
});
const LedgerIcon = createIconic({
    alt: 'Ledger Wallet',
    iconName: 'ledger',
    sourcePath: 'icons/wallets/ledger.svg',
});
const LilicoIcon = createIconic({
    alt: 'Lilico Wallet',
    iconName: 'lilico',
    sourcePath: 'icons/wallets/lilico.svg',
});
const LinenIcon = createIconic({
    alt: 'Linen Wallet',
    iconName: 'linen.svg',
    sourcePath: 'icons/wallets/linen.svg',
});
const MaticIcon = createIconic({
    alt: 'Matic Wallet',
    iconName: 'matic',
    sourcePath: 'icons/wallets/matic.svg',
});
const MetaMaskIcon = createIconic({
    alt: 'MetaMask Wallet',
    iconName: 'metamask',
    sourcePath: 'icons/wallets/metamask.svg',
});
const MissingIcon = createIconic({
    alt: 'Missing Wallet',
    iconName: 'missing',
    sourcePath: 'icons/wallets/missing.svg',
});
const MyalgoIcon = createIconic({
    alt: 'MyAlgo Wallet',
    iconName: 'myalgo',
    sourcePath: 'icons/wallets/myalgo.svg',
});
const OmniIcon = createIconic({
    alt: 'Omni Wallet',
    iconName: 'omni',
    sourcePath: 'icons/wallets/omni.svg',
});
const OpeanSeaIcon = createIconic({
    alt: 'OpeanSea Wallet',
    iconName: 'opeansea',
    sourcePath: 'icons/wallets/opensea.svg',
});
const PeraIcon = createIconic({
    alt: 'Pera Wallet',
    iconName: 'pera',
    sourcePath: 'icons/wallets/pera.svg',
});
const PhantomIcon = createIconic({
    alt: 'Phantom Wallet',
    iconName: 'phantom',
    sourcePath: 'icons/wallets/phantom.svg',
});
const RainbowIcon = createIconic({
    alt: 'Rainbow Wallet',
    iconName: 'rainbow',
    sourcePath: 'icons/wallets/rainbow.svg',
});
const SafeIcon = createIconic({
    alt: 'Safe Wallet',
    iconName: 'safe',
    sourcePath: 'icons/wallets/safe.svg',
});
const SequenceIcon = createIconic({
    alt: 'Sequence Wallet',
    iconName: 'sequence',
    sourcePath: 'icons/wallets/sequence.svg',
});
const SlopeIcon = createIconic({
    alt: 'Slope Wallet',
    iconName: 'slope',
    sourcePath: 'icons/wallets/slope.svg',
});
const SolflareIcon = createIconic({
    alt: 'Solflare Wallet',
    iconName: 'solflare',
    sourcePath: 'icons/wallets/solflare.svg',
});
const SpotIcon = createIconic({
    alt: 'Spot Wallet',
    iconName: 'spot',
    sourcePath: 'icons/wallets/spot.svg',
});
const StrokeIcon = createIconic({
    alt: 'Stroke Wallet',
    iconName: 'stroke',
    sourcePath: 'icons/wallets/stroke.svg',
});
const TallyCashIcon = createIconic({
    alt: 'Taho Wallet',
    iconName: 'tallycash',
    sourcePath: 'icons/wallets/tallycash.svg',
});
const TerraIcon = createIconic({
    alt: 'Terra Wallet',
    iconName: 'terra',
    sourcePath: 'icons/wallets/terra.svg',
});
const TorusIcon = createIconic({
    alt: 'Torus Wallet',
    iconName: 'torus',
    sourcePath: 'icons/wallets/torus.svg',
});
const TrustIcon = createIconic({
    alt: 'Trust Wallet',
    iconName: 'trust',
    sourcePath: 'icons/wallets/trust.svg',
});
const UnstoppableIcon = createIconic({
    alt: 'Unstoppable Wallet',
    iconName: 'unstoppable',
    sourcePath: 'icons/wallets/unstoppable.svg',
});
const VenlyIcon = createIconic({
    alt: 'Venly Wallet',
    iconName: 'venly',
    sourcePath: 'icons/wallets/venly.svg',
});
const WalletConnectIcon = createIconic({
    alt: 'Wallet Connect Wallet',
    iconName: 'walletconnect',
    sourcePath: 'icons/wallets/walletconnect.svg',
});
const MagicLinkIcon = createIconic({
    alt: 'Magic Link Wallet',
    iconName: 'magiclink',
    sourcePath: 'icons/wallets/magic-link.svg',
});
const TurnkeyIcon = createIconic({
    alt: 'Turnkey',
    iconName: 'turnkey',
    sourcePath: 'icons/wallets/embedded-wallet.svg',
});
const ZengoIcon = createIconic({
    alt: 'Zengo Wallet',
    iconName: 'zengo',
    sourcePath: 'icons/wallets/zengo.svg',
});
const SuperbIcon = createIconic({
    alt: 'Superb Wallet',
    iconName: 'superb',
    sourcePath: 'icons/wallets/superb.svg',
});
const ZeroDevIcon = createIconic({
    alt: 'ZeroDev',
    iconName: 'zerodev',
    sourcePath: 'icons/wallets/embedded-wallet.svg',
});
const SmartWalletIcon = createIconic({
    alt: 'Smart Wallet',
    iconName: 'smartwallet',
    sourcePath: 'icons/wallets/smart-wallet.svg',
});
const MagicEdenIcon = createIconic({
    alt: 'Magic Eden',
    iconName: 'magiceden',
    sourcePath: 'icons/wallets/magiceden.svg',
});
const XverseEdenIcon = createIconic({
    alt: 'Xverse',
    iconName: 'xverse',
    sourcePath: 'icons/wallets/xverse.svg',
});
const UnisatIcon = createIconic({
    alt: 'Unisat',
    iconName: 'unisat',
    sourcePath: 'icons/wallets/unisat.svg',
});
const LeatherIcon = createIconic({
    alt: 'Leather',
    iconName: 'leather',
    sourcePath: 'icons/wallets/leather.svg',
});
const OkxIcon = createIconic({
    alt: 'OKX',
    iconName: 'okx',
    sourcePath: 'icons/wallets/okx.svg',
});
const CoinbaseWaaSIcon = createIconic({
    alt: 'Coinbase WaaS',
    iconName: 'coinbasewaas',
    sourcePath: 'icons/wallets/embedded-wallet.svg',
});

export { ArgentMobile, ArgentWebWallet, ArgentXIcon, BackpackIcon, BinanceIcon, BitPayIcon, BloctoIcon, BraavosIcon, Coin90Icon, CoinbaseIcon, CoinbaseWaaSIcon, CryptoIcon, DapperIcon, DawnIcon, DefaultIcon, EmailSignIcon, ExodusIcon, FireblocksIcon, FortmaticIcon, FrameIcon, GamestopIcon, GlowIcon, ImTokenWallet, InjectedWalletIcon, KeplrIcon, LeatherIcon, LedgerIcon, LilicoIcon, LinenIcon, MagicEdenIcon, MagicLinkIcon, MaticIcon, MetaMaskIcon, MissingIcon, MyalgoIcon, OkxIcon, OmniIcon, OneInchIcon, OpeanSeaIcon, PeraIcon, PhantomIcon, RainbowIcon, SafeIcon, SequenceIcon, SignInWithEmailIcon, SlopeIcon, SmartWalletIcon, SolflareIcon, SpotIcon, StrokeIcon, SuperbIcon, TallyCashIcon, TerraIcon, TorusIcon, TrustIcon, TurnkeyIcon, UnisatIcon, UnstoppableIcon, VenlyIcon, WalletConnectIcon, XverseEdenIcon, ZengoIcon, ZeroDevIcon };
