import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { getEmailProvider } from '../../../data/api.js';
import { allSupportedProviders } from '../constants.js';

const findEmailProvider = (environmentId, email) => __awaiter(void 0, void 0, void 0, function* () {
    const { emailProvider } = yield getEmailProvider({
        email,
        environmentId,
    });
    if (!allSupportedProviders.includes(emailProvider)) {
        throw new Error('Email provider is not supported');
    }
    return emailProvider;
});

export { findEmailProvider };
