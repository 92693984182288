import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import 'react';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import { isConnectOnly } from '../../utils/hooks/authenticationHooks/helpers/isConnectOnly.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../Alert/Alert.js';
import { Typography } from '../Typography/Typography.js';
import '../ShadowDOM/ShadowDOM.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const TosAndPp = () => {
    const { user, authMode, customPrivacyPolicy, customTermsOfServices, privacyPolicyUrl, termsOfServiceUrl, } = useInternalDynamicContext();
    const connectionType = isConnectOnly(user, authMode)
        ? 'connecting'
        : 'logging in';
    const renderTermsOfServices = () => {
        if (!termsOfServiceUrl) {
            return;
        }
        return (jsx("a", { className: 'tos-and-pp__link', href: termsOfServiceUrl, target: '_blank', rel: 'noreferrer', children: "Terms of Service" }));
    };
    const renderPrivacyPolicy = () => {
        if (!privacyPolicyUrl) {
            return;
        }
        return (jsxs(Fragment, { children: [termsOfServiceUrl && jsx(Fragment, { children: "\u00A0&\u00A0" }), jsx("a", { className: 'tos-and-pp__link', href: privacyPolicyUrl, target: '_blank', rel: 'noreferrer', children: "Privacy Policy" })] }));
    };
    return (jsxs(Fragment, { children: [(privacyPolicyUrl || termsOfServiceUrl) && (jsxs(Typography, { variant: 'body_small', weight: 'regular', className: 'tos-and-pp__text', children: ["By ", connectionType, ", you agree to", jsx("br", {}), "our\u00A0", renderTermsOfServices(), renderPrivacyPolicy(), "."] })), customTermsOfServices, customPrivacyPolicy] }));
};

export { TosAndPp };
