import { findWalletBookWallet } from '@dynamic-labs/wallet-book';
import InjectedWalletBase from './InjectedWalletBase.js';

// leaving ExodusEvm in here due to a backwards compatibility issue with this one wallet and v18
class ExodusEvm extends InjectedWalletBase {
    constructor(props) {
        super(props);
        this.name = 'ExodusEvm';
        this.walletConnectorFallback = true;
        this.wallet = findWalletBookWallet(this.walletBook, this.key);
    }
}

export { ExodusEvm };
