import { __awaiter } from '../_virtual/_tslib.js';
import { providers } from 'ethers';

const EthersExtension = {
    extend: (connector) => {
        const extensionSupportChain = ['ETH', 'EVM'];
        if (!extensionSupportChain.some((chain) => connector.supportedChains.includes(chain))) {
            return;
        }
        connector.ethers = {
            getRpcProvider: function () {
                var _a, _b;
                return __awaiter(this, void 0, void 0, function* () {
                    const publicClient = yield connector.getPublicClient();
                    const { chain, transport } = publicClient;
                    const network = {
                        chainId: chain === null || chain === void 0 ? void 0 : chain.id,
                        ensAddress: (_b = (_a = chain === null || chain === void 0 ? void 0 : chain.contracts) === null || _a === void 0 ? void 0 : _a.ensRegistry) === null || _b === void 0 ? void 0 : _b.address,
                        name: chain === null || chain === void 0 ? void 0 : chain.name,
                    };
                    if (transport.type === 'fallback')
                        return new providers.FallbackProvider(transport.transports.map(({ value }) => new providers.JsonRpcProvider(value === null || value === void 0 ? void 0 : value.url, network)));
                    return new providers.JsonRpcProvider(transport.url, network);
                });
            },
            getSigner: function () {
                return __awaiter(this, void 0, void 0, function* () {
                    const provider = yield connector.ethers.getWeb3Provider();
                    const address = yield connector.fetchPublicAddress();
                    const signer = provider.getSigner(address);
                    return signer;
                });
            },
            getWeb3Provider: function () {
                var _a, _b;
                return __awaiter(this, void 0, void 0, function* () {
                    const { chain } = yield connector.getPublicClient();
                    const walletClient = connector.getWalletClient();
                    const { transport } = walletClient !== null && walletClient !== void 0 ? walletClient : {};
                    if (!transport) {
                        throw new Error('Wallet is not connected, cannot create ethers client');
                    }
                    const network = {
                        chainId: chain === null || chain === void 0 ? void 0 : chain.id,
                        ensAddress: (_b = (_a = chain === null || chain === void 0 ? void 0 : chain.contracts) === null || _a === void 0 ? void 0 : _a.ensRegistry) === null || _b === void 0 ? void 0 : _b.address,
                        name: chain === null || chain === void 0 ? void 0 : chain.name,
                    };
                    const provider = new providers.Web3Provider(transport, network);
                    return provider;
                });
            },
        };
    },
    name: 'ethers-wallet-connector-extension',
};

export { EthersExtension };
