import { jsx, Fragment } from 'react/jsx-runtime';
import React, { useRef, useEffect } from 'react';
import { useConfig, useConnect, useDisconnect } from 'wagmi';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

const SyncDynamicWagmi = ({ children, connector, wallets, preventWagmiSyncFromCallingLogout, }) => {
    const { status: clientStatus } = useConfig();
    const { connect } = useConnect();
    const { disconnect } = useDisconnect();
    const { walletConnector } = useDynamicContext();
    const lastConnectedConnectorId = useRef(undefined);
    useEffect(() => {
        const isConnected = Boolean(lastConnectedConnectorId.current);
        /**
         * Disconnects from Wagmi when no more wallets are available
         * and returns early to prevent re-connecting to the same connector
         */
        if (!wallets.length) {
            /**
             * Check if the connector is already disconnected to prevent
             * disconnecting in a page transition on NextJS with multiple
             * layouts
             */
            if (isConnected) {
                /**
                 * This will prevent the Connector from calling handleLogOut when we are the ones calling disconnect,
                 * but will preserve the existing behavior for customers who use useDisconnect directly.
                 */
                preventWagmiSyncFromCallingLogout.current = true;
                disconnect();
                lastConnectedConnectorId.current = undefined;
            }
            return;
        }
        /**
         * Connects to Wagmi when a connector is available and
         * the connector has changed since the last connection
         */
        if (walletConnector &&
            connector &&
            (connector === null || connector === void 0 ? void 0 : connector.id) !== lastConnectedConnectorId.current) {
            lastConnectedConnectorId.current = connector.id;
            connect({ connector });
        }
    }, [
        clientStatus,
        connect,
        connector,
        disconnect,
        lastConnectedConnectorId,
        preventWagmiSyncFromCallingLogout,
        walletConnector,
        wallets,
    ]);
    if (React.version.startsWith('18')) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return jsx(Fragment, { children: children });
    }
    // use React.createElement to prevent bunding react/jsx-runtime,
    // which is not compatible when bundling apps using React 17
    return React.createElement(React.Fragment, null, children);
};

export { SyncDynamicWagmi };
