/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var SocialSignInProviderEnum;
(function (SocialSignInProviderEnum) {
    SocialSignInProviderEnum["Apple"] = "apple";
    SocialSignInProviderEnum["Bitbucket"] = "bitbucket";
    SocialSignInProviderEnum["Coinbasesocial"] = "coinbasesocial";
    SocialSignInProviderEnum["Discord"] = "discord";
    SocialSignInProviderEnum["Facebook"] = "facebook";
    SocialSignInProviderEnum["Github"] = "github";
    SocialSignInProviderEnum["Gitlab"] = "gitlab";
    SocialSignInProviderEnum["Google"] = "google";
    SocialSignInProviderEnum["Instagram"] = "instagram";
    SocialSignInProviderEnum["Linkedin"] = "linkedin";
    SocialSignInProviderEnum["Microsoft"] = "microsoft";
    SocialSignInProviderEnum["Twitch"] = "twitch";
    SocialSignInProviderEnum["Twitter"] = "twitter";
})(SocialSignInProviderEnum || (SocialSignInProviderEnum = {}));
function SocialSignInProviderEnumFromJSON(json) {
    return SocialSignInProviderEnumFromJSONTyped(json);
}
function SocialSignInProviderEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function SocialSignInProviderEnumToJSON(value) {
    return value;
}

export { SocialSignInProviderEnum, SocialSignInProviderEnumFromJSON, SocialSignInProviderEnumFromJSONTyped, SocialSignInProviderEnumToJSON };
