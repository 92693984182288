import { jsx, jsxs } from 'react/jsx-runtime';
import { useState } from 'react';
import { AccountControl } from '../AccountControl/AccountControl.js';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import { ShadowDOM } from '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import { IsBrowser } from '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import { PopperProvider } from '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import { NetworkPicker } from '../../../../components/NetworkPicker/NetworkPicker.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../DynamicWidgetHeader/DynamicWidgetHeader.js';
import { useWidgetContext } from '../../context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const DynamicNav = () => {
    const [isNetworkPickerOpen, setIsNetworkPickerOpen] = useState(false);
    const { primaryWallet, projectSettings, network } = useInternalDynamicContext();
    const { inlineControlsRef } = useWidgetContext();
    const evmNetworks = ((primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector) &&
        primaryWallet.connector.evmNetworks) ||
        [];
    if (!projectSettings)
        return null;
    const shouldHideNetwork = projectSettings.sdk.hideNetworkInDynamicWidget;
    return (jsx(IsBrowser, { children: jsxs("div", { ref: inlineControlsRef, "data-testid": 'DynamicNav', className: 'dynamic-widget-inline-controls', children: [!shouldHideNetwork && primaryWallet && (jsx(PopperProvider, { children: jsx(NetworkPicker, { currentNetwork: network, connector: primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector, showNetworkName: true, isNetworkPickerOpen: isNetworkPickerOpen, setIsNetworkPickerOpen: setIsNetworkPickerOpen, mainClassName: 'dynamic-widget-inline-controls__network-picker-main', buttonClassName: 'dynamic-widget-inline-controls__network-picker', listClassName: 'dynamic-widget-inline-controls__network-picker-list', checkboxClassName: 'dynamic-widget-inline-controls__network-picker-checkbox', evmNetworks: evmNetworks }) })), jsx("div", { className: 'dynamic-widget-inline-controls__account-control-container', children: jsx(AccountControl, { className: 'dynamic-widget-inline-controls__account-control' }) })] }) }));
};
const ShadowedDynamicNav = () => (jsx(ShadowDOM, { children: jsx(DynamicNav, {}) }));

export { DynamicNav, ShadowedDynamicNav };
