import { jsxs, jsx } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { usePasskeyContext } from '../../context/PasskeyContext/PasskeyContext.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import 'react';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '@dynamic-labs/utils';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import { ReactComponent as SvgClose } from '../../shared/assets/close.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import { usePasskeyRecovery } from '../../utils/hooks/usePasskeyRecovery/usePasskeyRecovery.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../Alert/Alert.js';
import { Typography } from '../Typography/Typography.js';
import '../ShadowDOM/ShadowDOM.js';
import { IconButton } from '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const SecureTurnkeyWalletCard = ({ className }) => {
    const { t } = useTranslation();
    const { initPasskeyRecoveryProcess } = usePasskeyRecovery();
    const { showSecureAccountBanner, setShowSecureAccountBanner } = usePasskeyContext();
    if (!showSecureAccountBanner)
        return null;
    return (jsxs("div", { className: classNames('secure-turnkey-wallet-card', className), children: [jsxs("div", { className: 'secure-turnkey-wallet-card__text-content', "data-testid": 'secure-wallet-button', onClick: () => initPasskeyRecoveryProcess(), children: [jsx(Typography, { variant: 'body_small', color: 'primary', copykey: 'dyn_secure_pregenerated_wallet.description', children: t('dyn_secure_pregenerated_wallet.description') }), jsx(Typography, { variant: 'body_small', weight: 'bold', color: 'brand-primary', copykey: 'dyn_secure_pregenerated_wallet.action', className: 'secure-turnkey-wallet-card__link', children: t('dyn_secure_pregenerated_wallet.action') })] }), jsx("div", { children: jsx(IconButton, { color: 'text-secondary', onClick: () => setShowSecureAccountBanner(false), children: jsx(SvgClose, { width: 16, height: 16 }) }) })] }));
};

export { SecureTurnkeyWalletCard };
