import { jsx } from 'react/jsx-runtime';
import React__default from 'react';
import { useViewContext } from '../ViewContext/ViewContext.js';
import { getWalletGroupType } from '../../utils/functions/getWalletGroupType/getWalletGroupType.js';

const WalletGroupContext = React__default.createContext(undefined);
const useWalletGroupContext = () => {
    const context = React__default.useContext(WalletGroupContext);
    if (context === undefined) {
        throw new Error('usage of useWalletGroupContext not wrapped in `WalletGroupContextProvider`.');
    }
    return context;
};
const WalletGroupContextProvider = ({ children, }) => {
    const [selectedWalletGroup, setSelectedWalletGroup] = React__default.useState();
    const { setView } = useViewContext();
    const navigateToWalletGroup = React__default.useCallback((walletGroup) => {
        setSelectedWalletGroup(walletGroup);
        const walletGroupType = getWalletGroupType(walletGroup);
        const mapGroupTypeToView = {
            'multi-chain': 'wallet-group',
            'multi-wallet': 'select-wallet-in-wallet-group',
        };
        const view = walletGroupType
            ? mapGroupTypeToView[walletGroupType]
            : mapGroupTypeToView['multi-chain'];
        setView(view);
    }, [setSelectedWalletGroup, setView]);
    const contextValue = {
        navigateToWalletGroup,
        selectedWalletGroup,
    };
    return (jsx(WalletGroupContext.Provider, { value: contextValue, children: children }));
};

export { WalletGroupContext, WalletGroupContextProvider, useWalletGroupContext };
