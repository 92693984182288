import { jsx } from 'react/jsx-runtime';
import { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useInternalDynamicEvents } from '../../utils/hooks/events/useDynamicEvents/useDynamicEvents.js';

const ViewContext = createContext(undefined);
const ViewContextProvider = ({ children, initialView = { props: { isWalletConnectList: true }, type: 'wallet-list' }, }) => {
    const [_view, _setView] = useState(null);
    const view = _view || initialView;
    const goToInitialView = useCallback(() => {
        _setView(null);
    }, []);
    const setView = useCallback((type, props) => {
        _setView({ props, type });
    }, []);
    useInternalDynamicEvents('logout', () => goToInitialView());
    const value = useMemo(() => ({
        goToInitialView,
        setView,
        view,
    }), [goToInitialView, setView, view]);
    return jsx(ViewContext.Provider, { value: value, children: children });
};
const useViewContext = () => {
    const context = useContext(ViewContext);
    if (context === undefined) {
        throw new Error('usage of useViewContext not wrapped in `ViewContextProvider`.');
    }
    return context;
};

export { ViewContext, ViewContextProvider, useViewContext };
