import './polyfills.js';
import { TurnkeyWalletConnectors } from '@dynamic-labs/turnkey';
export { PhantomEvm } from './injected/PhantomEvm.js';
export { ExodusEvm } from './injected/ExodusEvm.js';
import { injectedWalletOverrides, fetchInjectedWalletConnector } from './injected/fetchInjectedWalletConnectors.js';
export { fetchInjectedWalletConnector, injectedWalletOverrides } from './injected/fetchInjectedWalletConnectors.js';
import './walletConnect/walletConnectV2.js';
import '../_virtual/_tslib.js';
import '@walletconnect/ethereum-provider';
import 'viem';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
export { EthWalletConnector } from './EthWalletConnector.js';
export { INFURA_ID } from './constants.js';
import '@walletconnect/client';
import { fetchWalletConnectWallets, getWalletConnectConnector } from './walletConnect/fetchWalletConnectWallets.js';
import { Coinbase } from './coinbase/coinbase.js';
export { EthProviderHelper } from './ethProviderHelper.js';

const EthereumWalletConnectors = (props) => [
    ...injectedWalletOverrides,
    ...fetchInjectedWalletConnector(props),
    ...fetchWalletConnectWallets(props),
    ...TurnkeyWalletConnectors(props),
    Coinbase,
    getWalletConnectConnector(props),
];

export { EthereumWalletConnectors };
