import { exists } from '../runtime.js';

/* tslint:disable */
function CoinbaseMpcWalletPropertiesFromJSON(json) {
    return CoinbaseMpcWalletPropertiesFromJSONTyped(json);
}
function CoinbaseMpcWalletPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backup': !exists(json, 'backup') ? undefined : json['backup'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'claimed': !exists(json, 'claimed') ? undefined : json['claimed'],
    };
}
function CoinbaseMpcWalletPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backup': value.backup,
        'password': value.password,
        'claimed': value.claimed,
    };
}

export { CoinbaseMpcWalletPropertiesFromJSON, CoinbaseMpcWalletPropertiesFromJSONTyped, CoinbaseMpcWalletPropertiesToJSON };
