import { __awaiter } from '../../_virtual/_tslib.js';
import { Connector as Connector$1 } from 'wagmi';
import { getAddress, createWalletClient, custom, toHex } from 'viem';

class Connector extends Connector$1 {
    constructor({ chains, handleLogOut, walletConnector, preventWagmiSyncFromCallingLogout, }) {
        super({ chains, options: undefined });
        // wagmi properties
        this.id = 'dynamic';
        this.name = 'Dynamic';
        // also a wagmi prop, but is only used for auto-connect
        // which we have implemented ourselves, so this value
        // doesn't matter
        this.ready = false;
        this.onAccountsChanged = (accounts) => __awaiter(this, void 0, void 0, function* () {
            this.emit('change', {
                account: getAddress(accounts[0]),
            });
        });
        this.onChainChanged = (chain) => __awaiter(this, void 0, void 0, function* () {
            this.emit('change', {
                chain: { id: Number(chain), unsupported: false },
            });
        });
        this.onDisconnect = () => __awaiter(this, void 0, void 0, function* () { });
        this.handleLogOut = handleLogOut;
        this.walletConnector = walletConnector;
        this.id = `dynamic-${walletConnector.key}`;
        this.preventWagmiSyncFromCallingLogout = preventWagmiSyncFromCallingLogout;
    }
    _onAccountsChanged({ accounts }) {
        this.onAccountsChanged(accounts);
    }
    _onChainChanged({ chain }) {
        this.onChainChanged(chain);
    }
    _onDisconnect() {
        this.onDisconnect();
    }
    setupEventListeners() {
        this.walletConnector.on('accountChange', this._onAccountsChanged, this);
        this.walletConnector.on('chainChange', this._onChainChanged, this);
        this.walletConnector.on('disconnect', this._onDisconnect, this);
    }
    connect(config) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.walletConnector) {
                throw new Error('WalletConnector is not defined');
            }
            const account = yield this.getAccount();
            this.setupEventListeners();
            this.emit('message', { type: 'connecting' });
            return {
                account,
                chain: { id: yield this.getChainId(), unsupported: false },
            };
        });
    }
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            this.walletConnector.removeListener('accountChange', this._onAccountsChanged, this);
            this.walletConnector.removeListener('chainChange', this._onChainChanged, this);
            this.walletConnector.removeListener('disconnect', this._onDisconnect, this);
            if (!this.preventWagmiSyncFromCallingLogout.current) {
                yield this.walletConnector.endSession();
                yield this.handleLogOut();
            }
            /**
             * In our own Dynamic Wagmi sync component, we will flip the flag to true before calling disconnect,
             * and here it automatically flips back to false afterwards. This will prevent the Connector from
             * calling handleLogOut when we are the ones calling disconnect, but will preserve the existing behavior
             * for customers who use useDisconnect directly.
             */
            this.preventWagmiSyncFromCallingLogout.current = false;
        });
    }
    getAccount() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const address = yield ((_a = this.walletConnector) === null || _a === void 0 ? void 0 : _a.fetchPublicAddress());
            if (!address) {
                throw new Error('Not connected');
            }
            return getAddress(address);
        });
    }
    getChainId() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.walletConnector) {
                throw new Error('WalletConnector is not defined');
            }
            const network = yield this.walletConnector.getNetwork();
            if (!network) {
                throw new Error('Network is not defined');
            }
            return Number(network);
        });
    }
    getProvider(config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return (_a = this.walletConnector) === null || _a === void 0 ? void 0 : _a.getWalletClient();
        });
    }
    getWalletClient(config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const signer = yield this.walletConnector.getSigner();
            if (signer.account && signer.chain) {
                return signer;
            }
            const walletClient = createWalletClient({
                account: yield this.getAccount(),
                chain: (_a = this.chains.find((chain) => chain.id === (config === null || config === void 0 ? void 0 : config.chainId))) !== null && _a !== void 0 ? _a : this.chains[0],
                transport: custom(signer),
            });
            return walletClient;
        });
    }
    isAuthorized() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = (_b = (yield ((_a = this.walletConnector) === null || _a === void 0 ? void 0 : _a.getConnectedAccounts()))) !== null && _b !== void 0 ? _b : [];
            return accounts.length > 0;
        });
    }
    switchChain(chainId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            yield ((_a = this.walletConnector) === null || _a === void 0 ? void 0 : _a.switchNetwork({ networkChainId: chainId }));
            const id = toHex(chainId);
            return ((_b = this.chains.find((x) => x.id === chainId)) !== null && _b !== void 0 ? _b : {
                id: chainId,
                name: `Chain ${id}`,
                nativeCurrency: { decimals: 18, name: 'Ether', symbol: 'ETH' },
                network: `${id}`,
                rpcUrls: { default: { http: [''] }, public: { http: [''] } },
            });
        });
    }
    watchAsset(asset) {
        throw new Error('Method not implemented.');
    }
}

export { Connector };
