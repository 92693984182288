import { z } from 'zod';
import { nonEmptyString } from './utils/nonEmptyString.js';
import { brandSchema } from './walletSchema.js';

const WalletOverrideEntrySchema = z.object({
    brand: brandSchema.optional(),
    name: nonEmptyString,
});
const walletGroupSchema = z.object({
    brand: z
        .object({
        alt: nonEmptyString,
        primaryColor: nonEmptyString,
        spriteId: nonEmptyString,
    })
        .optional(),
    key: z.string(),
    name: z.string(),
    walletOverrides: z.record(WalletOverrideEntrySchema).optional(),
});

export { walletGroupSchema };
