import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWalletBookContext } from '@dynamic-labs/wallet-book';
import { ErrorContainer } from '../../components/ErrorContainer/ErrorContainer.js';
import { Search } from '../../components/Search/Search.js';
import { Skeleton } from '../../components/Skeleton/Skeleton.js';
import { useErrorContext } from '../../context/ErrorContext/ErrorContext.js';
import { WALLET_PICKER_SEARCH_KEY, LAST_USED_WALLET } from '../../utils/constants/localStorage.js';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import { walletListBuilder } from '../../utils/functions/walletListBuilder/walletListBuilder.js';
import { classNames } from '../../utils/functions/classNames/classNames.js';
import '../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';
import { LocalStorage } from '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../shared/consts/index.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../../components/Alert/Alert.js';
import '../../components/ShadowDOM/ShadowDOM.js';
import '../../components/IconButton/IconButton.js';
import '../../components/InlineWidget/InlineWidget.js';
import '../../components/IsBrowser/IsBrowser.js';
import '../../components/MenuList/Dropdown/Dropdown.js';
import '../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../components/Popper/Popper/Popper.js';
import '../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import { ReactComponent as SvgNoWalletFound } from '../../assets/no-wallet-found.js';
import 'formik';
import 'i18next';
import '../../components/OverlayCard/OverlayCard.context.js';
import '../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { DefaultFooter } from '../../layout/DynamicAuthLayout/DefaultFooter/DefaultFooter.js';
import { useAuthLayoutChecks } from '../../utils/hooks/useAuthLayoutChecks/useAuthLayoutChecks.js';
import { WalletListItem } from './WalletListItem/WalletListItem.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useViewSettings } from '../../utils/hooks/useViewSettings/useViewSettings.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';
import { FilterBridgeChainsName } from '../../utils/functions/walletFilters/index.js';
import { isUserLinkingWallet } from '../../shared/utils/functions/isUserLinkingWallet/isUserLinkingWallet.js';
import { SearchNotFoundMessage } from './SearchNotFoundMessage/SearchNotFoundMessage.js';
import { WalletListGridTabs } from './WalletListGridTabs/WalletListGridTabs.js';

LocalStorage.setToLS(WALLET_PICKER_SEARCH_KEY, '');
const WalletList = ({ isWalletConnectList = false, }) => {
    var _a, _b;
    const { walletsFilter, signWithEmailWalletName, walletConnectorOptions, projectSettings, defaultNumberOfWalletsToShow, bridgeChainsToConnect, connectedWallets, authMode, multiWallet, user, } = useInternalDynamicContext();
    const { view } = useViewContext();
    const [selectedTabIndex, setSelectedTabIndex] = useState(null);
    const isLinkingWallet = isUserLinkingWallet(user, view.type, false);
    const walletListViewSettings = useViewSettings('wallet-list');
    const selectedTabSettings = selectedTabIndex === null
        ? undefined
        : (_a = walletListViewSettings === null || walletListViewSettings === void 0 ? void 0 : walletListViewSettings.tabs) === null || _a === void 0 ? void 0 : _a.items[selectedTabIndex];
    const { walletBook } = useWalletBookContext();
    const { error } = useErrorContext();
    const { showDefaultFooter } = useAuthLayoutChecks();
    const [footerBorderIsVisible, setFooterBorderIsVisible] = useState(true);
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState((_b = LocalStorage.getFromLS(WALLET_PICKER_SEARCH_KEY)) !== null && _b !== void 0 ? _b : '');
    const getFilteredBridgeChains = (bridgeChainsToConnect === null || bridgeChainsToConnect === void 0 ? void 0 : bridgeChainsToConnect.length) &&
        FilterBridgeChainsName(bridgeChainsToConnect[0].chain);
    const filteredBridgeChains = getFilteredBridgeChains && getFilteredBridgeChains(walletConnectorOptions);
    const effectiveWalletFilter = useMemo(() => {
        if (selectedTabSettings && (selectedTabSettings === null || selectedTabSettings === void 0 ? void 0 : selectedTabSettings.walletsFilter)) {
            return selectedTabSettings === null || selectedTabSettings === void 0 ? void 0 : selectedTabSettings.walletsFilter;
        }
        return walletsFilter;
    }, [selectedTabSettings, walletsFilter]);
    const { numberOfWallets, walletsList } = walletListBuilder({
        authMode,
        connectedWallets,
        groupWallets: true,
        inputList: filteredBridgeChains || walletConnectorOptions,
        isWalletConnectList,
        lastUsedWalletKey: LocalStorage.getFromLS(LAST_USED_WALLET),
        multiWallet,
        numberOfWalletsToShow: defaultNumberOfWalletsToShow,
        searchFilter: filterValue,
        showMoreWalletsWithFilter: true,
        signWithEmailWalletName,
        walletBook,
        walletsFilter: effectiveWalletFilter,
    });
    const isSearchEnabled = numberOfWallets > defaultNumberOfWalletsToShow &&
        walletsList.length !== numberOfWallets;
    const walletListScrollRef = useRef(null);
    /* istanbul ignore next */
    const handleScroll = () => {
        const element = walletListScrollRef.current;
        if (!element) {
            return;
        }
        if ((element === null || element === void 0 ? void 0 : element.scrollTop) > (element === null || element === void 0 ? void 0 : element.clientHeight) * 1.25) {
            setFooterBorderIsVisible(false);
        }
        else {
            setFooterBorderIsVisible(true);
        }
    };
    const handleFilterValueChange = (value) => {
        LocalStorage.setToLS(WALLET_PICKER_SEARCH_KEY, value);
        setFilterValue(value);
    };
    const searchContainer = !projectSettings ? (jsx(Skeleton, { className: 'wallet-list__search-skeleton' })) : (isSearchEnabled && (jsx("div", { className: classNames('wallet-list__search-container', {
            'wallet-list__search-container--scroll': !error,
        }), children: jsx(Search, { copykey: 'dyn_wallet_list.search.label', label: t('dyn_wallet_list.search.label', { numberOfWallets }), value: filterValue, onChange: ({ target: { value } }) => handleFilterValueChange(value), onClickClear: () => handleFilterValueChange('') }) })));
    return (jsxs(Fragment, { children: [(walletListViewSettings === null || walletListViewSettings === void 0 ? void 0 : walletListViewSettings.tabs) && !isLinkingWallet && (jsx("div", { className: 'wallet-list__tabs-container', children: jsx(WalletListGridTabs, { items: walletListViewSettings.tabs.items, value: selectedTabIndex || 0, onSelect: setSelectedTabIndex }) })), searchContainer, Boolean(error) && (jsx(ErrorContainer, { className: 'wallet-list__error-container', withIcon: false, children: error })), jsx("div", { className: 'wallet-list__container', children: jsxs("div", { className: classNames('wallet-list__scroll-container', {
                        'wallet-list__scroll-container--error': Boolean(error),
                    }), "data-testid": 'wallet-list-scroll-container', ref: walletListScrollRef, onScroll: handleScroll, children: [!projectSettings ? (jsx(Skeleton, { count: 10, className: 'wallet-list__tile-skeleton' })) : (jsxs(Fragment, { children: [walletConnectorOptions.length === 0 && (jsx(ErrorContainer, { copykey: 'dyn_wallet_list.configuration_mismatch', children: t('dyn_wallet_list.configuration_mismatch') })), walletConnectorOptions.length && walletsList.length === 0 ? (jsx(SearchNotFoundMessage, { title: t('dyn_wallet_list.search.not_found.title'), subtitle: t('dyn_wallet_list.search.not_found.description'), image: jsx(SvgNoWalletFound, {}) })) : (walletsList.map((wallet, index) => (jsx(WalletListItem, { wallet: wallet, onResetSearchValue: () => handleFilterValueChange('') }, `${wallet.key}_${index}`))))] })), isSearchEnabled && !filterValue && projectSettings && (jsx(SearchNotFoundMessage, {}))] }) }), showDefaultFooter && (jsx(DefaultFooter, { hideBorder: !footerBorderIsVisible }))] }));
};

export { WalletList };
