import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { useContext, useCallback } from 'react';
import { DynamicError } from '@dynamic-labs/utils';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../constants/colors.js';
import '../../../constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { dynamicEvents } from '../../../../events/dynamicEvents.js';
import '../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../useUpdateUser/useUpdateUser.js';
import { UserFieldEditorContext } from '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import { validateUpdateRequest } from './validateUpdateRequest/validateUpdateRequest.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

/** Saves the values updated by the user */
const saveUserValues = ({ fields: newValues, updateUserResult: { isEmailVerificationRequired }, }, setShowAuthFlow, setView, resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
    // If no verification is needed, we are done
    if (!isEmailVerificationRequired) {
        resolve(newValues);
        return;
    }
    // Show email verification view
    setShowAuthFlow(true, {
        ignoreIfIsEmbeddedWidget: false,
        performMultiWalletChecks: false,
    });
    setView('verify-email');
    // Subscribe to email verification result
    dynamicEvents.once('emailVerificationResult', (wasSuccessful, newEmail) => {
        // Make sure we're using the correct, updated email. User might have changed it mid-OTP
        newValues.email = newEmail;
        wasSuccessful
            ? resolve(newValues)
            : reject('Failed to verify email, no fields were updated');
    });
});
// eslint-disable-next-line multiline-comment-style
/** This hook allows to open the widget modal and expose any fields for editing.
 * The fields must have been previously enabled in the dashboard
 */
const useUpdateUserWithModal = (updateUser) => {
    const { user, projectSettings, setShowAuthFlow } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const editorContext = useContext(UserFieldEditorContext);
    if (!editorContext)
        throw new DynamicError('useUserUpdateRequest can only be used inside the context of DynamicContextProvider');
    const { open: promptUserForValues } = editorContext;
    const updateUserWithModal = useCallback((fields, options) => new Promise((resolve, reject) => {
        // Catch invalid requests
        const errorResponse = validateUpdateRequest(fields, user, projectSettings);
        if (errorResponse) {
            reject(errorResponse);
            return;
        }
        // Get user values
        promptUserForValues({
            fields,
            submitText: options === null || options === void 0 ? void 0 : options.submitText,
            subtitle: options === null || options === void 0 ? void 0 : options.subtitle,
            title: options === null || options === void 0 ? void 0 : options.title,
        }, updateUser)
            .then((updateValues) => saveUserValues(updateValues, setShowAuthFlow, setView, resolve, reject))
            .catch((error) => {
            if (/email already exists/gi.test(error))
                throw error;
            reject(error);
        });
    }), [
        user,
        projectSettings,
        promptUserForValues,
        updateUser,
        setShowAuthFlow,
        setView,
    ]);
    return updateUserWithModal;
};

export { useUpdateUserWithModal };
