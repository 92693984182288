import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

/* eslint-disable prefer-destructuring */
const getProvider = (dynamicChain, rpcUrl) => {
    let http;
    let webSocket;
    if (typeof rpcUrl !== 'string') {
        http = rpcUrl.http;
        webSocket = rpcUrl.webSocket;
    }
    else {
        http = rpcUrl;
    }
    return (chain) => {
        if (chain.id !== dynamicChain.chainId)
            return null;
        return jsonRpcProvider({
            rpc: () => ({
                http,
                webSocket,
            }),
        })(chain);
    };
};
const getWagmiProvidersFromDynamicChains = (dynamicChains) => {
    const wagmiProviders = [];
    dynamicChains.forEach((dynamicChain) => {
        var _a;
        ((_a = dynamicChain.privateCustomerRpcUrls) !== null && _a !== void 0 ? _a : []).forEach((rpcUrl) => {
            wagmiProviders.push(getProvider(dynamicChain, rpcUrl));
        });
    });
    return [...wagmiProviders, publicProvider()];
};

export { getWagmiProvidersFromDynamicChains };
