import { useState, useCallback, useEffect } from 'react';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../utils/constants/colors.js';
import '../../../utils/constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import '../../../../../_virtual/_tslib.js';
import '@dynamic-labs/multi-wallet';
import { getAuthToken } from '../../../utils/functions/getAuthToken/getAuthToken.js';
import 'viem/chains';

const useCustomerCallbacks = ({ callbacks: { onAuthSuccess, onLinkSuccess, onUserProfileUpdate, onEmbeddedWalletCreated, }, handleLogOut, primaryWallet, secondaryWallets, user, isAuthenticated, setIsVerificationInProgress, }) => {
    const [callbackQueue, setCallbackQueue] = useState([]);
    /** Gets the wallet from its id */
    const getWallet = useCallback((id) => {
        var _a;
        return id
            ? (_a = [primaryWallet, ...secondaryWallets].find((wallet) => (wallet === null || wallet === void 0 ? void 0 : wallet.id) === id)) !== null && _a !== void 0 ? _a : undefined
            : undefined;
    }, [primaryWallet, secondaryWallets]);
    const handleNextCallback = useCallback((next, authToken, user) => {
        var _a, _b;
        switch (next === null || next === void 0 ? void 0 : next.callback) {
            case 'authSuccess': {
                try {
                    setIsVerificationInProgress(false);
                    onAuthSuccess === null || onAuthSuccess === void 0 ? void 0 : onAuthSuccess({
                        authToken,
                        handleLogOut,
                        isAuthenticated,
                        primaryWallet,
                        user,
                        walletConnector: primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector,
                    });
                }
                catch (e) {
                    logger.error('Error calling onAuthSuccess: ', e);
                }
                break;
            }
            case 'linkSuccess': {
                try {
                    const wallet = getWallet((_a = next.params) === null || _a === void 0 ? void 0 : _a.walletId);
                    onLinkSuccess === null || onLinkSuccess === void 0 ? void 0 : onLinkSuccess({
                        authToken,
                        user,
                        wallet,
                        walletConnector: wallet === null || wallet === void 0 ? void 0 : wallet.connector,
                    });
                }
                catch (e) {
                    logger.error('Error calling onLinkSuccess: ', e);
                }
                break;
            }
            case 'userProfileUpdate': {
                try {
                    onUserProfileUpdate === null || onUserProfileUpdate === void 0 ? void 0 : onUserProfileUpdate(user);
                }
                catch (e) {
                    logger.error('Error calling userProfileUpdate: ', e);
                }
                break;
            }
            case 'embeddedWalletCreated': {
                try {
                    onEmbeddedWalletCreated === null || onEmbeddedWalletCreated === void 0 ? void 0 : onEmbeddedWalletCreated((_b = next.params) === null || _b === void 0 ? void 0 : _b.verifiedCredential);
                }
                catch (e) {
                    logger.error('Error calling onEmbeddedWalletCreated: ', e);
                }
            }
        }
    }, [
        getWallet,
        handleLogOut,
        isAuthenticated,
        onAuthSuccess,
        onEmbeddedWalletCreated,
        onLinkSuccess,
        onUserProfileUpdate,
        primaryWallet,
        setIsVerificationInProgress,
    ]);
    useEffect(() => {
        if (!callbackQueue.length || !user)
            return;
        const authToken = getAuthToken();
        // checks if user logged in with a wallet, so we need to wait
        // for the primary wallet state to be set
        // if user logged in with email without embedded wallet, then
        // there is no wallet so we can go ahead and run the callback
        if (!authToken || (user.isAuthenticatedWithAWallet && !primaryWallet)) {
            return;
        }
        const updatedQueue = [...callbackQueue];
        handleNextCallback(updatedQueue.shift(), authToken, user);
        setCallbackQueue(updatedQueue);
    }, [callbackQueue, handleNextCallback, primaryWallet, user]);
    const setCallback = (callback, params) => {
        setCallbackQueue((prev) => [...prev, { callback, params }]);
    };
    return { setCallback };
};

export { useCustomerCallbacks };
