import { __awaiter } from '../../../_virtual/_tslib.js';
import { IframeStamper } from '@turnkey/iframe-stamper';
import { TurnkeyClient } from '@turnkey/http';
import { DynamicError } from '@dynamic-labs/utils';
import { logger } from '@dynamic-labs/wallet-connector-core';

const turnkeyBaseUrl = 'https://api.turnkey.com';
const turnkeyPasskeyRecoveryUrl = 'https://recovery.turnkey.com';
const turnkeyEmailRecoveryUrl = 'https://auth.turnkey.com';
const TURNKEY_RECOVERY_CREDENTIAL_EXPIRATION_SECONDS = 900; // 15 seconds
const TURNKEY_SESSION_EXPIRATION_SECONDS = 1800; //30 seconds
const EMAIL_AUTH_CREDENTIAL_TYPE = 'CREDENTIAL_TYPE_API_KEY_P256';
const PASSKEY_RECOVERY_CREDENTIAL_TYPE = 'CREDENTIAL_TYPE_RECOVER_USER_KEY_P256';
class TurnkeyAuthenticatorRecoveryHandler {
    constructor() {
        this.isSessionActive = () => {
            // it's only used for email auth session
            if (!this.__createdAt || this.__recoveryType !== 'email') {
                return false;
            }
            const isExpired = this.isExpired(this.__createdAt, this.__sessionExpiration || TURNKEY_SESSION_EXPIRATION_SECONDS);
            if (isExpired) {
                this.clear();
                return false;
            }
            return true;
        };
        this.isValidCode = (organizationId) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (!organizationId || !this.__turnkeyRecoveryUserId) {
                throw new DynamicError('Cannot proceed with your request');
            }
            const userResponse = yield ((_a = this.__client) === null || _a === void 0 ? void 0 : _a.getUser({
                organizationId,
                userId: this.__turnkeyRecoveryUserId,
            }));
            const credentialTypeMap = {
                email: EMAIL_AUTH_CREDENTIAL_TYPE,
                passkey: PASSKEY_RECOVERY_CREDENTIAL_TYPE,
            };
            const recoveryCredential = (_d = (_c = (_b = userResponse === null || userResponse === void 0 ? void 0 : userResponse.user) === null || _b === void 0 ? void 0 : _b.apiKeys) === null || _c === void 0 ? void 0 : _c.filter((k) => k.credential.type === credentialTypeMap[this.__recoveryType])) === null || _d === void 0 ? void 0 : _d.pop();
            if (!recoveryCredential) {
                return false;
            }
            const isExpired = this.isExpired(parseInt(recoveryCredential.createdAt.seconds, 10), TURNKEY_RECOVERY_CREDENTIAL_EXPIRATION_SECONDS);
            if (isExpired) {
                return false;
            }
            this.__createdAt = parseInt(recoveryCredential.createdAt.seconds, 10);
            return true;
        });
        this.isExpired = (createdAtSeconds, expirationTimeSeconds) => {
            const recoveryExpirationSeconds = createdAtSeconds + expirationTimeSeconds;
            const expirationTime = new Date(recoveryExpirationSeconds * 1000);
            if (new Date() >= expirationTime) {
                return true;
            }
            return false;
        };
    }
    get publicKey() {
        return this.__publicKey;
    }
    get client() {
        return this.__client;
    }
    get recoveryType() {
        return this.__recoveryType;
    }
    get recoveryUserId() {
        return this.__turnkeyRecoveryUserId || '';
    }
    set recoveryUserId(turnkeyRecoveryUserId) {
        this.__turnkeyRecoveryUserId = turnkeyRecoveryUserId;
    }
    clear() {
        var _a;
        (_a = this.__iframeStamper) === null || _a === void 0 ? void 0 : _a.clear();
        this.__recoveryType = undefined;
        this.__iframeStamper = undefined;
        this.__publicKey = undefined;
        this.__client = undefined;
        this.__turnkeyRecoveryUserId = undefined;
        this.__createdAt = undefined;
    }
    initRecovery(authType, iframeContainer, iframeElementId, sessionExpiration) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.__recoveryType) {
                this.clear();
            }
            this.__sessionExpiration = sessionExpiration;
            this.__recoveryType = authType;
            const iframeUrl = authType === 'passkey'
                ? turnkeyPasskeyRecoveryUrl
                : turnkeyEmailRecoveryUrl;
            this.__iframeStamper = new IframeStamper({
                iframeContainer,
                iframeElementId,
                iframeUrl,
            });
            yield this.__iframeStamper.init();
            this.__publicKey = this.__iframeStamper.publicKey();
            return this.__publicKey;
        });
    }
    verifyRecoveryCode(recoveryBundle, organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__iframeStamper) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                yield this.__iframeStamper.injectCredentialBundle(recoveryBundle);
                this.__client = new TurnkeyClient({
                    baseUrl: turnkeyBaseUrl,
                }, this.__iframeStamper);
                if (!organizationId || !this.__turnkeyRecoveryUserId) {
                    throw new DynamicError('Cannot proceed with your request');
                }
                if (!(yield this.isValidCode(organizationId))) {
                    throw new DynamicError('The code is invalid or expired.');
                }
            }
            catch (err) {
                logger.error('Error while verifying recovery code', err);
                if (err instanceof DynamicError) {
                    throw err;
                }
                throw new DynamicError('The code is invalid or expired.');
            }
        });
    }
    completeRecovery({ attestation, challenge, organizationId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__client || !this.__turnkeyRecoveryUserId) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                return this.__client.recoverUser({
                    organizationId,
                    parameters: {
                        authenticator: {
                            attestation: attestation,
                            authenticatorName: 'Passkey',
                            challenge,
                        },
                        userId: this.__turnkeyRecoveryUserId,
                    },
                    timestampMs: String(Date.now()),
                    type: 'ACTIVITY_TYPE_RECOVER_USER',
                });
            }
            catch (err) {
                logger.error('Error while completing recovery process', err);
                throw err;
            }
        });
    }
    addPasskeyAuthenticator({ attestation, challenge, organizationId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__client || !this.__turnkeyRecoveryUserId) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                return this.__client.createAuthenticators({
                    organizationId,
                    parameters: {
                        authenticators: [
                            {
                                attestation: attestation,
                                authenticatorName: 'Passkey',
                                challenge,
                            },
                        ],
                        userId: this.__turnkeyRecoveryUserId,
                    },
                    timestampMs: String(Date.now()),
                    type: 'ACTIVITY_TYPE_CREATE_AUTHENTICATORS_V2',
                });
            }
            catch (err) {
                logger.error('Error while creating new authenticator', err);
                throw err;
            }
        });
    }
}
const turnkeyAuthenticatorRecoveryHandler = new TurnkeyAuthenticatorRecoveryHandler();

export { turnkeyAuthenticatorRecoveryHandler };
