/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
var SignInProviderEnum;
(function (SignInProviderEnum) {
    SignInProviderEnum["Dynamic"] = "dynamic";
    SignInProviderEnum["MagicLink"] = "magicLink";
    SignInProviderEnum["Blocto"] = "blocto";
    SignInProviderEnum["Turnkey"] = "turnkey";
})(SignInProviderEnum || (SignInProviderEnum = {}));
function SignInProviderEnumFromJSON(json) {
    return SignInProviderEnumFromJSONTyped(json);
}
function SignInProviderEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function SignInProviderEnumToJSON(value) {
    return value;
}

export { SignInProviderEnum, SignInProviderEnumFromJSON, SignInProviderEnumFromJSONTyped, SignInProviderEnumToJSON };
