import { __awaiter } from '../../../_virtual/_tslib.js';
import { IframeStamper } from '@turnkey/iframe-stamper';
import { TurnkeyClient } from '@turnkey/http';
import { WebauthnStamper } from '@turnkey/webauthn-stamper';
import { getTLD, DynamicError } from '@dynamic-labs/utils';
import { logger } from '@dynamic-labs/wallet-connector-core';
import { turnkeyAuthenticatorRecoveryHandler } from '../AuthenticatorHandler/TurnkeyAuthenticatorRecoveryHandler.js';

const turnkeyBaseUrl = 'https://api.turnkey.com';
const turnkeyExportUrl = 'https://export.turnkey.com';
class ExportHandler {
    get publicKey() {
        return this.__publicKey;
    }
    clear() {
        var _a;
        (_a = this.__iframeStamper) === null || _a === void 0 ? void 0 : _a.clear();
        this.__iframeStamper = undefined;
        this.__publicKey = undefined;
        this.__turnkeyClient = undefined;
    }
    initExport(iframeContainer, iframeElementId) {
        return __awaiter(this, void 0, void 0, function* () {
            this.__iframeStamper = new IframeStamper({
                iframeContainer,
                iframeElementId,
                iframeUrl: turnkeyExportUrl,
            });
            yield this.__iframeStamper.init();
            this.__publicKey = this.__iframeStamper.publicKey();
            if (turnkeyAuthenticatorRecoveryHandler.isSessionActive()) {
                this.__turnkeyClient = turnkeyAuthenticatorRecoveryHandler.client;
            }
            else {
                let rpId = getTLD();
                if (!rpId) {
                    rpId = window.location.hostname;
                }
                const stamper = new WebauthnStamper({
                    rpId,
                });
                this.__turnkeyClient = new TurnkeyClient({
                    baseUrl: turnkeyBaseUrl,
                }, stamper);
            }
            return this.__publicKey;
        });
    }
    verifyExportWallet(exportBundle) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__iframeStamper) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                return yield this.__iframeStamper.injectWalletExportBundle(exportBundle);
            }
            catch (err) {
                logger.error('Error while verifying export wallet', err);
                throw err;
            }
        });
    }
    verifyExportPrivateKey(exportBundle) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__iframeStamper) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                return yield this.__iframeStamper.injectKeyExportBundle(exportBundle);
            }
            catch (err) {
                logger.error('Error while verifying export private key', err);
                throw err;
            }
        });
    }
    exportPrivateKey({ privateKeyId, organizationId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__iframeStamper ||
                !this.__publicKey ||
                !this.__turnkeyClient ||
                !privateKeyId) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                const newActivity = yield this.__turnkeyClient.exportPrivateKey({
                    organizationId,
                    parameters: { privateKeyId, targetPublicKey: this.__publicKey },
                    timestampMs: String(Date.now()),
                    type: 'ACTIVITY_TYPE_EXPORT_PRIVATE_KEY',
                });
                return newActivity.activity;
            }
            catch (err) {
                logger.error('Error while completing export private key process', err);
                throw err;
            }
        });
    }
    exportWallet({ walletId, organizationId, address, }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.__iframeStamper ||
                !this.__publicKey ||
                !this.__turnkeyClient ||
                !walletId) {
                throw new DynamicError('Cannot proceed with your request');
            }
            try {
                if (address) {
                    const newActivity = yield this.__turnkeyClient.exportWalletAccount({
                        organizationId,
                        parameters: { address, targetPublicKey: this.__publicKey },
                        timestampMs: String(Date.now()),
                        type: 'ACTIVITY_TYPE_EXPORT_WALLET_ACCOUNT',
                    });
                    return newActivity.activity;
                }
                const newActivity = yield this.__turnkeyClient.exportWallet({
                    organizationId,
                    parameters: { targetPublicKey: this.__publicKey, walletId },
                    timestampMs: String(Date.now()),
                    type: 'ACTIVITY_TYPE_EXPORT_WALLET',
                });
                return newActivity.activity;
            }
            catch (err) {
                logger.error('Error while completing export wallet process', err);
                throw err;
            }
        });
    }
}

export { ExportHandler };
