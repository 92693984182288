import { jsx, jsxs } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useWalletBookContext } from '@dynamic-labs/wallet-book';
import { Skeleton } from '../../../../../components/Skeleton/Skeleton.js';
import 'react';
import '../../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../../../../context/ViewContext/ViewContext.js';
import { LAST_USED_WALLET } from '../../../../../utils/constants/localStorage.js';
import '../../../../../utils/constants/colors.js';
import '../../../../../utils/constants/values.js';
import { LocalStorage } from '../../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../../shared/consts/index.js';
import '../../../../../events/dynamicEvents.js';
import '../../../../../../../_virtual/_tslib.js';
import '../../../../../context/CaptchaContext/CaptchaContext.js';
import '../../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../../../config/ApiEndpoint.js';
import '../../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import '../../../../../context/MockContext/MockContext.js';
import '../../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../../components/Alert/Alert.js';
import '../../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../../components/IconButton/IconButton.js';
import '../../../../../components/InlineWidget/InlineWidget.js';
import '../../../../../components/IsBrowser/IsBrowser.js';
import '../../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../../components/Popper/Popper/Popper.js';
import '../../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../../context/LoadingContext/LoadingContext.js';
import '../../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../../context/FooterAnimationContext/index.js';
import { WalletListItem } from '../../../../WalletList/WalletListItem/WalletListItem.js';
import { ListItemButton } from '../ListItemButton/ListItemButton.js';
import { walletListBuilder } from '../../../../../utils/functions/walletListBuilder/walletListBuilder.js';
import '../../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const WalletSimpleSignIn = ({ previewWalletsNumber, disabled = false, onshowAllWallets, }) => {
    const { t } = useTranslation();
    const { walletConnectorOptions, projectSettings, walletsFilter, signWithEmailWalletName, authMode, multiWallet, connectedWallets, } = useInternalDynamicContext();
    const { walletBook } = useWalletBookContext();
    const { numberOfWallets, walletsList } = walletListBuilder({
        authMode,
        connectedWallets,
        groupWallets: true,
        inputList: walletConnectorOptions,
        isWalletConnectList: false,
        lastUsedWalletKey: LocalStorage.getFromLS(LAST_USED_WALLET),
        loginWithEmail: true,
        multiWallet,
        numberOfWalletsToShow: previewWalletsNumber,
        searchFilter: undefined,
        signWithEmailWalletName,
        walletBook,
        walletsFilter,
    });
    return !projectSettings ? (jsx(Skeleton, { count: previewWalletsNumber + 1, className: 'login-with-email-wallet-list__skeleton' })) : (jsxs("div", { className: 'login-with-email-wallet-list__container', "data-testid": 'top-wallets-list', children: [walletsList.map((wallet, index) => (jsx(WalletListItem, { wallet: wallet, disabled: disabled }, `${wallet.key}_${index}`))), jsx(ListItemButton, { copykey: 'dyn_wallet_list.view_all', text: t('dyn_wallet_list.view_all'), onClick: onshowAllWallets, numberOfWallets: numberOfWallets, disabled: disabled })] }));
};

export { WalletSimpleSignIn };
