import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { isEmailWalletConnector, isEmailOTPWalletConnector, isBloctoConnector } from '@dynamic-labs/wallet-connector-core';
import { useCaptchaContext } from '../../../../context/CaptchaContext/CaptchaContext.js';
import 'react';
import '../../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import { LAST_USED_WALLET } from '../../../constants/localStorage.js';
import '../../../constants/colors.js';
import '../../../constants/values.js';
import { LocalStorage } from '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import { useErrorContext } from '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { isProgrammableNetworkSwitchSupported } from '../../../functions/isProgrammableNetworkSwitchSupported/isProgrammableNetworkSwitchSupported.js';
import { isSupportedNetwork } from '../../../functions/isSupportedNetwork/isSupportedNetwork.js';
import 'viem/chains';
import { useHandleWalletsToConnect } from '../../useHandleWalletsToConnect/useHandleWalletsToConnect.js';
import { useVerifyWallet } from '../../useVerifyWallet/useVerifyWallet.js';
import { showPendingConnectView } from '../helpers/showPendingConnectView.js';
import { isConnectOnly } from '../helpers/isConnectOnly.js';
import '../../../../config/ApiEndpoint.js';
import '../../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import '../../../../context/AccountExistsContext/AccountExistsContext.js';
import 'yup';
import 'react-i18next';
import '../../../../context/MockContext/MockContext.js';
import '../../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../../context/PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import '../../../../context/AccessDeniedContext/AccessDeniedContext.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../../context/LoadingContext/LoadingContext.js';
import '../../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../../context/FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useConnectAndSign = ({ shouldUpdateWallets = true, shouldCallCallback = true, } = {}) => {
    const { setView, goToInitialView } = useViewContext();
    const { engageCaptcha, getCaptchaToken } = useCaptchaContext();
    const { setErrorMessage } = useErrorContext();
    const { authMode, connectWallet, consumeNonce, displaySiweStatement, environmentId, projectSettings, networkValidationMode, setIsSingleWalletAccount, user, walletUiUtils, setIsVerificationInProgress, } = useInternalDynamicContext();
    const { handleWalletsToConnect } = useHandleWalletsToConnect();
    const verifyWallet = useVerifyWallet({
        consumeNonce,
        displaySiweStatement,
        environmentId,
        projectSettings,
        setIsSingleWalletAccount,
    });
    const handleWalletVerify = (walletConnector, publicWalletAddress, captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isEmailWalletConnector(walletConnector) ||
            isBloctoConnector(walletConnector)) {
            setView('pending-signature');
        }
        else {
            walletUiUtils.disabledConfirmationOnce();
        }
        yield verifyWallet({
            captchaToken,
            publicWalletAddress,
            walletConnector,
        });
    });
    const handleWalletVerifyError = (err) => {
        var _a;
        const userCancelled = (err === null || err === void 0 ? void 0 : err.code) === 'user-cancelled';
        if (!userCancelled)
            logger.debug(err);
        setErrorMessage((_a = err === null || err === void 0 ? void 0 : err.code) === null || _a === void 0 ? void 0 : _a.toString());
        goToInitialView();
        // Since auth failed, clear verification flag
        // But if user cancelled, clearStatesOnBackClick will have already cleared it. So avoid
        // clearing again since that would trigger a warning
        if (!userCancelled)
            setIsVerificationInProgress(false);
    };
    return ({ walletConnector, fetchPublicAddressOpts = undefined, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // Raise verification ongoing flag
        setIsVerificationInProgress(true);
        try {
            yield showPendingConnectView(walletConnector, setView);
            if (isEmailWalletConnector(walletConnector)) {
                if (isEmailOTPWalletConnector(walletConnector)) {
                    setView('email-wallet-otp-verification-view');
                }
                else if (!isBloctoConnector(walletConnector)) {
                    setView('wait-for-email-confirmation-view');
                }
            }
            const connectionResult = yield connectWallet(walletConnector, fetchPublicAddressOpts, {
                applyOnBeforeConnectSuccessConfirmation: shouldCallCallback,
                raiseOnConnect: true,
            });
            if (!(connectionResult === null || connectionResult === void 0 ? void 0 : connectionResult.address)) {
                return;
            }
            const { address } = connectionResult;
            const walletNetwork = yield walletConnector.getNetwork();
            const usesSupportedNetwork = isSupportedNetwork({
                network: walletNetwork,
                walletConnector,
            });
            const isNetworkValidationEnabledForSignIn = networkValidationMode === 'always' ||
                networkValidationMode === 'sign-in';
            if (!usesSupportedNetwork && isNetworkValidationEnabledForSignIn) {
                if (!isProgrammableNetworkSwitchSupported(walletConnector)) {
                    return setView('network-not-supported-manual');
                }
                return setView('network-not-supported');
            }
            if (isConnectOnly(user, authMode)) {
                LocalStorage.setToLS(LAST_USED_WALLET, walletConnector.key);
                if (shouldUpdateWallets) {
                    handleWalletsToConnect({ walletConnector });
                }
                return;
            }
            if ((_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.security.hCaptcha) === null || _a === void 0 ? void 0 : _a.enabled) {
                engageCaptcha({
                    authMethod: 'wallet',
                    onCaptchaSuccess: (captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
                        try {
                            yield handleWalletVerify(walletConnector, address, captchaToken);
                        }
                        catch (e) {
                            handleWalletVerifyError(e);
                        }
                    }),
                });
                return;
            }
            yield handleWalletVerify(walletConnector, address, getCaptchaToken());
        }
        catch (err) {
            handleWalletVerifyError(err);
        }
    });
};

export { useConnectAndSign };
