import { useEffect } from 'react';
import '../../../context/DynamicContext/DynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import '../../../events/dynamicEvents.js';
import '../../../../../_virtual/_tslib.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { isProgrammableNetworkSwitchSupported } from '../../functions/isProgrammableNetworkSwitchSupported/isProgrammableNetworkSwitchSupported.js';
import { isSupportedNetwork } from '../../functions/isSupportedNetwork/isSupportedNetwork.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import '../../../config/ApiEndpoint.js';
import '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import { useIsLoggedIn } from '../useIsLoggedIn/useIsLoggedIn.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useConnectedWalletsNetworkValidation = () => {
    const { connectedWallets, selectedWalletConnector, setSelectedWalletConnectorKey, setShowAuthFlow, bridgeChainsToConnect, sdkHasLoaded, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    const isLoggedIn = useIsLoggedIn();
    useEffect(() => {
        /**
         * This check was added to prevent the "wrong network" modal from appearing
         * after logging out. It seems like on log out, there is a render where the
         * connectedWallets hasn't been cleared out yet, so the logic in this hook
         * runs and ends up showing the "wrong network" modal if the connected wallet
         * is on an unsupported network. by checking sdkHasLoaded, we prevent this
         * extra popup because handleLogOut flips sdkHasLoaded to false, so on the
         * next render it will already be false.
         */
        if (!sdkHasLoaded || !isLoggedIn) {
            return;
        }
        const connectedWalletsWithInvalidNetwork = connectedWallets.filter((connectedWallet) => {
            const isChainInWalletsToConnect = bridgeChainsToConnect === null || bridgeChainsToConnect === void 0 ? void 0 : bridgeChainsToConnect.some((walletToConnectByChain) => walletToConnectByChain.chain === connectedWallet.chain);
            const isSupported = isSupportedNetwork({
                network: connectedWallet.network,
                walletConnector: connectedWallet.connector,
            });
            return !isChainInWalletsToConnect && !isSupported;
        });
        if (connectedWalletsWithInvalidNetwork.length) {
            // If we found wallets that require a change of network
            // then, one by one, in the first one we require a change of network.
            const [connectedWalletWithInvalidNetwork] = connectedWalletsWithInvalidNetwork;
            // Keeps the first wallet connectedWallet in setSelectedWalletConnectorKey
            // This is required because NetworkNotSupported uses walletConnector to display wallet icon, address etc.
            if (connectedWalletWithInvalidNetwork.connector.key !==
                (selectedWalletConnector === null || selectedWalletConnector === void 0 ? void 0 : selectedWalletConnector.key)) {
                setSelectedWalletConnectorKey(connectedWalletWithInvalidNetwork.connector.key);
            }
            const networkNotSupportedView = isProgrammableNetworkSwitchSupported(connectedWalletWithInvalidNetwork.connector)
                ? 'network-not-supported'
                : 'network-not-supported-manual';
            // Doesn't rerender if it's already true
            setShowAuthFlow(true);
            setView(networkNotSupportedView);
        }
    }, [
        sdkHasLoaded,
        connectedWallets,
        selectedWalletConnector,
        setSelectedWalletConnectorKey,
        setShowAuthFlow,
        setView,
        bridgeChainsToConnect,
        isLoggedIn,
    ]);
};

export { useConnectedWalletsNetworkValidation };
