import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { ProviderEnum } from '@dynamic-labs/sdk-api';
import 'react';
import '../../../context/DynamicContext/DynamicContext.js';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../constants/colors.js';
import '../../constants/values.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../shared/consts/index.js';
import '../../../events/dynamicEvents.js';
import '../../../context/CaptchaContext/CaptchaContext.js';
import '../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { isProviderEnabled } from '../../functions/isProviderEnabled/isProviderEnabled.js';
import { isTurnkeyEnabled } from '../../functions/isTurnkeyEnabled/isTurnkeyEnabled.js';
import 'viem/chains';
import '../../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../../context/AccountExistsContext/AccountExistsContext.js';
import { getEmailProvider, createEmailVerification } from '../../../data/api.js';
import { useEmailVerificationContext } from '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../context/MockContext/MockContext.js';
import '../useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../context/UserWalletsContext/UserWalletsContext.js';
import { useWalletItemActions } from '../useWalletItemActions/useWalletItemActions.js';
import '../../../components/Alert/Alert.js';
import '../../../components/ShadowDOM/ShadowDOM.js';
import '../../../components/IconButton/IconButton.js';
import '../../../components/InlineWidget/InlineWidget.js';
import '../../../components/IsBrowser/IsBrowser.js';
import '../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../components/Popper/Popper/Popper.js';
import '../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../components/OverlayCard/OverlayCard.context.js';
import '../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../context/PasskeyContext/PasskeyContext.js';
import '../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../context/LoadingContext/LoadingContext.js';
import '../../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../../context/WalletGroupContext/WalletGroupContext.js';
import '../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import { useConnectWithEmail } from '../useConnectWithEmail/useConnectWithEmail.js';
import '../../../context/FooterAnimationContext/index.js';
import '../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const useEmailProvider = ({ emailWalletConnector }) => {
    const { projectSettings, environmentId } = useInternalDynamicContext();
    const { setEmail: setEmailForVerification, setVerificationUUID } = useEmailVerificationContext();
    const { setView } = useViewContext();
    const { handleInstalledExtensionClick } = useWalletItemActions();
    const { connectWithEmail, loading: isEmailProviderLoading } = useConnectWithEmail({
        emailWalletConnector,
        handleConnect: handleInstalledExtensionClick,
    });
    const { providers } = projectSettings || {};
    const isInjectedWalletProviderEnabled = emailWalletConnector !== undefined;
    // this is enabled in the sense that we could use the given provider in the email form
    // for magic, as long as there are magic credentials available, then could have an email
    // address that is a magic address, even if magic was previously turned off in dashboard
    const isMagicOrBloctoEnabled = isInjectedWalletProviderEnabled &&
        (isProviderEnabled(providers || [], ProviderEnum.MagicLink) ||
            isProviderEnabled(providers || [], ProviderEnum.Blocto));
    const handleEmailOnlyLoginSubmit = (emailInput, captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
        const { email, verificationUUID } = yield createEmailVerification({
            captchaToken,
            email: emailInput,
            environmentId,
        });
        setView('login-with-email-verification');
        setEmailForVerification(email);
        setVerificationUUID(verificationUUID);
    });
    const handleMagicLinkOrBloctoSubmit = (emailInput) => __awaiter(void 0, void 0, void 0, function* () {
        yield connectWithEmail(emailInput);
    });
    const submitEmail = (emailInput, captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
        if (isMagicOrBloctoEnabled) {
            yield handleMagicLinkOrBloctoSubmit(emailInput);
            return;
        }
        yield handleEmailOnlyLoginSubmit(emailInput, captchaToken);
    });
    const handleEmailSubmitWithOptionalCaptcha = (emailInput, captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
        if ((emailWalletConnector !== undefined ||
            isTurnkeyEnabled(projectSettings)) &&
            (projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk.confirmEmailProviderForVerify)) {
            // see: https://linear.app/dynamic-labs/issue/DYN-2517
            // if confirmWalletTransfers setting is true, check api first to confirm emailProvider
            const { emailProvider } = yield getEmailProvider({
                email: emailInput,
                environmentId,
            });
            // For v0.19 we will support emailOnly and Dynamic as email providers
            // For v0.20/v1 we need to remove emailOnly and leave Dynamic as the only one email provider
            // Ticket: https://linear.app/dynamic-labs/issue/GVTY-213/remove-emailonly-as-a-provider-from-sdk
            if (emailProvider === ProviderEnum.EmailOnly ||
                emailProvider === ProviderEnum.Dynamic) {
                yield handleEmailOnlyLoginSubmit(emailInput, captchaToken);
                return;
            }
        }
        yield submitEmail(emailInput, captchaToken);
    });
    return {
        handleEmailSubmitWithOptionalCaptcha,
        isEmailProviderLoading,
    };
};

export { useEmailProvider };
