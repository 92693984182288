import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { Typography } from '../Typography/Typography.js';
import '@dynamic-labs/sdk-api';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../utils/constants/colors.js';
import '../../utils/constants/values.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import { toolkitZIndex } from '../../shared/consts/index.js';
import '../../context/DynamicContext/DynamicContext.js';
import '../../events/dynamicEvents.js';
import '../../../../_virtual/_tslib.js';
import '../../context/CaptchaContext/CaptchaContext.js';
import '../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import { isEmailProviderEnabled } from '../../utils/functions/isEmailProviderEnabled/isEmailProviderEnabled.js';
import 'viem/chains';
import '../../context/AccessDeniedContext/AccessDeniedContext.js';
import '../../context/AccountExistsContext/AccountExistsContext.js';
import '../../config/ApiEndpoint.js';
import '../../context/EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../context/ThemeContext/ThemeContext.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../context/MockContext/MockContext.js';
import '../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../context/UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../context/UserWalletsContext/UserWalletsContext.js';
import '../Alert/Alert.js';
import { ShadowDOM } from '../ShadowDOM/ShadowDOM.js';
import '../IconButton/IconButton.js';
import '../InlineWidget/InlineWidget.js';
import '../IsBrowser/IsBrowser.js';
import '../MenuList/Dropdown/Dropdown.js';
import '../Transition/ZoomTransition/ZoomTransition.js';
import '../Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../Transition/OpacityTransition/OpacityTransition.js';
import '../Popper/Popper/Popper.js';
import '../Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../OverlayCard/OverlayCard.context.js';
import { ThemeSwitch } from './components/ThemeSwitch.js';
import { PrimaryColorInput } from './components/PrimaryColorInput.js';
import { AuthModeSwitch } from './components/AuthModeSwitch.js';
import { LoginWithEmailSwitch } from './components/LoginWithEmailSwitch.js';
import { LoginWithEmailViewDisplayPriorityOrderSwitch } from './components/LoginWithEmailViewDisplayPriorityOrderSwitch.js';
import { TemplateSelect } from './components/TemplateSelect.js';
import { MultiWalletSwitch } from './components/MultiWalletSwitch.js';
import '../PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../context/PasskeyContext/PasskeyContext.js';
import '../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../context/LoadingContext/LoadingContext.js';
import '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../context/WalletGroupContext/WalletGroupContext.js';
import '../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../context/FooterAnimationContext/index.js';
import '../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';
import { useInternalDynamicContext } from '../../context/DynamicContext/useDynamicContext/useInternalDynamicContext.js';

const Toolkit = () => {
    const { projectSettings, user, showAuthFlow, showDynamicUserProfile, toolkitEnabled, authMode, setAuthMode, multiWallet, setMultiWallet, } = useInternalDynamicContext();
    const isToolkitVisible = useMemo(() => {
        if (!toolkitEnabled)
            return false;
        return showDynamicUserProfile || showAuthFlow;
    }, [showAuthFlow, showDynamicUserProfile, toolkitEnabled]);
    if (!isToolkitVisible)
        return null;
    return (jsx(ShadowDOM, { dataTestId: 'toolkit-shadowdom', className: 'toolkit-shadowdom', zIndex: toolkitZIndex, children: jsxs("div", { className: 'toolkit__container', children: [jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Theme" }), jsx(ThemeSwitch, {})] }), jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Template" }), jsx(TemplateSelect, {})] }), jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Primary Color" }), jsx(PrimaryColorInput, {})] }), !user && (jsxs(Fragment, { children: [jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Auth mode" }), jsx(AuthModeSwitch, { authMode: authMode, setAuthMode: setAuthMode })] }), jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Multi Wallet" }), jsx(MultiWalletSwitch, { multiWallet: multiWallet, setMultiWallet: setMultiWallet })] })] })), isEmailProviderEnabled((projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers) || []) && !user && (jsxs(Fragment, { children: [jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Log in with Email" }), jsx(LoginWithEmailSwitch, {})] }), jsxs("div", { className: 'toolkit__switcher', children: [jsx(Typography, { className: 'switcher__copy', as: 'h2', variant: 'body_normal', children: "Email vs Wallet priority" }), jsx(LoginWithEmailViewDisplayPriorityOrderSwitch, {})] })] }))] }) }));
};

export { Toolkit };
