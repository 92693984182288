import { useRef, useMemo } from 'react';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '../../../../utils/constants/colors.js';
import '../../../../utils/constants/values.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '@dynamic-labs/utils';
import '../../../../shared/consts/index.js';
import '../../../../events/dynamicEvents.js';
import '../../../../../../_virtual/_tslib.js';
import '../../DynamicContext.js';
import '../../../CaptchaContext/CaptchaContext.js';
import '../../../ErrorContext/ErrorContext.js';
import '@dynamic-labs/multi-wallet';
import 'viem/chains';
import '../../../AccessDeniedContext/AccessDeniedContext.js';
import '../../../AccountExistsContext/AccountExistsContext.js';
import '../../../../config/ApiEndpoint.js';
import '../../../EmailVerificationContext/EmailVerificationContext.js';
import 'react-dom';
import '../../../ThemeContext/ThemeContext.js';
import { useIsLoggedIn } from '../../../../utils/hooks/useIsLoggedIn/useIsLoggedIn.js';
import '@dynamic-labs/types';
import 'yup';
import 'react-i18next';
import '../../../MockContext/MockContext.js';
import '../../../../utils/hooks/useUserUpdateRequest/useUpdateUser/useUpdateUser.js';
import '../../../UserFieldEditorContext/UserFieldEditorContext.js';
import '@dynamic-labs/rpc-providers';
import '../../../UserWalletsContext/UserWalletsContext.js';
import '../../../../components/Alert/Alert.js';
import '../../../../components/ShadowDOM/ShadowDOM.js';
import '../../../../components/IconButton/IconButton.js';
import '../../../../components/InlineWidget/InlineWidget.js';
import '../../../../components/IsBrowser/IsBrowser.js';
import '../../../../components/MenuList/Dropdown/Dropdown.js';
import '../../../../components/Transition/ZoomTransition/ZoomTransition.js';
import '../../../../components/Transition/SlideInUpTransition/SlideInUpTransition.js';
import '../../../../components/Transition/OpacityTransition/OpacityTransition.js';
import '../../../../components/Popper/Popper/Popper.js';
import '../../../../components/Popper/PopperContext/PopperContext.js';
import 'react-focus-lock';
import 'formik';
import 'i18next';
import '../../../../components/OverlayCard/OverlayCard.context.js';
import '../../../../components/PasskeyCreatedSuccessBanner/PasskeyCreatedSuccessBanner.js';
import '../../../PasskeyContext/PasskeyContext.js';
import '../../../../views/WalletList/WalletList.js';
import 'qrcode';
import '@hcaptcha/react-hcaptcha';
import '../../../LoadingContext/LoadingContext.js';
import '../../../SocialRedirectContext/SocialRedirectContext.js';
import '../../../WalletGroupContext/WalletGroupContext.js';
import '../../../../widgets/DynamicBridgeWidget/views/WalletsView/components/SecondaryWallets/SecondaryWallets.js';
import '../../../FooterAnimationContext/index.js';
import '../../../../widgets/DynamicWidget/components/DynamicWidgetHeader/DynamicWidgetHeader.js';
import '../../../../widgets/DynamicWidget/context/DynamicWidgetContext.js';
import '../../../../components/UserProfile/parts/UserProfileField/components/VerifiedEmailIcon/VerifiedEmailIcon.js';
import '@dynamic-labs/viem-utils';
import '../../../../views/TransactionConfirmationView/helpers/transactionErrorMessage.js';
import '../../../../utils/hooks/useTransactionWithGasPrice/useTransactionWithGasPrice.js';
import '../../../../widgets/DynamicWidget/views/ManagePasskeysWidgetView/PasskeyCard/PasskeyCard.js';

const useOverrides = (dynamicContext) => {
    const context = useRef(dynamicContext);
    const isLoggedIn = useIsLoggedIn();
    context.current = dynamicContext;
    return useMemo(() => ({
        setAuthMode: (value) => {
            const { setAuthMode } = context.current;
            // If there's a connected wallet, log an error and ignore
            if (isLoggedIn) {
                logger.error("Calling DynamicContext's setAuthMode method when the user already has a wallet is a noop");
                return;
            }
            return setAuthMode(value);
        },
    }), [isLoggedIn]);
};

export { useOverrides };
